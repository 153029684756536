import { Injectable } from '@angular/core';

import { Http, Headers, URLSearchParams } from '@angular/http';
import { HttpService } from '../security/http.service';
import { Appsettings} from '../../configuration/appsettings';

import { Studen } from '../../models/courses/student.model';
@Injectable({
  providedIn: 'root'
})
export class StudenService {

  constructor( private _httpService: HttpService ) { }

  getAll( groupId: number) {
    // const API_URL = `${Appsettings.API_ENDPOINT_FULL}/students/${groupId}/getbygroup`;
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/students/getbygroup?studentGroupId=${groupId}&examScheduleId=0`;

    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }


  save( ids: number[], groupId: number ) {
    const statusId = 1;
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/students/sendinvitation/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    const params = {
      'studentGroupId': groupId,
      'userIds': ids,
    };
    // let body = `studenGroupId=${groupId}&userIds=${ids}`;

   return this._httpService.post(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  update( course ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/studentgroups/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');



   return this._httpService.put(API_URL, JSON.stringify( course ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  getStudenByEmail( email: string, roleId: number, courseId: number) {
    //  const emai = 'ramadrigalp@integraitsoluciones.com';
    // tslint:disable-next-line:max-line-length
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/users/getbymailrolecourse?partialMail=${email}&roleid=${roleId}&courseId=${courseId}`;

    return this._httpService.get(API_URL )
                            .map( res => res.json());

  }

  getCoursStudent(blockId: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/students/courses?blockId=${blockId}`;

    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }

  confirmInvitation(codigo: string) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/students/confirminvitation?codigo=${codigo}`;

    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }

  // Obtiene todos los glosarios del alumno por libro
  getAllGlossary( nodeId: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries/student?nodeId=${nodeId}`;

    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }
}
