import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Servicios
import { ContentTeacherService } from '../../../service/service.index';
// Modelos
import { TeacherContent } from '../../../models/courses/teacherContent.model';
import { TeacherContentType } from '../../../models/courses/teacherContentType.model';

import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-teacher-content-assign',
  templateUrl: './teacher-content-assign.component.html',
  styleUrls: ['./teacher-content-assign.component.scss']
})
export class TeacherContentAssignComponent implements OnInit {

  contentsTeacher: TeacherContent[] = [];
  contentsData: any[] = [];
  visible: boolean;
  assignIds: number[] = [];
  active: number;
  recourseType: TeacherContentType[] = [];

  lessonId: number;
  working: boolean;

   // Obtenemos los estudiantes
  @Input('contents') set contents ( value) {
    this.contentsData = value;
    this.getAllContent( this.contentsData );
  }

  // Obtenemos el id de la clase
  @Input('lessonId') set idLesson ( value ) {
    this.lessonId = value;
  }

  // Retornamos respuesta del cierre del modal
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(  private _srvContent: ContentTeacherService) {
    this.visible = false;
    this.getTypeContent();
   }

  ngOnInit() {
  }

  getAllContent( content: any[]) {
    this.contentsTeacher = this.contentsData.filter( item => item.resourceTypeId === this.active );
    if ( this.contentsTeacher.length > 0) {
      this.visible = true;
    }
  }

  // Gauarda los id de los contenidos seleccionados
  onChangeAssing( contentId: number, content, event) {

    let count = 0;
    for ( let c of this.contentsData) {
      if ( c.id === contentId) {
        if ( this.contentsData[count].selected ) {
          this.contentsData[count].selected = false;
        } else {
          this.contentsData[count].selected = true;
        }
      }
      count++;
    }
  }

  // Obtiene el listado de los tipos de contenido
  getTypeContent() {
    this._srvContent.getTypeContent().subscribe( result => {
      this.recourseType = [];
      if ( result.success ) {
        let data = result.data;
        for ( let t of data ) {
          let contentType = new TeacherContentType();
          contentType.id = t.id;
          contentType.description = t.description;

          this.recourseType.push( contentType );
        }
        this.active = this.recourseType[0].id;
      }
    });
  }

  changeFolder( folderId, content ) {
    this.contentsTeacher = [];
    this.active = folderId;
    this.visible = false;
    this.contentsTeacher = this.contentsData.filter( item => item.resourceTypeId === folderId );
    if ( this.contentsTeacher.length > 0) {
      this.visible = true;
    }
  }

  // Guarda la asignacion de archivos a la clase
  onSaveAssing() {
    this.working = true;
    for ( let c of this.contentsData) {
      if ( c.selected) {
        this.assignIds.push( c.id);
      }
    }

    if ( this.assignIds.length > 0 ) {
      this._srvContent.saveContentSection( this.assignIds, this.lessonId ).subscribe( result => {
        if ( result.success) {
          swal( Appsettings.APP_NAME , result.message , 'success');
          setTimeout(() => {
            this.closeModal.emit( true  );
          }, 1000);
        } else {
          swal( Appsettings.APP_NAME , result.message , 'error');
        }
        this.working = false;
      });
    } else {
      this.working = false;
      swal( Appsettings.APP_NAME , 'Debes selecionar minimo un elemento.', 'error');
    }
  }

}
