import { Component, OnInit, Input } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';

// Modelos
import { ContentBook } from '../../../models/content/contentBook.model';
import { PaginarionLocal } from '../../../models/general/pagination-local.model';
import { PagerService } from '../../../service/service.index';

@Component({
  selector: 'app-show-interactive',
  templateUrl: './show-interactive.component.html',
  styleUrls: ['./show-interactive.component.scss']
})
export class ShowInteractiveComponent implements OnInit {
  working: boolean;
  interactive: ContentBook[] = [];
  interactiveData: ContentBook[] = [];
  showInteractive: boolean;
  showContent: boolean;
  pager: PaginarionLocal ;
  sizePage: number = Number(Appsettings.DEFAULT_SIZE_PAGE);
  // Actualiza el valor de la variable cuando su valor cambia en el componente
    @Input('dataInteractive') set dataVideos(value: ContentBook[]) {
      this.interactive = value;
      if ( this.interactive.length > 0) {
        this.setPage(1);
      }
      this.showInteractive = ( this.interactive.length > 0 ) ? true : false;
    }
      // Parametro para mostrar listado de recursos del maestro
  @Input('showList') set listResource ( value: boolean ) {
    this.showContent = value;
  }
  constructor(  private _srvPager: PagerService ) {
    this.working = false;
  }

  ngOnInit() {

  }
 // Realizar paginación local
 setPage(page: number) {
   if ( !this.showContent) {
    this.interactive = this.interactive.filter( x => x.contentTypeId === 2);
   }
  this.pager = this._srvPager.getPager(this.interactive.length, page, this.sizePage);
  this.interactiveData = this.interactive.slice(this.pager.startIndex, this.pager.endIndex + 1);
}
}
