import { Injectable } from '@angular/core';

import { Http, Headers, URLSearchParams } from '@angular/http';
import { HttpService } from '../security/http.service';
import { Appsettings} from '../../configuration/appsettings';

// Modelos
import { Glossary } from '../../models/courses/glossary.model';
import { Word } from '../../models/courses/word.model';
@Injectable({
  providedIn: 'root'
})
export class GlossaryService {

  constructor(  private _httpService: HttpService) { }
  // Obtiene todos los glosarios de un maestro
  getAll(sizePage: number, currentPage: number, filter: string) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries/all`;
    const params = new URLSearchParams();

    params.append('pageSize', sizePage.toString());
    params.append('currentPage', currentPage.toString());
    params.append('filter', filter);

    return this._httpService
        .get(API_URL, { params })
        .map(res => res.json());
  }

  // Agrega un nuevo glosario
  save(glossary: Glossary) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    return this._httpService.post(API_URL, JSON.stringify( glossary ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Obtiene un glosario por ID
  getGlossaryById( glossaryId: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries?id=${glossaryId}`;


    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }

  // Actualiza un glosario
  update( glossary: Glossary ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    return this._httpService.put(API_URL, JSON.stringify( glossary ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Asocia un nodo a un glosario
  addNode( nodeId, glossaryId: number ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries/${glossaryId}/node`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    let node = {
      'GlossaryId' : glossaryId,
      'NodeId': nodeId
    };

    return this._httpService.post(API_URL, JSON.stringify( node ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Asocia una concepto a un glosariso
  addWord( glossaryId: number, word) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries/${glossaryId}/word`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');


    return this._httpService.post(API_URL, JSON.stringify( word ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Elimina un termino
  deleteWord( glossaryId: number, wordId: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries/${glossaryId}/word?id=${wordId}`;


    return this._httpService
        .delete(API_URL)
        .map(res => res.json());
  }

  // Elimina un glosario
  deleteGlossary( glossaryId: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries?id=${ glossaryId}`;


    return this._httpService
        .delete(API_URL)
        .map(res => res.json());
  }

  // Elimina un nodo del glosario
  deleteNodoGlossary( glossaryId: number, nodeId: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries/${glossaryId}/node?nodeId=${nodeId}`;


    return this._httpService
        .delete(API_URL)
        .map(res => res.json());
  }

  // Actualiza un concepto
  updateWord( glossary: Word, glossaryId: number) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/glossaries/${ glossaryId}/word`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    return this._httpService.put(API_URL, JSON.stringify( glossary ), { headers: HEADERS } )
                           .map( res => res.json());
  }


  // save( group: Group ) {
  //   let API_URL = `${Appsettings.API_ENDPOINT_FULL}/studentgroups/`;
  //   const HEADERS = new Headers();
  //   HEADERS.append('Content-Type', 'application/json');


  //  return this._httpService.post(API_URL, JSON.stringify( group ), { headers: HEADERS } )
  //                          .map( res => res.json());

  // }

  // update( course ) {
  //   let API_URL = `${Appsettings.API_ENDPOINT_FULL}/studentgroups/`;
  //   const HEADERS = new Headers();
  //   HEADERS.append('Content-Type', 'application/json');



  //  return this._httpService.put(API_URL, JSON.stringify( course ), { headers: HEADERS } )
  //                          .map( res => res.json());

  // }

  // getStudenByEmail( email: string) {
  //   //  const emai = 'ramadrigalp@integraitsoluciones.com';
  //   const API_URL = `${Appsettings.API_ENDPOINT_FULL}/users/${email}/getbypartialmail`;

  //   return this._httpService.get(API_URL )
  //                           .map( res => res.json());

  // }

  // getStudenByCourse( courseId: number ) {
  //   const API_URL = `${Appsettings.API_ENDPOINT_FULL}/students/${courseId}/getbygroup`;

  //   return this._httpService.get(API_URL )
  //                           .map( res => res.json());
  // }
}
