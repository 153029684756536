import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-get-file',
  templateUrl: './dialog-get-file.component.html',
  styleUrls: ['./dialog-get-file.component.scss']
})
export class DialogGetFileComponent implements OnInit {

  task: any[] = [];
  constructor(  private dialogRef: MatDialogRef<DialogGetFileComponent>,
                @Inject(MAT_DIALOG_DATA) public data) {
                this.task = data.task;
                }

  ngOnInit() {
  }

  onCloseConfirm( event ) {
    this.dialogRef.close( event );
  }
}
