import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value.trim();
     let initials = value.concat(' ').replace(/([a-zA-Z-0-9]{0,} )/g, function(match) {
      return (match.trim()[0]);
    });

    return initials;
  }

}
