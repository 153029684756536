import { Component, OnInit, OnDestroy } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart, NavigationError, NavigationCancel, RoutesRecognized } from "@angular/router";
import { AngularAgoraRtcService, Stream } from 'angular-agora-rtc';
import { PlatformLocation } from '@angular/common';

// SERVICES
import { GeneralConferencesService, StorageService, UserService } from '../../../service/service.index';

// COMPONENT SERVICES
import { SitemapService } from '../../../core/sitemap/sitemap.service';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { GeneralConferences } from '../../../models/videoConference/video-conference-model';

// DIALOGS
import swal from 'sweetalert2';
import { element } from '@angular/core/src/render3/instructions';
import { isUndefined } from 'util';


@Component({
  selector: 'app-video-conference-t',
  templateUrl: './video-conference-t.component.html',
  styleUrls: ['./video-conference-t.component.scss']
})
export class VideoConferenceTComponent implements OnInit, OnDestroy {

  working: boolean;
  generalConferences: GeneralConferences[] = [];
  idConference: string;
  ynConferencia: boolean = true;
  URL: string;
  validationComponent: string = '';
  localStream: Stream;
  remoteCalls: any = [];
  cameraId: string;
  arrayGuestList: any = [];
  userNameVideoConference: string;
  agoraId: number;


  //Controles boolean
  ynMicrophone: boolean = false;
  ynCamera: boolean = false;
  ynCall: boolean = true;
  ynShowControls: boolean = false;
  ynDisable: boolean = false;


  constructor(public _srvSiteMap: SitemapService,
    private _srvHeader: AdminHeaderService,
    private _srvVideoConference: GeneralConferencesService,
    private _storageSrv: StorageService,
    private _router: ActivatedRoute,
    private _routerEvent: Router,
    private agoraService: AngularAgoraRtcService,
    public dialog: MatDialog,
    private _location: PlatformLocation) {

    this.agoraService.createClient();
    this.working = false;
    this._srvSiteMap.setSiteMap([{
      'description': 'Contenido',
      'isCurrent': false
    },
    {
      'description': 'Eventos',
      'isCurrent': true
    }]);

    this.idConference = this._router.snapshot.paramMap.get("id");
    this.URL = window.location.href;
    this.ynDisable = false;

    _routerEvent.events.subscribe((val) => {
      console.log(val)
    });

  }

  ngOnInit() {
    this._srvHeader.setTitle('Administrar videoconferencias');
    this.generalConferences.push( new GeneralConferences() );
    this.getVideoConference();



    if (this._storageSrv.rol === "Administrador") {
    }

  }

  // Obtiene los datos del servicio
  getVideoConference(): void {

    // this.working = true;
    this._srvVideoConference.getAll().subscribe(
      res => {
        this.generalConferences = [];

        this.userNameVideoConference = res.data.nameuser;
        this.generalConferences = [];
        for (let d of res.data.video) {
          let conference = new GeneralConferences();

          conference.id = d.id;
          conference.title = d.title;
          conference.information = d.information;
          conference.dateTimeVideoConference = new Date(d.dateTimeVideoConference);
          conference.duration = d.duration;
          conference.updateDate = new Date(d.updateDate);
          conference.registerDate = new Date(d.registerDate);;
          conference.guests = d.guests;
          conference.commentary = d.commentary;
          conference.statusId = d.statusId;
          conference.RoomVideoConference = d.roomVideoConference;
          conference.externalGuest = d.externalGuest;
          conference.groups = d.studentGroupId;
          conference.course = d.courseId;
          conference.guestMail = d.guestMail;
          conference.fullName = d.fullName;
          conference.emailsExternal = d.emailsExternal;


          this.generalConferences.push(conference);
        }

        this.generalConferences = this.generalConferences.filter(x => x.RoomVideoConference === this.URL);

        if (this.generalConferences.length > 0) {
          this.ynConferencia = false;
        }
        else {
          this.ynConferencia = false;
        }


        if (this.generalConferences[0].statusId === 2) {
          this.ynConferencia = false;
          this.validationComponent = 'Da clic en el icono de la camara para iniciar';
        } else if (this.generalConferences[0].statusId === 1) {
          this.ynConferencia = true;
          this.validationComponent = 'La videoconferencia aun no ha sido validada';
        } else if (this.generalConferences[0].statusId === 3) {
          this.ynConferencia = true;
          this.validationComponent = 'La videoconferencia fue rechazada';
        } else if (this.generalConferences[0].statusId === 4) {
          this.ynConferencia = true;
          this.validationComponent = 'La videoconferencia ya ha terminado';
        } else {
          this.ynConferencia = true;
        }


        let guestList = this.generalConferences[0].guestMail;

        if (guestList === "") {
          guestList = this.generalConferences[0].emailsExternal;
        }

        let guestListPush = guestList.split(',');

        for (let ans of guestListPush) {
          let trimAns = ans.trim();
          this.arrayGuestList.push({
            name: 'Invitado externo',
            email: trimAns
          });
        }

        if (res.data) {
        }
        this.working = false;
      },
      err => {
        this.working = false;
      }
    );
  }

  startCall() {
    this.ynCall = !this.ynCall;
    this.ynShowControls = !this.ynShowControls;
    this.agoraService.client.join(null, this.idConference, null, (uid) => {
      this.localStream = this.agoraService.createStream(uid, true, null, null, true, false);
      this.localStream.setVideoProfile('720p_3');
      this.localStream['username'] = this._storageSrv.userName;
      this.subscribeToStreams();
    });
  }

  private subscribeToStreams() {
    this.localStream.on("accessAllowed", () => {
    });
    // The user has denied access to the camera and mic.
    this.localStream.on("accessDenied", () => {
    });

    this.localStream.init(() => {
      this.localStream.play('agora_local');
      this.agoraService.client.publish(this.localStream, function (err) {
      });
      this.agoraService.client.on('stream-published', function (evt) {
      });
    }, function (err) {
      console.log("getUserMedia failed", err);
    });

    this.agoraService.client.on('error', (err) => {
      if (err.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.agoraService.client.renewChannelKey("", () => {
        }, (err) => {
        });
      }
    });

    this.agoraService.client.on('stream-added', (evt) => {
      const stream = evt.stream;
      this.agoraService.client.subscribe(stream, (err) => {
        console.log("Subscribe stream failed", err);
      });
    });

    this.agoraService.client.on('stream-subscribed', (evt) => {
      const stream = evt.stream;
      if (!this.remoteCalls.includes(`agora_remote${stream.getId()}`)) this.remoteCalls.push(`agora_remote${stream.getId()}`);
      setTimeout(() => stream.play(`agora_remote${stream.getId()}`), 2000);
    });

    this.agoraService.client.on('stream-removed', (evt) => {
      const stream = evt.stream;
      stream.stop();
      this.remoteCalls = this.remoteCalls.filter(call => call !== `#agora_remote${stream.getId()}`);
      console.log(`Remote stream is removed ${stream.getId()}`);
      console.log("-------------------------------stream-removed--------------------------------------------");
    });

    this.agoraService.client.on('peer-leave', (evt) => {
      const stream = evt.stream;
      if (stream) {
        this.agoraId = stream.getId();
        document.getElementById('agora_remote' + this.agoraId).style.display = 'none';
        console.log("agora remote" + this.agoraId);
        // stream.stop();
        // this.remoteCalls = this.remoteCalls.filter(call => call === `#agora_remote${stream.getId()}`);
        console.log(`${evt.uid} left from this channel`);
        console.log("-------------------------------left from this channel --------------------------------------------");
      }
    });
  }

  leave() {
    swal({
      title: '¿Estás seguro? <br/> ¡Esta a punto de salir de la videoconferencia!',
      text: 'Para volver a ingresar a la videoconferencia es necesario refrescar la página.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, salir ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if (result.value) {
        this.working = true;
        this.ynCall = !this.ynCall;
        this.ynShowControls = !this.ynShowControls;
        this.ynDisable = true;
        document.getElementById('player_' + this.localStream.streamId).style.display = 'none';
        swal(Appsettings.APP_NAME, 'Gracias por participar.', 'success');
        this.agoraService.client.leave(() => {
          this.localStream.disableVideo();
          this.localStream.muteAudio();
          this.localStream.close();
          this.changeSrtatatusVideoConference();
        }, (err) => {
        });
      }
    });

  }
  audioDisabled() {
    this.ynMicrophone = !this.ynMicrophone;

    this.localStream.muteAudio();
  }
  audioEnabled() {
    this.ynMicrophone = !this.ynMicrophone;
    this.localStream.enableAudio();
  }
  videoDisabled() {
    this.ynCamera = !this.ynCamera;
    this.localStream.disableVideo();
  }
  videoEnabled() {
    this.ynCamera = !this.ynCamera;
    this.localStream.enableVideo();
  }

  ngOnDestroy() {
    if (!this.localStream) {
      return;
    }
    else {
      this.agoraService.client.leave(() => {
        this.ynCall = !this.ynCall;
        this.ynShowControls = !this.ynShowControls;
        this.ynDisable = true;
        document.getElementById('player_' + this.localStream.streamId).style.display = 'none';
        this.localStream.disableVideo();
        this.localStream.muteAudio();
        this.localStream.close();
      }, (err) => {
      });
    }
  }
changeSrtatatusVideoConference() {
if ( this._storageSrv.rol === 'Profesor') {
  this.generalConferences[0].statusId = 4;
  this._srvVideoConference.update( this.generalConferences[0] ).subscribe( res => {
    this.working = false;
  });
}else {
  this.working = false;
}
}
}
