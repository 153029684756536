import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

import 'rxjs/add/operator/debounceTime';
import { MatPaginator, MatTableDataSource } from '@angular/material';

// Servicios
import { GroupService, StudenService } from '../../service/service.index';

// Modelos
import { Studen } from '../../models/courses/student.model';

import swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-invitation',
  templateUrl: './dialog-invitation.component.html',
  styleUrls: ['./dialog-invitation.component.scss']
})

export class DialogInvitationComponent implements OnInit {

  working: boolean;
  workingProgess: boolean;
  students: Studen[] = [];
  stateCtrl = new FormControl();
  filteredStates: any[] = [];
  ids: any[] = [];
  groupId: number;
  courseId: number;
  studentsSelect: Studen[] = [];
  textSearch: string;

  // dataSource = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialogRef: MatDialogRef<DialogInvitationComponent>,
              private _srvGroup: GroupService,
              private _srvStuden: StudenService,
              @Inject(MAT_DIALOG_DATA) public data) {

    this.groupId = data.groupId;
    this.courseId = data.courseId;
    const roleId = 3;
    this.working = false;

    this.stateCtrl.valueChanges.debounceTime(400).subscribe( item => {
      this.textSearch = item;

      if ( item.length > 0 ) {

        this.working = true;
        this.filteredStates = [];
        this._srvStuden.getStudenByEmail(item, roleId, this.courseId).subscribe( result => {
          if ( result.success ) {
            for ( let s of result.data) {

              let seleted = this.studentsSelect.filter( x => x.id === s.id)[0];

              if ( seleted === undefined ) {
                let student = new Studen();
                student.id = s.id;
                student.fullName = s.fullName;
                student.email = s.email;

                this.filteredStates.push( student );
              }
            }
          }
          this.working = false;
        }, err => {
          this.working = false;
        });
      }
    });
  }

  ngOnInit() {
  }

  onCloseConfirm() {
    this.dialogRef.close( null );
  }

  onSubmit() {
    this.workingProgess = true;
    this.ids = [];
    for (let e of this.studentsSelect) {
      this.ids.push( e.id);
    }
    this._srvStuden.save( this.ids, this.groupId).subscribe( result => {
      if ( result.success) {
        swal('Exito!', 'Las invitaciones se enviaron correctamente.', 'success' );
        this.dialogRef.close( this.studentsSelect );
        this.studentsSelect = [];
      }
      this.workingProgess = false;
    }, err => {
      this.workingProgess = false;
    });
  }

  selectItem(states) {
    this.stateCtrl.setValue('');

    let student = new Studen();

    student.id = states.id;
    student.fullName = states.fullName;
    student.email = states.email;

    this.studentsSelect.push( student );
  }

  deleteItem(folder, event) {
    const tagName = event.target.tagName.toLowerCase();
    if ( tagName !== 'button') {
      let result;
      result = this.studentsSelect.findIndex( elem =>  elem.fullName === folder.fullName );
      this.studentsSelect.splice( result, 1);
    }
  }
}
