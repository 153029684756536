export class Group {
  id: number;
  description: string;
  status: number;
  courseId: number;
  examScheduleTotal?: number;
  selected: boolean;
  constructor ( _id: number, _description: string, _status: number, _courseId: number) {
    this.id = _id;
    this.description = _description;
    this.status = _status;
    this.courseId = _courseId;
    this.selected = false;
  }
}
