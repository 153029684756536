import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';

// Modelos
import { BookTeacher } from '../../../models/catalogs/bookTeacher.model';
// Servicios
import { BookService } from '../../../service/service.index';
import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-book-admin',
  templateUrl: './book-admin.component.html',
  styleUrls: ['./book-admin.component.scss']
})

export class BookAdminComponent implements OnInit {
  frmBook: FormGroup;
  working: boolean;
  authors: any[] = [];
  optionsChecked: any[] = [];
  // Retornamos el cierre del modal
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(  private _srvBook: BookService,
                @Inject(MAT_DIALOG_DATA) public data: any ) {
    this.getAllAuthors();
    let book = data.book;
    this.working = false;
    this.optionsChecked = [];
    for ( let o of book.authorId ) {
      this.optionsChecked.push( o.id );
    }

    this.frmBook = new FormGroup({
      id: new FormControl( book.id ),
      name: new FormControl( book.name , [Validators.required]),
      description: new FormControl( book.description , [Validators.required]),
      isbn: new FormControl(book.isbn , [Validators.required]),
      codebar: new FormControl( book.codebar , [Validators.required]),
      edition: new FormControl( book.edition , [Validators.required]),
      authorId: new FormControl( book.authorId, [Validators.required]),
    });
  }

  ngOnInit() {
  }

  onSave() {
    this.working = true;
    let book = new BookTeacher();
    book.id = this.frmBook.controls['id'].value;
    book.name = this.frmBook.controls['name'].value;
    book.description = this.frmBook.controls['description'].value;
    book.isbn = this.frmBook.controls['isbn'].value;
    book.codebar = this.frmBook.controls['codebar'].value;
    book.edition = this.frmBook.controls['edition'].value;
    book.authorId = this.frmBook.controls['authorId'].value;
    book.active = true;

    if ( book.id > 0) {
      this.updateBook( book );
    } else {
      this.saveBook( book );
    }

  }

  // Guarda un libro nuevo
  saveBook( book: BookTeacher) {
    this._srvBook.save( book ).subscribe( result => {
      if ( result.success ) {
        swal( Appsettings.APP_NAME, result.message , 'success');
        setTimeout(() => {
          this.closeModal.emit( true  );
        }, 2000);
      } else {
        swal( Appsettings.APP_NAME , result.message , 'error');
      }
      this.working = false;
    });
  }

  // Edita un libro nuevo
  updateBook( book: BookTeacher) {
    this._srvBook.update( book ).subscribe( result => {
      if ( result.success ) {
        swal( Appsettings.APP_NAME, result.message , 'success');
        setTimeout(() => {
          this.closeModal.emit( true  );
        }, 2000);
      } else {
        swal( Appsettings.APP_NAME , result.message , 'error');
      }
      this.working = false;
    });
  }

  // Obtiene todos los autores
  getAllAuthors(): void {
    this._srvBook.getAllAuthors().subscribe( result => {
      if ( result.success ) {
        for ( let a of result.data ) {
          this.authors.push( {
            id: a.id,
            name: a.fullName
          } );
        }
      }
    });
  }

}
