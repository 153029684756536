export class Book {
  id: number;
  codebar: string;
  description: string;
  isbn: string;
  name: string;
  autor?: string;
}


