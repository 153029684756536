import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';


@Injectable({
  providedIn: 'root'
})
export class VimeoService {

  constructor(private http: Http) {
  }

  // Obtener los datos de un video desde vimeo
  getVideo(id: string) {
    let apiUrl = `https://api.vimeo.com/videos/${id}`;
    let headers = new Headers();
    headers.append('Content-Type', 'application/vnd.vimeo.video+json');
    headers.append('Authorization', `Bearer ${Appsettings.VIMEO_TOKEN}`);

    return this.http.get(apiUrl, { headers })
                    .map(res => res.json());
  }

}
