import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';
import { AdministratorModule  } from '../../components/layouts/administrator/administrator.module';

import { TEACHER_ROUTES } from './teacher.routes';

// MODULES
import { AdmonComponentModule } from '../../components/administrator/admon-components.module';

// COMPONENTS
import { TeacherLayoutComponent } from './teacher-layout.component';

// MATERIALIZE
import { MatTabsModule,
  MatInputModule,
  MatDatepickerModule,
  MatRadioModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatBadgeModule,
  MatListModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatDividerModule,
  MatExpansionModule,
  MatSidenavModule,
  MatTooltipModule} from '@angular/material';

import { TreeviewModule } from 'ngx-treeview';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { StudentsComponent } from './students/students.component';
import { SectionsComponent } from './sections/sections.component';
import { ExamScheduleComponent } from './exam-schedule/exam-schedule.component';
import { CoursesComponent } from './courses/courses.component';
import { ExamQualifyComponent } from './exam-qualify/exam-qualify.component';
import { DialogRelationContentComponent } from '../../dialogs/dialog-relation-content/dialog-relation-content.component';
import { TaskScheduleComponent } from './task-schedule/task-schedule.component';
import { EventsComponent } from './events/events.component';
import { HomeworkQualifyComponent } from './homework-qualify/homework-qualify.component';
import { DialogStudenHomeworkComponent } from '../../dialogs/dialog-studen-homework/dialog-studen-homework.component';
import { AttendanceListComponent } from './attendance-list/attendance-list.component';
import { InterestSiteComponent } from './interest-site/interest-site.component';
import { VideoConferenceComponent } from './video-conference/video-conference.component';
import { TeacherVideoConferenceComponent } from './teacher-video-conference/teacher-video-conference.component';


  @NgModule({
  imports: [
    TEACHER_ROUTES,
    CoreModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatListModule,
    AdministratorModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatBadgeModule,
    MatExpansionModule,
    AdmonComponentModule,
    MatSidenavModule,
    MatTooltipModule,
    TreeviewModule.forRoot(),
    CdkTreeModule,
    MatTreeModule
  ],
  declarations: [
    TeacherLayoutComponent,
    StudentsComponent,
    SectionsComponent,
    ExamScheduleComponent,
    CoursesComponent,
    ExamQualifyComponent,
    DialogRelationContentComponent,
    TaskScheduleComponent,
    EventsComponent,
    HomeworkQualifyComponent,
    DialogStudenHomeworkComponent,
    AttendanceListComponent,
    InterestSiteComponent,
    VideoConferenceComponent,
    TeacherVideoConferenceComponent
  ],
  entryComponents: [
    DialogRelationContentComponent,
    DialogStudenHomeworkComponent
  ]
})
export class TeacherModule { }
