import { Component, OnInit, AfterViewInit } from '@angular/core';

// COMPONENT SERVICES
import { BookNavService } from '../../components/layouts/publication/book-nav/book-nav.service';
import { ActivatedRoute } from '@angular/router';

declare function removeBackground(): any;
declare function setHeight(): any;

@Component({
  selector: 'app-courses-layout',
  templateUrl: './courses-layout.component.html',
  styleUrls: ['./courses-layout.component.scss']
})
export class CoursesLayoutComponent implements OnInit, AfterViewInit {

  constructor( )  { }

  ngOnInit() {
    removeBackground();
  }

  ngAfterViewInit() {
     setHeight();
  }

}
