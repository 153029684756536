import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { StorageService } from '../shared/storage.service';
import { SessionValidateService } from './session-validate.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor( private _storageService: StorageService,
               private _router: Router,
               private _svrSessionValidate: SessionValidateService) {}

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let result = this._svrSessionValidate.validateSession(state.url);


    if ( !result) {
      this._router.navigateByUrl('/admin/no-access');
    }
    return result;
  }
}

