import { Pipe, PipeTransform } from '@angular/core';
import { UserUtilities } from '../../shared/user.utilities';

@Pipe({
  name: 'fileType'
})
export class FileTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let url: string;
    let extension = UserUtilities.getExtensionToUrl(value);

    switch (extension.toLowerCase()) {
      case 'Video/mpeg':
          url = 'video_library';
        break;
      case 'jpg':
      case 'png':
          url = 'photo_size_select_actual';
        break;
      case 'mp3':
      case 'wav':
      case '3gp':
      case 'ogg':
          url = 'audiotrack';
        break;
      case 'pdf':
          url = 'picture_as_pdf';
        break;
      case 'doc':
      case 'docx':
      case 'txt':
        url = 'format_color_text';
        break;
      case 'xls':
      case 'xlsx':
      case 'cvs':
          url = 'table_chart';
          break;
      case 'ppt':
      case 'pptx':
         url = 'personal_video';
         break;
      case 'zip':
      case 'rar':
          url = 'archive';
          break;
      default:
        url = 'description';
        break;
    }
    return url;
  }

}
