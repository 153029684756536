import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor( private http: Http ) { }

  // Método que autentica un usuario en la aplicación
  refreshAuthenticationTaken( refreshToken  ) {
    let apiUrl = `${Appsettings.API_ENDPOINT}/token`;
    let headers = new Headers();
    let body = `refresh_token=${refreshToken}&grant_type=refresh_token&client_id=ngAuthApp`;
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http
      .post(apiUrl, body, { headers: headers })
      .map(res => res.json());

  }
}
