import { Component, OnInit,  ChangeDetectionStrategy, ViewChild, TemplateRef, OnDestroy } from '@angular/core';

// CALENDAR
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';

// DIALOGS
import { DialogEventDetailComponent } from '../../../dialogs/dialog-event-detail/dialog-event-detail.component';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';
import { ScheduledEventService } from '../../../service/service.index';
import { MatDialog } from '@angular/material';
import { Appsettings } from '../../../configuration/appsettings';
import swal from 'sweetalert2';


declare function setHeight(): any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {



  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[];
  eventsSource: any[];


  activeDayIsOpen: boolean = false;
  working: boolean = false;
  constructor( private _srvHeader: AdminHeaderService,
               public dialog: MatDialog ,
               private _srvEvent: ScheduledEventService) {
        this.getEvents();
        this._srvHeader.setBreadcrumb([ Appsettings.APP_NAME , 'Calendario']);

  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  ngOnInit(): void {
    const month = this.viewDate.toLocaleString('es-Mx', {
      month: 'long'
    });
    this._srvHeader.setTitle(`Calendario de ${month} ${ this.viewDate.getFullYear()}`);
  }

  changeMonth(viewDate: Date, toDay: boolean) {
    if ( toDay ) {
      viewDate = new Date();
    }
    const monthName = viewDate.toLocaleString('es-Mx', {
      month: 'long'
    });
    this._srvHeader.setTitle(`Calendario de ${monthName} ${ this.viewDate.getFullYear()}`);
    this.activeDayIsOpen = false;
    this.getEvents();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    let selectEventType = this.eventsSource.filter(x => x.eventType === event.eventType);
    let selectedEvent = selectEventType.filter(x => x.id === event.id)[0];

    if ( selectedEvent ) {
      const DIALOG_REF = this.dialog.open( DialogEventDetailComponent, {
        width: '350px',
        minHeight: '320px',
        autoFocus: false,
        data: {
          event: selectedEvent,
          color: event.color
        }

      });

      let day = new Date();
      day.getDay()

      DIALOG_REF.afterClosed().subscribe(response => {
      });
    }
  }


  getEvents() {
    this.working = true;
    this._srvEvent.getByMonth( this.viewDate).subscribe( res => {

      if ( res.success ) {

        this.eventsSource = [];
        this.events = [];

        for ( let e of res.data ) {

          let citaCalendario: CalendarEvent;

          citaCalendario = {
            id: e.id,
            start: new Date(e.dateEvent),
            end: new Date(e.endEvent),
            title: this.getTitleEvent(e),
            color: this.getEventColor(e.eventType),
            eventType: e.eventType
            // actions: this.actions
          };
          this.eventsSource.push(e);
          this.events.push(citaCalendario);
        }

        this.refresh.next();
      }

      this.working = false;
    }, err => {
      this.working = false;
      swal(Appsettings.APP_NAME, 'Problemas al conectarse con el servidor', 'error');
    });
  }

  getEventColor( estatusId: number ): any {

    switch (estatusId) {
      case 1: // Event
        return {
          primary: '#1e90ff',
          secondary: '#D1E8FF'
        };
      case 2: // HomeWork
        return {
          primary: '#ff2626',
          secondary: '#f7b7b7'
        };
      case 4: // VideoConferencia
      return{
        primary: '#7dc240',
        secondary: '#75b140'
      }
      default:
        return {
          primary: '#E3BC08',
          secondary: '#FDF1BA'
        };
    }
}

  getTitleEvent( event: any  ): string {
    let title: string = '';
    switch ( event.eventType ) {
      case 1: // Event
        return `Evento: ${event.name} | Hora: ${ event.initTime}`;
      case 2: // Homework
        return `Tarea: ${event.name} | Curso: ${ event.course.name} | Grupo ${ event.group.description}`;
      case 4: // VideoConferencia
        return `Videoconferencia: ${event.name} | Titulo: ${event.name} | Enlace ${ event.description}`;
      default:
        return `Examen: ${event.name} | Curso: ${ event.course.name} | Grupo ${ event.group.description}`;
    }
  }
}
