import { Component, OnInit } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';
import { MatDialog } from '@angular/material';

// SERVICES
import { GeneralEventService } from '../../../service/service.index';

// COMPONENT SERVICES
import { SitemapService } from '../../../core/sitemap/sitemap.service';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { Pagination } from '../../../models/general/pagination.model';
import { GeneralConferences } from '../../../models/videoConference/video-conference-model';

// DIALOGS
import { DialogGeneralEventComponent } from '../../../dialogs/dialog-general-event/dialog-general-event.component';

import swal from 'sweetalert2';

@Component({
  selector: 'app-video-conference',
  templateUrl: './video-conference.component.html',
  styleUrls: ['./video-conference.component.scss']
})
export class VideoConferenceComponent implements OnInit {
  working: boolean;
  generalConferences: GeneralConferences[] = [];

  // PAGINATION VARIABLES
  textSearch: string = '';
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor(public _srvSiteMap: SitemapService,
              private _srvHeader: AdminHeaderService,
              private _srvEvent: GeneralEventService,
              public dialog: MatDialog) {

                this.working  = false;
                this._srvSiteMap.setSiteMap( [{ 'description' : 'Contenido',
                                                'isCurrent' : false },
                                              { 'description' : 'Eventos' ,
                                              'isCurrent' : true }]);

              }

  ngOnInit() {
    this.setPage( { page: 1, size: this.sizePage });
    this._srvHeader.setTitle('Administración de eventos');
  }

    // Realizar búsqueda
    onKeyUp(event) {
      if ( this.textSearch.length >= 3 || this.textSearch.length === 0 ) {
        this.setPage( { page: 1, size: this.sizePage });
      }
    }
  // Cambiar de página
  setPage( paginationData: object ) {

    this.working = true;
    this.page    =  Number(paginationData['page']);
    this._srvEvent.getAll( this.page , Number(paginationData['size']) , this.textSearch).subscribe( result => {

      this.generalConferences   = [];
      let index = 1;

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let n of result.data) {
            let videoConferences = new GeneralConferences();

            // event.id = n.id;
            // event.description = n.description;
            // event.published = n.published;
            // event.eventDate = new Date(n.registerDate);
            // event.name = n.name;
            // event.hours = n.hours;
            // event.place = n.place;
            // event.urlImage = n.urlImage;
            // event.index = this.pagination.getIndexRegister(index);

            this.generalConferences.push( videoConferences );
            index++;
        }
      }
      this.working = false;
    }, result => {
      this.working = false;
    });

  }
}
