import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { TreeUtilities } from '../../../../shared/tree.utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

// SERVICES
import { StorageService, QuestionBankService, TeacherExamService } from '../../../../service/service.index';
// MODEL
import { QuestionBank } from '../../../../models/courses/questionBank.model';
import { Question } from '../../../../models/courses/question.model';
import { Answer } from '../../../../models/courses/answer.model';
import { TeacherExam } from '../../../../models/courses/teacherExam.model';


// CONFIGURATION
import { Appsettings } from '../../../../configuration/appsettings';

// DIALOGS
import { DialogExamSelectQuestionsComponent } from '../../../../dialogs/dialog-exam-select-questions/dialog-exam-select-questions.component';

import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';

import swal from 'sweetalert2';
import { ContentObserver } from '@angular/cdk/observers';




@Component({
  selector: 'app-generate-exam',
  templateUrl: './generate-exam.component.html',
  styleUrls: ['./generate-exam.component.scss']
})
export class GenerateExamComponent implements OnInit, OnDestroy {

  working: boolean = false;
  questionBanks: QuestionBank[] = [];
  selectedQuestionBanks: QuestionBank[] = [];
  examApplicationType: string = '1';
  bankQuestionId: number;

  showGenerateBtn: boolean = true;
  examId: number;

  structure: any[] = [];
  selectedItemPath = '';
  value;

  frmExam: FormGroup;

  nodos: any[] = [];
  items: TreeviewItem[] = [];
  config = TreeviewConfig.create({
      hasFilter: true,
      hasCollapseExpand: true
  });

  totalQuestion: number = 0;

  // NgMOdel
  description: string = '';
  showOpenQuestios: boolean;
  buttonText = 'Generar';

  constructor( private _srvStorage: StorageService,
               private _srvQuestionBank: QuestionBankService,
               private _srvTeacherExam: TeacherExamService,
               private _router: Router,
               private _route: ActivatedRoute,
               private _srvHeader: AdminHeaderService,
               public dialog: MatDialog ) {

    this.showOpenQuestios = false;
    this.frmExam = new FormGroup({
      id: new FormControl(0),
      teacherExamTypeId: new FormControl(1, [ Validators.required]),
      description: new FormControl('', [Validators.required, Validators.maxLength(200) ]),
      questions: new FormControl('', [ Validators.required, Validators.min(1)])
    });


  }

  ngOnInit() {
    this.buildTree();
    this._srvHeader.setTitle('Generar examen' );
    this._srvHeader.setUrlBackButton(['/sali/admin-exams', 'Mis exámenes']);

    this._route.params.subscribe( params => {

      if ( params.id > 0) {
        this.working = true;
        this._srvTeacherExam.getById( params.id).subscribe( res => {

          if ( res.success ) {

            const teacherExam = new TeacherExam();
            teacherExam.id = res.data.id;
            teacherExam.description = res.data.description;
            teacherExam.teacherExamTypeId = res.data.teacherExamTypes.id;
            teacherExam.questions = res.data;
            this.buttonText = 'Actualizar';

            this.setDataExam( teacherExam, res.data.teacherExamQuestion) ;

          }
        }, err => {
           this.working = false;
        });
      }

    });
  }

  ngOnDestroy(): void {
    this._srvHeader.setUrlBackButton([]);
  }

  onChangeModelOpen( event, bank: QuestionBank ) {
    if ( parseInt(event) > bank.totalOpenQuestions() ) {
      bank.selectedOpenQuestion = bank.totalOpenQuestions();
    }
    this.sumSelectedQuestions();
  }

  onChangeModelMultiple( event, bank: QuestionBank ) {
    if ( parseInt( event ) > bank.totalMultipleQuestions() ) {
      bank.selectedMultipleQuestion =  bank.totalMultipleQuestions();
    }
    this.sumSelectedQuestions();
  }

  onChangeModelRelational( event, bank: QuestionBank) {
    if ( parseInt( event) > bank.totalRelationalQuestions() ) {
      bank.selectedRelationalQuestion = bank.totalRelationalQuestions();
    }
    this.sumSelectedQuestions();
  }

  onChangeModelTrueFalse( event, bank: QuestionBank) {
    if ( parseInt( event) > bank.totalTrueFalseQuestions() ) {
      bank.selectedTrueFalseQuestion = bank.totalTrueFalseQuestions();
    }
    this.sumSelectedQuestions();
  }

  // Evento para agregar un banco de preguntas al listado para seleccionar preguintas
  onClickAddBank( ) {

    if ( this.bankQuestionId > 0 ) {

      let selected = this.selectedQuestionBanks.filter( x => x.id === this.bankQuestionId );

      if ( selected.length ) {
        swal(Appsettings.APP_NAME, 'Ya tienes seleccionado este banco de preguntas', 'warning');
        return false;
      }

      let bank = this.questionBanks.filter( x => x.id === this.bankQuestionId )[0];

      if ( bank ) {
        this.selectedQuestionBanks.push( bank );
      }
    }
  }

  // Agregar un nuevo exámen del maestro
  onClickSave( ): void {

    let questions: number[] = [];
    this.working = true;

     for ( let bank of this.selectedQuestionBanks ) {

      if ( bank.selectedOpenQuestion > 0 || bank.selectedMultipleQuestion > 0 || bank.selectedRelationalQuestion > 0 || bank.selectedTrueFalseQuestion > 0 ) {

        if ( bank.openCuestionsId.length === 0 ) {
          bank.openCuestionsId = this.getQuestionsId(bank, bank.selectedOpenQuestion, 2 );
        }

        if ( bank.multipleCuestionsId.length === 0  ) {
          bank.multipleCuestionsId = this.getQuestionsId( bank , bank.selectedMultipleQuestion, 1 );
        }

        if ( bank.relationalQuestionsId.length === 0)  {
          bank.relationalQuestionsId = this.getQuestionsId( bank, bank.selectedRelationalQuestion, 3);
        }

        if ( bank.trueFalseQuestionsId.length === 0 ) {
          bank.trueFalseQuestionsId = this.getQuestionsId( bank, bank.selectedTrueFalseQuestion, 4);
        }
        questions = questions.concat( bank.openCuestionsId, bank.multipleCuestionsId, bank.relationalQuestionsId, bank.trueFalseQuestionsId );


      }
    }

    if ( questions.length === 0 ) {
      swal(Appsettings.APP_NAME, 'Por lo menos debe seleccionar una pregunta para generar un examen', 'warning');
      this.working = false;
      return;
    }

    if ( !this.validate() ) {
      this.working = false;
      return;
    }

    const teacherExam = new TeacherExam();
    teacherExam.description = this.description;
    teacherExam.teacherExamTypeId =  parseInt( this.examApplicationType );
    teacherExam.questions = questions;

    if  ( this.examId > 0) {
      teacherExam.id = this.examId;
    }

    if ( teacherExam.id > 0 ) {
      this.update( teacherExam );
    } else {
      this.save( teacherExam );
    }
  }

  // Agregar un nuevo exámen
  save(teacherExam: TeacherExam) {
    this._srvTeacherExam.save( teacherExam ).subscribe( res => {
      if ( res.success ) {
        this.showGenerateBtn = false;
        teacherExam.id = res.data;
        this.resetForm();
        swal(Appsettings.APP_NAME, res.message, 'success');
      }
      this.working = false;
    }, res => {
      this.working = false;
      swal(Appsettings.APP_NAME, 'Tuvimos problemas al crear el examen, favor de intentar más tarde', 'warning');
    });

  }

  // Actualizar los datos de un examen existente
  update(teacherExam: TeacherExam) {
    this._srvTeacherExam.update( teacherExam ).subscribe( res => {
      if ( res.success ) {
        this.showGenerateBtn = false;
        teacherExam.id = res.data;
        this.resetForm();
        swal(Appsettings.APP_NAME, res.message, 'success');
      }
      this.working = false;
    }, res => {
      this.working = false;
      swal(Appsettings.APP_NAME, 'Tuvimos problemas al crear el examen, favor de intentar más tarde', 'warning');
    });

  }

  // Cancelar la operación de guardar los datos de un exámen
  onClickCancel( ): void {
    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, cancelar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this._router.navigateByUrl('/sali/admin-exams');
      }
    });
  }

  // Evento que se ejecuta cuando se realiza un cambio en el elemento seleccionado
  onValueChange( value ): void {

    this.working = true;
    this._srvQuestionBank.geAlltByNodeId( value.value ).subscribe( res => {

      if ( res.success ) {
        this.questionBanks = [];
        for ( const bank of res.data ) {

          const newBank = this.createNewQuestionBank( bank );

          for ( const q of bank.questions ) {
            const question =  this.createNewQuestion( q );

            if ( q.answers.length > 0 ) {

              for ( const a of q.answers ) {
                const answer = this.createAnswer( a );
                question.answers.push( answer );
              }
            }
            newBank.questions.push( question );
          }
          this.questionBanks.push( newBank );
        }
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
    // this.frmQuestionBank.controls['nodeId'].setValue(value.value);

  }

  // Crear la estructura del usuario en base al perfil de publicación
  buildTree( ): void {

    this.nodos = [];

    this._srvQuestionBank.getStructureWithBanks().subscribe( res => {


      if ( res.success) {

        // const jsonNode = JSON.parse(this._srvStorage.nodes);
        const jsonNode = res.data;

        for (const node of jsonNode) {

          const existNode = this.nodos.filter( x => x.value === node.id )[0];
          if ( existNode === undefined ) {
            this.structure.push(node);
            this.nodos.push({
              text: node.description,
              value: node.id,
              collapsed: true,
              checked: false,
              parentId: node.parentId
            });
          }
        }
        this.items.push(TreeUtilities.buildHierarchy(this.nodos));
      }
    }, err => {
    });
  }

  // Obtiene un array de index aleatorios para ser guardados
  getRamdonIndexQuestions( bank: QuestionBank, numberQuestions: number, questionTypeId: number ): number[] {


    let maxIndex: number = 0;
    let indexQuestion: number[] = [];

    if ( numberQuestions === undefined) {
      return indexQuestion;
    }

    if (questionTypeId === 1 || questionTypeId === 5 ) {
      maxIndex = bank.questions.filter( x => x.questionTypeId === 1 || x.questionTypeId === 5).length;
    } else {
      maxIndex = bank.questions.filter( x => x.questionTypeId === questionTypeId).length;
    }

    if ( numberQuestions  === maxIndex ) {
      for (let i = 0; i < maxIndex; i++ ) {
        indexQuestion.push( i );
      }
    } else {
      while ( indexQuestion.length < numberQuestions ) {
        let nr = Math.floor( (Math.random() * maxIndex));
        if ( indexQuestion.indexOf( nr ) === -1 ) {
          indexQuestion.push( nr );
        }
      }
    }
    return indexQuestion;
  }

  // Realizar las suma de todas las preguntas seleccionadas
  sumSelectedQuestions( ): void {

    this.totalQuestion = 0;

    for ( let bank of this.selectedQuestionBanks ) {
      let totalOpen = bank.selectedOpenQuestion  ? bank.selectedOpenQuestion : 0;
      let totalMultiple = bank.selectedMultipleQuestion  ? bank.selectedMultipleQuestion : 0;
      let totalRelational = bank.selectedRelationalQuestion ? bank.selectedRelationalQuestion : 0;
      let totalTreuFlase = bank.selectedTrueFalseQuestion ? bank.selectedTrueFalseQuestion : 0;
      this.totalQuestion += (totalOpen + totalMultiple + totalRelational + totalTreuFlase);
    }
  }

  // Reiniciar formulario a su estado inicial
  resetForm( ): void {
    this.selectedQuestionBanks = [];
    this.description = '';
    this.questionBanks = [];
  }

  // Obtener los identificadores de las preguntas por los index proporcionados
  getQuestionsId( bank: QuestionBank, totalTypeQuestion: number , questionTypeId: number ): number[] {

    let arrayIndex: number[] = this.getRamdonIndexQuestions( bank , totalTypeQuestion, questionTypeId );
    let questionsByType: any[];
    let arraySeletedQuestions: number[] = [];

    if (questionTypeId === 1 || questionTypeId === 5 ) {
      questionsByType = bank.questions.filter( x => x.questionTypeId === 1 || x.questionTypeId === 5);
    } else {
      questionsByType = bank.questions.filter( x => x.questionTypeId === questionTypeId);
    }

    if ( arrayIndex.length > 0 ) {

      let cursor = 0;
      while ( cursor < arrayIndex.length  ) {
        arraySeletedQuestions.push(questionsByType[cursor].id);
        cursor++;
      }
    }
    return arraySeletedQuestions;
  }

  // Crear un nuevo objeto de banco de pregunta
  createNewQuestionBank ( bank: QuestionBank ): QuestionBank {

    const newBank = new QuestionBank();
    newBank.id = bank.id;
    newBank.description = bank.description;
    newBank.active = bank.active;
    newBank.name = bank.name;
    newBank.questions = [];
    return newBank;
  }

  // Crear un nuevo objeto de pregunta
  createNewQuestion( q: Question ): Question {

    const question =  new Question();
    question.id = q.id;
    question.active = q.active;
    question.content = q.content;
    question.explanation = q.explanation;
    question.questionTypeId = q.questionTypeId;
    question.answers = [];

    return question;

  }

  // Crear un nuevo objeto de respuesta
  createAnswer( a: Answer ): Answer {

    const answer = new Answer();
    answer.id = a.id;
    answer.active = a.active;
    answer.description = a.description;
    answer.isCorrect = a.isCorrect;
    answer.order = a.order;
    answer.questionId = a.questionId;

    return answer;
  }

  // Evento para mostrar la ventana modal con el companente
  onClickSelectQuestions( questionBankId: number, numQuestions: number, questionTypeId: number ): void {

      let typeQuestion = questionTypeId; // El id del tipo de pregunta
      let quantityQuestions = numQuestions;  // Cantidad de preguntas que queremos obtener
      let blockQuestionsId = questionBankId; // El id del banco de preguntas
      let showAnswers = false; // Define si se muesta la respuesta correcta de las preguntas

      const questionBank = this.selectedQuestionBanks.filter( x => x.id === questionBankId)[0];
      // Un arreglo de id´s validos para seleccionar preguntas
      // let ids = [62 , 20];
      let ids = [];

      // if ( questionTypeId === 1 ) {
      //   ids = questionBank.multipleCuestionsId;
      // } else {
      //   ids = questionBank.openCuestionsId;
      // }
      switch (questionTypeId) {
        case 1:
          ids = questionBank.multipleCuestionsId;
          break;
        case 2:
          ids = questionBank.openCuestionsId;
          break;
        case 3:
          ids = questionBank.relationalQuestionsId;
          break;
        case 4:
          ids = questionBank.trueFalseQuestionsId;
          break;
        default:
          break;
      }
      const DIALOG_REF = this.dialog.open( DialogExamSelectQuestionsComponent, {
        width: '850px',
        height: '600px',
        autoFocus: false,
        disableClose: true,
        data: {
                quantityQuestions: quantityQuestions,
                blockQuestionsId: blockQuestionsId,
                showAnswers: showAnswers,
                ids: ids,
                typeQuestion: typeQuestion
              }
      });

      DIALOG_REF.afterClosed().subscribe( response => {
        let arrayIds = response;
        if ( questionBank ) {

          switch (questionTypeId) {
            case 1:
              questionBank.multipleCuestionsId = arrayIds;
              questionBank.selectedMultipleQuestion = arrayIds.length;
              break;
            case 2:
              questionBank.openCuestionsId = arrayIds;
              questionBank.selectedOpenQuestion = arrayIds.length;
              break;
            case 3:
              questionBank.relationalQuestionsId = arrayIds;
              questionBank.selectedRelationalQuestion = arrayIds.length;
              break;
            case 4:
              questionBank.trueFalseQuestionsId = arrayIds;
              questionBank.selectedTrueFalseQuestion = arrayIds.length;
              break;
            default:
              break;
          }
          this.sumSelectedQuestions();
        }
      });
  }

  /// Validar los datos para generar un examen
  validate ( ): boolean {

    let validator: boolean = true;

    if (this.description.length === 0 ) {
      validator = false;
      swal( Appsettings.APP_NAME, 'La descripción es obligatoria', 'warning' );
    }

    if ( this.selectedQuestionBanks.length === 0) {
      validator = false;
      swal( Appsettings.APP_NAME, 'Debes seleccionar un banco de preguntas para guardar un examen' , 'warning' );
    }

    if (  this.totalQuestion === 0 ) {
      validator = false;
      swal( Appsettings.APP_NAME, 'Debes seleccionar más de una pregunta para generar un examen' , 'warning' );
    }

    return validator;
  }

  // Eliminar un banco de preguntas asignado en el exámen
  onClickRemoveBank( bank: QuestionBank ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.selectedQuestionBanks = this.selectedQuestionBanks.filter( x => x.id !== bank.id );
        swal(Appsettings.APP_NAME, 'Banco de preguntas eliminado correctamente del examen', 'success');
      }
    });

  }

  changeStatus() {
    (this.showOpenQuestios) ? this.showOpenQuestios = false : this.showOpenQuestios = true;
  }

  // Asignar los datos d eun exámen existente
  setDataExam( teacherExam: TeacherExam , questions: any[]) {

    this.working = true;

    this.description =  teacherExam.description;
    this.examId = teacherExam.id;
    this.examApplicationType  = teacherExam.teacherExamTypeId.toString();
    teacherExam.questions = teacherExam.questions;

    const questionBankIds = questions.map( x =>  x.question.questionBankId ).filter((x, i, a) => a.indexOf(x) == i);

    this._srvQuestionBank.getList(questionBankIds.join(',')).subscribe( res => {
      if ( res.success ) {
        this.questionBanks = [];
        for ( const bank of res.data ) {

          const newBank = this.createNewQuestionBank( bank );

          for ( const q of bank.questions ) {
            const question =  this.createNewQuestion( q );

            if ( q.answers.length > 0 ) {

              for ( const a of q.answers ) {
                const answer = this.createAnswer( a );
                question.answers.push( answer );
              }
            }
            newBank.questions.push( question );
          }
          this.selectedQuestionBanks.push( newBank );
        }
      }
    }, err => {
      this.working = false;
    }, () => {

      // console.log(questions);

      for ( const q of this.selectedQuestionBanks ) {

        let multipleQuestions = questions.filter( x => (x.question.questionTypeId === 1 || x.question.questionTypeId === 5)  && x.question.questionBankId === q.id)
                                         .map( x => x.question.id );
        let openCuestions  = questions.filter( x => x.question.questionTypeId === 2 && x.question.questionBankId === q.id)
                                      .map( x => x.question.id );
        let relationQuestions =  questions.filter( x => x.question.questionTypeId === 3 && x.question.questionBankId === q.id)
                                          .map( x => x.question.id );
        let trueFalseQuestions = questions.filter( x => x.question.questionTypeId === 4 && x.question.questionBankId === q.id)
                                          .map( x => x.question.id );


        // console.log(multipleQuestions, openCuestions, relationQuestions, trueFalseQuestions );

        q.multipleCuestionsId = multipleQuestions;
        q.selectedMultipleQuestion = multipleQuestions.length;

        q.openCuestionsId = openCuestions;
        q.selectedOpenQuestion = openCuestions.length;

        q.relationalQuestionsId = relationQuestions;
        q.selectedRelationalQuestion = relationQuestions.length;

        q.trueFalseQuestionsId = trueFalseQuestions;
        q.selectedTrueFalseQuestion = trueFalseQuestions.length;

        }

        this.sumSelectedQuestions();
        this.working = false;

    });
  }

}
