export class Lesson {

  id: number;
  name: string;
  content: string;
  order: number;
  courseSectionId: number;
  files: any[];
  showFile: boolean;
  // constructor( _id: number, _name: string, _description: string) {
  //   this.id = _id;
  //   this.name = _name;
  //   this.description = _description;
  // }
}
