import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileHolder } from 'angular2-image-upload';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

// SERVICES
import {  UserService } from '../../service/service.index';
import { Router } from '@angular/router';
import { StorageService } from '../../service/shared/storage.service';

// MODELS
import { User } from '../../models/security/user.model';
import { FileContent } from '../../models/catalogs/file.model';

import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';


@Component({
  selector: 'app-dialog-user-profile-img',
  templateUrl: './dialog-user-profile-img.component.html',
  styleUrls: []
})
export class DialogUserProfileImgComponent implements OnInit {

  isChangeData: boolean = false;
  working: boolean = false;
  frmProfileImg: FormGroup;
  profileImage: string;

  showKey: boolean;
  imageProfile: string;

  user: User;
  fileContent: File;
  fileContentBase: string;
  fileName: string;
  isUpdateFile: boolean;

  constructor(
    private _srvUser: UserService,
    private _router: Router,
    private _storage: StorageService,
    public dialogRef: MatDialogRef<DialogUserProfileImgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User){
      this.profileImage = this.data.urlImg;
      this.frmProfileImg = new FormGroup({
        'file': new FormControl('')
      });

    }

  ngOnInit() {
  }

  onSubmit(){

    let fileUpload = new FileContent();

    if ( this.fileContent ) {

      const arrayFileBase = this.fileContentBase.split(',');

      fileUpload = new FileContent(
        arrayFileBase[1],
        this.fileContent.type,
        this.fileContent.name,
        arrayFileBase[0]
      );

    }

    const USER = {
      'id': this.data.id,
      'profileimage': this.fileContentBase,
      'filename': this.fileName,
      'fileContentBase64': fileUpload.content
    };


    this._srvUser.uploadImg( USER, this.fileContent ).subscribe( res => {
      if ( res.success ) {
        swal( Appsettings.APP_NAME, res.message, 'success');
      this.profileImage = res.data;
      this.data.urlImg = res.data;
      } else {
        swal( Appsettings.APP_NAME, res.message, 'error');
      }
    }, err => {
      swal( Appsettings.APP_NAME, 'Problemas al cambiar la imagen de perfil', 'error' );
    });

  }

  onRemoved(event: FileHolder) {

    this.fileContentBase = '';
    this.fileContent = null;
    this.frmProfileImg.controls['file'].setValue('');
    this.fileName = '';
  }

  onUploadFinished(event: FileHolder) {
    this.imageProfile = event.src;
    this.fileContentBase = event.src;
    this.fileContent     = event.file;
    this.fileName       = this.fileContent.name;
    this.isUpdateFile    = true;

    this.frmProfileImg.controls['file'].setValue(event.file.name);
  }

  onUploadStateChanged(state: boolean) {
  }

  onCloseConfirm() {
    this.dialogRef.close({status: true, image:  this.profileImage});
  }


}
