import { Component, OnInit, ViewChild , Input, EventEmitter, Output } from '@angular/core'
import { Route, ActivatedRoute } from '@angular/router';
import { Appsettings } from '../../../configuration/appsettings';

import swal, { SweetAlertType } from 'sweetalert2';
import { UserUtilities } from '../../../shared/user.utilities';

// TABLA DINAMICA
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MatSliderModule } from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import 'rxjs/add/observable/of';

import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';

// SERVICES COMPONENTS
import { AdminHeaderService } from '../../layouts/administrator/admin-header/admin-header.service';
import { AttendanceService } from '../../../service/publishing/attendance.service';
import { StudenService } from '../../../service/publishing/studen.service';

// MODELS
import { Group } from '../../../models/courses/group.model';
import { Course } from '../../../models/catalogs/course.model';
import { Attendance } from '../../../models/catalogs/attendances.model';
import { Studen } from '../../../models/courses/student.model';
import { moveBindingIndexToReservedSlot } from '@angular/core/src/render3/instructions';



@Component({
  selector: 'app-attendances-list',
  templateUrl: './attendances-list.component.html',
  styleUrls: ['./attendances-list.component.scss']
})
export class AttendancesListComponent implements OnInit {

  dataSource: tableDataSource;

  _dataSubject = new BehaviorSubject<any[]>([]);

  displayedColumns: string [] = [];
  columns: object [] = [];
  tableData: string [] = [];


  columnSliderValue: number;
  rowSliderValue: number;

  ////////////////////////////

  minDate = new Date();
  maxDate = new Date();

  startDate: Date;
  endDate: Date;

  date1 = '';
  date2 = '';

  daysValues: any[] = [];
  datesPeriod = [];
  daysPeriod: number;
  checks = [];

  working: boolean = false;
  checkDay: boolean = true;
  studenGroupId: number;
  groupName: string;
  studentsAttndnces: Attendance[] = [];
  students: Studen[] = [];
  attendances: Attendance[];

  course: Course;

  frmList: FormGroup;
  listDays: any[] = [];

  showmsg: boolean;
  @Input() group: any;
  constructor(
    private _srvHeader: AdminHeaderService,
    private _srvAttndnce: AttendanceService,
    private _route: ActivatedRoute,
    private _srvStudent: StudenService
  ) {

    this.showmsg = false;
    this.dataSource = new tableDataSource(this._dataSubject);

    this.groupName = 'Matemáticas avanzadas';
    let date = new Date();
    let daten = date.setDate(date.getDate() + 1);
    this.frmList = new FormGroup({
      startDate: new FormControl(),
      endDate: new FormControl()
   });

   this.course = JSON.parse(localStorage.getItem('sali.course'));

   this.minDate = this.course.startDate;
   this.maxDate = this.course.endDate;


    this.daysValues.push( this.course.monday );
    this.daysValues.push( this.course.tuesday );
    this.daysValues.push( this.course.wednesday );
    this.daysValues.push( this.course.thursday );
    this.daysValues.push( this.course.friday );
    this.daysValues.push( this.course.saturday );
    this.daysValues.push( this.course.sunday );
  }

  ngOnInit() {
    this.studenGroupId = this.group.id;
    this.groupName = this.group.description;
  }

// Obtiene los datos de asistencia para el periodo seleccionado
  getStudentsAssistance(init: string, end: string, groupId: number) {
    this.studenGroupId = groupId;
    this._srvAttndnce.getAttendanceData(init, end, groupId).subscribe(res => {
      if (res.success) {

        this.studentsAttndnces = [];

        for ( let a of res.data ) {

          let attendance = new Attendance();

          attendance.attendanceDate = a.day;
          attendance.studentGroupId = a.attendance.studentGroupId;
          attendance.userId = a.attendance.userId;
          attendance.attendanceClass = a.attendance.attendance;

          this.studentsAttndnces.push(attendance);

        }
      }
    });

  }

  // Guarda los datos de asistencia para el periodo seleccionado
  sendData() {

    this.working = true;

    let datas: any[] = [];
    let attendanceDay1: any[] = [];
    let attendanceDay2: any[] = [];
    let attendanceDay3: any[] = [];
    let attendanceDay4: any[] = [];
    let attendanceDay5: any[] = [];
    let attendanceDay6: any[] = [];
    let attendanceDay7: any[] = [];
    let dataAttendance: any[] = [];
    for (let e of this.students) {
      attendanceDay1.push({'userId': e.id, 'attendanceClass': e.day1});
      attendanceDay2.push({'userId': e.id, 'attendanceClass': e.day2});
      attendanceDay3.push({'userId': e.id, 'attendanceClass': e.day3});
      attendanceDay4.push({'userId': e.id, 'attendanceClass': e.day4});
      attendanceDay5.push({'userId': e.id, 'attendanceClass': e.day5});
      attendanceDay6.push({'userId': e.id, 'attendanceClass': e.day6});
      attendanceDay7.push({'userId': e.id, 'attendanceClass': e.day7});
    }
    datas.push(attendanceDay1);
    datas.push(attendanceDay2);
    datas.push(attendanceDay3);
    datas.push(attendanceDay4);
    datas.push(attendanceDay5);
    datas.push(attendanceDay6);
    datas.push(attendanceDay7);
    let index = 0;
    for ( let c of this.columns) {
      let date = new Date(c['header']);
      let newDate = UserUtilities.dateSerialize(date);
      dataAttendance.push({
        'attendanceDate': newDate,
        'studentAttendances':
          datas[index]
      });
      index++;
    }

    let attendance = {
      'studentGroupId': this.studenGroupId,
      'attendances': dataAttendance
    };

    this._srvAttndnce.saveGroupAttendance( attendance ).subscribe( result => {
      if ( result.success ) {
        swal(Appsettings.APP_NAME, result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME, result.message, 'warning');
      }
      this.working = false;
    });
  }

  // Obtiene los alumnos existentes en un grupo
  getStudents() {
    this.working = true;

    this._srvStudent.getAll(this.studenGroupId).subscribe(result => {
      this.students = [];
      if ( result.success ) {

        let dateInit = new Date( this.startDate );
        // let newDateInit = dateInit.getFullYear() + '/' + dateInit.getMonth() + 1 + '/' +  dateInit.getDate() ;
        let newDateInit = UserUtilities.dateSerialize(dateInit);


        let dateEnd = new Date( this.endDate);
        // let newDateEnd = dateEnd.getFullYear() + '/' + dateEnd.getMonth() + 1 + '/' +  dateEnd.getDate() ;
        let newDateEnd = UserUtilities.dateSerialize(dateEnd);
        
        this._srvAttndnce.getAttendanceData( newDateInit, newDateEnd, this.studenGroupId).subscribe( res => {
          if ( res.success) {
              let assistenDay1: any[] = [];
              let r = res.data;
              for ( let d of r) {
                for (let a of d.attendance ) {
                  assistenDay1.push({ 'userId': a.userId, 'attendance': a.attendance});
                }
              }
              for (let s of result.data ) {

                let student = new Studen();
                student.id = s.userId;
                student.fullName = s.userName;
                student.checkDate = s.checkDate;

                let attenDay1 =  assistenDay1.filter( item => item.userId === s.userId )[0];
                student.day1 = (attenDay1) ? attenDay1['attendance'] : false;

                let attenDay2 =  assistenDay1.filter( item => item.userId === s.userId )[1];
                student.day2 = (attenDay2) ? attenDay2['attendance'] : false;

                let attenDay3 =  assistenDay1.filter( item => item.userId === s.userId )[2];
                student.day3 = (attenDay3) ? attenDay3['attendance'] : false;

                let attenDay4 =  assistenDay1.filter( item => item.userId === s.userId )[3];
                student.day4 = (attenDay4) ? attenDay4['attendance'] : false;

                let attenDay5 =  assistenDay1.filter( item => item.userId === s.userId )[4];
                student.day5 = (attenDay5) ? attenDay5['attendance'] : false;

                let attenDay6 =  assistenDay1.filter( item => item.userId === s.userId )[5];
                student.day6 = (attenDay6) ? attenDay6['attendance'] : false;

                let attenDay7 =  assistenDay1.filter( item => item.userId === s.userId )[6];
                student.day7 = (attenDay7) ? attenDay7['attendance'] : false;

                student.day1Active = this.listDays[0]['active'];
                student.day2Active = this.listDays[1]['active'];
                student.day3Active = this.listDays[2]['active'];
                student.day4Active = this.listDays[3]['active'];
                student.day5Active = this.listDays[4]['active'];
                student.day6Active = this.listDays[5]['active'];
                student.day7Active = this.listDays[6]['active'];
                student.active = true;

                student.day1Name = this.listDays[0]['textDay'] + ' ' + this.listDays[0]['day'];
                student.day2Name = this.listDays[1]['textDay'] + ' ' + this.listDays[1]['day'];
                student.day3Name = this.listDays[2]['textDay'] + ' ' + this.listDays[2]['day'];
                student.day4Name = this.listDays[3]['textDay'] + ' ' + this.listDays[3]['day'];
                student.day5Name = this.listDays[4]['textDay'] + ' ' + this.listDays[4]['day'];
                student.day6Name = this.listDays[5]['textDay'] + ' ' + this.listDays[5]['day'];
                student.day7Name = this.listDays[6]['textDay'] + ' ' + this.listDays[6]['day'];

                this.students.push(student);
                this.getDayActive('d');
              }
            this.showmsg = (this.students.length > 0 ) ? true : false;
          }
          this.working = false;
        });
      }
    });

  }

  getDayActive( item: string ) {
    let c = this.daysValues.indexOf('Lun');
  }


   // Funcion que trae los datos de asistencia del periodo seleccionado
  selectAttendancePeriod() {
    let weekday = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    this.listDays = [];
    this.startDate = this.frmList.controls.startDate.value;
    let endDate = new Date(this.frmList.controls.startDate.value);

    endDate.setDate( endDate.getDate() + 6);
    this.endDate = endDate;

    this.daysPeriod = UserUtilities.getNumersOfDays(this.startDate, this.endDate) + 1;
    this.getStudents();
    this.columns = this.generateColumns (this.daysPeriod);
    let dayIndex = 0;
    for (let c of this.columns) {
      let newDate = new Date( c['columnDef'] );
      let day = newDate.getDate() ;
      let dayText = weekday[newDate.getDay()];
      switch (dayText) {
        case 'Lun':
            dayIndex = 0;
          break;
        case 'Mar':
            dayIndex = 1;
          break;
        case 'Mie':
            dayIndex = 2;
          break;
        case 'Jue':
            dayIndex = 3;
          break;
        case 'Vie':
            dayIndex = 4;
          break;
        case 'Sab':
            dayIndex = 5;
          break;
        case 'Dom':
            dayIndex = 6;
          break;
        default:
          break;
      }
      let dayActive = this.daysValues[dayIndex];

      this.listDays.push({ 'day': day, 'textDay': dayText, 'active': dayActive });

    }
}



// Función que filtra los dias de la semana del curso en el calendario
courseDaysFilter = (d: Date): boolean => {
  const day = d.getDay();
  this.course = JSON.parse(localStorage.getItem('sali.course'));

  let validDays = this.getValidCourseDays(this.daysValues);

  return day !== validDays[day];
}

// Función que retorna la posición de los valores true de un array para el filtrado en el calendario
getValidCourseDays(arr) {

    let validDays = [];
  // Don't show a false ID to this bouncer.
    for (let index = 0; index < arr.length; index++) {

      if( arr[index] == false ){
        validDays.push(arr[index]);
      }
    }
     return validDays;
    }

updateTableData() {

    this.displayedColumns = this.generateHeaders(this.daysPeriod);
    this.columns = this.generateColumns (this.daysPeriod);
    this.tableData = this.generateData (this.daysPeriod, this.students.length);

    this._dataSubject.next(this.tableData);
}

// Create headers, this is just a array of strings
generateHeaders(tableColumns: number) {

    let innerIndex: number = 1;
    let dateIndex = this.startDate;
    let displayedColumns: string [] = ['students'];

    do {
      displayedColumns.push (dateIndex.toLocaleDateString());
      dateIndex = UserUtilities.sumMinutes(dateIndex, 60 * 24);
    }
    while (innerIndex++ < tableColumns);

    return displayedColumns;

  }
  // Create columns, this is an array of objects. The object the holds the headingName, Label and Cell
  generateColumns(tableColumns: number) {
    let innerIndex: number = 1;
    let dateIndex = UserUtilities.sumMinutes(this.startDate, -60 * 24);
    let columnObj: object;
    let columnName: object = new function() {
      this.columnDef = 'students';
      this.header    = 'Nombre';
      this.cell      = [];
    };

    let columns: object[] = [];

    do {
      columnObj = new function() {
        this.columnDef = dateIndex;
        this.header    = dateIndex;
        this.cell      = [];
        this.value     = false;
      };

      columns.push(columnObj);
      dateIndex = UserUtilities.sumMinutes(dateIndex, 60 * 24);
    }
    while (innerIndex ++ < tableColumns);

    return columns;

  }

  // Create table data, this is just a 2d array of sequential numbers
  generateData (tableColumns: number, tableRows: number) {
    let innerIndex: number = 1;
    let outerIndex: number = 0;
    let value: string;
    let tableRow: string [] = [];
    let tableData: any [] = [];

    do {
      innerIndex = 1;
      tableRow.push (this.students[outerIndex].fullName);
      do {
        value = '';
        tableRow.push (value);
      }
      while (innerIndex++ < tableColumns);

      tableData.push(tableRow);
      tableRow = [];

    }
    while (outerIndex++ < tableRows - 1);

    return tableData;

  }



}

export class tableDataSource extends DataSource<any>
{

  constructor(private _data: BehaviorSubject<any[]>) {
    super();
  }

  connect() {
    return this._data.asObservable();
  }

  disconnect() {}

}
