import { Injectable } from '@angular/core';
import { Headers, URLSearchParams } from '@angular/http';
import { HttpService } from '../security/http.service';
import { Appsettings} from '../../configuration/appsettings';

// Modelos
import { Notice } from '../../models/courses/notice.model';
@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(  private _httpService: HttpService) { }
  // Obtiene todos los avisos de un maestro
  getAll(sizePage: number, currentPage: number, filter: string    ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/announcements/all`;
    const params = new URLSearchParams();

    params.append('pageSize', sizePage.toString());
    params.append('currentPage', currentPage.toString());
    params.append('filter', filter);

    return this._httpService.get(API_URL, { 
          params })
        .map(res => res.json());
  }

  // Agrega un nuevo aviso
  save(notice: Notice) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/announcements`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    return this._httpService.post(API_URL, JSON.stringify( notice ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Obtiene un aviso por ID
  getNoticeById( noticeId: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/announcements?id=${noticeId}`;

    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }

  // Actualiza un aviso
  update( notice: Notice ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/announcements/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    return this._httpService.put(API_URL, JSON.stringify( notice ), { headers: HEADERS } )
                           .map( res => res.json());
  }


  // Elimina un aviso
  deleteNotice( noticeId: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/announcements?id=${ noticeId}`;

    return this._httpService
        .delete(API_URL)
        .map(res => res.json());
  }


}
