import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-exam-select-questions',
  templateUrl: './dialog-exam-select-questions.component.html',
  styleUrls: ['./dialog-exam-select-questions.component.scss']
})
export class DialogExamSelectQuestionsComponent implements OnInit {

  quantity: number;
  bankQuestionsId: number;
  showAnswers: boolean;
  selectIds: number[];
  typeQuestion: number;

  constructor(  public dialogRef: MatDialogRef<DialogExamSelectQuestionsComponent>,
                @Inject(MAT_DIALOG_DATA) public data ) {
    this.quantity = data.quantityQuestions;
    this.bankQuestionsId = data.blockQuestionsId;
    this.showAnswers = data.showAnswers;
    this.selectIds = data.ids;
    this.typeQuestion = data.typeQuestion;

  }

  ngOnInit() {
  }
  // Evento cierra el modal
  onCloseConfirm() {
    this.dialogRef.close(this.selectIds);
  }

  onSubmit() {
  }

  getIds( event) {
    this.dialogRef.close( event );
  }
}
