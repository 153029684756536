import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// SERVICES COMPONENT
import { BookNavService } from '../../../components/layouts/publication/book-nav/book-nav.service';
import { StorageService } from '../../../service/service.index';



@Component({
  selector: 'app-glossaries',
  templateUrl: './glossaries.component.html',
  styleUrls: ['./glossaries.component.scss']
})
export class GlossariesComponent implements OnInit {

  nodeId: number;

  constructor( private _route: ActivatedRoute,
               public _bookNavService: BookNavService,
               private _svrStorage: StorageService ) { }

 ngOnInit() {
  if (this._svrStorage.rol === 'Profesor') {
    this._bookNavService.showMenu = true;
    this._bookNavService.showContentTab = true;
  }

  if (this._svrStorage.rol === 'Alumno') {
    this._bookNavService.showCourseTab = true;
    this._bookNavService.showContentTab = false;

  }

    this._route.params.subscribe(params => {
      this.nodeId = Number(params['id']) ;
      this._bookNavService.setNode( this.nodeId );
    });
  }

}
