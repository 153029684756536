import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Pagination } from '../../models/general/pagination.model';
import { Appsettings } from '../../configuration/appsettings';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() pagination: Pagination;
  @Output() changePage: EventEmitter<object> = new EventEmitter<object>();

  size: string = Appsettings.DEFAULT_SIZE_PAGE;

  constructor() { }

  onChangeSizePage() {
    this.changePage.emit( { page: 1, size: this.size });
  }

  onChangePage( page: number, cantContinue: boolean ) {
    if (!cantContinue) {
      this.changePage.emit( { page: page, size: this.size });
    }
  }

  ngOnInit() {
    this.size = Appsettings.DEFAULT_SIZE_PAGE;
  }

}
