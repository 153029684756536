import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-pagesexternal',
  templateUrl: './pagesexternal.component.html',
  styleUrls: ['./pagesexternal.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class PagesexternalComponent implements OnInit, AfterViewInit {

  constructor() {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

}
