import { Question } from './question.model';
import { Node } from '../content/nodes.model';

export class QuestionBank {
  id: number;
  name: string;
  description: string;
  active: boolean;
  nodeId: number;
  questions?: Question[];
  node?: Node;
  index: number;

  selectedMultipleQuestion: number;
  selectedOpenQuestion: number;
  selectedRelationalQuestion: number;
  selectedTrueFalseQuestion: number;

  multipleCuestionsId: number[] = [];
  openCuestionsId: number[] = [];
  relationalQuestionsId: number[] = [];
  trueFalseQuestionsId: number[] = [];

  // Obtener el total de las peguntas multiples
  public totalMultipleQuestions(): number  {
    if ( this.questions ) {
      let totalImage = this.questions.filter( x => x.questionTypeId === 5).length;
      let totalMultiple = this.questions.filter( x => x.questionTypeId === 1 ).length;
      return totalImage + totalMultiple;
    } else {
      return 0;
    }
  }

  // Obtener el total de las peguntas abiertas
  public totalOpenQuestions(): number  {
    if ( this.questions ) {
      return this.questions.filter( x => x.questionTypeId === 2 ).length;
    } else {
      return 0;
    }
  }

  // Obtener el tota de las pregintas relacionales
  public totalRelationalQuestions(): number {
    if ( this.questions ) {
      return this.questions.filter( x => x.questionTypeId === 3).length;
    } else {
      return 0;
    }
  }

  // Obtener rel total de las preguntas verdadero / falso
  public totalTrueFalseQuestions(): number {
    if ( this.questions) {
      return this.questions.filter( x => x.questionTypeId === 4).length;
    } else {
      return 0;
    }
  }

}
