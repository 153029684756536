import { Routes, RouterModule } from '@angular/router';

// Servicios
import { AuthGuardService } from '../../service/security/auth-guard.service';

import { UserComponent } from './secutiry/user/user.component';
import { RoleComponent } from './secutiry/role/role.component';
import { AdminLayoutComponent } from './admin-layout.component';
import { PermissionComponent } from './secutiry/permission/permission.component';
import { ContentRegisterComponent } from './publication/content-register/content-register.component';
import { PublishingProfileComponent } from './secutiry/publishing-profile/publishing-profile.component';
import { StructureComponent } from './secutiry/structure/structure.component';

import { QuestionBankComponent } from './publication/question-bank/question-bank.component';
import { QuestionsComponent } from './publication/questions/questions.component';
import { GenerateExamComponent } from './publication/generate-exam/generate-exam.component';
import { AuthorComponent } from './author/author.component';

import { NewsComponent } from './news/news.component';
import { NoaccessComponent } from './secutiry/noaccess/noaccess.component';
import { BooksComponent } from './publication/books/books.component';
import { InventoryComponent } from './publication/inventory/inventory.component';
import { TipsComponent } from './tips/tips.component';
import { GeneralEventComponent } from './general-event/general-event.component';
import { UploadQuestionBankComponent } from './publication/upload-question-bank/upload-question-bank.component';
import { VideoConferenceComponent } from './video-conference/video-conference.component';
import { VideoConferenceTestComponent } from './video-conference-test/video-conference-test.component';
import { FuncionalityReportComponent } from './publication/reports/funcionality-report/funcionality-report.component';

const AppPageAdmin: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
        { path: 'user', component: UserComponent, canActivate: [ AuthGuardService ]},
        { path: 'permission', component: PermissionComponent, canActivate: [ AuthGuardService ] },
        { path: 'role', component: RoleComponent, canActivate: [ AuthGuardService ]},
        { path: 'content-register', component: ContentRegisterComponent, canActivate: [ AuthGuardService ]},
        { path: 'profile', component: PublishingProfileComponent, canActivate: [ AuthGuardService ]},
        { path: 'structure', component: StructureComponent, canActivate: [ AuthGuardService ]},
        { path: 'questions-list', component: QuestionBankComponent, canActivate: [ AuthGuardService ]},
        { path: 'question/:id', component: QuestionsComponent, canActivate: [ AuthGuardService ] },
        { path: 'generate-exam', component: GenerateExamComponent, canActivate: [ AuthGuardService ] },
        { path: 'generate-exam/:id', component: GenerateExamComponent, canActivate: [ AuthGuardService ] },
        { path: 'books', component: BooksComponent, canActivate: [ AuthGuardService ]},
        { path: 'author', component: AuthorComponent, canActivate: [ AuthGuardService ]},
        { path: 'inventory', component: InventoryComponent, canActivate: [ AuthGuardService ]},
        { path: 'content-news', component: NewsComponent, canActivate: [ AuthGuardService ] },
        { path: 'content-tips', component: TipsComponent, canActivate: [ AuthGuardService ] },
        { path: 'content-events', component: GeneralEventComponent },
        { path: 'upload-question-bank/:id/:type', component: UploadQuestionBankComponent },
        { path: 'video-conference', component: VideoConferenceComponent },
        { path: 'video-conference/:id', component: VideoConferenceTestComponent },
        { path: 'functionality-report', component: FuncionalityReportComponent},
        { path: 'no-access', component: NoaccessComponent},
        { path: '', redirectTo: '/home/login', pathMatch: 'full'},
    ]
  }
];
export const ADMIN_ROUTES = RouterModule.forChild( AppPageAdmin );
