import { Component, OnInit, OnDestroy } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';

// MODELS
import { Pagination } from '../../../models/general/pagination.model';
import { ReleatedStudent } from '../../../models/general/releated-student';
import { Course } from '../../../models/catalogs/course.model';
import { Group } from '../../../models/courses/group.model';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// SERVICES
import { CourseService } from '../../../service/service.index';

@Component({
  selector: 'app-related-students',
  templateUrl: './related-students.component.html',
  styleUrls: ['./related-students.component.scss']
})
export class RelatedStudentsComponent implements OnInit, OnDestroy {

  courses: Course[] = [];
  groups: Group[] = [];
  students: ReleatedStudent[] = [];
  contentSection: any[] = [];

  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  courseId: string = '0';
  groupId: string = '0';
  textSearch: string = '';

  working: boolean = false;

  constructor( private _srvHeader: AdminHeaderService,
              private _srvCourse: CourseService ) {
    this._srvHeader.setTitle('Relación Alumno - Profesor');
    this._srvHeader.setBreadcrumb([ Appsettings.APP_NAME , 'Mi salón', 'Relación Alumno-Profesor']);
    this.getCourses();
  }

  ngOnInit() {
    this.setPage( { page: 1, size: this.sizePage });
  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  // Obtener todas las relaciones del mestro
  setPage( paginationData: object )  {
    this.working = true;
    this._srvCourse.getRelatedStudentsPagination( Number(paginationData['size']), Number(paginationData['page']) ,
                                                  this.textSearch, this.courseId, this.groupId ).subscribe( res => {

      let index = 1;
      this.students = [];

      if ( res.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let u of res.data) {
          let student = new ReleatedStudent();

          student.courseDescription = u.courseDescription;
          student.courseId = u.courseId;
          student.courseName = u.courseName;
          student.courseSubject = u.courseSubject;
          student.id = u.id;
          student.student = u.student;
          student.studentEmail = u.studentEmail;
          student.studentGroupDescription = u.studentGroupDescription;
          student.studentGroupId = u.studentGroupId;
          student.studentId = u.studentId;
          student.studentStatusDescription = u.studentStatusDescription;
          student.studentStatusId = u.studentStatusId;
          student.studentUrlImage = u.studentUrlImage;
          student.studentUserId = u.studentUserId;
          student.teacher = u.teacher;
          student.index = this.pagination.getIndexRegister( index );

          index++;
          this.students.push(student);

        }
      }

      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Obtener todos los cursos del profesor
  getCourses(): void {
    this.courses = [];

    this._srvCourse.getAll().subscribe( result => {
      if ( result.success) {
        let data = result.data;
        for ( let c of data) {

          let course = new Course( this.contentSection, c.id, c.name, c.description, c.gol, c.startDate, c.endDate, c.nodeId,
                                   c.subject, c.monday, c.tuesday, c.wednesday, c.thursday, c.friday, c.saturday, c.sunday, c.parentId);

          if ( c.groups.length > 0 ) {
            for ( let g of c.groups ) {
              let group = new Group(g.id, g.description, g.status, g.courseId);
              course.groups.push(group);
            }
          }

          this.courses.push(course);
        }
      }
    });
  }

  onChangeCourse( ): void {
    if ( Number(this.courseId) > 0 ) {
      this.groups = this.courses.filter( x => x.id === Number(this.courseId))[0].groups;
      this.groupId = '0';
    } else {
      this.groups = [];
    }
    this.onChangeFilter();
  }


  // Relaizar filtrado dpor curso y grupo
  onChangeFilter( ): void {
    this.setPage( { page: 1, size: this.sizePage });
  }

  // Realizar búsqueda
  onKeyUp(event) {
    if ( this.textSearch.length >= 4 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }


}
