import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BOOK_ROUTES } from './book.routes';

// LAYOUTS
import { PublicationModule } from '../../components/layouts/publication/publication.module';
import { AdmonComponentModule } from '../../components/administrator/admon-components.module';
import { AdministratorModule } from '../../components/layouts/administrator/administrator.module';
import { CoreModule } from '../../core/core.module';

import { BookLayoutComponent } from './book-layout.component';

// COMPONENT
import { VideoComponent } from './video/video.component';
import { TracksComponent } from './tracks/tracks.component';
import { DocumentsComponent } from './documents/documents.component';
import { GlossariesComponent } from './glossaries/glossaries.component';
import { InterestingSitesComponent } from './interesting-sites/interesting-sites.component';
import { CoursesListComponent } from './courses-list/courses-list.component';

// MATERIALIZE
import { MatIconModule } from '@angular/material';
import { InteractiveComponent } from './interactive/interactive.component';

@NgModule({
  imports: [
    CommonModule,
    PublicationModule,
    AdministratorModule,
    CoreModule,
    AdmonComponentModule,
    MatIconModule,
    BOOK_ROUTES
  ],
  declarations: [
    BookLayoutComponent,
    VideoComponent,
    TracksComponent,
    DocumentsComponent,
    GlossariesComponent,
    InterestingSitesComponent,
    CoursesListComponent,
    InteractiveComponent,
  ]
})
export class BookModule { }
