import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateIdElement'
})
export class GenerateIdElementPipe implements PipeTransform {

  transform(value: string, nameElement: string, args?: any): any {

    let nameCleaned = this.getCleanedString(nameElement);
    let typeElement = value.toLocaleLowerCase();

    return typeElement + '-' + nameCleaned;
  }





   getCleanedString(cadena) {
    // Definimos los caracteres que queremos eliminar
    let specialChars = '!@#$^&%*()+=-[]\/{}|:<>?,.';
    // Los eliminamos todos
    for ( let i = 0; i < specialChars.length; i++) {
        cadena = cadena.replace(new RegExp('\\' + specialChars[i], 'gi'), '');
    }
    // Lo queremos devolver limpio en minusculas
    cadena = cadena.toLocaleLowerCase();

    // Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
    cadena = cadena.replace(/ /g, '-');

    // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
    cadena = cadena.replace(/á/gi, 'a');
    cadena = cadena.replace(/é/gi, 'e');
    cadena = cadena.replace(/í/gi, 'i');
    cadena = cadena.replace(/ó/gi, 'o');
    cadena = cadena.replace(/ú/gi, 'u');
    cadena = cadena.replace(/ñ/gi, 'n');

    return cadena;
 }
}
