import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

import { TeacherExam } from '../../models/courses/teacherExam.model';

@Injectable({
  providedIn: 'root'
})
export class TeacherExamService {

  constructor( private httpService: HttpService ) { }

  // Registrar una nueva programación de exámenes
  save( teacherExam: TeacherExam ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/teacherexams`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    const params = {
      teacherExamTypeId: teacherExam.teacherExamTypeId,
      description: teacherExam.description,
      questions: teacherExam.questions
    };

    return this.httpService.post( API_URL, JSON.stringify(params), { headers: HEADERS } )
                           .map( res => res.json());

  }

  // Registrar una nueva programación de exámenes
  update( teacherExam: TeacherExam ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/teacherexams`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    const params = {
      id: teacherExam.id,
      teacherExamTypeId: teacherExam.teacherExamTypeId,
      description: teacherExam.description,
      questions: teacherExam.questions
    };

    return this.httpService.put( API_URL, JSON.stringify(params), { headers: HEADERS } )
                           .map( res => res.json());

  }

  getByUser( userId: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/teacherexams/teacher`;
    const params = new URLSearchParams();
    params.append('userdId', userId.toString());

    return this.httpService.get( API_URL, { params })
                           .map( res => res.json());


  }

  // Obtener todos los examenes de un usuario
  getByUserShortInfo( userId: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/teacherexams/teacher-short`;
    const params = new URLSearchParams();

    params.append('userdId', userId.toString());
    return this.httpService.get( API_URL, { params })
                           .map( res => res.json());


  }

  // Obtener todos los examenes de un usuario paginados
  getByUserPagination(sizePage: number, currentPage: number, filter: string, type: number) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/teacherexams/all`;
    const params = new URLSearchParams();

    params.append('pageSize', sizePage.toString());
    params.append('currentPage', currentPage.toString());
    params.append('filter', filter);
    params.append('type', type.toString());

    return this.httpService.get( API_URL, { params })
                           .map( res => res.json());


  }

  // Elimina los de manera permanente un exámen
  delete( id: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/teacherexams?id=${id.toString()}`;
    return this.httpService.delete(API_URL)
                           .map( res => res.json());
  }

  getById( Id: number ) {
    let status = true;
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/teacherexams?id=${Id}&image=${status}`;
    const params = new URLSearchParams();
    // params.append('id', Id.toString());
    // params.append('image', true);
    return this.httpService.get( API_URL, { params })
                           .map( res => res.json());


  }

  saveExamStuden( exam) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/tests`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post( API_URL, JSON.stringify(exam), { headers: HEADERS } )
                           .map( res => res.json());
  }

}
