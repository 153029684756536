import { Component, OnInit, AfterViewInit, AfterContentInit } from '@angular/core';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { Appsettings } from '../../../../configuration/appsettings';

// MODELS
import { NodeTotal } from '../../../../models/general/node-total';
import { Pagination } from '../../../../models/general/pagination.model';

// UTILITIES
import { TreeUtilities } from '../../../../shared/tree.utilities';

// SERVICES
import { NodeService, StorageService } from '../../../../service/service.index';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';


declare function setFullHeightByElement( selector ): any;

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {


  items: TreeviewItem[] = [];
  selectedNode: TreeviewItem = null;
  selectedFileTypeId: number;
  nodos: any[] = [];
  structure: any[] = [];
  working: boolean = false;

  total: NodeTotal[] = [];
  showListContent: boolean = false;

  files: any[] = [];
  audios: number = 0;
  videos: number = 0;
  documents: number = 0;
  images: number = 0;

  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  mainNodeSystem: number;

  audioTypeId: number;
  videoTypeId: number;
  documentTypeId: number;
  imageTypeId: number;


  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 470
  });

  constructor( private _srvNode: NodeService,
               private _storageSrv: StorageService,
               private _srvHeader: AdminHeaderService  ) {
                if ( this._storageSrv.systemId === 1 ) {
                  this.audioTypeId = 2;
                  this.videoTypeId = 1;
                  this.documentTypeId = 4;
                  this.imageTypeId = 3;
                } else {
                  this.audioTypeId = 6;
                  this.videoTypeId = 5;
                  this.documentTypeId = 4;
                  this.imageTypeId = 0;
                }

  }

  ngOnInit() {
    if ( this._storageSrv.systemId === 1 ) {
      this.mainNodeSystem = Appsettings.SALI_STRUCTURE_ID;
    } else {
      this.mainNodeSystem = Appsettings.RA_STRUCTURE_ID;
    }

    this.getNodes();

    this.getChildTotalContent( this.mainNodeSystem );
    this._srvHeader.setTitle('Inventario de contenido');
  }


  // OBtener los nodos
  getNodes(): void {
  this.working = true;
   this._srvNode.getStructureByPatent( this.mainNodeSystem ).subscribe(
      res => {
        if (res.data) {
          for (const nodo of res.data) {


            this.nodos.push({
              text: nodo.description,
              value: nodo.id,
              collapsed: nodo.parentId === 0 ?  false : true,
              checked: false,
              parentId: nodo.parentId
            });
          }
          this.items.push( TreeUtilities.buildHierarchy(this.nodos) );
        }
        this.working = false;
      },
      err => {
        this.working = false;
      }
    );
  }

  getChildTotalContent( nodeId: number ): void {
    this.working = true;
    this._srvNode.getChildTotalContent( nodeId ).subscribe( res => {

      this.total = [];
      this.audios = 0;
      this.videos = 0;
      this.images = 0;
      this.documents = 0;

      if ( res.success ) {
        for ( let c of res.data ) {
          let content = new NodeTotal();

          content.id = c.indexOf,
          content.name = c.name;
          content.description = c.description,
          content.nodeTypeId = c.nodeTypeId,
          content.panrentId = c.panrentId;
          content.total = c.totalContent.total;
          content.totalVideo = c.totalContent.totalVideo;
          content.totalAudio = c.totalContent.totalAudio;
          content.totalImage = c.totalContent.totalImage;
          content.totalDocument = c.totalContent.totalDocument;

          this.audios += content.totalAudio;
          this.videos += content.totalVideo;
          this.images += content.totalImage;
          this.documents += content.totalDocument;

          this.total.push(content);
        }
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Obtener el listado del tipo de archivo seleccionado , del nodo seleccionado
  getFilesByType( fileTyId: number ): void {

    if ( this.selectedFileTypeId !== fileTyId ) {
      this.selectedFileTypeId = fileTyId;
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // funcion para cambiar la pagina
  setPage( paginationData: object ) {

    this.working = true;
    const nodeId = this.selectedNode == null ? this.mainNodeSystem : this.selectedNode.value.value;

    this._srvNode.getAllNodeFilesType( nodeId, this.selectedFileTypeId, Number(paginationData['page']) , Number(paginationData['size']) ).subscribe( res => {
    this.files = [];

      if ( res.success ) {
        this.showListContent = true;

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        this.sizePage = paginationData['size'];


        for ( let f of res.data ) {
          this.files.push( f );
        }
      }

      this.working = false;

    }, err => {
       this.working = false;
    });


  }

  // SELECCIONAR NODO
  select(item: TreeviewItem, checked: boolean ): void {

    if ( this.selectedNode == null || this.selectedNode.value.value !== item.value.value ) {
      this.showListContent = false;
      this.getChildTotalContent(item.value.value);
      this.selectedNode = item;
    }

  }

  resizeTreeContainer() {
    setFullHeightByElement('.treeview-container');
  }

}
