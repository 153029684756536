import { Injectable, OnDestroy } from '@angular/core';
import { StorageService  } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menu: any = [
    // { title: 'Inicio', icon: 'icon icon-inicio', url: '#' },
    { title: 'Libros', icon: 'icon icon-publicacion', url: '/sali/level' },
    // { title: 'Evento', icon: 'icon icon-cal', url: '#' },
    // { title: 'Recursos', icon: 'icon icon-recurso', url: '#'  },
    // { title: 'Recursos académicos', icon: 'icon icon-hachette', url: '#' },
    { title: 'Administración',
      icon: 'icon icon-inicio',
      url: '',
      children: [
        { title: 'Contenidos' , url: '/admin/content-register' },
        { title: 'Usuarios', url: '/admin/user' },
        { title: 'Permisos', url: '/admin/permission' },
        { title: 'Roles', url: '/admin/role' },
        { title: 'Perfiles', url: '/admin/profile' },
        { title: 'Estructura', url: '/admin/structure' }
      ]}
  ];

  constructor(private _srvStorage: StorageService) {
    this.getMenu();
  }

  getMenu() {
    this.menu = [];
    if ( this._srvStorage.permissions.length ) {
      const menuJSON = JSON.parse( this._srvStorage.permissions);
      if ( menuJSON ) {
        this.menu = menuJSON.filter( x => x.showInMenu !== false );
      }
    }
    return this.menu;
  }

}
