import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

// Modelos
import { Permission } from '../../models/security/permission.model';

// Servicios
import { PermissionService } from '../../service/service.index';
import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';

@Component({
  selector: 'app-dialog-permission',
  templateUrl: './dialog-permission.component.html',
})
export class DialogPermissionComponent implements OnInit {

  public frmPermision: FormGroup;
  record: Permission = new Permission(0, '', '', false);

  working: boolean;
  isUpdateData: boolean;
  permisions: Permission[] = [];

  // Constructor del componente
  constructor(public dialogRef: MatDialogRef<DialogPermissionComponent>,
              public _svrPermission: PermissionService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              ) {


            this.permisions  = this.data.permissions;
            this.working = false;
            this.isUpdateData = false;
            this.frmPermision = new FormGroup({
              'id' : new FormControl(data.permision.id, [ Validators.required]),
              'module' : new FormControl(data.permision.module, [ Validators.required] ),
              'description' : new FormControl(data.permision.description, [ Validators.required]),
              'active' : new FormControl(data.permision.active),
              'url' : new FormControl(data.permision.url),
              'icon' : new FormControl(data.permision.icon),
              'parentId' : new FormControl(data.permision.parentId),
              'showInMenu' : new FormControl(data.permision.showInMenu)
            });
  }

  // Evento de componente
  ngOnInit() {

    if ( this.data.parentId > 0 ) {
      this.frmPermision.controls['parentId'].setValue( this.data.parentId);
    }

  }

  // Evento para cerrar la ventana modal
  onCloseConfirm(event: boolean) {
    this.dialogRef.close( event );
  }

  // Evento que se ejecuta para actualizar o agregar un nuevo registo
  onSubmit() {
      this.working = true;
      let record = new Permission(0, '', '', false);
      record.id = this.frmPermision.controls['id'].value;
      record.module = this.frmPermision.controls['module'].value;
      record.description = this.frmPermision.controls['description'].value;
      record.active = this.frmPermision.controls['active'].value;
      record.url = this.frmPermision.controls['url'].value;
      record.icon = this.frmPermision.controls['icon'].value;
      record.parentId = this.frmPermision.controls['parentId'].value;
      record.showInMenu = this.frmPermision.controls['showInMenu'].value;

      if ( record.id !== 0 ) {
        this.updatePermision( record );
      } else {
        this.savePermision( record );

      }

    }

    // Crea un nuevo rol
    savePermision( permision: Permission ): void {
    this._svrPermission.register( permision ).subscribe(res  => {
      if ( res.success ) {
        swal(Appsettings.APP_NAME, res.message, 'success');
        this.onCloseConfirm( true );
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Actualiza un rol
  updatePermision( permision: Permission ): void {
    this._svrPermission.update( permision ).subscribe(res  => {
      if ( res.success ) {
        swal(Appsettings.APP_NAME, res.message, 'success');
        this.onCloseConfirm( true );
      } else {
        swal(Appsettings.APP_NAME, res.message , 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }


}



