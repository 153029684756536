import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';
import { ScheduledEventComponent } from '../../../components/administrator/scheduled-event/scheduled-event.component';

// MODELS
import { Course } from '../../../models/catalogs/course.model';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {

  course: Course;

  @ViewChild(ScheduledEventComponent)
  public schedule: ScheduledEventComponent;

  constructor( private _route: ActivatedRoute,
               private _srvHeader: AdminHeaderService) {
      this._srvHeader.setBreadcrumb([Appsettings.APP_NAME, 'Mi salón', 'Eventos']);

   }

   ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  ngOnInit() {

    this._route.params.subscribe( params => {
      this.course = JSON.parse(localStorage.getItem('sali.course'));
      this.schedule.course = this.course;
      this.schedule.getEvents();
      this._srvHeader.setTitle('Programación de eventos: ' + this.course.name);
    });
  }

  onClickAdd() {
    this.schedule.onClickAdd();
  }

}
