import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// Servicios
import { ForumService, PagerService } from '../../../service/service.index';
import { StorageService } from './../../../service/shared/storage.service';

// Modelos
import { Thread } from '../../../models/catalogs/thread.model';
import { FilterForum } from '../../../models/general/filter-forum.model';
import { PaginarionLocal } from '../../../models/general/pagination-local.model';

// Componentes
import { DialogAddThreadComponent } from '../../../dialogs/dialog-add-thread/dialog-add-thread.component';
import { Appsettings } from '../../../configuration/appsettings';


@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit, OnDestroy {


  threads: Thread[] = [];
  sources: Thread[] = [];
  filters: FilterForum = new FilterForum();
  postSelected: number = 0;
  totalPost: number = 0;
  showFilterDetail: boolean  = false;
  selectedThread: Thread;
  showDetailTeacher: boolean = false;
  showDetailCourse: boolean = false;
  showDetailGroup: boolean = false;
  filterOption: string = '';
  coursesubject: string = '';

  teacherMode: boolean;
  working: boolean;

  pager: PaginarionLocal;
  sizePage: 5;

  constructor( private _srvHeader: AdminHeaderService,
               private _srvForum: ForumService,
               private _router: Router,
               public _dialog: MatDialog,
               public _srvStorage: StorageService,
               private _srvPager: PagerService) {

    this.teacherMode = this._srvStorage.rol == 'Profesor' ? true : false;
    this.working = false;
    this._srvHeader.setTitle('Foro de discusión');
    this._srvHeader.setBreadcrumb([ Appsettings.APP_NAME , 'Foros']);
    this.getAll();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  // Obtiene todos los hilos
  getAll() {
    this.working = true;
    this.threads = [];
    this.sources = [];
    this._srvForum.getAll().subscribe( result => {

      if ( result.success ) {
        for ( let t of result.data.threads ) {
          let thread = new Thread();
          thread.id = t.id;
          thread.courseName = t.courseName;
          thread.creatorRol = t.creator.rol;
          thread.avatar = t.creator.avatar;
          thread.description = t.description;
          thread.initDate = t.initDate;
          thread.title = t.title;
          thread.totalPosts = t.totalPosts;
          thread.views = t.views;
          thread.votes = t.votes;
          thread.creator = t.creator.fullName;
          thread.creatorEmail = t.creator.email;
          thread.courseSubject = t.courseSubject;
          thread.groups = t.groups;

          this.sources.push( thread );
        }

        this.setPage(1);

        this.totalPost = this.sources.map( x => x.totalPosts )
                                     .reduce( (total, thread ) => total + thread, 0);

        if ( result.data.filters.courses ) {
          this.filters.courses = result.data.filters.courses;
        }

        if ( result.data.filters.groups ) {
          this.filters.groups = result.data.filters.groups;
        }

        if ( result.data.filters.teachers ) {
          this.filters.teachers = result.data.filters.teachers;
        }

        this.selectedThread = this.threads[0];

      }
      this.working = false;
     });
  }

  filterThread( filter: string, option: string ) {

    this.showDetailTeacher = false;
    this.showDetailCourse = false;
    const data: Thread[] = [];

    switch ( filter ) {
      case 'courses':
        this.threads    = this.sources.filter( x => x.courseName === option );
        this.selectedThread = this.threads[0];
        this.filterOption = `Curso: ${option}`;
        this.coursesubject  = `Materia: ${ this.selectedThread.courseSubject }`;
        this.showDetailCourse = true;
        break;
      case 'group':
        this.threads = this.sources.filter( x => x.groups.indexOf(option) > -1 );
        this.selectedThread = this.threads[0];
        this.filterOption   = `Grupo: ${option}`;
        this.coursesubject  = `Materia: ${ this.selectedThread.courseSubject }`;
        this.showDetailCourse = true;
        break;
      case 'teacher':
        this.threads           = this.sources.filter( x => x.creator === option );
        this.selectedThread    = this.threads[0];
        this.showDetailTeacher = true;
        break;
      default:
        this.threads = this.sources;
    }

    // this.setPage(1);

    this.pager = null;
    this.totalPost = this.sources.map( x => x.totalPosts ).reduce( (total, thread ) => total + thread);
    this.postSelected = this.threads.map( x => x.totalPosts ).reduce( (total, thread ) => total + thread);
  }

  // Direcciona al post
  navigatePost( threadId: number ) {
    this._router.navigateByUrl(`/sali/post/${threadId}`);
  }

  // Abre el modal para agregar un nuevo modal
  openModalAddThread() {
    const DIALOG_REF = this._dialog.open(DialogAddThreadComponent, {
      width: '800px',
      height: '650px',
      autoFocus: false,
      disableClose: true
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      if ( response ) {
        this.getAll();
      }
    });
  }

    // Realizar paginación local
    setPage(page: number) {
      debugger;
      this.threads = [];
      this.pager   = this._srvPager.getPager(this.sources.length, page, this.sizePage);
      this.threads = this.sources.slice(this.pager.startIndex, this.pager.endIndex + 1);

    }

}
