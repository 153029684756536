import { Routes, RouterModule } from '@angular/router';

// GUARD
import { AuthGuardService } from '../../service/service.index';
import { VideoConferenceTestComponent } from '../administrator/video-conference-test/video-conference-test.component';
import { Component } from '@angular/core';
import { VideoConferenceCatComponent } from '../videoConference/video-conference/video-conference.component';
import { VideoConferenceComponent} from './videoConference.component';

const appVideoConference: Routes = [
  {
    path: 'video',
    component: VideoConferenceComponent,
    children: [
      { path: 'video-conference', component: VideoConferenceCatComponent },
      { path: 'video-conference/:id', component: VideoConferenceTestComponent },
    ]
  }
];
export const VIDEOCONFERENCE_ROUTES = RouterModule.forChild( appVideoConference );
