export class ResourceType {
  id: number;
  description: string;

  constructor( );
  constructor( _id: number, _description: string );
  constructor( _id?: number, _description?: string ) {
    this.id = _id || 0,
    this.description = _description || '';
  }

}
