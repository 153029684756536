import { Component, OnInit } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';
import { MatDialog } from '@angular/material';

// SERVICES
import { NewsHomeService } from '../../../service/service.index';

// COMPONENT SERVICES
import { SitemapService } from '../../../core/sitemap/sitemap.service';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { Pagination } from '../../../models/general/pagination.model';
import { NewHome } from '../../../models/home/new.model';

// DIALOGS
import { DialogNewsComponent } from '../../../dialogs/dialog-news/dialog-news.component';

import swal from 'sweetalert2';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  working: boolean;
  news: NewHome[] = [];

  // PAGINATION VARIABLES
  textSearch: string = '';
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor( public _srvSiteMap: SitemapService,
               private _srvHeader: AdminHeaderService,
               private _srvNews: NewsHomeService,
               public dialog: MatDialog) {

                this.working  = false;
                this._srvSiteMap.setSiteMap( [{ 'description' : 'Contenido',
                                                'isCurrent' : false },
                                              { 'description' : 'Noticias' ,
                                              'isCurrent' : true }]);

               }

  ngOnInit() {
    this.setPage( { page: 1, size: this.sizePage });
    this._srvHeader.setTitle('Administración de noticias');
  }

  // Evento para crear una noticia nueva
  onCreate() {
    const DIALOG_REF = this.dialog.open( DialogNewsComponent, {
      width: '500px',
      minHeight: '400px',
      data: new NewHome(),
      autoFocus: false,
      disableClose: true
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      if ( response ) {
        this.setPage( { page: this.page, size: this.sizePage });
      }
    });

  }

  // Realizar búsqueda
  onKeyUp(event) {
    if ( this.textSearch.length >= 3 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Evento para editar una noticia existente
  onEditItem( newHome: NewHome ): void {

    const DIALOG_REF = this.dialog.open( DialogNewsComponent, {
      width: '500px',
      minHeight: '400px',
      data: newHome,
      autoFocus: false,
      disableClose: true
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      if ( response ) {
        this.setPage( { page: this.page, size: this.sizePage });
      }
    });

  }

  // evento para eliminar una noticia existente
  onDeleteItem( newHome: NewHome ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.working = true;
        this._srvNews.delete( newHome.id ).subscribe( res => {

          if ( res.success ) {

            this.working = false;
            swal(Appsettings.APP_NAME, 'El registro fue eliminado correctamente.', 'success' );
            this.setPage( { page: this.page, size: this.sizePage });

          } else {
            this.working = false;
            swal(Appsettings.APP_NAME, res.message, 'error' );
          }
        }, err => {
          this.working = false;
          swal( Appsettings.APP_NAME , 'Problemas al eliminar la noticia',  'error' );
        });
      }
    });

  }

  // Cambiar de página
  setPage( paginationData: object ) {

    this.working = true;
    this.page    =  Number(paginationData['page']);
    this._srvNews.getAll( this.page , Number(paginationData['size']) , this.textSearch).subscribe( result => {

      this.news   = [];
      let index = 1;

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let n of result.data) {
            let newHome = new NewHome();

            newHome.id = n.id;
            newHome.description = n.description;
            newHome.published = n.published;
            newHome.registerDate = new Date(n.registerDate);
            newHome.title = n.title;
            newHome.urlImage = n.urlImage;
            newHome.index = this.pagination.getIndexRegister(index);

            this.news.push( newHome );
            index++;
        }
      }
      this.working = false;
    }, result => {
      this.working = false;
    });

  }

}
