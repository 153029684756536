import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Appsettings } from '../../../../configuration/appsettings';
// Models
import { Role } from '../../../../models/security/role.model';
import { Permission } from '../../../../models/security/permission.model';
import { Pagination } from '../../../../models/general/pagination.model';

// SERVICES
import { RoleService, StorageService} from '../../../../service/service.index';

// SERVICES COMPONENT
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';

// DIALOGS COMPONENTS
import { DialogRoleComponent } from '../../../../dialogs/dialog-role/dialog-role.component';
import { DialogRolePermComponent } from '../../../../dialogs/dialog-roleperm/dialog-roleperm.component';

import swal from 'sweetalert2';


class RolePerm {
  idRole: number;
  permissions: Permission[];
}

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit  {

  working: boolean;
  roles: Role[];
  permissions: Permission[];
  roleperm: RolePerm = new RolePerm();
  showFilters: boolean;
  
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();
  textSearch: string;

  // @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialog,
              private _roleSrv: RoleService,
              private _storageSrv: StorageService,
              private _srvHeader: AdminHeaderService ) {

                this.showFilters = false;
                this.working = false;
  
    
              }
              
  ngOnInit(): void {
    this.setPage( { page: 1, size: this.sizePage } );
    this._srvHeader.setTitle('Administración de roles');
  }


  onClickUpdateStatus( e: Role ) {
    this.working = true;
    e.active = !e.active;

    this._roleSrv.putRole(e).subscribe( res => {
      this.setPage( { page: 1, size: this.sizePage } );
    });
  }

  getPermissions(id) {
    this.roleperm.idRole = id;
    this._roleSrv.getPermissions(id).subscribe((result) =>{

      this.roleperm.permissions = result.data.map((rol) => {

        return rol;
      });


      const DIALOG_REF = this.dialog.open( DialogRolePermComponent, {
        width: '500px',
        height: '580px',
        disableClose : true,
        data: this.roleperm,
        autoFocus: false
      });

    });
  }

  onEditPermissions(id) {
    this.getPermissions(id);
  }

  onEditItem( element: Role ): void {
    const DIALOG_REF = this.dialog.open( DialogRoleComponent, {
      width: '320px',
      height: '320px',
      disableClose : true,
      data: element,
      autoFocus: false
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      if ( response ) {
        this.setPage( { page: 1, size: this.sizePage } );
      }
    });
  }

  addRole() {
    const DIALOG_REF = this.dialog.open( DialogRoleComponent, {
      width: '320px',
      height: '320px',
      disableClose : true,
      data: new Role(0, '', '', true),
      autoFocus: false
    });

    DIALOG_REF.afterClosed().subscribe( response => {     
      if ( response ) {
        this.setPage( { page: 1, size: this.sizePage } );
      }
    });
  }

  onDeleteItem( element ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.working = true;

        this._roleSrv.delete( element.id ).subscribe( res => {
          if ( res.success ) {
            swal(Appsettings.APP_NAME, 'El registro fue eliminado correctamente.', 'success');
            this.setPage( { page: 1, size: this.sizePage });
          } else {
            swal(Appsettings.APP_NAME, res.message, 'error');
          }
          this.working = false;
        }, err => {
          this.working = false;
        });

      }
    });
  }

  onDetailItem( element ): void {
  }

  onKeyUp(event) {

    if ( this.textSearch.length >= 4 || this.textSearch.length == 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  setPage( paginationData: object ) {

    this.working = true;
    this._roleSrv.getAllPagination( Number(paginationData['size']), Number(paginationData['page']) , this.textSearch).subscribe( result => {

      let index = 1;
      this.roles = [];

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        let data = result.data;
        for ( let c of data) {

          let role = new Role(0, '', '');
          role.id = c.id;
          role.name = c.name;
          role.description = c.description;
          role.active = c.active;
          role.index = this.pagination.getIndexRegister(index);
          this.roles.push( role );
          index++;
        }
      }
      this.working = false;
    }, result => {
      this.working = false;
    });
  }

}

