import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog} from '@angular/material';

// SERVICES
import { AuthorService } from '../../../service/catalogs/author.service';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { Author } from '../../../models/catalogs/author.model';
import { Pagination } from '../../../models/general/pagination.model';

/// DIALOG
import { DialogAuthorComponent } from '../../../dialogs/dialog-author/dialog-author.component';

import swal from 'sweetalert2';
import { count } from 'rxjs-compat/operator/count';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: []
})
export class AuthorComponent implements OnInit {

  working: boolean = false;
  authors: Author[];

  textSearch: string = '';
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor( public dialog: MatDialog,
              private _srvAuthor: AuthorService,
              private _srvHeader: AdminHeaderService) {
       this.setPage( { page: 1, size: this.sizePage } );
  }

  ngOnInit() {
    this._srvHeader.setTitle('Administración de autores');
  }

  // Evento para asignar la edición de un registro
  onEditItem( element: Author ): void {
    const DIALOG_REF = this.dialog.open( DialogAuthorComponent, {
      width: '450px',
      height: '480px',
      disableClose : true,
      data: element,
      autoFocus: false
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      if ( response ) {
        this.setPage( { page: 1, size: this.sizePage } );
      }
    });
  }

  // Agregar un nuevo autor
  addAuthor() {
    let author = new Author();
    author.id = 0;
    author.comment = '';
    author.fullName = '';
    author.lastName = '';

    const DIALOG_REF = this.dialog.open( DialogAuthorComponent, {
      width: '450px',
      height: '480px',
      disableClose : true,
      data: author,
      autoFocus: false
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      if (response ) {
        this.setPage( { page: 1, size: this.sizePage } );
      }
    });
  }

  // Eliminar un autor existente
  onDeleteItem( element ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.working = true;
        this._srvAuthor.delete( element.id ).subscribe( res => {

          if ( res.success ) {

            this.working = false;
            swal( Appsettings.APP_NAME, 'El autor fue eliminado correctamente.', 'success' );
            this.setPage( { page: 1, size: this.sizePage });

          } else {
            this.working = false;
            swal(Appsettings.APP_NAME, res.message , 'error' );
          }
        }, err => {
          this.working = false;
          swal(Appsettings.APP_NAME, 'Problemas al eliminar el autor', 'error' );
        });

      }
    });
  }

  // Realizar una búsqueda
  onKeyUp(event) {
    if ( this.textSearch.length >= 4 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // funcion para cambiar la pagina
  setPage( paginationData: object ) {

    this.working = true;
    this._srvAuthor.getAllPagination( Number(paginationData['size']), Number(paginationData['page']) , this.textSearch).subscribe( result => {

      let index = 1;
      this.authors = [];

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        let data = result.data;
        for ( let c of data) {

          let author = new Author();
          author.id = c.id;
          author.comment = c.comment;
          author.fullName = c.fullName;
          author.lastName = c.lastName;
          author.index = this.pagination.getIndexRegister(index);
          this.authors.push( author );
          index++;
        }
      }
      this.working = false;
    }, result => {
      this.working = false;
    });
  }

}
