import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './routes/home/home.component';

const APP_ROUTES: Routes = [
    { path: '', component: HomeComponent },
    { path: 'inicio/:section', component: HomeComponent },
    { path: '**', component: HomeComponent }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { 
    useHash: true ,
    scrollPositionRestoration: 'enabled'
});
