import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';

// Servicios
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// Modelos
import { Group } from '../../../models/courses/group.model';

// Componentes
import { InterestSitesComponent } from '../../../components/administrator/interest-sites/interest-sites.component';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-interest-site',
  templateUrl: './interest-site.component.html',
  styleUrls: ['./interest-site.component.scss']
})
export class InterestSiteComponent implements OnInit, OnDestroy {


  @ViewChild( InterestSitesComponent )
  public timerComponent: InterestSitesComponent;

  group: Group;

  courseId: number;
  constructor(  private _srvHeader: AdminHeaderService,
                private _route: ActivatedRoute) {
    this._srvHeader.setBreadcrumb([Appsettings.APP_NAME, 'Mi salón', 'Sitios de interés']);
                 }

  ngOnInit() {
    this._route.params.subscribe( params => {
      this.courseId = params['courseId'];
      this.group = JSON.parse(localStorage.getItem('sali.group'));
      this._srvHeader.setTitle('Sitios de interes: ' + this.group.description);
    });
  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  // Muestra el formulario para un nuevo sitio de interes
  onClickAddNew() {
    if ( !this.timerComponent.showForm) {
      this.timerComponent.addInterestSite(  );
    }
  }
}
