import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../../configuration/appsettings';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// Servicios
import { ForumService } from '../../../service/service.index';

// Modelos
import { Thread } from '../../../models/catalogs/thread.model';

import swal from 'sweetalert2';
import { UserUtilities } from '../../../shared/user.utilities';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit , OnDestroy {
 
  threadId: number;
  thread: Thread  = new Thread();
  posts: Thread[] = [];

  htmlContent: string;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '3rem',
    placeholder: 'Ingrese su comentario aquí...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
  };

  frmPost: FormGroup;
  working: boolean;
  isTeacher: boolean = false;
  usable: boolean;
  constructor(  private _srvHeader: AdminHeaderService,
                private _route: ActivatedRoute,
                private _srvForum: ForumService) {

      this.working = true;
      this.usable = true;
      this.frmPost =  new FormGroup({
        post: new FormControl('', [Validators.required])
      });

      this._route.params.subscribe(params => {
        this.threadId = params.id;
        this.thread = new Thread();
        this.getPosts( this.threadId );

      });
   }

  ngOnInit() {
    this._srvHeader.setUrlBackButton(['/sali/forum', 'Regresar']);
  }

  ngOnDestroy(): void {
    this._srvHeader.setUrlBackButton([]);
  }


  // Obtiene los posts
  getPosts( threadId: number) {

    this._srvForum.getPosts( threadId ).subscribe( result => {
      this.posts = [];
      if ( result.success) {

        let t = result.data.thread;
        let thread = new Thread();

        this.isTeacher = result.data.isTeacher;

        thread.id = t.id;
        thread.courseName = t.courseName;
        thread.creatorRol = t.creator.rol;
        thread.avatar = t.creator.avatar;
        thread.description = t.description;
        thread.initDate = t.initDate;
        thread.title = t.title;
        thread.totalPosts = t.totalPosts;
        thread.views = t.views;
        thread.votes = t.votes;
        thread.creator = t.creator.fullName;
        thread.bestAnswer = t.bestAnswer;
        thread.owner = t.owner;

        this.thread = thread;

        if ( t.posts ) {
          for (let p of t.posts ) {

            let post = new Thread();

            post.id = p.id;
            post.description = p.description;
            post.creator = p.creator.fullName;
            post.creatorRol = p.creator.rol;
            post.avatar = p.creator.avatar;
            post.initDate = p.createDate;
            post.bestAnswer = p.bestAnswer;
            post.votes = p.views;
            post.owner = p.owner;
            post.canLike = p.canLike;

            this.posts.push( post );
          }
        }
        this._srvHeader.setTitle( 'Sala: ' +  this.thread.title );
      }
      this.working = false;
    } );
  }

  // Crea un post nuevo
  sendPost() {
    this.working = true;
    let post = {
      'title': 'Lorem',
      'description': this.frmPost.controls['post'].value,
      'forumThreadId': this.threadId,
      'replay': '',
    };

    this._srvForum.savePost( post ).subscribe( result => {
      if ( result.success ) {
        this.resetForm();
        this.getPosts( this.threadId );
        swal(Appsettings.APP_NAME, result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME, result.message, 'error');
      }
    // this.working = false;
    });
  }

  // Limpiar el formulario
  resetForm( ) {
    this.frmPost.reset();
    this.frmPost.updateValueAndValidity();
  }

  // Obtener la clase para el badge del rol del usuario
  getBageColor( rol: string ) {
    if (rol && rol.indexOf( 'Alumno' ) > - 1 ) {
      return 'bg-badge-blue';
    } else if (rol && rol.indexOf( 'Profesor1' ) > - 1 ) {
      return 'bg-badge-brown';
    }
    {}return 'bg-badge-yellow';
  }

  // Destacar el comentario
  updateBestAnswer( post: Thread ) {

     const titleConfirm = !post.bestAnswer ? 'Marcar como mejor comentario' : 'Desmarcar como mejor comentario';
     const actionConfirm = !post.bestAnswer ? 'marcar' : 'desmarcar';

    swal({
      title: titleConfirm,
      text: 'Actualizar estatus del comentario',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, ' +  actionConfirm + ' ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.working = true;
        this._srvForum.updateStatusPost(post.id, !post.bestAnswer ).subscribe( res => {

          if ( res.success ) {
            swal( Appsettings.APP_NAME, 'Comentario marcado correctamente', 'success');
            post.bestAnswer = !post.bestAnswer;
          } else {
            swal( Appsettings.APP_NAME, res.message , 'success');
          }
          this.working = false;
        }, err => {
          this.working = false;
        });
      }
    });
  }

  // Eliminar un comentario
  deleteComment( post: Thread ) {
    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.working = true;
        this._srvForum.deletePost(post.id).subscribe( res => {

          if ( res.success ) {
            swal( Appsettings.APP_NAME, 'Comentario eliminado correctamente', 'success');
            this.posts = this.posts.filter( x => x.id !== post.id );
          } else {
            swal( Appsettings.APP_NAME, res.message , 'success');
          }
          this.working = false;
        }, err => {
          this.working = false;
        });
      }
    });
  }

  // Votar o quitar el voto
  onClickVote( post: Thread)  {
    this.usable = false;
    const vote = post.canLike ? 1 : -1;

    if ( post.process ) {
      return false;
    }

    this._srvForum.votePost( post.id, vote ).subscribe( res => {
      post.process = true;
      if ( res.success ) {
        post.canLike = res.data;
        if ( !res.data ) {
          post.votes += 1;
        } else {
          post.votes -= 1;
        }
      }
      post.process = false;
      this.usable = true;
    });

  }
}
