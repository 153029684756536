import { Group } from '../courses/group.model';
export class Course {
  sectionContent: any[];
  id: number;
  name: string;
  description: string;
  gol: string;
  startDate: Date;
  endDate: Date;
  nodeId: number;
  subject: string;
  institution: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  parentId: number;
  active: boolean;
  groupId: number;
  groups: Group[];
  selected: boolean;
  showDetail: boolean;
  teacherFullName: string;
  teacherEmail: string;

  constructor ( _sectionContent: any[],
                _id: number,
                _name: string,
                _description: string,
                _gol: string,
                _startDate: Date,
                _endDate: Date,
                _nodeId: number,
                _subject: string,
                _monday: boolean,
                _tuesday: boolean,
                _wednesday: boolean,
                _thursday: boolean,
                _friday: boolean,
                _saturday: boolean,
                _sunday: boolean,
                _groupId?: number,
                _parentId?: number,
                _Institution?: string

  ) {
    this.sectionContent = _sectionContent;
    this.id = _id;
    this.name = _name;
    this.description = _description;
    this.gol = _gol;
    this.startDate = _startDate;
    this.endDate = _endDate;
    this.nodeId = _nodeId;
    this.subject = _subject;
    this.monday = (_monday) ? _monday : false;
    this.tuesday = (_tuesday) ? _tuesday : false;
    this.wednesday = (_wednesday) ? _wednesday : false;
    this.thursday = (_thursday) ? _thursday : false;
    this.friday = (_friday) ? _friday : false;
    this.saturday = (_saturday) ? _saturday : false;
    this.sunday = (_sunday) ? _sunday : false;
    this.institution = _Institution;
    this.active = true;
    this.groupId = _groupId;
    this.parentId = _parentId;
    this.groups = [];
    this.selected = false;
    this.showDetail = false;

  }
}
