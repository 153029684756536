import { ContentType } from './conentType.model';
import { FileType } from './fileType.model';
import { empty } from 'rxjs';
import { Tag } from './tag.model';

export class Content {
  id?: number;
  name: string;
  urlContent: string;
  isPublic: boolean;
  active: boolean;
  activoDescription: string;
  contentType: ContentType;
  fileType:  FileType;
  content: any[];
  formativeFieldId: number;
  areaId: number;
  purposeId: number;
  trainingFieldId: number;
  resourceTypeId: number;
  thumbnails: string;
  index: number;
  tags: Tag[];
  description: string;
  visits: number;
  registerDate: Date;
  isRecommended: boolean;
  blockId: number;
  urlInteractive: string;

  constructor();
  constructor( _name: string, _contentType: string  )
  constructor( _name?: string, _contentType?: string  ) {
    this.name = _name;
    this.contentType = new ContentType();
    this.fileType = new FileType();
    this.thumbnails = null;
    this.tags = [];
    this.id = 0;
  }

}
