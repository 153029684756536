import { Routes, RouterModule } from '@angular/router';
import { BookLayoutComponent } from './book-layout.component';

// COMPONENTS
import { VideoComponent } from './video/video.component';
import { TracksComponent } from './tracks/tracks.component';
import { DocumentsComponent } from './documents/documents.component';
import { GlossariesComponent } from './glossaries/glossaries.component';
import { InterestingSitesComponent } from './interesting-sites/interesting-sites.component';
import { CoursesListComponent } from './courses-list/courses-list.component';
import { InteractiveComponent } from './interactive/interactive.component';



const AppPageBook: Routes = [
    {
      path: 'book',
      component: BookLayoutComponent,
      children: [
          { path: 'video/:id', component: VideoComponent },
          { path: 'tracks/:id', component: TracksComponent },
          { path: 'documents/:id', component: DocumentsComponent },
          { path: 'courses/:id', component: CoursesListComponent },
          { path: 'glossaries/:id', component: GlossariesComponent },
          { path: 'sites/:id', component: InterestingSitesComponent },
          { path: 'interactive/:id', component: InteractiveComponent }
      ]
    }
  ];

export const BOOK_ROUTES = RouterModule.forChild( AppPageBook );
