import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';

import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';
import { ActivoBooleanPipe } from '../../core/pipes/activo-boolean.pipe';
import { PublishingProfile } from '../../models/security/publishingProfile.model';


@Injectable()
export class PublishingProfileService {

  constructor( private http: Http,
               private httpService: HttpService ) {
   }

     // Método que obtiene todos los usuarios registrados este activo o no
  getAllPagination(sizePage: number, currentPage: number, filter: string) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/publishingProfiles/all`;
    const params = new URLSearchParams();

    params.append('pageSize', sizePage.toString());
    params.append('currentPage', currentPage.toString());
    params.append('filter', filter);

    return this.httpService
        .get(API_URL, { params })
        .map(res => res.json());
   }

  // Método que realiza petición al API para obtener los roles existentes
  getPublishingProfiles () {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/publishingProfiles/`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = {
    };

    return this.httpService.get(apiUrl).map(response => response.json());
  }

  putPublishingProfile ( record ) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/publishingProfiles/`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = record;

    return this.httpService.put(apiUrl, params).map(response => response.json());
  }

  postPublishingProfile ( record ) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/publishingProfiles/`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = record;

    return this.httpService.post(apiUrl, params).map(response => response.json());
  }

  getNodes ( id ) {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/publishingProfiles/`+id+`/nodes`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = {
    };

    return this.httpService.get(apiUrl).map(response => response.json());
  }

  postNodes (id, objeto) {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/publishingProfiles/` + id + `/nodes`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = objeto;

    return this.httpService.post(apiUrl, params).map(response => response.json());
  }

  deleteProfile(element: PublishingProfile){
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/publishingProfiles/` + element.id ;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    //let params = objeto;

    return this.httpService.delete(apiUrl).map(response => response.json());
  }

  getLinkedUsers (profileid) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/publishingProfiles/` + profileid + `/linkedusers`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = {
      ProfileId: profileid
    };

    return this.httpService.get(apiUrl).map(response => response.json());
  }


}
