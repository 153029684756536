import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Group } from '../../../models/courses/group.model';
import { Course } from '../../../models/catalogs/course.model';
import { MatDialog } from '@angular/material';

// DIALOGS
import { DialogGroupComponent } from '../../../dialogs/dialog-group/dialog-group.component';
import { DialogCourseComponent } from '../../../dialogs/dialog-course/dialog-course.component';

import { CourseService, GroupService } from '../../../service/service.index';
import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';
import { Observable, Subscription, fromEvent } from 'rxjs';

declare function setFullHeightByElement(selector): any;

@Component({
  selector: 'app-groups-nav',
  templateUrl: './groups-nav.component.html',
  styleUrls: ['./groups-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GroupsNavComponent implements OnInit, AfterViewInit {


  working: boolean = false;
  courses: Course[] = [];
  contentSection: any[] = [];

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  showDetail: boolean;

  constructor( private _dialog: MatDialog,
               private _router: Router,
               private _srvCourse: CourseService,
               private _srvGroup: GroupService,
               private _route: ActivatedRoute
                 ) {
                   this.showDetail = true;
                 }

  ngOnInit() {
    this.getCourses();
    // console.log('init');

    setFullHeightByElement('.lst-couses');
    this.resizeObservable$ = fromEvent(window, 'resize');

    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      setFullHeightByElement('.lst-couses');
    });
  }

  ngAfterViewInit(): void {
    // console.log('after');
  }

  /*********** GROUP ************/

  onCreateGroup( course: Course ) {

    let group = new Group( 0, '', 0, course.id);

    const DIALOG_REF = this._dialog.open( DialogGroupComponent, {
      width: '500px',
      height: '350px',
      autoFocus: false,
      disableClose: true,
      data: { courseName: 'Grupo', group: group}
    });

    DIALOG_REF.afterClosed().subscribe( res => {
      if ( res ) {
        course.groups.push(new Group( res.id ,res.description, res.status , res.courseId ));
      }
    });
  }

  onEditGroup( group: Group ) {
    const DIALOG_REF = this._dialog.open( DialogGroupComponent, {
      width: '400px',
      height: '300px',
      autoFocus: false,
      disableClose: true,
      data: { courseName: 'Grupo', group: group}
    });

    DIALOG_REF.afterClosed().subscribe( res => {
      if ( res ) {
        group.description = res.description;
      }
    });
  }

  onDeleteGroup( course: Course, group: Group ) {

    swal({
      title: '¿Estás seguro de eliminar el grupo?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.working = true;
        this._srvGroup.delete( group.id ).subscribe( res => {
          if(res.success) {
            course.groups = course.groups.filter( x => x.id !== group.id);
            swal(Appsettings.APP_NAME, res.message, 'success');
          } else {
            swal(Appsettings.APP_NAME, res.message, 'error');
          }
          this.working = false;
        }, err => {
          this.working = false;
        });
      }
    });
  }

  /*********** COURSES ************/

  onClickNewCourse() {
    const course = new Course( [], 0, '', '', '', null, null, null, '', null, null, null, null, null, null, null, 0);
    const DIALOG_REF = this._dialog.open( DialogCourseComponent, {
      width: '600px',
      minHeight: '520px',
      autoFocus: false,
      disableClose: true,
      data: { nodeId: 0 , course: course, edit: false  }
    });

    DIALOG_REF.afterClosed().subscribe( res => {
      if ( res ) {
        this.courses.push( res );
      }
    });
  }

  onClickEditCourse( course: Course ) {

    const DIALOG_REF = this._dialog.open( DialogCourseComponent, {
      width: '600px',
      minHeight: '520px',
      autoFocus: false,
      disableClose: true,
      data: { nodeId: course.nodeId , course: course, edit: true  }
    });

    DIALOG_REF.afterClosed().subscribe( res => {
      if ( res != null ) {
        const updateCourse = this.courses.filter( x => x.id === res.id)[0];
        if ( updateCourse ) {
          updateCourse.institution = res.institution;
          updateCourse.description = res.description ;
          updateCourse.endDate = res.endDate ;
          updateCourse.gol = res.gol ;
          updateCourse.groupId = res.groupId ;
          updateCourse.name = res.name ;
          updateCourse.nodeId = res.nodeId ;
          updateCourse.startDate = res.startDate ;
          updateCourse.friday = res.friday ;
          updateCourse.monday = res.monday ;
          updateCourse.saturday = res.saturday ;
          updateCourse.sunday = res.sunday ;
          updateCourse.thursday = res.thursday ;
          updateCourse.tuesday = res.tuesday ;
          updateCourse.wednesday = res.wednesday ;
          updateCourse.subject = res.subject ;
        }

      }
    });
  }

  onDeleteCourse( course: Course ) {

    swal({
      title: '¿Estás seguro de eliminar el grupo?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.working = true;
        this._srvCourse.delete( course.id ).subscribe( res => {
          if ( res.success ) {
            this.courses = this.courses.filter( x => x.id !== course.id);
            swal(Appsettings.APP_NAME, res.message, 'success');
          } else {
            swal(Appsettings.APP_NAME, res.message, 'error');
          }
          this.working = false;
        }, err => {
          this.working = false;
        });
      }
    });
  }

  getCourses() {
    this.working = true;
    this.courses = [];
    this._srvCourse.getAll().subscribe( result => {
      if ( result.success) {
        let data = result.data;
        console.log(data);
        for ( let c of data) {

          let course = new Course( this.contentSection,
                                   c.id,
                                   c.name,
                                   c.description,
                                   c.gol,
                                   c.startDate,
                                   c.endDate,
                                   c.nodeId,
                                   c.subject,
                                   c.monday,
                                   c.tuesday,
                                   c.wednesday,
                                   c.thursday,
                                   c.friday,
                                   c.saturday,
                                   c.sunday,
                                   c.groupId,
                                   c.parentId,
                                   c.institution
                                   );

          if ( c.groups.length > 0 ) {
            for ( let g of c.groups ) {
              let group = new Group(g.id, g.description, g.status, g.courseId);
              course.groups.push(group);
            }
          }
          this.courses.push(course);
          console.log(this.courses);
        }
      }
      this.setActiveCourse();
      this.working = false;
    });
  }

  setActiveCourse() {

    let course = JSON.parse(localStorage.getItem('sali.course'));
    if ( course !== undefined &&  course !== null ) {

      for ( let c of this.courses ) {
        if ( c.id === course.id ) {
          c.selected = true;
        }
      }
    }

    let group = JSON.parse(localStorage.getItem('sali.group'));
    if ( group !== undefined &&  group !== null ) {
      for ( let c of this.courses ) {
        for ( let g of c.groups ) {
          if ( g.id === group.id ) {
            g.selected = true ;
          }
        }
     }
    }
  }

  resetSelection() {
    for ( let c of this.courses ) {
      for ( let g of c.groups ) {
        g.selected = false;
      }
   }

   for ( let c of this.courses) {
     c.selected = false;
   }
  }

  /**** GO TO REDIRECT  ******/
  onClickGoToContent( course: Course) {

    this.resetSelection();

    course.selected = true;
    localStorage.setItem('sali.course', JSON.stringify(course));
    this._router.navigateByUrl(`/teacher/course-content/${course.id}`);

  }

  onClickGoToStudent( course: Course, studenGroup: Group ) {

    this.resetSelection();

    studenGroup.selected = true;
    course.selected = true;

    localStorage.setItem('sali.group', JSON.stringify(studenGroup));
    localStorage.setItem('sali.course', JSON.stringify(course));

    this._router.navigateByUrl(`/teacher/students/${studenGroup.id}/${course.id}`);
  }

  onClickGoToExamSchedule( course: Course, studenGroup: Group ) {

    this.resetSelection();

    studenGroup.selected = true;
    course.selected = true;

    localStorage.setItem('sali.group', JSON.stringify(studenGroup));
    localStorage.setItem('sali.course', JSON.stringify(course));

    this._router.navigateByUrl(`/teacher/exam-schedule/${studenGroup.id}/${course.id}`);
  }

  // Muestra la pantalla para tareas
  onClickGoToHomework( course: Course, studenGroup: Group) {
    this.resetSelection();

    studenGroup.selected = true;
    course.selected = true;

    localStorage.setItem('sali.group', JSON.stringify(studenGroup));
    localStorage.setItem('sali.course', JSON.stringify(course));

    this._router.navigateByUrl(`/teacher/task-schedule/${studenGroup.id}`);

  }
  // Muestra la pantalla de sitios de interés
  onClickGoToSiteInterests( course: Course) {
    this.resetSelection();

    course.selected = true;

    localStorage.setItem('sali.course', JSON.stringify(course));

    this._router.navigateByUrl(`/teacher/interest-site/${course.id}`);
  }

  onClickGoToEvent( course: Course ) {
    this.resetSelection();
    course.selected = true;
    localStorage.setItem('sali.course', JSON.stringify(course));

    this._router.navigateByUrl(`/teacher/events/${course.id}`);
  }

  onClickGoToAssistanceList(course: Course, studenGroup: Group) {
    this.resetSelection();

    studenGroup.selected = true;
    course.selected = true;

    localStorage.setItem('sali.group', JSON.stringify(studenGroup));
    localStorage.setItem('sali.course', JSON.stringify(course));

    this._router.navigateByUrl(`/teacher/attendance/${studenGroup.id}/${course.id}`);
  }




}
