export class Answer {
  id: number;
  description: string;
  isCorrect: boolean;
  active: boolean;
  order?: number;
  questionId: number;
  index?: number;
  relationDescription?: string;
  indexRelation: number;
  textrRelation: string;
  imageWidth: number;
  imageHeight: number;
}
