import { QuestionType } from './questionType.model';
import { Answer } from './answer.model';

export class Question {
  id: number;
  content: string;
  active: boolean;
  orden: number;
  explanation: string;
  questionBankId: number;
  questionTypeId: number;
  questionType?: QuestionType;
  answers: Answer[];
  index: number;
  indexResponse: number;
  urlImage?: string;
  filename?: string;
  fileContentBase64?: string;
  isMultiple: boolean;
  imageWidth: number;
  imageHeight: number;

}
