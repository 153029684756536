import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videoConferenceStatus'
})
export class VideoConferenceStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let status: string;
    switch(value){
      case 1:
        status = 'Registrada';
      break;
      case 2:
        status = 'Aceptado';
      break;
      case 3:
        status = 'Rechazado';
      break;
      case 4:
        status = 'Terminado';
      break;
    }
    return status;
  }


}
