import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// SERVICES
import { GroupService } from '../../../service/service.index';

// MODELS
import { Studen } from '../../../models/courses/student.model';
import { Appsettings } from '../../../configuration/appsettings';


@Component({
  selector: 'app-exam-qualify',
  templateUrl: './exam-qualify.component.html',
  styleUrls: ['./exam-qualify.component.scss']
})
export class ExamQualifyComponent implements OnInit, OnDestroy {

  students: Studen[] = [];
  rating: number;
  examScheduleId: number;
  examScheduleTypeId: number;
  typeExam: number;
  groupId: number;
  description: string;
  working: boolean;

  constructor( private _srvHeader: AdminHeaderService,
               private _srvGroup: GroupService,
               private _route: ActivatedRoute) {

    this._srvHeader.setBreadcrumb([Appsettings.APP_NAME, 'Mi salón', 'Calificar examen']);

  }

  ngOnInit() {
    this._route.params.subscribe( params => {

      const group =  JSON.parse(localStorage.getItem('sali.group'));
      const course = JSON.parse(localStorage.getItem('sali.course'));

      let name = group.description.replace(/\//g, '%2F');
      let urlBack = `/teacher/exam-schedule/${group.id}/${course.id}`;

      this.examScheduleId = params.examScheduleId;
      this.typeExam = params.typeExam;
      this.groupId = params.groupId;
      this.description = params.description;
      this.examScheduleTypeId = params.statusId;

      this._srvHeader.setTitle('Calificar examen: ' + this.description);
      this._srvHeader.setUrlBackButton([urlBack, 'Exámenes']);

      this.getStudents();
    });
  }

  ngOnDestroy(): void {
    this._srvHeader.setUrlBackButton([]);
  }

  // Obtener los estudiantes del grupo y programación de examenes
  getStudents() {

      this.students = [];
      this.working = true;
      this._srvGroup.getStudenByCourse( this.groupId , this.examScheduleId ).subscribe( result => {
        if ( result.success) {
          let students = result.data.filter( item =>  item.checkDate !== null );
          for ( let e of students ) {

            let studen = new Studen();

            studen.id = e.userId;
            studen.fullName = e.userName;
            studen.rating = e.score;
            studen.commentary = e.comments;
            studen.totalCorrect = e.totalCorrect;
            studen.totalQuestions = e.totalQuestions;
            studen.testId = e.testId;
            studen.isAnswered = e.isAnswered;

            this.students.push( studen );
          }
        }
         this.working = false;
      }, err => {
        this.working = false;
      });
  }

}
