import { TreeviewItem } from 'ngx-treeview';

export class TreeUtilities {

  // Construye la estructura requerida por el componente ngx-treeview
  public static buildHierarchy(arry: any): TreeviewItem {
    const roots = [],
      children = {};

    // find the top level nodes and hash the children based on parent
    for (let i = 0, len = arry.length; i < len; ++i) {
      const item = arry[i], p = item.parentId, target = !p ? roots : children[p] || (children[p] = []);

      target.push({
        value: item,
        text: item.text,
        collapsed: item.collapsed,
        checked: item.checked,
      });

    }

    // function to recursively build the tree
    let findChildren = function(parentId) {
      if (children[parentId.value.value]) {
        parentId.children = children[parentId.value.value];
        for (let i = 0, len = parentId.children.length; i < len; ++i) {
          findChildren(parentId.children[i]);
        }
      }
    };
    for (let i = 0, len = roots.length; i < len; ++i) {
      findChildren(roots[i]);
    }
    let datas = JSON.stringify(roots);
    datas = datas.slice(1, -1);
    return (new TreeviewItem(JSON.parse(datas)));
  }

  // Obtener la ruta completa dentro de la estructura de arbol
  public static getFullPathNode(nodeId: number, structure: any ): string {

   let child = structure.filter(r => r.id === nodeId)[0];
   let fullpath = '';

   if ( child !== undefined ) {

     let parentId = child.parentId;
     let router: any[] = [];
     let path = '';

     router.push(child);

     while (parentId > 0) {
       child = structure.filter(r => r.id === parentId)[0];
       router.push(child);
       parentId = child.parentId;
     }

     router = router.reverse();
     return  router.map(r => r.description).join('/');
   }
   return fullpath;
  }

  // Realizar la búsqueda de un nodo dentro de la estructura de árbol
  public static findElementTree(treeNodes: TreeviewItem , id: number): TreeviewItem {
    if (treeNodes.value.value === id) {
      return treeNodes;
    } else if (treeNodes.children !== undefined && treeNodes.children.length) {
      for (const children of treeNodes.children) {
        const node = this.findElementTree(children, id);
        if (node != null) {
          node.checked = true;
          return node;
        }
      }
    }
    return null;
  }

  // Agregar un nuevo nodo en el componente de árbol
  public static addNode( node: any, treeNodes ): boolean {

      let isAddNode = false;

      for (const tmpItem of treeNodes) {

        if ( tmpItem !== undefined ) {

          if ( tmpItem.children === undefined ) {
            tmpItem['children'] = [node];
          } else {
            tmpItem.children.push(node);
          }
          tmpItem.collapsed = false ;
          isAddNode = true;
          break;

        } else {
          if ( tmpItem.children !== undefined ) {
            this.addNode(node, tmpItem.children);
          }
        }
      }
      return isAddNode;
  }

}
