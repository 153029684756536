import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { HeaderService } from './header.service';
import { DialogLoginComponent } from '../../../../dialogs/dialog-login/dialog-login.component';
import { Router } from '@angular/router';

declare function scrollingToElement(element): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor( public _headerSrv: HeaderService,
               public dialog: MatDialog,
               private _router: Router ) {

  }

  ngOnInit() { }

  onClickLogin() {
    const DIALOG_REF = this.dialog.open( DialogLoginComponent, {
      width: '360px',
      minHeight: '340px',
      data: [],
      autoFocus: false,
      disableClose: true
    });

  }

  onClickNav( id: string , url: string ) {
    if ( this._router.url.indexOf('inicio') > - 1 ) {
      scrollingToElement(id);
    } else {
      this._router.navigateByUrl( url );
    }
  }

}
