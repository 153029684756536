import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-upload-file',
  templateUrl: './dialog-upload-file.component.html',
  styleUrls: ['./dialog-upload-file.component.scss']
})
export class DialogUploadFileComponent implements OnInit {

  taskId: number;
  constructor(  private dialogRef: MatDialogRef<DialogUploadFileComponent>,
                @Inject(MAT_DIALOG_DATA) public data) {

    this.taskId = data.taskId;
  }

  ngOnInit() {
  }

  onCloseConfirm( event ) {
    this.dialogRef.close( event );
  }

}
