import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class HeaderService {

  showButton = true;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  showReturnButton( visible: boolean)  {
    this.showButton = visible;
  }


}
