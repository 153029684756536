import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ExamScheduleService } from '../../../service/service.index';

// COMPONENT SERVICES
import { BookNavService } from '../../../components/layouts/publication/book-nav/book-nav.service';
import { CourseNavService } from '../../../components/layouts/publication/course-nav/course-nav.service';

// DIALOGS
import { DialogUploadFileComponent } from '../../../dialogs/dialog-upload-file/dialog-upload-file.component';
import { DialogGetFileComponent } from '../../../dialogs/dialog-get-file/dialog-get-file.component';
import { DialogStudentAnswersComponent } from '../../../dialogs/dialog-student-answers/dialog-student-answers.component';

// MODELS
import { Course } from '../../../models/catalogs/course.model';

import { Subscription } from 'rxjs';


@Component({
  selector: 'app-course-exams',
  templateUrl: './course-exams.component.html',
  styleUrls: ['./course-exams.component.scss']
})
export class CourseExamsComponent implements OnInit, AfterViewInit, OnDestroy {
 
  courseId: number;
  nodeId: number;
  working: boolean;
  course: Course;

  exams: any[] = [];

  private changeCourseEndRef: Subscription = null;

  constructor( public _dialog: MatDialog,
               private _srvExam: ExamScheduleService,
               private _route: ActivatedRoute,
               private _router: Router,
               public _bookNavService: BookNavService,
               public _courseNavService: CourseNavService ) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.courseId = Number(params['id']);
      this.nodeId = Number(params['nodeId']);
      this._bookNavService.setNode( this.nodeId );

      this._courseNavService.asyncGetStudentCourses( this.nodeId, this.courseId ).then( () => {
        this.course = this._courseNavService.course;
        this.getExams();
      });

      this.changeCourseEndRef = this._courseNavService.ChangeCourseEnd$.subscribe( () => {
        this.getExams();
      });

    });
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {
    this.changeCourseEndRef.unsubscribe();
  }

  // Obtener la información de los exámenes
  getExams(): void {

    if ( this.course == null ) {
      return ;
    }

   this._courseNavService.working = true;
    this._srvExam.getAllExamStuden( this._courseNavService.course.groupId ).subscribe(res => {
      this.exams = [];

      if ( res.success ) {

        for (let exam of res.data) {
          let  teacherExamTypes: number;
          if ( exam.teacherExam.teacherExamTypes) {
            teacherExamTypes =  exam.teacherExam.teacherExamTypes.id;
          } else {
            teacherExamTypes = 2;
          }

          let statusExam = false;

          if (!exam.isAnswered) {
            let result = this.validateDates(exam.applicationDate, this._courseNavService.course.endDate);

            if (!result) {
              statusExam = true;
            }
          }

          let newExam = {
            name: exam.description,
            id: exam.id,
            applicationDate: exam.applicationDate,
            rating: 0,
            teacherExamId: exam.teacherExamId,
            hasScore: exam.hasScore,
            isAnswered: exam.isAnswered,
            score: exam.hasScore ? exam.score.score : null,
            statusExam: statusExam,
            comment: exam.hasScore ? exam.score.comments : null,
            teacherExamTypes: teacherExamTypes
          };

          this.exams.push( newExam );
        }
      }
      this._courseNavService.working = false;
    }, err => {
      this._courseNavService.working = false;
    });

  }

  // Validar fechas del curso
  validateDates( examApply: Date, endDate: Date ): boolean {
    let newToday = new Date();
    let newExamApply = new Date(examApply);
    let courseEndDate = new Date(endDate);
    let result = false;

    let resToday = new Date( newToday.getFullYear(), newToday.getMonth(), newToday.getDate() );
    let resApply = new Date( newExamApply.getFullYear(), newExamApply.getMonth(), newExamApply.getDate() );
    let endCourseDate = new Date( courseEndDate.getFullYear(), courseEndDate.getMonth(), courseEndDate.getDate() );

    if ( resApply <= endCourseDate || resToday < resApply) {
      result = false;
    }
    if (resToday >= resApply ) {
      result = true;
    }

    return result;
  }

  // Muestra modal con los archivos asociados a la tarea
  onShowFilesTask( task ) {
      const DIALOG_REF = this._dialog.open(DialogGetFileComponent, {
        width: '800px',
        height: '600px',
        autoFocus: false,
        disableClose: true,
        data: { task: task }
      });
  
      DIALOG_REF.afterClosed().subscribe(response => {});
  }

  // Muestra modal para cargar las tareas del alumno
  onShowUploadFile( taskId ) {
      const DIALOG_REF = this._dialog.open(DialogUploadFileComponent, {
        width: '800px',
        height: '600px',
        autoFocus: false,
        disableClose: true,
        data: { taskId: taskId }
      });
  
      DIALOG_REF.afterClosed().subscribe(response => {});
  }

  // Ver las respuestas del alumno
  onShowAnswers( exam ): void {
    const DIALOG_REF = this._dialog.open( DialogStudentAnswersComponent, {
      width: '1000px',
      height: '700px',
      autoFocus: false,
      disableClose: true,
      data: { teacherExamId: exam.id, nameExam: exam.name, studenId: 0 }
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      
    });
  }

  // Presentar un examen
  onShowExam( exam ) {
    this._router.navigateByUrl(
      `/sali/apply-exam/${exam.teacherExamId}/${exam.id}/${this._courseNavService.nodeId}`
    );
  }

}
