import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';

// Modelos
import { Studen } from '../../models/courses/student.model';
@Component({
  selector: 'app-dialog-exam-ratings',
  templateUrl: './dialog-exam-ratings.component.html',
  styleUrls: ['./dialog-exam-ratings.component.scss']
})
export class DialogExamRatingsComponent implements OnInit {

  students: Studen[] = [];
  rating: number;
  examScheduleId: number;
  typeExam: number;
  // displayedColumns: string[] = ['name', 'hits', 'rating'];
  // dataSource = new MatTableDataSource<any>(this.students);

  constructor(  public dialogRef: MatDialogRef<DialogExamRatingsComponent>,
                @Inject(MAT_DIALOG_DATA) public data) {
                  this.students = data.students;
                  this.examScheduleId = data.examScheduleId;
                  this.typeExam = data.typeExam;
                  // this.dataSource = new MatTableDataSource<any>(this.students);
                 }

  ngOnInit() {
  }

   // Evento para cerrar la ventana modal
   onCloseConfirm() {
    this.dialogRef.close(false);
  }

  sendData() {
  }
}
