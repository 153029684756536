import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// Servicios
import { CourseService, GroupService } from '../../service/service.index';

// Modelos
import { Group } from '../../models/courses/group.model';
import { Appsettings } from '../../configuration/appsettings';
import swal from 'sweetalert2';
@Component({
  selector: 'app-dialog-group',
  templateUrl: './dialog-group.component.html',
  styleUrls: ['./dialog-group.component.scss']
})
export class DialogGroupComponent implements OnInit {
  working: boolean;
  frmGroup: FormGroup;
  courses: any[] = [];
  courseId: number;
  group: Group;
  // group: Group[] = [];

  constructor(  private dialogRef: MatDialogRef<DialogGroupComponent>,
                private _srvCourse: CourseService,
                private _srvGroup: GroupService,
                @Inject(MAT_DIALOG_DATA) public data) {
    this.working = false;
    this.group = data.group;
    this.courseId = this.group.courseId;
    this.frmGroup = new FormGroup({
      'description': new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.frmGroup.controls['description'].setValue(this.group.description);
    this._srvCourse.getAll().subscribe( result => {
      if ( result.success ) {
        for ( let c of result.data) {
          this.courses.push({'id': c.id, 'name': c.name});
        }
      }
    });
  }

  onSubmit() {
    this.working = true;
    if ( this.group.id === 0) {
      this.createGroup();
    } else {
      this.updateGroup();
    }

  }
  onCloseConfirm( data ) {
    this.dialogRef.close( data );
  }

  createGroup() {
    const grupo = new Group( this.group.id, this.frmGroup.controls['description'].value,  1, this.courseId);
      this._srvGroup.save(grupo).subscribe( result => {
        if ( result.success) {

          this.onCloseConfirm( result.data );

          const toast = swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 6000
          });

          toast({type: 'success', title: 'Datos del grupo guardados correctamente'});

        } else {
          swal(Appsettings.APP_NAME, 'Tuvimos problemas al guardar los datos, favor de intentar más tarde', 'error');
        }
        this.working = false;
      });
  }

  updateGroup() {
    const grupo = new Group( this.group.id, this.frmGroup.controls['description'].value,  1, this.courseId);
      this._srvGroup.update(grupo).subscribe( result => {
        if ( result.success) {
          this.onCloseConfirm( result.data );

          const toast = swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 6000
          });

          toast({type: 'success', title: 'Datos del grupo guardados correctamente'});

        } else {
          swal(Appsettings.APP_NAME, 'Tuvimos problemas al guardar los datos, favor de intentar más tarde', 'error');
        }
        this.working = false;
      });
  }

}
