export class GeneralEvent {
    id: number;
    name: string;
    description: string;
    place: string;
    eventDate: Date;
    urlImage: string;
    hours: string;
    published: boolean;
    index: number;
    filename?: string;
    fileContentBase64?: string;

    constructor() {
        this.id = 0;
        this.name = '';
        this.description = '';
        this.place = '';
        this.hours = '';
        this.urlImage = '';
        this.published = false;
    }
}

