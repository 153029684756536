import { Routes, RouterModule } from '@angular/router';
import { CoursesLayoutComponent } from './courses-layout.component';
import { CourseContainComponent } from './course-contain/course-contain.component';
import { CourseExamsComponent } from './course-exams/course-exams.component';
import { CourseHomeworksComponent } from './course-homeworks/course-homeworks.component';

const AppPageCourse: Routes = [
  {
    path: 'course',
    component: CoursesLayoutComponent,
    children: [
        { path: 'content/:id/:nodeId', component:  CourseContainComponent },
        { path: 'exams/:id/:nodeId', component:  CourseExamsComponent },
        { path: 'homeworks/:id/:nodeId', component:  CourseHomeworksComponent }
    ]
  }
];

export const COURSE_ROUTES = RouterModule.forChild( AppPageCourse );
