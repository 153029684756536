import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(  private httpService: HttpService) { }

    // Obtiene todos los libros psginados
    getAll() {

      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/messages/conversations`;
      return this.httpService.get( API_URL )
                             .map( res => res.json());
    }

    // Obtiene las conversaciones de un grupo
    getAllMessages( chatGroupId: number) {
      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/messages?id=${ chatGroupId }`;
      return this.httpService.get( API_URL )
                             .map( res => res.json());
    }

    // Envia un mensaje
    save( item: {} ) {
      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/messages`;
      const HEADERS = new Headers();

      HEADERS.append('Content-Type', 'application/json');

      return this.httpService.post(API_URL, JSON.stringify( item ), { headers: HEADERS } )
                            .map( res => res.json());
    }

    // Obtiene todos los libros psginados
    getAllUser() {

      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/messages/contacts`;
      return this.httpService.get( API_URL )
                             .map( res => res.json());
    }
}
