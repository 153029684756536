import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './recover/recover.component';
import { PagesexternalComponent } from './pagesexternal.component';
import { RegistryComponent } from './registry/registry.component';
import { ContaintListComponent } from './containt-list/containt-list.component';
import { ContaintDetailComponent } from './containt-detail/containt-detail.component';
import { TipListComponent } from './tip-list/tip-list.component';
import { TipDetailComponent } from './tip-detail/tip-detail.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { EventListComponent } from './event-list/event-list.component';

const AppPageExternalRoutes: Routes = [
  {
    path: 'home',
    component: PagesexternalComponent,
    children: [
        /*{ path: 'login', component: LoginComponent},
        { path: 'recover', component: RecoverComponent}, */
        { path: 'registry', component: RegistryComponent},
        { path: 'list-news', component: ContaintListComponent},
        { path: 'detail-new/:id', component: ContaintDetailComponent},
        { path: 'list-tips', component: TipListComponent},
        { path: 'detail-tip/:id', component: TipDetailComponent},
        { path: 'list-events', component: EventListComponent},
        { path: 'detail-event/:id', component: EventDetailComponent},
        { path: '', redirectTo: '/home/login', pathMatch: 'full'}
    ]
  }
];
export const PAGESEXTERNAL_ROUTES = RouterModule.forChild( AppPageExternalRoutes );
