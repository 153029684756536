import { Injectable } from '@angular/core';
import { Http, XHRBackend, RequestOptions, Request, RequestOptionsArgs, Response, Headers } from '@angular/http';
import { StorageService } from '../shared/storage.service';
import { SessionService } from '../security/session.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/mergeMap';

@Injectable()

export class HttpService  extends Http {

  constructor( backend: XHRBackend,
               options: RequestOptions,
               private storageService: StorageService,
               private _srvSession: SessionService ) {
   super(backend, options);
  }

  request(url: string|Request, options?: RequestOptionsArgs): Observable<Response> {
    const TOKEN = this.storageService.token;
    if (typeof url === 'string') {
      if (!options) {
        options = {headers: new Headers()};
      }
      options.headers.set('Authorization', `Bearer ${TOKEN}`);
    } else {
      url.headers.set('Authorization', `Bearer ${TOKEN}`);
    }

    return super.request(url, options).catch(err => {
      if ( err.status === 401 ) {
        if ( this.storageService.refreshToken !== undefined) {
          return this._srvSession.refreshAuthenticationTaken( this.storageService.refreshToken )
                      .flatMap( res => {
                         this.storageService.refreshToken = res.refresh_token;
                         this.storageService.token = res.access_token;
                         return this.request( url, options );
                      } ).catch( e => {
                        this.storageService.logoutRedirect();
                        return Observable.throw(e);
                      });
        } else {
          this.storageService.logoutRedirect();
        }
      } else {
        return Observable.throw(err);
      }
    });
  }
}
