import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Appsettings } from '../../../../configuration/appsettings';

// SERVICES
import { UserService, CatalogService } from '../../../../service/service.index';

// COMPONENT SERVICES
import { SitemapService } from '../../../../core/sitemap/sitemap.service';
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';

// DIALOGS COMPONENTS
import { DialogUserComponent } from '../../../../dialogs/dialog-user/dialog-user.component';

// MODELS
import { User } from '../../../../models/security/user.model';
import { Role } from '../../../../models/security/role.model';
import { Pagination } from '../../../../models/general/pagination.model';

import swal from 'sweetalert2';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit  {

  working: boolean;
  users: User [] =[];
  showFilters: boolean;
  roles: any[];

  textSearch: string;
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor(
    public dialog: MatDialog ,
    public _srvUser: UserService,
    public _srvSiteMap: SitemapService,
    public _srvCatalog: CatalogService,
    private _srvHeader: AdminHeaderService  ) {

      this.showFilters = false;
      this.working  = false;
      this._srvSiteMap.setSiteMap( [{ 'description' : 'Administración',
                                      'isCurrent' : false },
                                    { 'description' : 'Usuario' ,
                                    'isCurrent' : true }]);
  }

  ngOnInit(): void {
    this.setPage( { page: 1, size: this.sizePage });
    this.getCatalogs();
    this._srvHeader.setTitle('Administración de usuarios');
  }

  // Agregar un nuevo registro
  onCreate ( ) {

    const DIALOG_REF = this.dialog.open( DialogUserComponent, {
      width: '750px',
      height: '600px',
      data: {
          user: new User(0, '', '', new Date(), '', new Role(1, '', ''), '', '1', '', 1, '', '', ''),
        catalogs: this.roles
      },
      autoFocus: false,
      disableClose: true
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      if ( response ) {
        this.setPage( { page: 1, size: this.sizePage });
      }
    });
  }

  // Asignar los valores para editar un registro
  onEditItem( element ): void {

    element.isEdit = true;
    const DIALOG_REF = this.dialog.open( DialogUserComponent, {
      width: '750px',
      height: '500px',
      data: {
        user: element,
        catalogs: this.roles
      },
      autoFocus: false,
      disableClose: true
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      if ( response ) {
        this.setPage( { page: 1, size: this.sizePage });
      }
    });

  }

  // Función para eliminar un elemento
  onDeleteItem( element ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.working = true;
        this._srvUser.delete( element.id ).subscribe( res => {

          if ( res.success ) {
            console.log(res);
            this.working = false;
            swal(Appsettings.APP_NAME, res.message, 'success' );
            this.setPage( { page: 1, size: this.sizePage });

          } else {
            this.working = false;
            swal(Appsettings.APP_NAME, res.message, 'error' );
          }
        }, err => {
          this.working = false;
          swal( Appsettings.APP_NAME , 'Problemas al eliminar el usuario',  'error' );
        });
      }
    });


  }

  // Realizar búsqueda
  onKeyUp(event) {
    if ( this.textSearch.length >= 3 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Obtener los catálogos utilizados para usuario
  getCatalogs() {

    this._srvCatalog.getRoleAll().subscribe( res => {
      if ( res.success ) {
        let activeRoles = res.data.filter( x => x.active === true);
        this.roles = activeRoles ;
      }
    });
  }

   // funcion para cambiar la pagina
   setPage( paginationData: object ) {

    this.working = true;
    this._srvUser.getAllPagination( Number(paginationData['size']), Number(paginationData['page']) , this.textSearch).subscribe( result => {

      this.users   = [];
      let index = 1;

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let u of result.data) {
            let user = new User(u.id, u.fullName, u.email, new Date(u.birthday)
                                , u.gender, new Role(u.role.id, u.role.name, u.role.name), u.key, u.stateId, '', 1, '', '', '');
            user.index = this.pagination.getIndexRegister(index);
            user.active = u.active;
            user.cityId = u.cityId;

            this.users.push( user );
            index++;
        }
      }
      this.working = false;
    }, result => {
      this.working = false;
    });
  }

}





