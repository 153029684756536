import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusInvitation'
})
export class StatusInvitationPipe implements PipeTransform {

  transform(value: Date, args?: any): any {
    return  (value) ? 'Confirmada' : 'Enviada';
  }

}
