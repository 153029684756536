import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material';


import swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-teacher-content',
  templateUrl: './dialog-teacher-content.component.html',
  styleUrls: ['./dialog-teacher-content.component.scss']
})
export class DialogTeacherContentComponent implements OnInit {

  cancelUpload: boolean;
  constructor(  private dialogRef: MatDialogRef<DialogTeacherContentComponent>,
                ) {
    this.cancelUpload = false;
  }

  ngOnInit() {
  }

  // Cierre el modal
  onCloseConfirm( event ) {
    if (event ) {
      this.dialogRef.close( event );
    } else {
      swal({
        title: '¿Estás seguro?',
        text: '¡Si cierra la ventana la carga del archivo se cancelará!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText:  '¡Sí, cancelar!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {

        if (!result.value) {
          this.cancelUpload = false;
        } else {
          this.dialogRef.close( event );
          this.cancelUpload = true;
        }
      });
    }
  }

  // Guarda el nuevo recurso
  onSubmit() {

  }

}
