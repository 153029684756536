import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../../configuration/appsettings';
import { Router } from '@angular/router';
import { FileHolder } from 'angular2-image-upload';

// SERVICES
import {  UserService, CityService } from '../../../service/service.index';

// MODELS
import { Role } from '../../../models/security/role.model';
import { Contry } from '../../../models/catalogs/contry.model';
import { State } from '../../../models/catalogs/state.model';

// COMPONENT SERIVCES
import { HeaderService } from '../../../components/layouts/anonymous/header/header.service';
import swal from 'sweetalert2';
import { FileContent } from '../../../models/catalogs/file.model';

@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.scss']
})
export class RegistryComponent implements OnInit {

  working; boolean;
  frmRegistry: FormGroup;
  urlPrivacy = Appsettings.PRIVACY_ANNOUNCEMENT;

  roles: Role[] = [];
  contries: Contry[] = [];
  states: State[] = [];

  showKey: boolean;
  imageProfile: string;

  fileContent: File;
  fileContentBase: string;
  fileName: string;
  isUpdateFile: boolean;

  constructor(
    private _srvHeader: HeaderService,
    private _srvUser: UserService,
    private _router: Router,
    private _srvCity: CityService
 ) {

    this.working = false;
    this.showKey = false;

    // INIT DATA SELECT
    this.roles.push( new Role(2, 'Profesor', 'Profesor') );
    this.roles.push( new Role(3, 'Alumno', 'Profesor') );

    this.getCities();

    this.frmRegistry = new FormGroup({
      'fullName' : new FormControl('', [ Validators.required ]),
      'birthDay': new FormControl('', [ Validators.required ]),
      'gender': new FormControl('M', [ Validators.required ]),
      'contry': new FormControl(1, [ Validators.required ]),
      'state': new FormControl(1, [ Validators.required ]),
      'email': new FormControl('', [ Validators.required,
                                     Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$') ]),
      'password': new FormControl('', [ Validators.required,
                                        Validators.pattern(/^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[:;<>?,.@#\]])\S{8,}$/) ]),
      'confimPassword': new FormControl(''),
      'role': new FormControl('', [ Validators.required ]),
      'keycct': new FormControl(''),
      'isAgree': new FormControl(false),
      'CCT':        new FormControl(''),
      'institution': new FormControl(''),
    });

    this.frmRegistry.controls['confimPassword'].setValidators([
      Validators.required,
      this.confirmPasswordValidator.bind(this.frmRegistry)
    ]);

    this.frmRegistry.controls['isAgree'].setValidators([
      this.agreeValidator
    ]);

  }

  ngOnInit() {
    this._srvHeader.showReturnButton( false );
  }

  // Obtener el cátalogo de ciudades
  getCities() {
    this.working = true;
    this._srvCity.getAll().subscribe( res => {
       if ( res.success ) {
         for ( const c of res.data ) {
          const city = new Contry( c.id , c.description);
          this.contries.push(city);
         }
       }
       this.working = false;
    }, err => {
      this.working  = false;
    }, () => {
      if ( this.contries.length > 0 ) {
        this.frmRegistry.controls['contry'].setValue(this.contries[0].id);
        this.getStates( this.contries[0].id );
      }
    });
  }

  // Obtener los estados de una ciudad
  getStates( cityId: number) {

    this.working = true;
    this._srvCity.getStates( cityId ).subscribe( res => {

      if ( res.success ) {
        for ( const s of res.data ) {
          this.states.push( new State( s.id , s.name));
        }
      }

      this.working = false;
    }, err => {
      this.working = false;
    });
  }


  onSubmit() {

    let fileUpload = new FileContent();

    if ( this.fileContent ) {

      const arrayFileBase = this.fileContentBase.split(',');

      fileUpload = new FileContent(
        arrayFileBase[1],
        this.fileContent.type,
        this.fileContent.name,
        arrayFileBase[0]
      );
    }

    const USER = {
      'fullName': this.frmRegistry.controls['fullName'].value,
      'birthDay': this.frmRegistry.controls['birthDay'].value,
      'gender': this.frmRegistry.controls['gender'].value,
      'contry': this.frmRegistry.controls['contry'].value,
      'stateId': this.frmRegistry.controls['state'].value,
      'email': this.frmRegistry.controls['email'].value,
      'password': this.frmRegistry.controls['password'].value,
      'roleId': this.frmRegistry.controls['role'].value,
      // 'key': this.frmRegistry.controls['keycct'].value,
      'profileimage': this.fileContentBase,
      'filename': this.fileName,
      'fileContentBase64': fileUpload.content,
      'systemId': Appsettings.SYSTEM_ID,
      'institution': this.frmRegistry.controls['institution'].value
    };

    this.working = true;

    this._srvUser.register( USER ).subscribe( res => {

      this.working = false;
      if ( res.success ) {
        swal(Appsettings.APP_NAME, res.message, 'success');
        setTimeout(() => {
          this._router.navigateByUrl('/home/login');
        }, 5000);
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
    }, err => {
      this.working = false;
      swal('Problemas al realizar el registro', 'SAIL', 'error');

    });
  }

  // Evento de cambiar país
  onChangeContry( $event ) {

    if ($event.value > 0) {
      this.getStates( $event.value );
    } else {
      this.states = [];
    }

  }

  onChangeRole(event) {
    // tslint:disable-next-line:radix
    // if ( parseInt( event) === 2) {
    //   this.showKey = true;
    //   this.frmRegistry.controls['keycct'].setValidators([
    //     Validators.required
    //   ]);
    //   this.frmRegistry.controls['keycct'].updateValueAndValidity();
    // } else {
    //   this.showKey = false;
    //   this.frmRegistry.controls['keycct'].setValue('');
    //   this.frmRegistry.controls['keycct'].setValidators([]);
    //   this.frmRegistry.controls['keycct'].clearValidators();
    //   this.frmRegistry.controls['keycct'].updateValueAndValidity();
    // }
    // this.frmRegistry.controls['keycct'].markAsUntouched();
  }

  // Validación de formulario
  confirmPasswordValidator(control: FormControl): { [s: string]: boolean }  {

    const forma: any = this;

    if ( control.value !== forma.controls['password'].value ) {
      return {
        confirmPasswordValidator: true
      };
    }

    return null;
  }

  // Validación de formulario
  agreeValidator(control: FormControl): { [s: string]: boolean }  {

    if ( control.value === false ) {
      return {
        agreeValidator: true
      };
    }

    return null;
  }

  onRemoved(event: FileHolder) {
    this.imageProfile = '';
    this.fileContentBase = '';
    this.fileContent = null;
    this.fileName = '';
  }

  onUploadFinished(event: FileHolder) {

    this.imageProfile    = event.src;
    this.fileContentBase = event.src;
    this.fileContent     = event.file;
    this.fileName        = this.fileContent.name;
    this.isUpdateFile    = true;

  }

  onUploadStateChanged(state: boolean) {
  }

    // Validar usuario contraside
    focusOutEmail() {
      if ( this.frmRegistry.controls['email'].valid ) {
        this.working = true;
        this._srvUser.validateUserSIDE( this.frmRegistry.controls['email'].value ).subscribe( res => {
          const cct: string[] = [];
          const cctUser = res.data.map( x => x.cct);

          for ( const c of cctUser ) {
            if ( cct.indexOf(c) === -1 ) {
              cct.push(c);
            }
          }

         this.frmRegistry.controls['CCT'].setValue( cct.join(','));
          this.working = false;
        }, err => {
          this.working = false;
        });
      }
    }
}
