import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// SERVICES
import { StorageService, NodeService } from '../../../../service/service.index';
import { UserUtilities } from '../../../../shared/user.utilities';

// MODEL
import { ContentBook } from '../../../../models/content/contentBook.model';
import { BookNavService } from './book-nav.service';

@Component({
  selector: 'app-book-nav',
  templateUrl: './book-nav.component.html',
  styleUrls: ['./book-nav.component.scss']
})
export class BookNavComponent implements OnInit {

  nameUser: string;
  profileImage: string;
  email: string;
  menu: any[] = [];
  working: boolean;

  accessType: number;
  idNodo: number;
  selectedNode: any;

  contentsBook: ContentBook[] = [];
  contentVideo: ContentBook[] = [];
  contentAudio: ContentBook[] = [];
  contentFile: ContentBook[] = [];



  constructor(  private _route: ActivatedRoute,
                public _bookNavService: BookNavService,
                private _svrStorage: StorageService,
                private _svrNode: NodeService,
                private _router: Router ) {
  }

  // Inicializar los datos del libro seleccionado
  ngOnInit() {

  }

  onChangeUnit( id: number ) {

    this._bookNavService.getOdaCotent( id );
  }

}
