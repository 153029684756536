import { Injectable } from '@angular/core';
import { StorageService  } from '../shared/storage.service';
import { HttpService } from '../security/http.service';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';



@Injectable()
export class PermissionService {
  constructor(  public storageService: StorageService,
                private http: Http,
                private httpService: HttpService) {
  }

  // Método que realiza petición al API Ppar obtener el listado de roles
  getAll() {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/permissions`;


    return this.httpService
        .get(apiUrl)
        .map(res => res.json());
  }

  getAllPagination(sizePage: number, currentPage: number, filter: string) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/permissions/all`;
    const params = new URLSearchParams();

    params.append('pageSize', sizePage.toString());
    params.append('currentPage', currentPage.toString());
    params.append('filter', filter);

    return this.httpService
        .get(API_URL, { params })
        .map(res => res.json());
   }

  // Método que realiza el registro de un nuevo permiso
  register( permisson) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/permissions`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.httpService
    .post(apiUrl, JSON.stringify( permisson ), { headers : headers})
    .map( res => res.json());
  }

  // Método que realiza la actualización del permiso
  update( permission ) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/permissions`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.httpService
    .put(apiUrl, JSON.stringify( permission ), { headers : headers})
    .map( res => res.json());
  }

  // Método que elimina un permiso
  updateStatus( permission ) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/permissions`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.httpService
    .put(apiUrl, JSON.stringify( permission ), { headers : headers})
    .map( res => res.json());
  }

  delete( id: number ) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/permissions?id=${id}`;
    return this.httpService.delete(apiUrl).map( res => res.json());
  }
}
