import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor( private httpService: HttpService ) { }

  // Obtener todos las ciudades 
  getAll() {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/cities`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }


  // Obtenet los estades una ciudad
  getStates( cityId: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/cities/${cityId}/states`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

}
