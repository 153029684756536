import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTimeSong'
})
export class GetTimeSongPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let audio = new Audio();
    let time;
    audio.src = value;
    audio.oncanplaythrough = () => {
      time = audio.duration;
    };
  }


  // conversion de minutos a segundos
  formatTime(seconds: any) {
    let minutes: any = Math.floor(seconds / 60);
    minutes = (minutes >= 10) ? minutes : '0' + minutes;
    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : '0' + seconds;
    return minutes + ':' + seconds;
  }
}
