import { Group } from '../courses/group.model';
export class Notice {
  id: number;  
  title: string;
  description: string;
  registerDate: Date;
  courseId: number = 0;  
  active: boolean;
  courses: number[];
  index: number;
  groupId: number = 0;
  groups: Group[];
}
