import { Component, OnInit } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';

// SERVICES
import { NewsHomeService } from '../../../service/service.index';

// MODELS
import { NewHome } from '../../../models/home/new.model';
import { Pagination } from '../../../models/general/pagination.model';

declare function setMinHeightByElement(selector): any;

@Component({
  selector: 'app-containt-list',
  templateUrl: './containt-list.component.html',
  styleUrls: ['./containt-list.component.scss']
})
export class ContaintListComponent implements OnInit {

  news: NewHome[] = [];
  working: boolean;

  // PAGINATION VARIABLES
  textSearch: string = '';
  sizePage: string = '8';
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor( private _srvNews: NewsHomeService) {
    this.setPages( { page: 1, size: this.sizePage });
  }

  ngOnInit() {    
    setMinHeightByElement('.container-home');
  }


  setPages(paginationData: object) {
    this.working = true;
    this.page    =  Number(paginationData['page']);
    this._srvNews.getAll( this.page , Number(paginationData['size']) , this.textSearch).subscribe( result => {

      
      this.news   = [];
      let index = 1;

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];


        for ( let n of result.data) {
          let newHome = new NewHome();

          newHome.id = n.id;
          newHome.description = n.description;
          newHome.published = n.published;
          newHome.registerDate = new Date(n.registerDate);
          newHome.title = n.title;
          newHome.urlImage = n.urlImage;
          newHome.index = this.pagination.getIndexRegister(index);

          this.news.push( newHome );
          index++;
        }
      }
      this.working = false;
    }, err => {
      this.working = false;
    }, () => {
      setMinHeightByElement('.container-home');
    });
  }


}
