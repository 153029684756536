import { Component, OnInit } from '@angular/core';

// SERVICES
import { TipsHomeService } from '../../../service/service.index';

// MODELS
import { TipHome } from '../../../models/home/tip.model';
import { Pagination } from '../../../models/general/pagination.model';
import { ActivatedRoute } from '@angular/router';

declare function setMinHeightByElement(selector): any;

@Component({
  selector: 'app-tip-detail',
  templateUrl: './tip-detail.component.html',
  styleUrls: ['./tip-detail.component.scss']
})
export class TipDetailComponent implements OnInit {

  tips: TipHome[] = [];
  working: boolean;
  id: number;
  tipSelected: TipHome;

  // PAGINATION VARIABLES
  textSearch: string = '';
  sizePage: string = '8';
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor( private _srvTip: TipsHomeService,
               private _route: ActivatedRoute ) { 
   
                this._route.params.subscribe( params => {
                  this.id = Number(params['id']);
            
                  this.working = true;
                  if ( this.id > 0) {
                    this._srvTip.getById( this.id).subscribe( res => {
            
                      if ( res.success ) {
                        this.tipSelected = new TipHome();
                        this.tipSelected .id = res.data.id;
                        this.tipSelected .description = res.data.description;
                        this.tipSelected .published = res.data.published;
                        this.tipSelected .registerDate = new Date(res.data.registerDate);
                        this.tipSelected .name = res.data.name;
                        this.tipSelected .urlImage = res.data.urlImage;
                      }
                    });
                  }
                  this.working = false;
                }, err => {
                  this.working = false;
                });

  }

  ngOnInit() {
    setMinHeightByElement('.container-home');
  }
 
}
