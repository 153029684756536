import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// ROUTES
import { COURSE_ROUTES } from './courses.routes';

// LAYOUT
import { CoursesLayoutComponent } from './courses-layout.component';

// MODULES
import { PublicationModule } from '../../components/layouts/publication/publication.module';
import { AdmonComponentModule } from '../../components/administrator/admon-components.module';
import { AdministratorModule } from '../../components/layouts/administrator/administrator.module';
import { CoreModule } from '../../core/core.module';

// COMPONENTS
import { CourseContainComponent } from './course-contain/course-contain.component';
import { CourseExamsComponent } from './course-exams/course-exams.component';
import { CourseHomeworksComponent } from './course-homeworks/course-homeworks.component';

// MATERIALIZE
import {  MatIconModule, MatButtonModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    PublicationModule,
    AdministratorModule,
    CoreModule,
    AdmonComponentModule,
    MatIconModule,
    MatButtonModule,
    COURSE_ROUTES
  ],
  declarations: [
    CoursesLayoutComponent,
    CourseContainComponent,
    CourseExamsComponent,
    CourseHomeworksComponent
  ]
})
export class CoursesModule { }
