export class ScheduledEvent {
  id: number;
  active: boolean;
  courseId: number;
  dateEvent: Date;
  endEvent: Date;
  description: string;
  duration: number;
  groups: number[];
  initTime: string;
  name: string;
}
