import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

// MODELS
import { Role } from '../../models/security/role.model';
import { Contry } from '../../models/catalogs/contry.model';
import { State } from '../../models/catalogs/state.model';
import { User } from '../../models/security/user.model';

// SERVICEs
import { UserService, CityService } from '../../service/service.index';

import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';

@Component({
  selector: 'app-dialog-user',
  templateUrl: './dialog-user.component.html',
  styleUrls: ['./dialog-user.component.scss']
})
export class DialogUserComponent implements OnInit {

  frmRegistry: FormGroup;
  roles: Role[] = [];
  contries: Contry[] = [];
  states: State[] = [];
  showKey: boolean;
  working: boolean = false;
  isChangeData: boolean = false;
  user: User;


  constructor(public dialogRef: MatDialogRef<DialogUserComponent>,
              public _srvUser: UserService,
              private _srvCity: CityService,
              @Inject(MAT_DIALOG_DATA) public data: any) {

                this.user = data.user;
                this.frmRegistry = new FormGroup({
                  'id' : new FormControl(  this.user.id , [ Validators.required ]),
                  'fullName' : new FormControl(  this.user.fullName, [ Validators.required ]),
                  'birthDay': new FormControl(  this.user.birthday, [ Validators.required ]),
                  'gender': new FormControl(this.user.gender, [ Validators.required ]),
                  'contry': new FormControl('', [ Validators.required ]),
                  'state': new FormControl('' ,[ Validators.required ]),
                  'password': new FormControl('', [ Validators.required, Validators.pattern(/^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[:;<>?,.@#\]])\S{8,}$/) ]),
                  'confimPassword': new FormControl(''),
                  'email': new FormControl(this.user.email, [ Validators.required,
                                                 Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$') ]),
                  'role': new FormControl(this.user.role.id, [ Validators.required ]),
                  'keycct': new FormControl(this.user.keycct),
                });

                if ( this.user.id === 0 ) {
                  this.frmRegistry.controls['confimPassword'].setValidators([
                    Validators.required,
                    this.confirmPasswordValidator.bind(this.frmRegistry)
                  ]);

                  this.frmRegistry.controls['password'].setValidators([
                    Validators.required,
                    Validators.pattern(/^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[:;<>?,.@#\]])\S{7,}$/)
                  ]);
                }

    if ( this.user.role.id === 2 ) { // ROL PROFESOR
      this.showKey = true;
      this.frmRegistry.controls['keycct'].setValidators([Validators.required]);
      this.frmRegistry.controls['keycct'].updateValueAndValidity();
    }

     // INIT DATA SELECT
    for ( let role of data.catalogs ) {
      this.roles.push(new Role(role.id, role.name, role.name));
    }

   this.getCities();
  }

  ngOnInit() {
    this.frmRegistry.controls['birthDay'].setValue(this.user.birthday);
  }

  onCloseConfirm() {
    this.dialogRef.close(this.isChangeData);
  }

  // Obtener el cátalogo de ciudades
  getCities() {
    this.working = true;
    this._srvCity.getAll().subscribe(res => {
      if (res.success) {
        for (const c of res.data) {
          const city = new Contry(c.id, c.description);
          this.contries.push(city);
        }
      }
      this.working = false;
    }, err => {
      this.working = false;
    }, () => {
      if (this.contries.length > 0) {

        if ( this.user.id > 0) {
          this.frmRegistry.controls['contry'].setValue(this.user.cityId);
          this.getStates(this.user.cityId);
        } else {
          this.frmRegistry.controls['contry'].setValue(this.contries[0].id);
          this.getStates(this.contries[0].id);
        }
      }
    });
  }

  // Obtener los estados de una ciudad
  getStates(cityId: number) {

    this.working = true;
    this._srvCity.getStates(cityId).subscribe(res => {

      if (res.success) {
        for (const s of res.data) {
          this.states.push(new State(s.id, s.name));
        }
      }
      this.working = false;
    }, err => {
      this.working = false;
    }, () => {
      if ( this.user.state.id > 0) {
        this.frmRegistry.controls['state'].setValue(this.user.state.id);
      } else {
        this.frmRegistry.controls['state'].setValue( this.states[0].id);
      }
    });
  }

  onChangeRole(event) {
    if (event.value === 2) {
      this.showKey = true;
      this.frmRegistry.controls['keycct'].setValidators([
        Validators.required
      ]);
      this.frmRegistry.controls['keycct'].updateValueAndValidity();
    } else {
      this.showKey = false;
      this.frmRegistry.controls['keycct'].setValue('');
      this.frmRegistry.controls['keycct'].setValidators([]);
      this.frmRegistry.controls['keycct'].clearValidators();
      this.frmRegistry.controls['keycct'].updateValueAndValidity();
    }
    this.frmRegistry.controls['keycct'].markAsUntouched();
  }

  onSubmit() {

    const USER = {
      'id': this.user.id,
      'fullName': this.frmRegistry.controls['fullName'].value,
      'birthDay': this.frmRegistry.controls['birthDay'].value,
      'gender': this.frmRegistry.controls['gender'].value,
      'contry': this.frmRegistry.controls['contry'].value,
      'stateId': this.frmRegistry.controls['state'].value,
      'email': this.frmRegistry.controls['email'].value,
      'roleId': this.frmRegistry.controls['role'].value,
      'key': this.frmRegistry.controls['keycct'].value,
      'systemId' : Appsettings.SYSTEM_ID
    };

    if ( this.user.id === 0) {
      USER['password'] =  this.frmRegistry.controls['password'].value;
      this.register( USER );
    } else {
      this.updateData( USER );
    }
  }

  // Validación de formulario
  confirmPasswordValidator(control: FormControl): { [s:string]: boolean }  {

      let forma: any = this;

      if ( control.value !== forma.controls['password'].value ) {
        return {
          confirmPasswordValidator: true
        };
      }

      return null;
  }

  updateData ( user ): void {

    this.working = true;
    this._srvUser.update( user ).subscribe( res => {

      if ( res.success ) {
        swal( Appsettings.APP_NAME, res.message, 'success');
        this.isChangeData = true;

      } else {
        swal( Appsettings.APP_NAME, res.message, 'error');
      }

      this.working = false;
    }, err => {
      this.working = false;
      swal('Problemas al realizar el registro', 'SAIL', 'error');

    });
  }

  register( user ): void {

    this.working = true;
    this._srvUser.register( user ).subscribe( res => {

      if ( res.success ) {
        swal( Appsettings.APP_NAME, res.message, 'success');
        this.isChangeData = true;
      } else {
        swal( Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
      swal('Problemas al realizar el registro', 'SAIL', 'error');
    });
  }

}
