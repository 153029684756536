export class FileContent {
  content: string;
  type: string;
  name: string;
  mimeType: string;

  constructor();
  constructor( _content: string,  _type: string, _name: string, _mimeType: string )
  constructor( _content?: string,  _type?: string, _name?: string, _mimeType?: string ) {

    this.content = _content || '';
    this.type = _type || '';
    this.name = _name || '';
    this.mimeType = _mimeType || '';

  }

}
