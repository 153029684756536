import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-dialog-book-glossary',
  templateUrl: './dialog-book-glossary.component.html',
  styleUrls: ['./dialog-book-glossary.component.scss']
})
export class DialogBookGlossaryComponent implements OnInit {

  glossaryId: number;

  constructor(  private dialogRef: MatDialogRef<DialogBookGlossaryComponent>,
                @Inject(MAT_DIALOG_DATA) public data) {
                  this.glossaryId = data.glossatyId;
                }

  ngOnInit() {
  }

  onCloseConfirm() {
    this.dialogRef.close(false);
  }
}
