import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../../configuration/appsettings';

import { AngularEditorConfig } from '@kolkov/angular-editor';

// Servicios
import { CourseService, ForumService } from '../../../service/service.index';

// Modelos
import { CourseTeacher } from '../../../models/courses/courseTeacher.model';

import swal from 'sweetalert2';


@Component({
  selector: 'app-add-thread',
  templateUrl: './add-thread.component.html',
  styleUrls: ['./add-thread.component.scss']
})
export class AddThreadComponent implements OnInit {

  working: boolean;
  frmThread: FormGroup;
  courses: CourseTeacher[] = [];
  groups: any[] = [];

  htmlContent: string;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '3rem',
    placeholder: 'Ingrese su comentario aquí...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
  };

  // Retornamos el cierre del modal
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(  private _srvCourse: CourseService,
                private _srvForum: ForumService) {
    this.working = false;
    let newDate = new Date();
    this.frmThread = new FormGroup({
      id: new FormControl(0),
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      initDate: new FormControl(newDate, [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      courseId: new FormControl('', [Validators.required]),
      groups: new FormControl('')
    });

    this.getCourses();
  }

  ngOnInit() {
  }

  // Obtiene todos los cursos
  getCourses() {
    this.working = true;
    this._srvCourse.getAll().subscribe( result => {
      if ( result.success ) {
        for ( let c of result.data ) {
          let course = new CourseTeacher();
          course.id = c.id;
          course.description = c.description;
          course.groups = c.groups;
          course.name = c.name;

          this.courses.push( course );
        }
        this.courses = this.courses.filter( item => item.groups.length > 0);
      }
      this.working = false;
    });
  }

  // Selección de un curso
  onChangeSection( event ) {
    this.groups = [];
    let courseId = event.value;
    let groups = this.courses.filter( item => item.id === courseId)[0];
    for ( let g of groups.groups) {
      this.groups.push({
        id: g.id,
        description: g.description
      });
    }
  }

  // Guarda el nuevo hilo
  onSaveThread() {
    this.working = true;
    let thread = {
      'title': this.frmThread.controls['title'].value,
      'description': this.frmThread.controls['description'].value,
      'initDate': this.frmThread.controls['initDate'].value,
      'courseId': this.frmThread.controls['courseId'].value,
      'groups': this.frmThread.controls['groups'].value,
      'endDate': this.frmThread.controls['endDate'].value,
    };

    this._srvForum.saveThread( thread ).subscribe( result => {
      if ( result.success ) {
        swal( Appsettings.APP_NAME, result.message , 'success');
        setTimeout(() => {
          this.working = false;
          this.closeModal.emit( true  );
        }, 2000);
      } else {
        swal( Appsettings.APP_NAME, result.message , 'error');
      }
    });
  }
}
