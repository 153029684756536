export class PublishingProfile
 {
  id: number;
  description: string;
  active: boolean;
  nodes: number[];
  index: number;

  constructor(_id: number, _description: string, _active: boolean, _nodes: number[]) {
    this.id = _id;
    this.description = _description;
    this.active = _active;

    this.nodes = _nodes;
  }
}
