import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-studen-homework',
  templateUrl: './dialog-studen-homework.component.html',
  styleUrls: ['./dialog-studen-homework.component.scss']
})
export class DialogStudenHomeworkComponent implements OnInit {

  studensHomework: any[] = [];
  homeworkName: string;
  homeworkDescription: string;
  constructor(  private dialogRef: MatDialogRef<DialogStudenHomeworkComponent>,
                @Inject(MAT_DIALOG_DATA) public data ) {
                  this.studensHomework = data.files;
                  this.homeworkName = data.homeworkName;
                  this.homeworkDescription = data.homeworkDescription;
                }

  ngOnInit() {
  }

  onCloseConfirm() {
    this.dialogRef.close( true );
  }

}
