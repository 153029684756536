import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MenuService } from '../../service/service.index';

declare function setHeight(): any;

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  /* encapsulation: ViewEncapsulation.None*/
})

export class AdminLayoutComponent implements OnInit, AfterViewInit {
  constructor( public  _srvMenu: MenuService) {

    let body: any = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-login');
    body.classList.remove('bg-landing');
   }

  ngOnInit() {
  }


  ngAfterViewInit() {
    // JAVASCRIPT
     setHeight();

    }
}
