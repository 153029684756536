import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../../configuration/appsettings';

import { MatDialog } from '@angular/material';

// SERVICES
import { SectionService, LessonService } from '../../../service/service.index';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { Section } from '../../../models/content/section.model';
import { Lesson } from '../../../models/content/lesson.model';
import { Course } from '../../../models/catalogs/course.model';

import swal from 'sweetalert2';

// Modal
import { DialogRelationContentComponent } from '../../../dialogs/dialog-relation-content/dialog-relation-content.component';
import { from } from 'rxjs';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit, OnDestroy {

  working: boolean;

  courseId: number;
  courseName: string;
  course: Course;

  frmSection: FormGroup;
  sections: Section[] = [];
  section: Section;
  sectionId: number = 0;
  editSections: boolean;

  lessonContent: Lesson[] = [];
  frmClass: FormGroup;
  editClass: boolean = false;

  @ViewChild('formSection') formSection;
  @ViewChild('formClass') formClass;

  showBotton: boolean;
  showAddSection: boolean;

  constructor(private _srvHeader: AdminHeaderService,
              private _srvSection: SectionService,
              private _srvLesson: LessonService,
              private _route: ActivatedRoute,
              public dialog: MatDialog ) {

                this._route.params.subscribe( params => {

                  this.courseId = parseInt(params.id);
                  this.courseName = params.name;

                  this.course = JSON.parse(localStorage.getItem('sali.course'));

                  this._srvHeader.setTitle('Secciones del curso: ' + this.course.name );
                  this.getSections( this.courseId );

                });

                this.editSections = false;
                this.showBotton = true;
                this.showAddSection = true;

                // Formulario de nueva seccion
                this.frmSection = new FormGroup({
                  id: new FormControl(0),
                  name: new FormControl('', [ Validators.required]),
                  description: new FormControl('', [ Validators.required])
                });

                 // Formulario de nueva clase
                this.frmClass = new FormGroup({
                  id: new FormControl(0),
                  name: new FormControl('', [Validators.required]),
                  description: new FormControl('', [Validators.required])
                });

                this._srvHeader.setBreadcrumb([Appsettings.APP_NAME, 'Mi salón', 'Contenido']);

               }

ngOnInit() {

}

ngOnDestroy(): void {
  this._srvHeader.setBreadcrumb([]);
}

/********** SECTIONS *****************/

onChangeSection(event) {
 // console.log('change');
 this.getLessons();
}

// Obtener todas las secciones del curso
getSections( courseId: number) {
    this.working = true;
    this.sections = [];
    this._srvSection.getContentByIdCourse( courseId ).subscribe( result => {
      this.working = false;
      if ( result.success ) {
        for ( let s of  result.data) {
          let section = new Section();
          section.id = s.id;
          section.name = s.name;
          section.content = s.content;
          section.courseId = courseId;
          section.class = s.classCount;
          this.sections.push( section);
        }
      }

      if ( this.sections.length > 0) {
        this.sectionId = this.sections[0].id;
        this.getLessons();
      } else {
        this.lessonContent = [];
      }

    });
}

// Guarda la nueva sección
onClickSave(): void {

    this.section = new Section();

    this.section.name     = this.frmSection.controls['name'].value;
    this.section.content  = this.frmSection.controls['description'].value;
    this.section.courseId = this.courseId;
    this.section.id       = this.frmSection.controls['id'].value;

    if ( this.section.id > 0) {
      this.update();
    } else {
      this.save();
    }
}

// Guarda una nueva seccion
save(): void {
    this.working = true;
    this._srvSection.save( this.section ).subscribe( result => {
      if ( result.success) {

        this.section.id = result.data;
        this.editSections = false;
        this.sections.push(this.section);
        this.sectionId = this.section.id;

        this.getLessons();
        this.resetSectionForm();

        swal( Appsettings.APP_NAME, result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME, result.message, 'error');
      }
      this.working = false;
    });
}

// Actualiza la seccion
update(): void {
    this.working = true;
    this._srvSection.update( this.section).subscribe( result => {
      if ( result.success) {

        let sectionUpdate = this.sections.filter( x => x.id === this.section.id)[0];

        if (sectionUpdate) {
          sectionUpdate.name = this.section.name;
          sectionUpdate.content = this.section.content;
        }

        this.editSections = false;
        this.resetSectionForm();

        swal(Appsettings.APP_NAME, result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME, result.message, 'error');
      }
      this.working = false;
    });
}

// Inicializar el formulario de preguntas
resetSectionForm( ): void {
    this.frmSection.reset();
    this.frmSection.markAsUntouched();
    this.frmSection.markAsPristine();
    this.frmSection.updateValueAndValidity();
}

// Cancelar la modificación o el registro nuevo
onClickCancelSection() {
    this.editSections = false;
    this.resetSectionForm();
    this.onChangeStatus();
}

// Asignar los datos de una sección en el formulario para su edición
onClickEditSection() {

    let item = this.sections.filter( x => x.id === this.sectionId )[0] ;


    if ( item ) {
      this.frmSection.controls['id'].setValue(item.id);
      this.frmSection.controls['name'].setValue(item.name);
      this.frmSection.controls['description'].setValue(item.content);
      this.editSections = true;
    }
}

// Elimina una clase
onClickDeleteSection( ) {
  swal({
    title: '¿Deseas eliminar la sección?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '¡Sí!',
    cancelButtonText: 'Cancelar'
  }).then((res) => {
    if ( res.value) {
      this.working = true;
      this._srvSection.delete( this.sectionId ).subscribe( result => {
        if ( result.success) {
          swal(Appsettings.APP_NAME, 'Sección eliminada correctamente', 'success');
          this.sections = this.sections .filter( x => x.id !==  this.sectionId);
          this.sectionId = 0;
        } else {
          swal(Appsettings.APP_NAME , result.message, 'error');
          this.working = false;
        }
        this.working = false;
      }, err => {
        swal(Appsettings.APP_NAME , 'Problemas al conectarse con el servidor', 'error');
        this.working = false;
      });
    }
  });

}

// Oculata el boton agregar
onChangeStatus() {
  this.showBotton = ( this.showBotton ) ? false : true;
}

// Oculata el boton agregar sección
//onChangeStatusSection() {
//  this.showAddSection = ( this.showAddSection ) ? false : true;
// }

  /**************** LESSON *******************/

// Obtiene todas las clases de la sección
getLessons() {

  // console.log(this.sectionId);

  this.working = true;
  this.lessonContent = [];

  this._srvLesson.getContentByIdSection(this.sectionId).subscribe( res => {
    if ( res.success) {
      for (let l of res.data) {
        let lesson = new Lesson();
        lesson.id = l.id;
        lesson.name = l.name;
        lesson.content = l.content;
        lesson.files = l.files;
        this.lessonContent.push( lesson);
      }
    }
    this.working = false;
    // console.log(this.lessonContent);
  });
}

// Agrega una nueva clase
onClicAddLesson() {
  this.working = true;
  let id = this.frmClass.controls['id'].value;
  let lesson = new Lesson();

  lesson.id = id;
  lesson.name = this.frmClass.controls['name'].value;
  lesson.content = this.frmClass.controls['description'].value;



  if ( id === null  || id === 0) {
    lesson.courseSectionId = this.sectionId;
    this.createLesson( lesson );
  } else {
    this.updateLesson( lesson );
  }

}

// Crea una nueva clase
createLesson( lesson: Lesson) {

  this._srvLesson.save( lesson).subscribe( result => {
    if (result.success) {
      this.editClass = false;
      this.showAddSection = true;
      lesson.id = result.data;
      this.lessonContent.push( lesson );
      this.resetClassForm();
      swal(Appsettings.APP_NAME , 'Clase agregada correctamente', 'success');
    } else {
      swal(Appsettings.APP_NAME , result.message, 'error');
    }
    this.working = false;
  });
}

// Actualiza una clase
updateLesson( lesson: Lesson) {
  this._srvLesson.update( lesson ).subscribe( result => {
    if ( result.success) {
      this.showAddSection = true;
      this.editClass = false;
      let lessionUpdate = this.lessonContent.filter( x => x.id === lesson.id);

      if ( lessionUpdate ) {
        lessionUpdate[0].name = lesson.name;
        lessionUpdate[0].content = lesson.content;
      }

      this.resetClassForm();

      this.working = false;
    } else {
      this.editClass = false;
      this.working = false;
    }
  });

}

 // Inicializar el formulario de preguntas
resetClassForm( ): void {
  this.frmClass.reset();
  this.frmClass.markAsUntouched();
  this.frmClass.markAsPristine();
  this.frmClass.updateValueAndValidity();

}

// Cancelar edición o creación de una clase
onClickCancelClass() {
  this.editClass = false;
  this.resetClassForm();

}

// Asignar los datos al formulario de clase para su edición
onClickEditLesson( lession: Lesson ) {
  this.editClass = true;
  this.frmClass.controls['id'].setValue( lession.id);
  this.frmClass.controls['name'].setValue( lession.name);
  this.frmClass.controls['description'].setValue( lession.content);
}

// Elimina una clase
onClickDeleteLesson( element: Lesson) {
  swal({
    title: '¿Deseas eliminar la clase?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '¡Sí!',
    cancelButtonText: 'Cancelar'
  }).then((res) => {
    if ( res.value) {
      this.working = true;
      this._srvLesson.delete( element.id).subscribe( result => {
        if ( result.success) {
          swal(Appsettings.APP_NAME, 'Clase eliminada correctamente', 'success');
          this.lessonContent = this.lessonContent.filter( x => x.id !== element.id);

        } else {
          swal(Appsettings.APP_NAME , result.message, 'error');
          this.working = false;
        }
        this.working = false;
      }, err => {
        swal(Appsettings.APP_NAME , 'Problemas al conectarse con el servidor', 'error');
        this.working = false;
      });
    }
  });

}

// Mostrar y reiniciar el formulario de captura de una clase
onClickAddNewLesson() {
  this.editClass = true;
  this.resetClassForm();
}

// Abrir modal para relacionar archivos a la clase
openModal( lessionId: number, files: any[]) {

  let filesIds: number[] = [];
  if ( files ) {
    for ( let f of files ) {
      filesIds.push( f.id );
    }
  }
  const DIALOG_REF = this.dialog.open( DialogRelationContentComponent, {
    width: '1100px',
    height: '600px',
    autoFocus: false,
    disableClose: true,
    data: { id: lessionId, filesIds: filesIds}
  });

  DIALOG_REF.afterClosed().subscribe( response => {
    if ( response ) {
      this.getLessons();
    }
  });
}

}
