import { Injectable } from '@angular/core';

import { Headers } from '@angular/http';
import { HttpService } from '../security/http.service';
import { Appsettings} from '../../configuration/appsettings';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor( private _httpService: HttpService ) { }

  // Obtener todos los tags filtrados
  getAllPagination(sizePage: number, currentPage: number, filter: string) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/tags/all?pageSize=${sizePage}&currentPage=${currentPage}&filter=${filter}`;
    return this._httpService.get(API_URL)
                            .map(res => res.json());
   }

   // Remover un tag asignado de un contenido
   removeTag( id: number, contentId: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/tags/remove?id=${id}&contentId=${contentId}`;
    return this._httpService.delete(API_URL)
                            .map(res => res.json());

   }

}

