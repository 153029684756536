import { Component, OnInit } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';

// SERVICES
import { GeneralEventService } from '../../../service/service.index';

// MODELS
import { Pagination } from '../../../models/general/pagination.model';
import { GeneralEvent } from '../../../models/home/general-event.model';


declare function setMinHeightByElement(selector): any;

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  working: boolean;
  generalEvents: GeneralEvent[] = [];

  // PAGINATION VARIABLES
  textSearch: string = '';
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor( private _srvEvent: GeneralEventService ) { }

  ngOnInit() {
    this.setPage( { page: 1, size: this.sizePage });
    setMinHeightByElement('.container-home');
  }

  setPage( paginationData: object ) {

    this.working = true;
    this.page    =  Number(paginationData['page']);
    this._srvEvent.getAll( this.page , Number(paginationData['size']) , this.textSearch).subscribe( result => {

      this.generalEvents   = [];
      let index = 1;

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let n of result.data) {
            let event = new GeneralEvent();

            event.id = n.id;
            event.description = n.description;
            event.published = n.published;
            event.eventDate = new Date(n.registerDate);
            event.name = n.name;
            event.hours = n.hours;
            event.place = n.place;
            event.urlImage = n.urlImage;
            event.index = this.pagination.getIndexRegister(index);

            this.generalEvents.push( event );
            index++;
        }
      }
      this.working = false;
    }, result => {
      this.working = false;
    }, () => {
      setMinHeightByElement('.container-home');
    });

  }

}
