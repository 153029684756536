import { Component, OnInit } from '@angular/core';

// SERVICES
import { TipsHomeService } from '../../../service/service.index';

// MODELS
import { TipHome } from '../../../models/home/tip.model';
import { Pagination } from '../../../models/general/pagination.model';

declare function setMinHeightByElement(selector): any;

@Component({
  selector: 'app-tip-list',
  templateUrl: './tip-list.component.html',
  styleUrls: ['./tip-list.component.scss']
})
export class TipListComponent implements OnInit {

  tips: TipHome[] = [];
  working: boolean;

  // PAGINATION VARIABLES
  textSearch: string = '';
  sizePage: string = '8';
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor( private _srvTip: TipsHomeService) { 
    this.setPages( { page: 1, size: this.sizePage });
  }

  ngOnInit() {
  }

  setPages(paginationData: object) {
    this.working = true;
    this.page    =  Number(paginationData['page']);
    this._srvTip.getAll( this.page , Number(paginationData['size']) , this.textSearch ).subscribe( result => {

      this.tips   = [];
      let index = 1;

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let t of result.data) {
            let tip = new TipHome();

            tip.id = t.id;
            tip.description = t.description;
            tip.published = t.published;
            tip.registerDate = new Date(t.registerDate);
            tip.name = t.name;
            tip.urlImage = t.urlImage;
            tip.index = this.pagination.getIndexRegister(index);

            this.tips.push( tip );
            index++;
        }
      }
      this.working = false;
    }, err => {
      this.working = false;
    }, () => {
      setMinHeightByElement('.container-home');
    });
  }

}
