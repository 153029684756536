import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Servicios
import {  QuestionBankService } from '../../../service/service.index';

// Modelos
import { SelectQuestions } from '../../../models/courses/selectQuestions.model';

import { from } from 'rxjs';
import { element } from 'protractor';
// import { EventEmitter } from 'events';
@Component({
  selector: 'app-exam-select-questions',
  templateUrl: './exam-select-questions.component.html',
  styleUrls: ['./exam-select-questions.component.scss']
})
export class ExamSelectQuestionsComponent implements OnInit {
  quantity: number;
  rans: number[] = [];
  totalQuestions: number[] = [];
  questions: SelectQuestions[] = [];
  showAnswers: boolean;
  selectsIds: number[];
  idQuestionType: number;
  questionType: number;
  showQuestions: boolean;
  showMsg: boolean;
  // Leemos el id del tipo de pregunta
  @Input('typeQuestion') set typeQuestion( value: number) {
    this.idQuestionType = value;
  }
  // Leemos la cantidad de preguntas que queremos obtener
  @Input('quantityQuestions') set quantityQuestions(value: number) {
    this.quantity = value;
  }

  // Obtenermos el banco de preguntas
  @Input('bankQuestions') set bankQuestions(value: number) {
    if ( value > 0) {
      this.getQuestions( value );
    }
  }

  // Obtenemos la opcion mostar u ocultar respuesta correcta
  @Input('viewAnswers') set viewAnswers( value: boolean) {
    this.showAnswers = value;
  }

  // Obtemos los id enviados por el usuario
  @Input('idsSelects') set idsSelects( value: number[]) {
    this.selectsIds = value;

  }

  // Retornamos el arreglo de id's
  @Output() ReturnIds: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor(  private _srvQuestionsBank: QuestionBankService) {
  }

  ngOnInit() {
    this.showQuestions = true;
    this.showMsg = true;
  }

  // Retorna los ids seleccionados
  returnIds() {
    this.ReturnIds.emit( this.rans  );
  }

  // Obtiene todas la pregurnas de un banco
  getQuestions( idBank: number ) {

    this.questions = [];

    this._srvQuestionsBank.getBydId(idBank).subscribe( result => {
      let data = result.data;
      // Filtramos las preguntas activas
      let questions = data.questions.filter( elem => elem.active === true );
      // Obtenemos las preguntas con imagenes
      let questionImage = questions.filter( elem => elem.questionTypeId === 5);
      // Filtramos por el tipo de pregunta
      if ( this.idQuestionType || this.idQuestionType > 0) {
        this.questionType = this.idQuestionType;
        questions = questions.filter( item => item.questionTypeId === this.idQuestionType );

        // Agregamos las preguntas con imagenes al array de preguntas multiples
        if ( this.idQuestionType ===  1) {
          if ( questionImage.length > 0) {
            for ( let i of questionImage) {
              questions.push( i );
            }
          }
        }
      }

      // Genera el rando de ids deacuerdo a las preguntas filtradas

      if ( this.selectsIds.length > 0 && this.quantity == this.selectsIds.length  ) {
        this.rans =  this.selectsIds;
        this.generateArrayQuestions( questions );
      } else {
        this.numberRand(this.quantity, questions);
      }

    });

  }

  // Genera el array de preguntas
  generateArrayQuestions( questions ) {
    for ( let q of questions) {

      let question = new SelectQuestions();
      question.id = q.id;
      question.content = q.content;
      question.answers = q.answers;
      question.typeQuestions = q.questionTypeId;
      question.urlImage = q.urlImage;

      question.select = false;
      if ( this.rans.lastIndexOf(q.id) !== -1) {
        question.select = true;
      }

      this.questions.push( question);
    }
    this.showQuestions = ( this.questions.length > 0 ) ?  false : true;
    this.showMsg = false;
  }

  // Crea un array randon para seleccionar las preguntas
  numberRand( rand: number, questions: any[]): void {

    let questionsIds: number[] = [];
    this.rans = [];
    let count = 0;

    for (let q of questions) {
      questionsIds.push( q.id);
      questionsIds.sort((a, b) => a - b);
    }

    let max = questionsIds.pop();
    let min = questionsIds[0];

    if ( rand === questions.length) {
      for ( let q of questions) {
        this.rans.push( q.id);
      }
    } else {
      while ( count < rand) {
        let number = Math.floor(Math.random() * ( max - min) + min);
        if ( questionsIds.lastIndexOf( number) !== -1) {
          if ( this.rans.lastIndexOf( number ) === -1 ) {
            this.rans.push( number );
            count++;
          }
        }
      }
    }

    // Creamo el arreglo de preguntas
    this.generateArrayQuestions( questions );
  }

  // obtenemos el id selecionado y validamos para agregarlo o quitarlo
  sendIdQuestion( questionId: number) {
    let index = this.rans.indexOf(questionId);
    if ( index !== -1 )  {
      this.rans.splice(index, 1);
    } else {
      this.rans.push( questionId);
    }
  }
}
