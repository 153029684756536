import { Book } from "./book.model";

export class Node {
  active: boolean;
  description: string;
  id: number;
  nodeTypeDesc: string;
  nodeTypeId: number;
  parentId: number;
  urlImage: string;
  book?: Book;
  accessType: number;
  accessTypeDescription: string;
  constructor(_active: boolean,
              _description: string,
              _id: number,
              _nodeTypeDesc: string,
              _nodeTrpeId: number,
              _parentId: number,
              _urlImage: string) {

    this.active = _active;
    this.description = _description;
    this.id = _id;
    this.nodeTypeDesc = _nodeTypeDesc;
    this.nodeTypeId = _nodeTrpeId;
    this.parentId = _parentId;
    this.urlImage = _urlImage;
  }
}
