import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';
import { AdministratorModule  } from '../../components/layouts/administrator/administrator.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { SALI_ROUTES } from './sali.routes';

// MODULES
import { AdmonComponentModule } from '../../components/administrator/admon-components.module';

// COMPONENTS
import { SaliLayoutComponent } from './sali-layout.component';
import { LevelComponent } from './level/level.component';
import { BookComponent } from './book/book.component';


// import { DropdownTreeviewSelectComponent } from '../../core/ngx-dropdown-treeview-select/dropdown-treeview-select.component'

// import { DialogCourseComponent } from '../../dialogs/dialog-course/dialog-course.component';
// import { DialogGroupComponent } from '../../dialogs/dialog-group/dialog-group.component';
import { DialogInvitationComponent } from '../../dialogs/dialog-invitation/dialog-invitation.component';
import { DialogExamRatingsComponent } from '../../dialogs/dialog-exam-ratings/dialog-exam-ratings.component';
import { DialogBookGlossaryComponent } from '../../dialogs/dialog-book-glossary/dialog-book-glossary.component';
import { UserProfileDataComponent } from './profile/user-profile-data/user-profile-data.component';
import { DialogUserProfileImgComponent } from '../../dialogs/dialog-user-profile-img/dialog-user-profile-img.component';
import { DialogUserProfilePasswordComponent } from '../../dialogs/dialog-user-profile-password/dialog-user-profile-password.component';
import { DialogTeacherContentComponent } from '../../dialogs/dialog-teacher-content/dialog-teacher-content.component';
import { DialogEventDetailComponent } from '../../dialogs/dialog-event-detail/dialog-event-detail.component';
import { DialogStudentAnswersComponent } from '../../dialogs/dialog-student-answers/dialog-student-answers.component';
import { DialogSearchUserComponent } from '../../dialogs/dialog-search-user/dialog-search-user.component';
import { DialogAddThreadComponent } from '../../dialogs/dialog-add-thread/dialog-add-thread.component';
// MATERIALIZE
import { MatTabsModule,
  MatInputModule,
  MatDatepickerModule,
  MatRadioModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatPaginatorModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatBadgeModule,
  MatListModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatDividerModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatTooltipModule } from '@angular/material';

  import { EmbedVideo } from 'ngx-embed-video';


  import { ListExamComponent } from './list-exam/list-exam.component';
  import { GenerateGlossaryComponent } from './generate-glossary/generate-glossary.component';

  import { TreeviewModule } from 'ngx-treeview';
  import { MatTreeModule } from '@angular/material/tree';
  import { CdkTreeModule } from '@angular/cdk/tree';
  import { GlossaryListComponent } from './glossary-list/glossary-list.component';
  import { ImageUploadModule } from 'angular2-image-upload';
  import { ApplyExamComponent } from './apply-exam/apply-exam.component';

  import { DialogGetFileComponent } from '../../dialogs/dialog-get-file/dialog-get-file.component';
  import { DialogUploadFileComponent } from '../../dialogs/dialog-upload-file/dialog-upload-file.component';
  import { CalendarComponent } from './calendar/calendar.component';
  import { ForumComponent } from './forum/forum.component';
  import { PostComponent } from './post/post.component';

  import { ContentComponent } from './content/content.component';
  import { MessagesComponent } from './messages/messages.component';

  import { RelatedStudentsComponent } from './related-students/related-students.component';
  import { InventoryCourseComponent } from './inventory-course/inventory-course.component';
  import { GenerateNoticeComponent } from './generate-notice/generate-notice.component';
  import { NoticeListComponent } from './notice-list/notice-list.component';


@NgModule({
  declarations: [
    SaliLayoutComponent,
    LevelComponent,
    BookComponent,
    DialogInvitationComponent,
    DialogExamRatingsComponent,
    ListExamComponent,
    GenerateGlossaryComponent,
    GlossaryListComponent,
    DialogBookGlossaryComponent,
    DialogTeacherContentComponent,
    DialogEventDetailComponent,
    UserProfileDataComponent,
    DialogUserProfileImgComponent,
    DialogUserProfilePasswordComponent,
    ApplyExamComponent,
    DialogGetFileComponent,
    DialogUploadFileComponent,
    CalendarComponent,
    ContentComponent,
    DialogStudentAnswersComponent,
    MessagesComponent,
    ForumComponent,
    PostComponent,
    DialogSearchUserComponent,
    DialogAddThreadComponent,
    RelatedStudentsComponent,
    InventoryCourseComponent,
	GenerateNoticeComponent,
    NoticeListComponent
  ], exports: [
        UserProfileDataComponent
  ],
  imports: [
    SALI_ROUTES,
    CoreModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatListModule,
    AdministratorModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatBadgeModule,
    MatExpansionModule,
    AdmonComponentModule,
    TreeviewModule.forRoot(),
    CdkTreeModule,
    MatTreeModule,
    ImageUploadModule.forRoot(),
    MatTooltipModule,
    MatButtonToggleModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  entryComponents: [
    DialogExamRatingsComponent,
    DialogUserProfileImgComponent,
    DialogUserProfilePasswordComponent,
    DialogBookGlossaryComponent,
    DialogInvitationComponent,
    DialogTeacherContentComponent,
    DialogGetFileComponent,
    DialogUploadFileComponent,
    DialogEventDetailComponent,
    DialogStudentAnswersComponent,
    DialogSearchUserComponent,
    DialogAddThreadComponent
  ]
})
export class SaliModule { }
