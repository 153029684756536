import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;


  }

  questionBank( res , pic1, typeExam: boolean ) {
    let content;
    let questions = res.data.questions;
        content = [];
        // Titulo de examen
        let title = {
          text: res.data.name,
          style: 'title'
        };
        content.push(title);
        // Subtitulo ó descripcón del examen
        let subtitle = {
          // text: res.data.description,
          text: '',
          style: 'subtitle'
        };
        content.push(subtitle);
        // Encabezado del examen
        let dataTable1 = {
          style: 'tableExample',
          table: {
            widths: ['10%', '60%', '10%', '20%'],
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: 'Escuela',
                  fontSize: 10,
                  bold: true
                },
                {
                  border: [false, false, false, false],
                  fillColor: '#eeeeee',
                  text: ''
                },
                {
                  border: [false, false, false, false],
                  text: 'Fecha',
                  fontSize: 10,
                  bold: true
                },
                {
                  border: [false, false, false, false],
                  fillColor: '#eeeeee',
                  text: ''
                }
              ]
            ]
          }
        };

        let dataTable2 = {
          style: 'tableExample',
          table: {
            widths: ['10%', '40%', '10%', '40%'],
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: 'Profesor',
                  fontSize: 10,
                  bold: true
                },
                {
                  border: [false, false, false, false],
                  fillColor: '#eeeeee',
                  text: ''
                },
                {
                  border: [false, false, false, false],
                  text: 'Grupo',
                  fontSize: 10,
                  bold: true
                },
                {
                  border: [false, false, false, false],
                  fillColor: '#eeeeee',
                  text: ''
                }
              ]
            ]
          }
        };

        let dataTable3 = {
          style: 'tableExample',
          table: {
            widths: ['10%', '40%', '10%', '40%'],
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: 'Alumno',
                  fontSize: 10,
                  bold: true
                },
                {
                  border: [false, false, false, false],
                  fillColor: '#eeeeee',
                  text: ''
                },
                {
                  border: [false, false, false, false],
                  fontSize: 10,
                  text: 'Matrícula',
                  bold: true
                },
                {
                  border: [false, false, false, false],
                  fillColor: '#eeeeee',
                  text: ''
                }
              ]
            ]
          }
        };

        content.push(dataTable1);
        content.push(dataTable2);
        content.push(dataTable3);
        content.push({ text: '\n\n'});

        let num = 1;
        // Filtramos las pregunta de tipo selección multiple
        let multipleQuestions =  questions.filter( x => x.questionTypeId === 1);
        // Filtramos las pregunta de tipo abiertas
        let openQustions =  questions.filter( x => x.questionTypeId === 2);
        // Filtramos las pregunta de tipo relacional
        let relationalQustions =  questions.filter( x => x.questionTypeId === 3);
        // Filtramos las pregunta de tipo verdadero/falso
        let trueFalseQustions =  questions.filter( x => x.questionTypeId === 4);
        // Filtramos las preguntas de tipo con imagenes
        let imageQuestions = questions.filter( x => x.questionTypeId === 5);
        // Creamos la sección de preguntas de respuesta multiple
        if (multipleQuestions.length > 0) {
          content.push( this.generateQuestions( multipleQuestions, 'Elige la respuesta correcta', 1, typeExam));
          content.push({ text: '\n\n'});
        }
        // Creamos la sección de preguntas abiertas
        if (openQustions.length > 0) {
          content.push( this.generateQuestions( openQustions, 'Escriba a continuación su respuesta correcta', 2, typeExam));
          content.push({ text: '\n\n'});
        }
        // Creamos la sección de preguntas relacionales
        if (relationalQustions.length > 0) {
          let relationDescription: any[] = [];
          relationalQustions.forEach( function(item) {
            let answers = item.answers;

            answers.forEach( function(a) {

              relationDescription.push( a.relationDescription);
            });
          });

          relationDescription = ( typeExam ) ? relationDescription : this.randArray( relationDescription) ;
          content.push( this.generateQuestionsRelational( relationalQustions, 'Relaciona ambas columnas.', relationDescription, typeExam));
          content.push({ text: '\n\n'});
        }
        // Creamos la sección de preguntas verdadero/falso
        if ( trueFalseQustions.length > 0) {
          content.push( this.generateQuestions( trueFalseQustions, 'Seleccione la respuesta correcta', 4, typeExam));
          content.push({ text: '\n\n'});
        }
        // Cremos la sección de preguntas con imagenes
        if ( imageQuestions.length > 0 ) {
          content.push( this.generateQuestions( imageQuestions, 'Elige la respuesta correcta', 5, typeExam));
          content.push({ text: '\n\n'});
        }
        // Agregamos la imagen de encabezado y los estilos necesarios para los elementos
        let dd = {
          background: function( currentPage ) {
            return {
              image: pic1,
              width: 640
            };
          },
          styles: {
            title: {
              fontSize: 12,
              bold: true,
              alignment: 'right',
              margin: [0, 10, 0, 0]
            },
            subtitle: {
              fontSize: 10,
              alignment: 'right',
              margin: [0, 0, 0, 20]
            },
            directions: {
              margin: [10, 10, 10, 0],
              fontSize: 10,
              bold: true
            },
            question: {
              margin: [10, 0, 10, 0],
              fontSize: 10
            },
            answer: {
              margin: [20, 5, 20, 5],
              fontSize: 9,
              bold: true,
            },
            answerCorrect: {
              margin: [20, 1, 20, 1],
              bold: true,
              decoration: 'underline',
              decorationStyle: 'solid',
              decorationColor: 'black'
            },
            footer: {
              margin: [30, 5, 30, 10],
              fontSize: 10
            },
            tableExample: {
              margin: [0, 5, 0, 0]
            },
            tableAnswers: {
              margin: [5, 5, 5, 5]
            },
            tableHeader: {
              bold: true,
              fontSize: 10,
              color: 'black'
            },
          },
          pageSize: 'Letter',
          pageOrientation: 'portrait',
          pageMargins: [ 25, 100, 25, 80 ],
          footer: function(currentPage, pageCount) {
            return  [{
              text: 'Página ' + currentPage.toString() + ' de '  + pageCount,
              style: 'footer',
              alignment: 'right' }];
            },
          images: {
            topbar1: ''
          },
          content: []
       };

       dd.content = content;
      // Generamos y descargamos el pfd
      // pdfMake.createPdf(dd).download('ExamenSali - ' + res.data.description + '.pdf');
      pdfMake.createPdf(dd).getBase64( function(encodedstring) {
        let data = 'data:application/octet-stream;base64,' + encodedstring;
        let btnDownload = document.createElement('a');
        btnDownload.download = 'ExamenSali - ' + res.data.description + '.pdf';
        btnDownload.href = data;
        document.body.appendChild( btnDownload);
        btnDownload.click();
      });

  }

  // Genera la seccion para preguntas multiple, abiertas y verdadero/falso
  private generateQuestions(questions: any, msg: string, questionType: number, typeExam: boolean) {
    let content = [];
    let num = 1;
    let showAnswers: boolean;
    let style: string;
    let color: string = '';
    let q = {
      text : msg,
      style: 'directions',
      fontSize: 12,
    };
    content.push(q);
    questions.forEach(function(item) {
      let qs = [];
      let image_question = {};

      if ( item.urlImage ) {
        let url_question = item.urlImage;
        let extension_question = url_question.split(/\#|\?/)[0].split('.').pop().trim();
        let width_question = ( item.imageWidth > 500 ) ? 400 : item.imageWidth;
        let height_question = ( item.imageHeight > 500 ) ? 250 : item.imageHeight;
        if ( extension_question !== 'gif') {
          image_question = {
            // tslint:disable-next-line:max-line-length
            image: `data:image/${extension_question};base64,${item.imageBase64}`,
            width: width_question,
            height: height_question,
            margin: [10, 5, 0, 5],
          };
        }
      }

      if ( item.urlImage ) {
         qs = [
          {
            text : String(num++) + '. ' + item.content,
            style: 'question',
            bold: true,
            margin: [0, 5, 0, 5],
            fontSize: 10,
            alignment: 'justify'
          },
          image_question
        ];
      } else {
         qs = [{
          text : String(num++) + '. ' + item.content,
          style: 'question',
          bold: true,
          margin: [0, 5, 0, 5],
          fontSize: 10,
          alignment: 'justify'
        }];
      }

      content.push(qs);
      // Explicación de la respuesta
      if ( typeExam ) {
        let e = {
          text: 'Explicación de la respuesta: ' + item.explanation,
          style: 'question',
          bold: false,
          color: 'green',
          margin: [0, 5, 0, 5],
          fontSize: 9,
          alignment: 'justify'
        };
        content.push( e );
      }
      if (questionType === 1 || questionType === 4) {
        // Multiple
        let answers = item.answers;
        let ol = [];

        answers.forEach(function(a) {
          if ( typeExam ) {
            showAnswers = ( typeExam ) ? a.isCorrect : false;
            style = ( a.isCorrect ) ? 'answerCorrect' : 'answer';
            color = (showAnswers) ? 'green' : '';
          }

          let astyle = {
            text: a.description,
            style: style,
            bold: showAnswers,
            color: color
          };
          ol.push(astyle);
        });

        let q1 = {
          ol: ol,
          type: 'upper-alpha',
          style: 'answer'
        };
        content.push(q1);
      }
      if ( questionType === 2) {
        let q2 = {
          // tslint:disable-next-line:max-line-length
          text: 'R:______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________ ',
          style: 'answer'
        };
        content.push(q2);
      }
      if ( questionType === 5) {
        // Preguntas con imagenes
        let answers = item.answers;
        let ol = [];

        answers.forEach(function(a) {
          let answerCorrect = {};
          let image_answer = {};
          if ( a.description ) {
            let width = ( a.imageWidth > 500 ) ? 400 : a.imageWidth;
            let height = ( a.imageHeight > 500 ) ? 250 : a.imageHeight;
            let url_answer = a.description;

            let extension_answer = url_answer.split(/\#|\?/)[0].split('.').pop().trim();
            if ( extension_answer !== 'gif') {
              image_answer = {
                // tslint:disable-next-line:max-line-length
                image: `data:image/${extension_answer};base64,${a.imageBase64}`,
                width: width,
                height: height,
                margin: [0, 5, 0, 5],
              };

            }
          }

          if ( typeExam ) {
            if ( a.isCorrect ) {
              answerCorrect = {
                text: 'Respuesta correcta',
                style: 'answerCorrect',
                color: 'green'
                };
            }

          }

          let image = [{
            text: ' ',
            type: 'upper-alpha',
          },
          image_answer,
          answerCorrect
          ];
          ol.push(image);
        });

        let q5 = {
          ul: ol,
          type: 'upper-alpha',
        };
        content.push(q5);
      }

    });
    return content;
  }

  // Genera la seccion de preguntas relacionales
  private generateQuestionsRelational( questions: any, msg: string, relations: any[], typeExam: boolean) {
    let content = [];
    let num = 1;
    let count = 0;
    let correct: any = '';
    // Agregamos la instruccion general
    let q = {
      text : msg,
      style: 'directions',
      fontSize: 12,
    };
    content.push(q);

    // Recorremos el arreglo de preguntas
    questions.forEach(function(item) {
      let countRelation = 1;
      // Agregamos la pregunta
      let q = {
        text : String(num++) + '. ' + item.content,
        style: 'answer',
        bold: true,
        margin: [0, 5, 0, 5],
        fontSize: 10,
        alignment: 'justify'
      };
      content.push(q);

        // Obtenemos las respuestas de la pregunta
        let answers = item.answers;
        let relation: any[] = [];
        // let relationDescription: any[] = [];
        // Creamos el encabezado para cada seccion de respuestas de las preguntas
        relation.push([
          {
            text: 'Columna A',
            fontSize: 10,
            bold: true,
            margin: [0, 8, 0, 8],
            style: 'tableHeader'
          },
          {
            text: '',
            fontSize: 10,
            bold: false,
            margin: [0, 8, 0, 8],
            style: 'tableHeader'
          },
          {
            text: 'Columna B',
            fontSize: 10,
            bold: true,
            margin: [0, 8, 0, 8],
            style: 'tableHeader'
          }
        ]);
         // Creamos el objeto con las relaciones en random con las respuestas
        answers.forEach( function(a) {
          correct = (typeExam) ? countRelation : '';
          relation.push([
            {
              text: countRelation + '._ ' + a.description,
              fontSize: 11,
              bold: false,
              margin: [0, 0, 0, 1],
              alignment: 'justify',
              style: 'answer'
            },
            {
              text: '',
              fontSize: 11,
              bold: false,
              margin: [0, 0, 0, 1],
              style: 'answer'
            },
            {
              text: ' (  ' + correct + '  ) ' + relations[count],
              fontSize: 11,
              bold: false,
              margin: [0, 0, 0, 1],
              style: 'answer'
            }
          ]);
          countRelation++;
          count++;
        });
        // Generamos la tabla con las respuestas
        let dataTable2 = {
          style: 'tableAnswers',
          table: {
            headerRows: 1,
            widths: ['50%', '5%', '45%'],
            body:
              relation
          },
          layout: 'lightHorizontalLines'
        };
        content.push(dataTable2);


    });
    return content;
  }
  // Ordenena el array de respuesta en random
  public randArray( arra1: any[]) {
    let ctr = arra1.length, temp, index;

    while (ctr > 0) {
        index = Math.floor(Math.random() * ctr);
        ctr--;
        temp = arra1[ctr];
        arra1[ctr] = arra1[index];
        arra1[index] = temp;
    }
    return arra1;
  }

}
