import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

// Modelos
import { BookTeacher } from '../../models/catalogs/bookTeacher.model';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(  private httpService: HttpService  ) { }

  // Obtiene todos los libros psginados
  getAll( size: number, page: number, filter: string ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/books/all?currentPage=${page}&pageSize=${size}&filter=${filter}`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtiene todos los libros sin paginar
  getAllBooks() {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/books`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Guarda un nuevo libro
  save( item: BookTeacher) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/books`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( item ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Actualiza un libro
  update( item: BookTeacher) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/books`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.put(API_URL, JSON.stringify( item ), { headers: HEADERS } )
                            .map( res => res.json());
  }

  // Elimina un libro
  delete( bookId: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/books?id=${bookId}`;
    return this.httpService.delete(API_URL)
                           .map( res => res.json());
  }

  // Obtiene todos los autores
  getAllAuthors() {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/authors`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }
}
