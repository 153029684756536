import { Component, OnInit } from '@angular/core';

// COMPONENT SERVICES
import { BookNavService } from '../book-nav/book-nav.service';
import { CourseNavService } from './course-nav.service';

// MODEL
import { Course } from '../../../../models/catalogs/course.model';

@Component({
  selector: 'app-course-nav',
  templateUrl: './course-nav.component.html',
  styleUrls: ['./course-nav.component.scss']
})
export class CourseNavComponent implements OnInit {

  constructor( public _bookNavService: BookNavService,
               public _courseNavService: CourseNavService ) { }

  ngOnInit() {
  }

  // REALIZAR EL CAMBIO DE CURSO
  onChangeCourse( course: Course ) {
    this._courseNavService.changeCorurse( course.id );
  }

}
