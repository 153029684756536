import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// COMPONENT SERVicES
import { HeaderService } from '../../../components/layouts/anonymous/header/header.service';

// SERVICES
import { UserService } from '../../../service/service.index';

import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  working: boolean;
  frmRecover: FormGroup;

  constructor(private _srvUser: UserService,
              private _router: Router,
              private _srvHeader: HeaderService ) {
    this.working = false;

    this.frmRecover = new FormGroup({
      'username': new FormControl('', [ Validators.required,
                                        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')])
    });

   }

  ngOnInit() {
    this._srvHeader.showReturnButton( false );
  }

  onSubmit() {

    const email = this.frmRecover.controls['username'].value;
    this.working = true;
    this._srvUser.resetPassword( email ).subscribe( res => {

       if ( res.success ) {
        swal( Appsettings.APP_NAME, res.message, 'success');
        setTimeout(() => {
          this._router.navigateByUrl('/home/login');
        }, 5000);
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      swal( Appsettings.APP_NAME, 'Problemas al recobrar la contraseña',  'error');
      this.working = false;
    } );

  }

}
