import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// SERVICES COMPONENT
import { BookNavService } from '../../../components/layouts/publication/book-nav/book-nav.service';
import { StorageService } from '../../../service/service.index';


@Component({
  selector: 'app-interesting-sites',
  templateUrl: './interesting-sites.component.html',
  styleUrls: ['./interesting-sites.component.scss']
})
export class InterestingSitesComponent implements OnInit {

  constructor( private _route: ActivatedRoute,
               public _bookNavService: BookNavService,
               private _svrStorage: StorageService ) { }

  nodeId: number;

  ngOnInit() {
    if (this._svrStorage.rol === 'Profesor') {
      this._bookNavService.showMenu = true;
      this._bookNavService.showContentTab = true;
    }

    if (this._svrStorage.rol === 'Alumno') {
      this._bookNavService.showCourseTab = true;
      this._bookNavService.showContentTab = false;

    }

    this._route.params.subscribe(params => {
        this.nodeId = Number(params['id']) ;
        this._bookNavService.setNode( this.nodeId );
    });
  }

}
