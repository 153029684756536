import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';
import { Appsettings } from '../../../configuration/appsettings';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';
import { ExamSchedulesComponent } from '../../../components/administrator/exam-schedules/exam-schedules.component';

// MODELS
import { Group } from '../../../models/courses/group.model';

@Component({
  selector: 'app-exam-schedule',
  templateUrl: './exam-schedule.component.html',
  styleUrls: ['./exam-schedule.component.scss']
})
export class ExamScheduleComponent implements OnInit, OnDestroy {


  data: any;
  group: Group;

  @ViewChild(ExamSchedulesComponent)
  public timerComponent: ExamSchedulesComponent;

  constructor( private _srvHeader: AdminHeaderService,
               private _route: ActivatedRoute ) {
                this.data = null;
                this._srvHeader.setBreadcrumb([Appsettings.APP_NAME, 'Mi salón', 'Programación de exámenes']);
  }

  ngOnInit() {

    this._route.params.subscribe( params => {

      this.data = {
        id: params.id
      };

      this.group = JSON.parse(localStorage.getItem('sali.group'));

      this.timerComponent.studentGroupId =  this.data.id;
      this.timerComponent.groupName = this.data.description;
      this.timerComponent.getExamSchedules();
      this._srvHeader.setTitle('Programación de exámenes: ' + this.group.description);
    });
  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  onClickAddNew() {
    if (!this.timerComponent.showForm ) {
      this.timerComponent.onClickAdd();
    }
  }

}
