import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpModule, XHRBackend, RequestOptions } from '@angular/http';
import { APP_ROUTING } from './app.routes';
import { PersistenceModule } from 'angular-persistence';
import { registerLocaleData } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { EmbedVideoService } from 'ngx-embed-video';

import localEsMX from '@angular/common/locales/es-MX';
import localEn from '@angular/common/locales/en';

registerLocaleData(localEsMX, 'es-Mx');
registerLocaleData(localEn, 'en');

// Componentes
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

// MODULES
import { PagesExternalModule } from './routes/anonymous/pagesexternal.module';
import { AnonymousModule } from './components/layouts/anonymous/anonymous.module';
import { AdminModule } from './routes/administrator/admin.module';
import { SaliModule } from './routes/sali/sali.module';
import { ServiceModule } from './service/service.module';
import { TeacherModule } from './routes/teacher/teacher.module';
import { Appsettings } from './configuration/appsettings';
import { BookModule } from './routes/book/book.module';
import { CoursesModule } from './routes/courses/courses.module';
import {VideoConferenceModule} from './routes/videoConference/videoConference.module';

@NgModule({
  declarations: [
    AppComponent
   ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(Appsettings.FIREBASE_CONFIG),
    AngularFireMessagingModule,
    PersistenceModule,
    HttpModule,
    BrowserAnimationsModule,
    PagesExternalModule,
    AnonymousModule,
    AdminModule,
    SaliModule,
    TeacherModule,
    ServiceModule,
    CoreModule,
    BookModule,
    CoursesModule,
    APP_ROUTING,
    VideoConferenceModule,

  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-Mx' },
    EmbedVideoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
