import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class SitemapService {

  location: any[] = [];

  setSiteMap ( _location: any [] ) {
    this.location = _location;
  }

}
