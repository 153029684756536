import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../configuration/appsettings';
import { FileHolder } from 'angular2-image-upload';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

// MODELS
import { FileContent } from '../../models/catalogs/file.model';
import { TipHome } from '../../models/home/tip.model';

// SERVICEs
import { TipsHomeService } from '../../service/service.index';
import swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-tip',
  templateUrl: './dialog-tip.component.html',
  styleUrls: ['./dialog-tip.component.scss']
})
export class DialogTipComponent implements OnInit {

  frmTip: FormGroup;
  working: boolean = false;
  isChangeData: boolean = false;

  fileContent: File;
  fileContentBase: string;
  filenName: string;
  isUpdateFile: boolean;

  constructor(public dialogRef: MatDialogRef<DialogTipComponent>,
              public _srvTip: TipsHomeService,
              @Inject(MAT_DIALOG_DATA) public data: TipHome) {

      this.frmTip =  new FormGroup({
        'id' : new FormControl(  this.data.id , [ Validators.required ]),
        'title': new FormControl( this.data.name, [ Validators.required]),
        'description': new FormControl( this.data.description, [ Validators.required]),
        'published': new FormControl( this.data.published, [ Validators.required]),
        'file': new FormControl('')
      });

      if ( this.data.urlImage != null  && this.data.id > 0) {
          this.filenName = 'archivo';
          this.frmTip.controls['file'].setValue( this.data.urlImage );
          this.isUpdateFile = false;
      }

    }

  ngOnInit() {
  }

  // Evento para cerrar la modal
  onCloseConfirm() {
    this.dialogRef.close(this.isChangeData);
  }

  // Evento para agregar o editar una noticia
  onSubmit() {

    this.working = true;
    let fileUpload = new FileContent();
    const tip = new TipHome();

    if ( this.fileContent ) {

      const arrayFileBase = this.fileContentBase.split(',');

      fileUpload = new FileContent(
        arrayFileBase[1],
        this.fileContent.type,
        this.fileContent.name,
        arrayFileBase[0]
      );
    }

    tip.id =  this.data.id;
    tip.name =   this.frmTip.controls['title'].value;
    tip.description =   this.frmTip.controls['description'].value;
    tip.published =   this.frmTip.controls['published'].value;
    tip.fileContentBase64 = fileUpload.content;
    tip.filename = fileUpload.name;

    if ( tip.id > 0) {
      this.update( tip );
    } else {
      this.save( tip );
    }
  }

  // Evento para remover el archivo que se subio
  onRemoved( event: FileHolder ) {
    this.fileContentBase = '';
    this.fileContent = null;
    this.frmTip.controls['file'].setValue('');
    this.filenName = '';
  }

  // Inicializar los valorea al asignar el archivo
  onUploadFinished( event: FileHolder ) {
    this.fileContentBase = event.src;
    this.fileContent     = event.file;
    this.filenName       = this.fileContent.name;
    this.isUpdateFile    = true;
    this.frmTip.controls['file'].setValue(event.file.name);
  }

  onUploadStateChanged( event ) {
  }

  // Guardar los datos de una noticia existente
  save(  tip: TipHome ): void {
    this._srvTip.save( tip ).subscribe( res => {
      if ( res.success ) {
        this.isChangeData = true;
        this.onCloseConfirm();
        swal( Appsettings.APP_NAME,  res.message, 'success');
      } else {
        swal( Appsettings.APP_NAME,  res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Actualizar los datos de una noticia existente
  update( tip: TipHome ): void {
    this._srvTip.update( tip ).subscribe( res => {
      if ( res.success ) {
        this.isChangeData = true;
        this.onCloseConfirm();
        swal( Appsettings.APP_NAME,  res.message, 'success');
      } else {
        swal( Appsettings.APP_NAME,  res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

}
