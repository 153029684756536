import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// SERVICES
import { UserService, StorageService } from '../../service/service.index';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Appsettings } from '../../configuration/appsettings';

import swal from 'sweetalert2';
import { DialogPasswordRecoveryComponent } from '../dialog-password-recovery/dialog-password-recovery.component';

@Component({
  selector: 'app-dialog-login',
  templateUrl: './dialog-login.component.html',
  styleUrls: ['./dialog-login.component.scss']
})
export class DialogLoginComponent implements OnInit {

  roles: any[] = [];
  showRoles: boolean = false;
  frmLogin: FormGroup;
  working: boolean;
  systemId: number = 1;
  urlPrivacy = Appsettings.PRIVACY_ANNOUNCEMENT;

  constructor( private _userSrv: UserService,
               private _storageSrv: StorageService,
               public dialogRef: MatDialogRef<DialogLoginComponent>,
               public dialog: MatDialog,
               private _router: Router) {

                this.working  = false;
                this.frmLogin = new FormGroup({
                  'username': new FormControl('', [ Validators.required,
                                                    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]),
                  'password': new FormControl('', [ Validators.required]),
                  'systemId': new FormControl('')
                });

                this._storageSrv.resetLocalData();

               }

  ngOnInit() { }

  onSubmit()  {

    const usuario = this.frmLogin.controls['username'].value;

    this.working = true;
    this._userSrv.login(
      this.frmLogin.controls['username'].value,
      this.frmLogin.controls['password'].value,
      this.systemId
    ).subscribe( res => {

      if ( res.access_token ) {

        this._storageSrv.token          = res.access_token ;
        this._storageSrv.email          = res.email;
        this._storageSrv.name           = res.fullName;
        this._storageSrv.userName       = this.frmLogin.value.username;
        this._storageSrv.rol            = res.rol;
        this._storageSrv.refreshToken   = res.refresh_token;

        this._userSrv.getProfile().subscribe( r => {
console.log( r );
          if ( r.success ) {

            if ( r.data.permissions ) {
              this._storageSrv.permissions = JSON.stringify(r.data.permissions);
            }

            if ( r.data.publishingNodes ) {
              this._storageSrv.nodes = JSON.stringify(r.data.publishingNodes);
            }

            if ( r.data.notifications ) {
              this._storageSrv.notifications = JSON.stringify(r.data.notifications);
            }

            this._storageSrv.profileImage =  r.data.user.urlImage;
            this._storageSrv.systemId = r.data.systemId;

            // if (res.rol === 'Alumno' || res.rol === 'Profesor') {
            if (res.rol !== 'Administrador') {
              this._router.navigateByUrl('/sali/level');
            } else if (res.rol.indexOf('Administrador') > -1 ) {
              this._router.navigateByUrl('/admin/content-register');
            } else {
              this._router.navigateByUrl('/home/login');
              this.working = false;
            }
          }

          this.working = false;
          this.dialogRef.close();

        }, err => {
          swal( Appsettings.APP_NAME, 'Problemas al autenticar el usuario',  'error');
          this.working = false;
        } );

      } else {
        swal( Appsettings.APP_NAME, 'Usuario y/o contraseña no validos', 'error');
        this.working = false;
      }
    }, err => {
      swal( Appsettings.APP_NAME, 'Problemas al autenticar el usuario',  'error');
      this.working = false;
    });

  }

  onCloseConfirm() {
    this.dialogRef.close(null);
  }

  onFocursOutEmail( event ) {

    let email = this.frmLogin.controls['username'].value;
    this.showRoles = false;

    if ( this.frmLogin.controls['username'].valid ) {
      this.working = true;
      this.roles = [];
      this._userSrv.getRolesByUserName(email).subscribe( res => {
        if ( res.success ) {

          for ( let r of res.data ) {

          this.roles.push(
            {
              rol: r.rol,
              rolId: r.rolId,
              systemId: r.systemId,
              systemName: r.systemName
            });
          }
          let adminRole =  this.roles.filter( x => x.rol.toLowerCase().indexOf('administrador') > -1);
          if ( adminRole.length > 0 ) {
            this.showRoles = this.roles.length > 1 && adminRole.length > 0;
            this.systemId = this.roles[0].systemId;
          } else {
            this.systemId = 1;
          }
        }
        this.working = false;
      } , err => {
        this.working = false;
      });
    }
  }

  onKeyUp( event ) {
    this.showRoles = false;
  }

  onClickRecover() {

    this.onCloseConfirm();

    const DIALOG_REF = this.dialog.open( DialogPasswordRecoveryComponent, {
      width: '340px',
      height: '290px',
      data: [],
      autoFocus: false,
      disableClose: true
    });

  }

}
