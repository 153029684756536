import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';

import { HttpService } from '../security/http.service';
import { Appsettings} from '../../configuration/appsettings';

// Modelo
import { Lesson } from '../../models/content/lesson.model';
@Injectable({
  providedIn: 'root'
})
export class LessonService {

  constructor(  private _httpService: HttpService) { }

  getAll() {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/courseClasses`;


    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }


  save( lesson: Lesson ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/courseClasses/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');


   return this._httpService.post(API_URL, JSON.stringify( lesson ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  update( lesson ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/courseClasses/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');



   return this._httpService.put(API_URL, JSON.stringify( lesson ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  getLessonDetail( idSection: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/coursesections/${idSection}/detail`;

    return this._httpService.get(API_URL )
                            .map( res => res.json());
  }

  getContentByIdSection( idSection: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/courseClasses/${idSection}/getBySection`;

    return this._httpService.get(API_URL )
                            .map( res => res.json());

  }

  delete( lessonId: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/courseClasses?id=${lessonId}`;
    return this._httpService.delete(API_URL)
                           .map( res => res.json());
  }
}
