import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TreeviewModule } from 'ngx-treeview';
import { LoadingComponent } from './loading/loading.component';
import { CommonModule } from '@angular/common';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { GenerateIdElementPipe } from './pipes/generate-id-element.pipe';
import { ActivoBooleanPipe } from './pipes/activo-boolean.pipe';
import { StatusInvitationPipe } from './pipes/status-invitation.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { IconFilePipe } from './pipes/icon-file.pipe';
import { FileTypePipe } from './pipes/file-type.pipe';
import { GetTimeSongPipe } from './pipes/get-time-song.pipe';
import { ShortNamePipe } from './pipes/short-name.pipe';
import { SafePipe } from './pipes/safe.pipe';


import { DropdownTreeviewSelectComponent } from './ngx-dropdown-treeview-select/dropdown-treeview-select.component';


import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule} from '@angular/common/http';
import { PaginationComponent } from './pagination/pagination.component';

import { ViewerVideoComponent } from './viewer-video/viewer-video.component';
import { ViewerAudioComponent } from './viewer-audio/viewer-audio.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { ViewerFileComponent } from './viewer-file/viewer-file.component';

// MATERIALIZE
import { MatTabsModule,
  MatInputModule,
  MatDatepickerModule,
  MatRadioModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatMenuModule,
  MatPaginatorModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatToolbarModule, 
  DateAdapter} from '@angular/material';
import { AppDateAdapter } from '../shared/appDateAdapter';

@NgModule({
  declarations: [
    LoadingComponent,
    GenerateIdElementPipe,
    ActivoBooleanPipe,
    StatusInvitationPipe,
    TruncatePipe,
    DropdownTreeviewSelectComponent,
    IconFilePipe,
    ShortNamePipe,
    FileTypePipe,
    GetTimeSongPipe,
    SafePipe,
    PaginationComponent,
    ViewerVideoComponent,
    ViewerAudioComponent,
    AudioPlayerComponent,
    ViewerFileComponent,
  ],
  exports: [
    LoadingComponent,
    GenerateIdElementPipe,
    ActivoBooleanPipe,
    StatusInvitationPipe,
    TruncatePipe,
    DropdownTreeviewSelectComponent,
    IconFilePipe,
    ShortNamePipe,
    FileTypePipe,
    GetTimeSongPipe,
    SafePipe,
    AngularEditorModule,
    HttpClientModule,
    PaginationComponent,
    ViewerVideoComponent,
    ViewerAudioComponent,
    AudioPlayerComponent,
    ViewerFileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TreeviewModule.forRoot(),
    AngularEditorModule,
    HttpClientModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSlideToggleModule,
  ],
providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
  ],
})
export class CoreModule {}
