export const environment = {
  production: true,
  // nameApp: 'SALI - PRODUCTIVO',
  nameApp: 'SALI',

  // Para UNITEC
  apiUrl: 'https://ralarousse-prod.azurewebsites.net',

  raUrl: 'https://recursosacademicos-deploy.azurewebsites.net',
  firebase:  {
    apiKey: 'AIzaSyC6pRUOdHuELne0QrpqIDqetPeLobTc4OM',
    authDomain: 'sali-2.firebaseapp.com',
    databaseURL: 'https://sali-2.firebaseio.com',
    projectId: 'sali-2',
    storageBucket: 'sali-2.appspot.com',
    messagingSenderId: '753653057085'
  }

};
