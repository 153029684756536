import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { EmbedVideoService } from 'ngx-embed-video';
import { MatDialog } from '@angular/material';
import { MatPaginator, MatTableDataSource } from '@angular/material';

// Modelos
import { Unit } from '../../../models/catalogs/unit.model';
import { ContentBook } from '../../../models/content/contentBook.model';
import { Course } from '../../../models/catalogs/course.model';
import { TaskSchedule } from '../../../models/courses/taskSchedule.model';
import { InterestSite } from '../../../models/catalogs/interestSite.model';
// Servicios
import {
  NodeService,
  StorageService,
  StudenService,
  SectionService,
  ExamScheduleService,
  HomeworksService,
  InterestSiteService
} from '../../../service/service.index';
import { BreadcrumbService } from '../../../core/breadcrumb/breadcrumb.service';

// Componentes
import { DialogCourseComponent } from '../../../dialogs/dialog-course/dialog-course.component';
import { DialogGetFileComponent } from '../../../dialogs/dialog-get-file/dialog-get-file.component';
import { DialogUploadFileComponent } from '../../../dialogs/dialog-upload-file/dialog-upload-file.component';
import { DialogStudentAnswersComponent } from '../../../dialogs/dialog-student-answers/dialog-student-answers.component';
// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// UTILITIES
import { UserUtilities } from '../../../shared/user.utilities';

import swal from 'sweetalert2';
import { consumeBinding } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit, OnDestroy {
  selectedNode: any;

  unitSelected: number;
  contentSelected: number;
  idBook: number;
  working: boolean;
  contentSections: any[] = [];
  units: Unit[] = [];
  contentsBook: ContentBook[] = [];
  contentVideo: ContentBook[] = [];
  contentAudio: ContentBook[] = [];
  contentFile: ContentBook[] = [];
  contenCours: Course[] = [];
  exams: any[] = [];
  course: Course;
  showVideo: boolean;
  showAudio: boolean;
  showFile: boolean;
  showCourse: boolean;
  bookCover: string;
  showMenu: boolean;
  showCourseTab: boolean;
  showContentCourse: boolean;
  videoEmbed: any;
  idNodo: number;
  // videoId: any = 'iHhcHTlGtRs';
  dataSource = new MatTableDataSource<any>(this.exams);
  displayedColumns: string[] = ['name', 'applicationDate', 'rating'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  taskSchedules: TaskSchedule[] = [];

  stopSound: boolean;
  accessType: number;
  interestSite: InterestSite[] = [];
  showContentTab: boolean;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _svrNode: NodeService,
    private _location: Location,
    private _svrBreadcrum: BreadcrumbService,
    private _svrEmbed: EmbedVideoService,
    private _svrStorage: StorageService,
    public _dialog: MatDialog,
    private _srvStuden: StudenService,
    private _svrSecction: SectionService,
    private _srvHeader: AdminHeaderService,
    private _srvExam: ExamScheduleService,
    private _srvHomework: HomeworksService,
    private _srvSite: InterestSiteService
  ) {
    this.showMenu = false;
    this.showCourseTab = false;
    this.contentSelected = 1;
    this.working = false;
    this.showCourse = false;
    this.showContentCourse = false;
    this.showContentTab = false;
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.accessType = params['accessType'];
      this.idNodo = params['id'];

      if (this.idNodo > 0) {
        let nodes = JSON.parse(this._svrStorage.nodes);
        let node = nodes.filter(x => x.id == this.idNodo)[0];

        if (node !== undefined) {

          this.selectedNode = node;
          this._srvHeader.setTitle(this.selectedNode.description);

          this.getCours(this.idNodo);
          this.getNodos(this.idNodo);
          this.getContents(this.idNodo);
          this.getSites( this.idNodo );

          if (this._svrStorage.rol === 'Profesor') {
            this.showMenu = true;
            this.showContentTab = true;

          }
          if (this._svrStorage.rol === 'Alumno') {
            this.showCourseTab = true;
          }

          this._svrBreadcrum.showBackBotton = true;

          let router = UserUtilities.getSelectedNodeUrl(
            parseInt(this.idNodo.toString()),
            this._svrStorage.nodes
          );
          let descriptions = router
            .filter(x => x.id !== parseInt(this.idNodo.toString()))
            .map(x => x.description);

          this._srvHeader.setBreadcrumb(descriptions);
        } else {
          this._srvHeader.setTitle('');
          this._router.navigateByUrl('/admin/no-access');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._svrBreadcrum.clear();
    this._srvHeader.clearBreadcrumb();
  }

  // Obtiene los sitios de interés
  getSites( nodeId: number ) {
    this._srvSite.getAllCourse( nodeId).subscribe( result => {
      this.interestSite = [];
      if ( result.success ) {
        for ( let i of result.data) {
          let site = new InterestSite();
          site.id = i.id;
          site.title = i.title;
          site.uri = i.uri;
          site.description = i.description;
          site.courseId = i.courseId;

          this.interestSite.push( site );
        }
      }
    });
  }
  getNodos(idNodo: number) {
    this.working = true;
    this._svrNode.getSubNode(idNodo).subscribe(result => {
      if (result.success) {
        const data = result.data.children.filter(res => res.active === true);
        this.units = [];
        if ( result.data.parent.urlImage == null ) {
          this.bookCover = 'assets/images/icon_book.png';
        } else {
          this.bookCover = result.data.parent.urlImage;
        }

        if (data.length > 0) {
          for (const u of data) {
            this.units.push(new Unit(u.id, u.name));
          }
          this.getOdaCotent(this.units[0].id);
          this.unitSelected = this.units[0].id;
        }
      } else {
        swal('Error!', 'Error al consultar los datos.', 'error');
      }
      this.working = false;
    });
  }

  getContents(idNodo: number) {
    this._svrNode.getContent(idNodo).subscribe(result => {
      if (result.success) {
        let data = result.data.filter(res => res.active === true);
        // console.log( this.accessType );
        // if ( this.accessType == 1) {
        //   data = result.data.filter(res => res.isPublic === true);
        // }

        if (data.length > 0) {
          this.contentsBook = [];
          this.setContentData(data);
        } else { }
      } else { }
    });
  }

  onBack() {
    this._location.back();
  }

  getOdaCotent(idBlock: number) {
    this.working = true;
    this.unitSelected = idBlock;
    this._svrNode.getContent(idBlock).subscribe(result => {
      if (result.success) {
        this.contentVideo = [];
        this.contentAudio = [];
        this.contentFile = [];

        const data = result.data;
        if (data.length > 0) {
          for (const u of data) {

            if (u.contentTypeId === 2) {
              switch (u.fileType) {
                case 'Video/mpeg':
                  let thumbnails = u.thumbnails == null ? '/assets/images/thumbnails_video.jpg' : u.thumbnails;

                  let contentVideo = new ContentBook();
                  contentVideo.id = u.id;
                  contentVideo.name = u.name;
                  contentVideo.fileType = u.fileType;
                  contentVideo.urlContent = u.urlContent;
                  contentVideo.thumbnails = thumbnails;
                  contentVideo.contentTypeId = u.contentTypeId;
                  this.contentVideo.push( contentVideo);
                  break;
                case 'Audio/mp3':
                  let contentAudio = new ContentBook();
                  contentAudio.id = u.id;
                  contentAudio.name = u.name;
                  contentAudio.fileType = u.fileType;
                  contentAudio.urlContent = u.urlContent;
                  contentAudio.thumbnails = u.thumbnails;
                  contentAudio.contentTypeId = u.contentTypeId;
                  this.contentAudio.push( contentAudio );
                  break;
                case 'Documento/pdf':
                    let content = new ContentBook();
                    content.id = u.contentId;
                    content.name = u.name;
                    content.fileType = u.fileType;
                    content.urlContent = u.urlContent;
                    content.thumbnails = u.thumbnails;
                    content.contentTypeId = u.contentTypeId;
                  this.contentFile.push( content);
                  break;
                default:
                  break;
              }
            } else {
              // this.contentsBook.push(
              //   new ContentBook(u.id, u.name, u.fileType, u.urlContent, u.thumbnails)
              // );
            }
          }
          this.working = false;
        } else {
          this.working = false;
        }
      } else {
        this.working = false;
        swal('Error!', 'Error al consultar los datos.', 'error');
      }
    });
    // this.getCours( this.unitSelected);
  }

  newCourse() {
    const course = new Course(
      this.contentSections,
      0,
      '',
      '',
      '',
      null,
      null,
      null,
      '',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0
    );
    const DIALOG_REF = this._dialog.open(DialogCourseComponent, {
      width: '600px',
      height: '600px',
      autoFocus: false,
      disableClose: true,
      data: {
        nodeId: this.idNodo,
        course: course,
        units: this.units,
        blockId: this.unitSelected,
        edit: false
      }
    });

    DIALOG_REF.afterClosed().subscribe(response => {});
  }

  getCours(idNodo: number): void {
    this.contenCours = [];
    this.showCourse = false;
    this.showContentCourse = false;
    this._srvStuden.getCoursStudent(idNodo).subscribe(result => {
      if (result.success) {
        let coursesData = result.data;
        if (coursesData.length > 0) {
          for (let c of coursesData) {
            let group = c.studentGroups[0];
            // tslint:disable-next-line:max-line-length
            this.contenCours.push(
              new Course(
                c.courseSections,
                c.id,
                c.name,
                c.description,
                c.gol,
                c.startDate,
                c.endDate,
                c.nodeId,
                c.subject,
                c.monday,
                c.tuesday,
                c.wednesday,
                c.thursday,
                c.friday,
                c.saturday,
                c.sunday,
                group.id
              )
            );
          }
          this.showCourse = true;
        }
      }
    });
  }

  viewDetail() {}

  showDataCourse(course: Course) {
    this.working = true;
    this.getTask(course.groupId);
    const data = this.contenCours.filter(res => res.id === course.id);
    this._srvExam.getAllExamStuden(data[0].groupId).subscribe(res => {
      this.exams = [];
      for (let exam of res.data) {
        let  teacherExamTypes: number;
        if ( exam.teacherExam.teacherExamTypes) {
          teacherExamTypes =  exam.teacherExam.teacherExamTypes.id;
        } else {
          teacherExamTypes = 2;
        }

        let score = 'Sin calificar';
        let comment: string;
        let statusExam = false;
        if (exam.hasScore) {
          score = exam.score.score;
          comment = exam.score.comments;
        }

        if (!exam.isAnswered) {
          let result = this.valueDates(exam.applicationDate, course.endDate);

          if (!result) {
            statusExam = true;
          }
        }

        this.exams.push({
          name: exam.description,
          id: exam.id,
          applicationDate: exam.applicationDate,
          rating: 0,
          teacherExamId: exam.teacherExamId,
          hasScore: exam.hasScore,
          isAnswered: exam.isAnswered,
          score: score,
          statusExam: statusExam,
          comment: comment,
          teacherExamTypes: teacherExamTypes
        });
      }

      this.working = false;
    });
    this.contentSections = [];
    if (course.sectionContent.length > 0) {
      this.contentSections.push(course.sectionContent);
    }
    this.showContentCourse = true;
    this.course = course;
  }

   // Obtiene todas la programacions de tarea del grupo
   getTask( groupId: number) {
    this.taskSchedules = [];
    this._srvHomework.getAll( groupId ).subscribe( result => {
      if ( result.success ) {
        for (let h of result.data ) {
          let homework = new TaskSchedule();
          homework.id = h.id;
          homework.name = h.name;
          homework.Description = h.description;
          homework.ScheduledDate = h.scheduledDate;
          homework.FilesId = h.files;
          this.taskSchedules.push( homework );
        }
      }
    });
  }
  // Direcciona a la pantalla para aplicar el examen
  onShowExam(exam) {
    this._router.navigateByUrl(
      `/sali/apply-exam/${exam.teacherExamId}/${exam.id}/${this.idNodo}`
    );
  }

  valueDates(examApply: Date, endDate: Date): boolean {
    let newToday = new Date();
    let newExamApply = new Date(examApply);
    let courseEndDate = new Date(endDate);

    let resToday = new Date(
      newToday.getFullYear(),
      newToday.getMonth(),
      newToday.getDate()
    );
    let resApply = new Date(
      newExamApply.getFullYear(),
      newExamApply.getMonth(),
      newExamApply.getDate()
    );

    let endCourseDate = new Date(
      courseEndDate.getFullYear(),
      courseEndDate.getMonth(),
      courseEndDate.getDate()
    );
    let result = false;


    if ( resApply <= endCourseDate || resToday < resApply) {
      result = false;
    }
    if (resToday >= resApply ) {
      result = true;
    }

    return result;
  }

  // Asignar datos de contenido
  setContentData(data: any[]) {

    if (data.length > 0) {
      this.contentVideo = [];
      this.contentAudio = [];
      this.contentFile = [];
      this.contentsBook = [];


      for (const u of data) {
        let canAccess: boolean = false;
        if (u.contentTypeId === 2 || u.contentTypeId === 3) {

          if ( this.selectedNode.nodeAccessTypeId === 3 ) {
            canAccess = true;
          } else {
            canAccess = u.isPublic;
          }

          switch (u.fileType) {
            case 'Video/mpeg':
              let thumbnails = u.thumbnails == null ? '/assets/images/thumbnails_video.jpg' : u.thumbnails;
              let contentVideo = new ContentBook();
              contentVideo.id = u.id;
              contentVideo.name = u.name;
              contentVideo.fileType = u.fileType;
              contentVideo.urlContent = u.urlContent;
              contentVideo.thumbnails = thumbnails;
              contentVideo.isPublic = u.isPublic;
              contentVideo.canAccess = canAccess;
              contentVideo.contentTypeId = u.contentTypeId;
              this.contentVideo.push( contentVideo );
              break;
            case 'Audio/mp3':
              let contentAudio = new ContentBook();
              contentAudio.id = u.id;
              contentAudio.name = u.name;
              contentAudio.fileType = u.fileType;
              contentAudio.urlContent = u.urlContent;
              contentAudio.thumbnails = u.thumbnails;
              contentAudio.contentTypeId = u.contentTypeId;
              contentAudio.isPublic = u.isPublic;
              contentAudio.canAccess = canAccess;
              this.contentAudio.push( contentAudio );
              break;
            case 'Documento/pdf':
              let contentFile = new ContentBook();
              contentFile.id = u.contentId;
              contentFile.name = u.name;
              contentFile.fileType = u.fileType;
              contentFile.urlContent = u.urlContent;
              contentFile.thumbnails = u.thumbnails;
              contentFile.contentTypeId = u.contentTypeId;
              contentFile.isPublic = u.isPublic;
              contentFile.canAccess = canAccess;
              this.contentFile.push( contentFile );
              break;
            default:
              break;
          }
        } else {
          // this.contentsBook.push(
          //   new ContentBook(u.id, u.name, u.fileType, u.urlContent, u.thumbnails)
          // );
        }
      }
    }
  }

  // Muestra modal con los archivos asociados a la tarea
  onShowFilesTask( task ) {
    const DIALOG_REF = this._dialog.open(DialogGetFileComponent, {
      width: '800px',
      height: '600px',
      autoFocus: false,
      disableClose: true,
      data: { task: task }
    });

    DIALOG_REF.afterClosed().subscribe(response => {});
  }

  // Muestra modal para cargar las tareas del alumno
  onShowUploadFile( taskId ) {
    const DIALOG_REF = this._dialog.open(DialogUploadFileComponent, {
      width: '800px',
      height: '600px',
      autoFocus: false,
      disableClose: true,
      data: { taskId: taskId }
    });

    DIALOG_REF.afterClosed().subscribe(response => {});
  }

  // Ver las respuestas del alumno
  onShowAnswers( exam ): void {
    const DIALOG_REF = this._dialog.open(DialogStudentAnswersComponent, {
      width: '1000px',
      height: '700px',
      autoFocus: false,
      disableClose: true,
      data: { teacherExamId: exam.id, nameExam: exam.name, studenId: 0 }
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      
    });
  }

  changeTab( event ) {
    (event !== 1 ) ? this.stopSound = true : this.stopSound = false;
  }
}
