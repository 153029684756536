export class Role {

  id: number;
  name: string;
  description: string;
  active: boolean;
  activoDescription: string;
  index: number;

  constructor(id: number, name: string, description: string, _active?: boolean ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.active = _active;
  }
}
