import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { StorageService } from '../shared/storage.service';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

@Injectable()
export class GeneralConferencesService {
  constructor(  public storageService: StorageService,
                private http: Http,
                private httpService: HttpService) {
  }

  // Método que realiza petición al API para obtener la esistrtructura de SALI
  getAll() {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/VideoConference`;
    return this.httpService
        .get(API_URL)
        .map(res => res.json());
  }

  getAllusers(){

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/users/all?=&currentPage=1&pageSize=1000000`;
    return this.httpService
        .get(API_URL)
        .map(res => res.json());
  }

  getCourseAll() {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/courses/coursegroup`;
    return this.httpService
          .get(API_URL)
          .map(res => res.json());
  }

    // Obtener todos los cursos
    getAllCourses() {
      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/courses/teacher`;
      return this.httpService
          .get(API_URL)
          .map(res => res.json());
    }

  // Obtiene la video conferencia por medio del ID
  getStructureByPatent( id: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/${id}/structure`;
    const params = new URLSearchParams();

    params.append('id', id.toString());

    return this.httpService
        .get(API_URL, { params})
        .map(res => res.json());
  }

  // Guardar una nueva video conferencia
  save( videoConference: any ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/VideoConference`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    // const params = {

    //   'title': videoConference.title,
    //   'information': videoConference.information,
    //   'DateTimeVideoConference': videoConference.date,
    //   'duration': videoConference.duration,
    //   'invited': videoConference.invited,
    //   'anotherGuest': videoConference.anotherGuest,
    //   'commentary': videoConference.comentary,

    // };

   return this.httpService.post(API_URL, JSON.stringify( videoConference ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  // Actualizar la información la video conferencia
  update( videoConference: any ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/VideoConference/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

   return this.httpService.put(API_URL, JSON.stringify( videoConference ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  // Eliminar una video conferencia
  delete( id: number ) {

    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/VideoConference`;
    const params = new URLSearchParams();
    params.append('id', id.toString());

    return this.httpService.delete( API_URL, { params} )
                           .map( res => res.json());
  }
  // Obtener libros por nodo y paginación
  booksByNode( nodeId: number, page: number, size: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/${nodeId}/books?page=${page}&size=${size}`;

    return this.httpService
                .get(API_URL)
                .map( res => res.json());
  }

}
