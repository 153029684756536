import { Injectable } from '@angular/core';
import { StorageService } from '../shared/storage.service';
@Injectable()
export class SessionValidateService {

  constructor( private _svrStorage: StorageService) { }


  validateSession(url: string): any {


    const permission = JSON.parse( this._svrStorage.permissions );
    let canAccess: boolean = false;


    for ( const item of permission ) {
      if ( item.children.length > 0 ) {

        for ( const child of item.children) {
           if (( child.url !== '' && child.url !== null ) &&  url.indexOf( child.url ) > -1) {
            canAccess = true;
            break;
           }
        }
      }

      if ( (item.url !== ''  && item.url !== null)  && url.indexOf( item.url ) > -1 ) {
        canAccess = true;
      }

    }

    return canAccess;


  }

  private validRol(rol: string): boolean {
    if (  rol === 'Alumno'
          || this._svrStorage.rol === 'Profesor'
          || this._svrStorage.rol === 'Administrador' ) {
        return true;
    }
    return false;
  }
}
