import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatDialogRef } from '@angular/material';
import { Appsettings } from '../../configuration/appsettings';

// COMPONENT SERVicES
import { HeaderService } from '../../components/layouts/anonymous/header/header.service';

// SERVICES
import { UserService, StorageService } from '../../service/service.index';

import swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-user-profile-password',
  templateUrl: './dialog-user-profile-password.component.html',
  styleUrls: []
})
export class DialogUserProfilePasswordComponent {

  working: boolean;
  isChangeData: boolean = false;
  frmPasswrd: FormGroup;
  Email: string;

  constructor(public dialogRef: MatDialogRef<DialogUserProfilePasswordComponent>,
              private _srvUser: UserService,
              private _srvStorage: StorageService) {

      this.working = false;
      this.Email = this._srvStorage.email;

      this.frmPasswrd = new FormGroup({
      'password': new FormControl('', [ Validators.required]),
      'newpassword': new FormControl('', [ Validators.required,
                                          Validators.pattern(/^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[:;<>?,.@#\]])\S{7,}$/) ]),
      'newconfirmpassword': new FormControl('')
    });

    this.frmPasswrd.controls['newconfirmpassword'].setValidators([
      Validators.required,
      this.confirmPasswordValidator.bind(this.frmPasswrd)
    ]);
  }

  // Guardar los cambios de contraseña
  onSubmit() {
    const OldPassword = this.frmPasswrd.controls['password'].value;
    const NewPassword = this.frmPasswrd.controls['newpassword'].value;
    const newconfirmpassword = this.frmPasswrd.controls['newconfirmpassword'].value;

    this.working = true;

      this._srvUser.changePassword( this.Email, NewPassword, OldPassword ).subscribe( res => {

        if ( res.success ) {
         swal(Appsettings.APP_NAME, res.message, 'success');
         this.isChangeData = true;
       } else {
         swal(Appsettings.APP_NAME, res.message, 'error');
       }
       this.working = false;
     }, err => {
       swal(Appsettings.APP_NAME, 'Problemas al cambiar la contraseña', 'error');
       this.working = false;
     });
  }

  // Validación de formulario
  confirmPasswordValidator(control: FormControl): { [s: string]: boolean }  {

    const forma: any = this;

    if ( control.value !== forma.controls['newpassword'].value ) {
      return {
        confirmPasswordValidator: true
      };
    }

    return null;
  }

  // Cerrar modal
  onCloseConfirm() {
    this.dialogRef.close(this.isChangeData);
  }

}
