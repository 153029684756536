import { Injectable } from '@angular/core';

import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

// MODELS
import { TeacherResource } from '../../models/courses/teacherResource.model';

@Injectable({
  providedIn: 'root'
})
export class TeacherResourceService {

  constructor( private _httpService: HttpService ) { }

   // Registrar un nuevo recurso
   save ( ratings: TeacherResource ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/resources/`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this._httpService.post(API_URL, JSON.stringify( ratings ), { headers: HEADERS } )
                           .map( res => res.json());
  }
}
