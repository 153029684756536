export class Permission {
  id: number;
  module: string;
  description: string;
  active: boolean;
  assigned: boolean;
  url: string;
  icon: string;
  parentId: number;
  showInMenu: boolean;
  index: number;

  constructor(_id: number, _module: string, _description: string, _active: boolean) {
    this.id = _id;
    this.module = _module;
    this.description = _description;
    this.active = _active;
    this.assigned = false;
    this.showInMenu = false;
  }
}
