import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TreeviewModule } from 'ngx-treeview';
import {
  MatInputModule,
  MatDatepickerModule,
  MatRadioModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatMenuModule,
  MatPaginatorModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatDividerModule,
  MatListModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatPaginatorIntl,
  MatBadgeModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatStepperModule
} from '@angular/material';

import { ImageUploadModule } from 'angular2-image-upload';
import { MatPaginatorIntlCro } from '../../../app/shared/spanish-paginator-intl';

// MODULES
import { ADMIN_ROUTES } from './admin.routes';
import { CoreModule } from '../../core/core.module';
import { FileUploadModule } from 'ng2-file-upload';
import { AdmonComponentModule } from '../../components/administrator/admon-components.module';

// SERVICES COMPONENT
import { SitemapService } from '../../core/sitemap/sitemap.service';
import { BreadcrumbService } from '../../core/breadcrumb/breadcrumb.service';
import { HeaderService } from '../../components/layouts/anonymous/header/header.service';

// COMPONENTS
import { UserComponent } from './secutiry/user/user.component';
import { RoleComponent } from './secutiry/role/role.component';
import { AdminLayoutComponent } from './admin-layout.component';
import { AdministratorModule } from '../../components/layouts/administrator/administrator.module';
import { PermissionComponent } from './secutiry/permission/permission.component';
import { ContentRegisterComponent } from './publication/content-register/content-register.component';
import { PublishingProfileComponent } from './secutiry/publishing-profile/publishing-profile.component';
import { StructureComponent } from './secutiry/structure/structure.component';
import { QuestionBankComponent } from './publication/question-bank/question-bank.component';
import { QuestionsComponent  } from './publication/questions/questions.component';
// import { DropdownTreeviewSelectComponent } from '../../core/ngx-dropdown-treeview-select/dropdown-treeview-select.component'
import { NoaccessComponent  } from './secutiry/noaccess/noaccess.component';
import { GenerateExamComponent } from './publication/generate-exam/generate-exam.component';
import { BooksComponent } from './publication/books/books.component';
import { AuthorComponent } from './author/author.component';
import { InventoryComponent } from './publication/inventory/inventory.component';
import { NewsComponent } from './news/news.component';

// DIALOGS
import { DialogUserComponent } from '../../dialogs/dialog-user/dialog-user.component';
import { DialogRoleComponent } from '../../dialogs/dialog-role/dialog-role.component';
import { DialogPermissionComponent } from '../../dialogs/dialog-permission/dialog-permission.component';
import { DialogRolePermComponent } from '../../dialogs/dialog-roleperm/dialog-roleperm.component';
import { DialogContentComponent } from '../../dialogs/dialog-content/dialog-content.component';
import { DialogStructureComponent } from '../../dialogs/dialog-structure/dialog-structure.component';
import { DialogPublishingComponent } from '../../dialogs/dialog-publishing/dialog-publishing.component';
import { DialogBookComponent } from '../../dialogs/dialog-book/dialog-book.component';
import { DialogUserProfileComponent } from '../../dialogs/dialog-userprofile/dialog-userprofile.component';
import { DialogAdminStructureComponent } from '../../dialogs/dialog-admin-structure/dialog-admin-structure.component';
import { DialogExamScheduleComponent } from '../../dialogs/dialog-exam-schedule/dialog-exam-schedule.component';
import { DialogExamSelectQuestionsComponent } from '../../dialogs/dialog-exam-select-questions/dialog-exam-select-questions.component';
import { DialogResourceComponent } from '../../dialogs/dialog-resource/dialog-resource.component';
import { DialogAdminBooksComponent } from '../../dialogs/dialog-admin-books/dialog-admin-books.component';
import { DialogAuthorComponent } from '../../dialogs/dialog-author/dialog-author.component';
import { DialogNewsComponent } from '../../dialogs/dialog-news/dialog-news.component';
import { DialogTipComponent } from '../../dialogs/dialog-tip/dialog-tip.component';
import { DialogGeneralEventComponent } from '../../dialogs/dialog-general-event/dialog-general-event.component';
import { DialogContentLargeComponent } from '../../dialogs/dialog-content-large/dialog-content-large.component';
import { DialogVideoConferenceFormComponent } from '../../dialogs/dialog-video-conference-form/dialog-video-conference-form.component';


import { TipsComponent } from './tips/tips.component';
import { GeneralEventComponent } from './general-event/general-event.component';
import { UploadQuestionBankComponent } from './publication/upload-question-bank/upload-question-bank.component';
import { VideoConferenceComponent } from './video-conference/video-conference.component';
import { VideoConferenceTestComponent } from './video-conference-test/video-conference-test.component';
import { FuncionalityReportComponent } from './publication/reports/funcionality-report/funcionality-report.component';


@NgModule({
  declarations: [
    UserComponent,
    AdminLayoutComponent,
    PermissionComponent,
    RoleComponent,
    AdminLayoutComponent,
    StructureComponent,
    ContentRegisterComponent,
    PublishingProfileComponent,
    DialogAuthorComponent,
    DialogUserComponent,
    DialogRoleComponent,
    DialogPermissionComponent,
    DialogRolePermComponent,
    DialogContentComponent,
    DialogStructureComponent,
    DialogPublishingComponent,
    DialogBookComponent,
    DialogUserProfileComponent,
    DialogAdminStructureComponent,
    DialogExamScheduleComponent,
    DialogExamSelectQuestionsComponent,
    DialogNewsComponent,
    DialogTipComponent,
    DialogGeneralEventComponent,
    QuestionBankComponent,
    QuestionsComponent,
    DialogResourceComponent,
    DialogContentLargeComponent,
    // DropdownTreeviewSelectComponent,
    GenerateExamComponent,
    NoaccessComponent,
    AuthorComponent,
    BooksComponent,
    DialogAdminBooksComponent,
    InventoryComponent,
    NewsComponent,
    TipsComponent,
    GeneralEventComponent,
    UploadQuestionBankComponent,
    VideoConferenceComponent,
    DialogVideoConferenceFormComponent,
    VideoConferenceTestComponent,
    FuncionalityReportComponent
    ], exports: [
    UserComponent
  ],
  imports: [
    CoreModule,
    AdmonComponentModule,
    ADMIN_ROUTES,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AdministratorModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatDividerModule,
    MatListModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatTabsModule,
    MatStepperModule,
    FileUploadModule,
    ImageUploadModule.forRoot(),
    TreeviewModule.forRoot(),

  ],
  entryComponents: [
    DialogAuthorComponent,
    DialogUserComponent,
    DialogRoleComponent,
    DialogPermissionComponent,
    DialogRolePermComponent,
    DialogContentComponent,
    DialogStructureComponent,
    DialogPublishingComponent,
    DialogBookComponent,
    DialogUserProfileComponent,
    DialogAdminStructureComponent,
    DialogExamScheduleComponent,
    DialogExamSelectQuestionsComponent,
    DialogResourceComponent,
    DialogAdminBooksComponent,
    DialogNewsComponent,
    DialogTipComponent,
    DialogGeneralEventComponent,
    DialogContentLargeComponent,
    DialogVideoConferenceFormComponent
  ], providers: [
    SitemapService,
    HeaderService,
    BreadcrumbService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro}
  ]
})
export class AdminModule {}
