import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CourseService, StorageService } from '../../service/service.index';

// Modelos
import { Course } from '../../models/catalogs/course.model';
import { Unit } from '../../models/catalogs/unit.model';

import { Appsettings } from '../../configuration/appsettings';

import { TreeUtilities } from '../../shared/tree.utilities';
import { UserUtilities } from '../../shared/user.utilities';

import { TreeviewItem } from 'ngx-treeview';
import swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-course',
  templateUrl: './dialog-course.component.html',
  styleUrls: ['./dialog-course.component.scss']
})

export class DialogCourseComponent implements OnInit {

  frmCourse: FormGroup;
  working: boolean;
  nodeId: number;
  courseId: number;
  edit: boolean;
  course: Course;
  path: string = '';

  nodos: any[] = [];
  value;
  items: TreeviewItem[] = [];

  // Constructor
  constructor(public dialogRef: MatDialogRef<DialogCourseComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private _srvCourse: CourseService,
              private _storage: StorageService ) {

                this.nodeId = data.nodeId;
                this.course = data.course;
                this.edit = data.edit;


                this.frmCourse = new FormGroup({
                  'id' : new FormControl(0),
                  'name': new FormControl( '', [Validators.required]),
                  'description': new FormControl( '', [Validators.required]),
                  'gol': new FormControl( '', [Validators.required]),
                  'subject': new FormControl( '', [Validators.required]),
                  'institution': new FormControl( '', [Validators.required]),
                  'startDate': new FormControl( '', [Validators.required]),
                  'endDate': new FormControl( '', [Validators.required]),
                  'monday': new FormControl( false),
                  'tuesday': new FormControl( false),
                  'wednesday': new FormControl( false),
                  'thursday': new FormControl( false),
                  'friday': new FormControl( false),
                  'saturday': new FormControl( false),
                  'sunday': new FormControl( false),
                  // 'nodeId': new FormControl('', [Validators.required])
                });
  }

  // Evento al inicializar el componente
  ngOnInit() {

    this.getNodes();
    // this.frmCourse.controls['nodeId'].setValue(this.nodeId);
    this.frmCourse.controls['name'].setValue(this.course.name);

    this.frmCourse.controls['description'].setValue(this.course.description);
    this.frmCourse.controls['gol'].setValue(this.course.gol);
    this.frmCourse.controls['subject'].setValue(this.course.subject);
    this.frmCourse.controls['monday'].setValue(this.course.monday);
    this.frmCourse.controls['tuesday'].setValue(this.course.tuesday);
    this.frmCourse.controls['wednesday'].setValue(this.course.wednesday);
    this.frmCourse.controls['thursday'].setValue(this.course.thursday);
    this.frmCourse.controls['friday'].setValue(this.course.friday);

    this.frmCourse.controls['saturday'].setValue(this.course.saturday);
    this.frmCourse.controls['sunday'].setValue(this.course.sunday);

    this.frmCourse.controls['startDate'].setValue(this.course.startDate);
    this.frmCourse.controls['endDate'].setValue(this.course.endDate);

    this.frmCourse.controls['institution'].setValue(this.course.institution);

    if ( this.edit ) {
      let listNodes = UserUtilities.getSelectedNodeUrl(this.nodeId, this._storage.nodes);

      if (listNodes.length) {
        listNodes = listNodes.reverse();
        this.path = listNodes.map(r => r.description).join('/');
      }
    }

  }

  // Evento para cerrar la modal
  onCloseConfirm() {
      this.dialogRef.close(null);
  }

  // Guardar o actualizar los datos de un curso
  onSubmit() {
    this.working = true;
    const course = new Course(
      new Array(),
      this.course.id,
      this.frmCourse.controls['name'].value,
      this.frmCourse.controls['description'].value,
      this.frmCourse.controls['gol'].value,
      this.frmCourse.controls['startDate'].value,
      this.frmCourse.controls['endDate'].value,
      this.nodeId,
      // this.frmCourse.controls['nodeId'].value,
      this.frmCourse.controls['subject'].value,
      this.frmCourse.controls['monday'].value,
      this.frmCourse.controls['tuesday'].value,
      this.frmCourse.controls['wednesday'].value,
      this.frmCourse.controls['thursday'].value,
      this.frmCourse.controls['friday'].value,
      this.frmCourse.controls['saturday'].value,
      this.frmCourse.controls['sunday'].value,
    );

    course.institution = this.frmCourse.controls['institution'].value;

    if ( this.course.id > 0) {
      this._srvCourse.update( course).subscribe( result => {
        this.validateResult( result, course);
      });
    } else {
      this._srvCourse.save( course ).subscribe( result => {
        this.validateResult(result, course);
      });
    }
  }

  // Obtiene los datos de la estructura
  getNodes(): void {
      let json = JSON.parse( this._storage.nodes);
      json = json.filter( x => x.nodeTypeId !== 6 );

      for (const node of json) {
        let checkedNode = false;

        this.nodos.push({
          text: node.description,
          value: node.id,
          collapsed: false,
          checked: checkedNode,
          parentId: node.parentId
        });
      }

      this.items.push(TreeUtilities.buildHierarchy(this.nodos));
  }

  // Validar el resultado para mostrar el mensaje de resultado
  validateResult( result: any, course: Course): void {
    if ( result.success) {
      swal( Appsettings.APP_NAME , result.message , 'success');

      if ( result.data != null ) {
        course.id = result.data;
      }

      this.dialogRef.close(course);
    } else {
      swal( Appsettings.APP_NAME , result.message, 'error');
      this.working = false;
    }
    this.working = false;
  }

  // Obtener el item seleccionado
  onValueChange( item ) {

    if ( item ) {
      this.nodeId   = item.value;
    }
  }
}
