import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Appsettings } from '../../../../configuration/appsettings';

// SERVICES
import { PublishingProfileService, UserService } from '../../../../service/service.index';

// MODELS
import { PublishingProfile } from '../../../../models/security/publishingProfile.model';
import { ProfileUser } from '../../../../models/security/profileuser.model';
import { Pagination } from '../../../../models/general/pagination.model';

// DIALOG
import { DialogPublishingComponent } from '../../../../dialogs/dialog-publishing/dialog-publishing.component';
import { DialogUserProfileComponent } from '../../../../dialogs/dialog-userprofile/dialog-userprofile.component';

// SERVICES COMPONENT
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';

import swal from 'sweetalert2';

@Component({
  selector: 'app-publishing-profile',
  templateUrl: './publishing-profile.component.html',
  styleUrls: ['./publishing-profile.component.scss']
})
export class PublishingProfileComponent implements OnInit {

  profileUser: ProfileUser = new ProfileUser(0, '', false, [], []);
  working: boolean;
  publishingProfiles: PublishingProfile[];

  textSearch: string = '';
  lastTextSearch: string = '';
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor(public dialog: MatDialog,
              private _publishingProfileSrv: PublishingProfileService,
              private _srvHeader: AdminHeaderService ) {
    this.working = false;
  }

  ngOnInit( ) {
    this._srvHeader.setTitle('Administración de perfiles de publicación');
    this.setPage( { page: 1, size: this.sizePage } );
  }

  // Agregar nuevo registro
  onClickAdd( ) {
    const DIALOG_REF = this.dialog.open( DialogPublishingComponent, {
      width: '600px',
      height: '650px',
      autoFocus: false,
      disableClose: false,
      data: new PublishingProfile(0, '', true, [])
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      this.setPage( { page: 1, size: this.sizePage } );
    });
  }

  // Editar un registro existente
  onClickEdit( element ) {
    const DIALOG_REF = this.dialog.open( DialogPublishingComponent, {
      width: '600px',
      height: '650px',
      autoFocus: false,
      disableClose: false,
      data: element
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      this.setPage( { page: 1, size: this.sizePage } );
    });
  }

  onClickUsers( element ) {
    this.profileUser.id = element.id;
    this.profileUser.description = element.description;
    this.profileUser.active = element.active;

    this._publishingProfileSrv.getLinkedUsers(this.profileUser.id).subscribe((result) =>{

      if (result.success) {


        this.profileUser.users = result.data.u;
        this.profileUser.linkedUsers = result.data.p;

        this.checkProfiles();

        const DIALOG_REF = this.dialog.open( DialogUserProfileComponent, {
          width: '600px',
          height: '650px',
          disableClose : true,
          data: this.profileUser
        });
      }
    });
  }

  checkProfiles( ) {
    let selected = this.profileUser.linkedUsers;
    this.profileUser.users.forEach(
      function(item) {
        let id = item.id;
        item.selected = selected.indexOf(id) !== -1;

    });

  }

  onClickUpdateStatus( e: PublishingProfile ) {
    this.working = true;
    e.active = !e.active;
    e.nodes = [];

    this._publishingProfileSrv.putPublishingProfile(e).subscribe( res => {
      this.setPage( { page: 1, size: this.sizePage } );
    });
  }

  // Eliminar un perfil d epublicación existente
  onClickDelete( e: PublishingProfile ) {
    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.working = true;
        this._publishingProfileSrv.deleteProfile(e).subscribe( res => {
        if ( res.success ) {
          this.setPage( { page: 1, size: this.sizePage } );
          swal(Appsettings.APP_NAME, res.message, 'success');
        } else {
          swal(Appsettings.APP_NAME, res.message, 'error');
        }
        this.working = false;
        });
      }
    });

  }

  // Filtrar los registros
  onKeyUp( event ) {
    if ((this.textSearch.length >= 4 || this.textSearch.length === 0) && this.textSearch !== this.lastTextSearch) {
      this.lastTextSearch = this.textSearch;
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Funcion para cambiar la página
  setPage( paginationData: object ) {
    this.working = true;
    this._publishingProfileSrv.getAllPagination( Number(paginationData['size']), Number(paginationData['page']) , this.textSearch ).subscribe( result => {
      this.publishingProfiles = [];
      if ( result.success ) {
        let index = 1;
        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for (let p  of result.data) {
            let profile = new PublishingProfile(p.id, p.description, p.active, p.nodes);
            profile.index = this.pagination.getIndexRegister(index);

            this.publishingProfiles.push( profile );
            index++;
        }
      }
      this.working = false;
    });
  }


}
