import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';
import { MatDialog } from '@angular/material';

// Modales
import { DialogTeacherContentComponent } from '../../../dialogs/dialog-teacher-content/dialog-teacher-content.component';

// Servicios
import { ContentTeacherService } from '../../../service/service.index';

// Modelos
import { TeacherContent } from '../../../models/courses/teacherContent.model';
import { TeacherContentType } from '../../../models/courses/teacherContentType.model';

import swal from 'sweetalert2';
import { element } from '@angular/core/src/render3/instructions';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy {

  working: boolean;
  icon: string;
  contents: TeacherContent[] = [];
  totalContents: any[] = [];
  active: number;
  visible: boolean;


  recourseType: TeacherContentType[] = [];

  constructor(  private _srvHeader: AdminHeaderService,
                public dialog: MatDialog,
                private _srvContent: ContentTeacherService) {


    this.working = false;
    this.icon = 'folder';
    this._srvHeader.setTitle('Mi repositorio');
    this._srvHeader.setBreadcrumb([ Appsettings.APP_NAME , 'Mi salón', 'Mi repositorio']);

    this.getTypeContent();
    this.getData();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  // Obtiene todo el contenido del profesor
  getData() {
    this._srvContent.getAll().subscribe( result => {
      this.contents = [];
      if ( result.success ) {
        // this.active = 1;
        this.totalContents = result.data;
        let contents = this.totalContents.filter( item => item.resourceTypeId === this.active);
        if ( contents.length > 0 ) {
          for ( let content of contents) {
            let cont = new TeacherContent();
            cont.id = content.id;
            cont.name = content.name;
            cont.description = content.description;
            cont.registerDate = content.registerDate;
            cont.contentUrl = content.url;
            this.contents.push( cont );
          }
          this.visible = true;
        }
      }
    });
  }

  // Obtiene el listado de los tipos de contenido
  getTypeContent() {
    this._srvContent.getTypeContent().subscribe( result => {
      this.recourseType = [];
      if ( result.success ) {
        let data = result.data;
        for ( let t of data ) {
          let contentType = new TeacherContentType();
          contentType.id = t.id;
          contentType.description = t.description;

          this.recourseType.push( contentType );
        }
        this.active = this.recourseType[0].id;
      }
    });
  }
  // Abre el modal para cargar un nuevo contenido
  onClickNewResource() {
    const DIALOG_REF = this.dialog.open( DialogTeacherContentComponent, {
      width: '500px',
      minHeight: '550px',
      autoFocus: false,
      disableClose: true,
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      if ( response) {
        this.getData();
      }
    });
  }


  // Elimina un contenido del profesor
  onDeleteContent( contentId ) {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if (result.value) {
        this.working = true;
        this._srvContent.deletContent( contentId).subscribe( res => {
            if ( res.success ) {
              this.getData();
              swal( Appsettings.APP_NAME,  res.message, 'success');
              this.working = false;
            } else {
              swal( Appsettings.APP_NAME,  res.message, 'warning');
              this.working = false;
            }
          });
      }
    });
  }

  changeFolder( folderId ) {
    this.contents = [];
    this.active = folderId;
    this.visible = false;
    let contents = this.totalContents.filter( item => item.resourceTypeId === folderId );
    if ( contents.length > 0) {
      this.visible = true;
      for ( let content of contents) {
        let cont = new TeacherContent();
        cont.id = content.id;
        cont.name = content.name;
        cont.description = content.description;
        cont.registerDate = content.registerDate;
        cont.contentUrl = content.url;
        this.contents.push( cont );
      }
    }

  }
}
