import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// Servicios
import { InterestSiteService, PagerService } from '../../../service/service.index';
// Modelos
import { InterestSite } from '../../../models/catalogs/interestSite.model';
import { PaginarionLocal } from '../../../models/general/pagination-local.model';


import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';
import { ValidateUrl } from '../../../shared/url.validator';
@Component({
  selector: 'app-interest-sites',
  templateUrl: './interest-sites.component.html',
  styleUrls: ['./interest-sites.component.scss']
})
export class InterestSitesComponent implements OnInit {

  working: boolean;
  showForm: boolean;
  sites: InterestSite[] = [];
  siteTotal: InterestSite[] = [];
  siteRes: InterestSite[] = [];
  frmSite: FormGroup;
  courseId: number;

  pager: PaginarionLocal ;
  sizePage: number = 4;

  @Input('courseId') set idCourse ( value ) {
    this.courseId = value;
    this.getAll();
  }
  constructor(  private _srvSite: InterestSiteService,
                private _srvPager: PagerService ) {
    this.working = false;
    this.showForm = false;

    // let myreg = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
    let myreg = '';
    this.frmSite = new FormGroup({
      id: new FormControl(0),
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      uri: new FormControl('', [Validators.required, ValidateUrl])
    });



  }

  ngOnInit() {
  }

  // Obtiene todos los sitios de interés
  getAll() {
    this.working = true;
    this._srvSite.getAll( this.courseId ).subscribe( result => {
      this.sites = [];
      this.siteTotal = [];
      if ( result.success ) {
        for ( let i of result.data) {
          let site = new InterestSite();
          site.id = i.id;
          site.title = i.title;
          site.uri = i.uri;
          site.description = i.description;
          site.courseId = i.courseId;

          this.siteTotal.push( site );
          this.siteRes.push( site );

        }
        this.setPage(1);
      }
      this.working = false;
    });
  }

   // Realizar paginación local
  setPage(page: number) {
    this.pager = this._srvPager.getPager(this.siteTotal.length, page, this.sizePage);
    this.sites = this.siteTotal.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  onClickEdit( site: InterestSite): void {
    this.showForm = true;
    this.frmSite.controls['id'].setValue( site.id );
    this.frmSite.controls['title'].setValue( site.title );
    this.frmSite.controls['description'].setValue( site.description );
    this.frmSite.controls['uri'].setValue( site.uri );
  }

  addRating() {

  }
  // Elimina un sitio de interes
  onClickDelete( siteId: number): void {
    this.working = true;
    this._srvSite.delete( siteId ).subscribe( result => {
      if ( result.success ) {
        swal( Appsettings.APP_NAME, result.message, 'success');
        this.getAll();
      } else {
        swal( Appsettings.APP_NAME, result.message, 'error');
      }
      this.working = false;
    });
  }

  addInterestSite(): void {
    this.showForm = true;
  }
  // Cancela el formulario
  onClickCancel() {
    this.showForm = false;
    this.resetClassForm();
  }

  onClickSave() {
    let site = new InterestSite();
    site.id = this.frmSite.controls['id'].value;
    site.title = this.frmSite.controls['title'].value;
    site.description = this.frmSite.controls['description'].value;
    site.uri = this.frmSite.controls['uri'].value;
    site.courseId = this.courseId;

    if ( site.id !== 0) {
      this.updateSite( site );
    } else {
      this.saveSite( site );
    }
  }
  // Guarda un sitio de interes
  saveSite( site: InterestSite ): void {
    this.working = true;
    this._srvSite.save( site ).subscribe( result => {
      if ( result.success ) {
        this.showForm = false;
        this.resetClassForm();
        this.getAll();
        swal( Appsettings.APP_NAME, result.message, 'success');
      } else {
        swal( Appsettings.APP_NAME, result.message, 'error');
      }
      this.working = false;
    });
  }
  // Actualiza un sitio de interes
  updateSite( site: InterestSite ) {
    this.working = true;
    this._srvSite.update( site ).subscribe( result => {
      if ( result.success ) {
        this.showForm = false;
        this.resetClassForm();
        this.getAll();
        swal( Appsettings.APP_NAME, result.message, 'success');
      } else {
        swal( Appsettings.APP_NAME, result.message, 'error');
      }
      this.working = false;
    });
  }

    // Inicializar el formulario de preguntas
    resetClassForm( ): void {
      this.frmSite.reset();
      this.frmSite.markAsUntouched();
      this.frmSite.markAsPristine();
      this.frmSite.updateValueAndValidity();
      this.frmSite.controls['id'].setValue( 0 );
    }
}
