import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// SERVICES
import { AuthorService } from '../../service/catalogs/author.service';

import { Author } from '../../models/catalogs/author.model';

import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';

@Component({
  selector: 'app-dialog-author',
  templateUrl: './dialog-author.component.html',
  styles: []
})
export class DialogAuthorComponent implements OnInit {

  public frmAuthor: FormGroup;
  working: boolean = false;

  constructor(  public dialogRef: MatDialogRef<DialogAuthorComponent>,
                private _authorSrv: AuthorService,
                @Inject(MAT_DIALOG_DATA) public data: Author) {

      this.frmAuthor = new FormGroup({
          id   : new FormControl(data.id, [Validators.required]),
          fullName : new FormControl(data.fullName, [Validators.required]),
          lastName : new FormControl(data.lastName, [Validators.required]),
          comment : new FormControl(data.comment)
      });

     }

  ngOnInit() {
  }

  onCloseConfirm( event: boolean) {
    this.dialogRef.close( event );
  }

  onSubmit() {
    this.working = true;
    let record = new Author();
    record.id = this.frmAuthor.controls['id'].value;
    record.fullName = this.frmAuthor.controls['fullName'].value;
    record.lastName = this.frmAuthor.controls['lastName'].value;
    record.comment = this.frmAuthor.controls['comment'].value;

    if ( record.id !== 0 ) {
      this.updateAuthor( record );
    } else {
      this.saveAuthor( record );

    }
  }

  // Crea un nuevo autor
  saveAuthor( author: Author ): void {
    this._authorSrv.save( author ).subscribe(res  => {
      if ( res.success ) {
        swal( Appsettings.APP_NAME, res.message, 'success');
        this.onCloseConfirm( true );
      } else {
        swal( Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Actualiza un autor
  updateAuthor( author: Author ): void {
    this._authorSrv.update( author ).subscribe( res  => {
      if ( res.success ) {
        swal( Appsettings.APP_NAME, res.message, 'success');
        this.onCloseConfirm( true );
      } else {
        swal( Appsettings.APP_NAME, res.message,  'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });

  }


}
