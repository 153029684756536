import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconFile'
})
export class IconFilePipe implements PipeTransform {

  transform(value: string, args?: any): any {

    let icon: string = 'fa fa-file';

    if  ( value.length === 0 ) {
      return icon;
    }

    let fileName  = value.substr(value.indexOf('filename')).replace(/%22/g, '');
    let extension = fileName.split('.');

    if ( extension.length === 2 ) {
      switch(extension[1]){
        case 'pdf':
          icon = 'far fa-file-pdf';
          break;
        case 'xlsx':
        case 'xlx':
          icon = 'far fa-file-excel';
          break;
        case 'pptx':
          icon = 'far fa-file-powerpoint';
          break;
        case 'jpg':
        case 'png':
          icon = 'far fa-file-image';
          break;
        case 'mp3':
          icon = 'fas fa-music';
          break;
        default:
          icon = 'fa fa-file';
          break;
      }

    } else {
      return icon;
    }
    return  icon;
  }

}
