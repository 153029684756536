import { Component, OnInit } from '@angular/core';
import { Appsettings } from '../../../../configuration/appsettings';

@Component({
  selector: 'app-admin-footer',
  templateUrl: './admin-footer.component.html',
  styleUrls: ['./admin-footer.component.scss']
})
export class AdminFooterComponent implements OnInit {
  urlPrivacy = Appsettings.PRIVACY_ANNOUNCEMENT;
  constructor() { }

  ngOnInit() {
  }

}
