import { Component, OnInit, ViewEncapsulation } from '@angular/core';

declare function setHeight(): any;

@Component({
  selector: 'app-videoConference-component',
  templateUrl: './videoConference.component.html',
  styleUrls: ['./videoConference.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoConferenceComponent implements OnInit {

  constructor() {
    let body: any = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-login');
    body.classList.remove('bg-landing');
  }
 
  ngOnInit() {
    setHeight();
  }

}
