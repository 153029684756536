export class ReleatedStudent{
  courseDescription: string;
  courseId: number;
  courseName: string;
  courseSubject: string;
  id: number;
  student: string;
  studentEmail: string;
  studentGroupDescription: string;
  studentGroupId: number;
  studentId: number;
  studentStatusDescription: string;
  studentStatusId: number;
  studentUrlImage: string;
  studentUserId: number;
  teacher: string;
  index: number;
}
