import { Component, OnInit, ViewEncapsulation, AfterContentInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Appsettings } from '../../configuration/appsettings';
import { StudenService, NewsHomeService, TipsHomeService, GeneralEventService, UserService } from '../../service/service.index';

// SERIVCES
import { HeaderService } from '../../components/layouts/anonymous/header/header.service';
import { remove } from 'lodash';

// MODELS
import { NewHome } from '../../models/home/new.model';
import { TipHome } from '../../models/home/tip.model';
import { GeneralEvent } from '../../models/home/general-event.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';


declare function scrollingToElement(element): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterContentInit  {

  news: NewHome[] = [];
  tips: TipHome[] = [];
  generalEvents: GeneralEvent[] = [];

  frmContact: FormGroup;

  sendingMail: boolean;

  constructor( private _srvHeader: HeaderService,
    private route: ActivatedRoute,
    private _router: Router,
    private _srvStudent: StudenService,
    private _srvNews: NewsHomeService,
    private _srvTip: TipsHomeService,
    private _srvUser: UserService,
    private _srvEvent: GeneralEventService,
    private elementRef: ElementRef ) {

      this.frmContact = new FormGroup( {
        'fullName': new FormControl('', [ Validators.required]),
        'email': new FormControl('', [ Validators.required,
                                       Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$') ]),
        'subject': new FormControl('',[ Validators.required]),
        'message': new FormControl('',[ Validators.required]),
      });
   }


  ngOnInit() {

  this.getNews();
  this.getTips();
  this.getEvent();

    this._srvHeader.showReturnButton( true );

    this.route.queryParams.subscribe(params => {
      if (typeof(params.codigo) !== 'undefined' && params.codigo !== '') {
        this._srvStudent.confirmInvitation(params.codigo).subscribe( res => {
          if ( res.success ) {
            this._router.navigateByUrl('/home/login');
          }
        });
      }
    });

    let s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = './assets/js/home_functions.js';
    this.elementRef.nativeElement.appendChild(s);
  }

  ngAfterContentInit(): void {
    this.route.params.subscribe( params => {
      if ( params['section'] ) {
          setTimeout( () => {
            scrollingToElement(`#${params['section']}`);
          }, 800 );
      }
    });
  }

  // Obtener las noticias más recientes
  getNews() {
    this._srvNews.getAll(1, 3, '').subscribe( result => {

      if ( result.success ) {

        for ( let n of result.data) {
          let newHome = new NewHome();

          newHome.id = n.id;
          newHome.description = n.description;
          newHome.published = n.published;
          newHome.registerDate = new Date(n.registerDate);
          newHome.title = n.title;
          newHome.urlImage = n.urlImage;

          this.news.push( newHome );
        }
      }

    });
  }

  // Obtener los tips mas recientes
  getTips() {

   this._srvTip.getAll( 1 , 3 , '' ).subscribe( result => {

      this.tips   = [];
      if ( result.success ) {
        for ( let t of result.data) {
            let tip = new TipHome();

            tip.id = t.id;
            tip.description = t.description;
            tip.published = t.published;
            tip.registerDate = new Date(t.registerDate);
            tip.name = t.name;
            tip.urlImage = t.urlImage;
            this.tips.push( tip );
        }
      }
      //this.working = false;
    }, result => {
      //this.working = false;
    });


  }

  // Cambiar de página
  getEvent() {

         this._srvEvent.getAll( 1 , 5 , '').subscribe( result => {

        this.generalEvents   = [];
        let index = 1;

        if ( result.success ) {

          for ( let n of result.data) {
              let event = new GeneralEvent();

              event.id = n.id;
              event.description = n.description;
              event.published = n.published;
              event.eventDate = new Date(n.registerDate);
              event.name = n.name;
              event.hours = n.hours;
              event.place = n.place;
              event.urlImage = n.urlImage;
              this.generalEvents.push( event );
              index++;
          }
        }
      });
  }

  // Enviar correo
  onSubmit() {

    let mail = {
      'fullName':  this.frmContact.controls['fullName'].value,
      'email':  this.frmContact.controls['email'].value,
      'subject':  this.frmContact.controls['subject'].value,
      'message':  this.frmContact.controls['message'].value,
      'systemId': Appsettings.SYSTEM_ID
    };
    this.sendingMail = true;
    this._srvUser.sendContactMail( mail ).subscribe( res => {
      if ( res.success ) {
        swal(Appsettings.APP_NAME, res.message, 'success');
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.sendingMail = false;
      this.frmContact.reset();
    }, err => {
      this.sendingMail = false;
      this.frmContact.reset();
    });
  }

}
