import { Component, OnInit } from '@angular/core';
import { Appsettings } from '../../../../configuration/appsettings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  urlPrivacy = Appsettings.PRIVACY_ANNOUNCEMENT;
  constructor() { }

  ngOnInit() {
  }

}
