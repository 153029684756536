import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Contact } from '../../models/content/contacts.model';

@Component({
  selector: 'app-dialog-search-user',
  templateUrl: './dialog-search-user.component.html',
  styleUrls: ['./dialog-search-user.component.scss']
})
export class DialogSearchUserComponent implements OnInit {

  contacts: Contact[] = [];
  contactsRes: Contact[] = [];
  textSearch: string;
  showContacts: boolean;
  constructor(  private dialogRef: MatDialogRef<DialogSearchUserComponent>,
                @Inject(MAT_DIALOG_DATA) public data ) {

    this.contacts = data.users;
    this.contactsRes = data.users;
    this.showContacts =  ( this.contacts.length > 0) ? true : false;
  }

  ngOnInit() {
  }

  onCloseConfirm( event, item: any[] ) {
    let data = {
      'status': event,
      'item': item
    };
    this.dialogRef.close( data );
  }

  // Recibe el usuario seleccionado
  onSendMessage(item) {
    this.onCloseConfirm( true, item );
  }

  onKeyUp( event ) {
    if ( this.textSearch.length >= 2 ) {
    let contacts = this.contacts.filter( u => u.fullName.toLocaleLowerCase().indexOf(this.textSearch.toLocaleLowerCase()) > -1);
    this.contacts = contacts;
    this.showContacts =  ( this.contacts.length > 0) ? true : false;
    } else {
      this.contacts = [];
      this.contacts = this.contactsRes;
      this.showContacts =  ( this.contacts.length > 0) ? true : false;
    }
  }
}

