import { Injectable, ɵConsole } from '@angular/core';
import { Appsettings } from '../../../../configuration/appsettings';

// MODEL
import { ContentBook } from '../../../../models/content/contentBook.model';
import { Course } from '../../../../models/catalogs/course.model';
import { Unit } from '../../../../models/catalogs/unit.model';

// SERVICES
import { StorageService, NodeService } from '../../../../service/service.index';
import swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class BookNavService {

  nodeId: number;
  node: any;
  working: boolean = false;
  units: Unit[] = [];
  unitSelected: Unit = null;
  authors: string = '';

  contentVideo: ContentBook[] = [];
  contentAudio: ContentBook[] = [];
  contentFile: ContentBook[] = [];
  contenCours: Course[] = [];
  contentInteractive: ContentBook[] = [];

  showContentTab: boolean = false;
  showMenu: boolean = false;
  showCourseTab: boolean = false;
  stopSound: boolean = false;

  constructor( private _svrStorage: StorageService,
               private _svrNode: NodeService ) {

}

  // Asignar los valores del nodo y bloques
  public setNode( id: number ): void {

    this.nodeId = id;
    let nodes = JSON.parse(this._svrStorage.nodes);
    this.node = nodes.filter( x => Number(x.id) === id )[0];

    if ( this.node.urlImage == null ) {
      this.node.urlImage = 'assets/images/icon_book.png';
    }

    if ( this._svrStorage.selectedNode !== this.nodeId ) {
      this._svrStorage.selectedUnit = 0;
      this.unitSelected = null;
    }

    if ( this.node.book.id > 0 ) {
      if ( this.node.book.authors.length > 0 ) {
        this.authors = this.node.book.authors.map( x => x.fullName + ' ' + x.lastName ).join(', ');
      }
    }

    this._svrStorage.selectedNode = this.nodeId;
    this.getBlocks();

  }

  // Asignar el nodo actual
  public asyncSetNode( id: number ) {

    let promise = new Promise(( resolve, reject ) => {

      this.nodeId = id;
      let nodes = JSON.parse(this._svrStorage.nodes);
      this.node = nodes.filter( x => Number(x.id) === id )[0];

      if ( this.node.urlImage == null ) {
        this.node.urlImage = 'assets/images/icon_book.png';
      }

      if ( this._svrStorage.selectedNode !== this.nodeId ) {
        this._svrStorage.selectedUnit = 0;
        this.unitSelected = null;
      }

      this._svrStorage.selectedNode = this.nodeId;
      this.getBlocks();

    });

    return promise;

  }

  // Inicializar contenido del nodo
  public initContent() {

    if ( this.units.length > 0 ) {
      this.getOdaCotent(  this._svrStorage.selectedUnit );
    } else {
      this.getContents();
    }
  }

  // Obtener los bloques de una publicación
  public getBlocks( ) {

    let nodes = JSON.parse(this._svrStorage.nodes);
    let childs = nodes.filter( x => Number(x.parentId) === this.nodeId );

    if ( childs ) {
      this.units = [];
      const units = [];

      for ( const u of childs ) {
        units.push(new Unit(u.id, u.name));
      }
      this.units = units.sort( this.dynamicSort('title'));


      if ( this.units.length ) {
        // console.log( this.units.sort( this.dynamicSort('title')) );

        if ( this._svrStorage.selectedNode === this.nodeId &&  this._svrStorage.selectedUnit > 0 ) {
         this.unitSelected = this.units.filter( x => x.id === this._svrStorage.selectedUnit)[0];
        } else {
          this.unitSelected = this.units[0];
          this._svrStorage.selectedUnit = this.unitSelected.id;
        }

      }
    }
  }

  //
  public dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        // console.log( a[property] );
        // console.log( b[property] );
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;

        return result * sortOrder;
    }
}
  // Obtener el contenido del libro
  public getContents( ) {
      this.working = true;
      this._svrNode.getContent(this.node.id).subscribe(result => {

        this.contentVideo = [];
        this.contentAudio = [];
        this.contentFile = [];
        this.contentInteractive = [];

        if (result.success) {
          let data = result.data.filter( res => res.active === true );
          if ( data.length > 0 ) {
            this.setContentData( data );
          }
        }
        this.working = false;
      }, err => {
        this.working = false;
      });
  }

  // Obtener Oda por unidad
  public getOdaCotent( idBlock: number ) {
    this.working = true;

    this._svrStorage.selectedUnit = idBlock;
    this.unitSelected =  this.units.filter( x => x.id == idBlock)[0];
    this._svrNode.getContent( idBlock ).subscribe( result => {

      this.contentVideo = [];
      this.contentAudio = [];
      this.contentFile = [];
      this.contentInteractive = [];

      if (result.success) {
        this.setContentData( result.data );
      } else {
        swal( Appsettings.APP_NAME, 'Error al consultar los datos.', 'error' );
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Asignar datos de contenido
  private setContentData(data: any[]) {

      this.contentVideo = [];
      this.contentAudio = [];
      this.contentFile = [];
      this.contentInteractive = [];
      if (data.length > 0) {

        for (const u of data) {

          let canAccess: boolean = false;
          if (u.contentTypeId === 2 || u.contentTypeId === 3) {

            if ( this.node.nodeAccessTypeId === 3 ) {
              canAccess = true;
            } else {
              canAccess = u.isPublic;
            }

            let contentBook = new ContentBook();
            contentBook.id = u.id;
            contentBook.name = u.name;
            contentBook.fileType = u.fileType;
            contentBook.urlContent = u.urlContent;
            contentBook.isPublic = u.isPublic;
            contentBook.canAccess = canAccess;
            contentBook.contentTypeId = u.contentTypeId;
            contentBook.description = u.description;

            switch (u.fileTypeId) {
              case 1:
                let thumbnails = u.thumbnails == null ? '/assets/images/thumbnails_video.jpg' : u.thumbnails;
                contentBook.thumbnails = thumbnails;
                this.contentVideo.push( contentBook );
                break;
              case 2:
                this.contentAudio.push( contentBook );
                break;
              case 4:
                contentBook.id = u.contentId;
                this.contentFile.push( contentBook );
                break;
              case 17:
                this.contentInteractive.push( contentBook );
                break;
              default:
                break;
            }
          }
        }
      }
  }

}
