export class GeneralConferences {
    id: number;
    title: string;
    information: string;
    dateTimeVideoConference: Date;
    duration: number;
    guests: string;
    statusId: number;
    groups: number;
    commentary: string;
    registerDate: Date;
    updateDate: Date;
    course: number;
    externalGuest: string;
    RoomVideoConference: string;
    userId: number;
    guestMail: string;
    emailsExternal: string;
    userName: string;
    groupName: string;
    courseName: string;
    ynShowDetails: boolean;
    ynNewRegister: boolean;
    StudentGroupId: number;
    fullName: string;
}



