import { Component, OnInit, Input } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';

// Modelos
import { InterestSite } from '../../../models/catalogs/interestSite.model';
import { PaginarionLocal } from '../../../models/general/pagination-local.model';
import { Course } from '../../../models/catalogs/course.model';

// Servicios
import { PagerService, InterestSiteService, StudenService } from '../../../service/service.index';
import { BookNavService } from '../../layouts/publication/book-nav/book-nav.service';


@Component({
  selector: 'app-show-sites-interest',
  templateUrl: './show-sites-interest.component.html',
  styleUrls: ['./show-sites-interest.component.scss']
})
export class ShowSitesInterestComponent implements OnInit {

  interestSite: InterestSite[] = [];
  siteInterest: InterestSite[] = [];
  siteInterestTotal: InterestSite[] = [];
  siteInterestRes: InterestSite[] = [];

  courses: Course[] = [];
  showSites: boolean;
  showTableSite: boolean;
  pager: PaginarionLocal ;

  sizePage: number = Number( Appsettings.DEFAULT_SIZE_PAGE );
  textSearch: string;
  showMsg: boolean;
  working: boolean;

  @Input('interestSite') set sites ( value ) {
    this.interestSite = value;
    this.showSites = ( this.interestSite.length > 0) ? true : false;
  }



  @Input('courses') set course( value ) {
    this.courses = value;
  }

  @Input() nodeId: number;

  constructor(  private _srvPager: PagerService,
                private _srvSite: InterestSiteService,
                public _bookNavService: BookNavService,
                private _srvStuden: StudenService ) {
    this.showMsg = false;
  }

  ngOnInit() {
    if ( this.interestSite.length === 0 ) {
      this.getSites();
    }

    if ( this.courses.length === 0 ) {
      this.getCours();
    }
  }
  // Filtra los sitios por curso
  onChangeCourse( event ) {
    this.siteInterest = [];
    let i = this.interestSite.filter( item => item.courseId === event );
    this.siteInterestTotal = i;
    this.siteInterestRes = i;
    this.setPage(1);

    this.showTableSite = ( this.siteInterest.length === 0 ) ? true : false;
  }

  // Realizar paginación local
  setPage(page: number) {
    this.pager = this._srvPager.getPager(this.siteInterestTotal.length, page, this.sizePage);
    this.siteInterest = this.siteInterestTotal.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  // Realiza la busqueda
  onKeyUp() {
    if ( this.textSearch.length >= 2 ) {
      const result = this.siteInterestTotal.filter(u => u.title.toLocaleLowerCase().indexOf(this.textSearch.toLocaleLowerCase()) > -1);
      if ( result.length > 0) {
        this.siteInterestTotal = [];
        this.siteInterestTotal = result;
        this.showMsg = false;
        this.setPage(1);
      } else {
        this.showMsg = true;
      }
    } else if ( this.textSearch.length === 0 ) {
      this.siteInterestTotal = [];
      this.siteInterestTotal = this.siteInterestRes;
      this.showMsg = false;
      this.setPage(1);
    }
  }

  // Obtiene los sitios de interés
  getSites(  ) {
    this._bookNavService.working = true;

      this._srvSite.getAllCourse( this.nodeId ).subscribe( result => {

        this.interestSite = [];

        if ( result.success ) {

          for ( let i of result.data) {

            let site = new InterestSite();

            site.id = i.id;
            site.title = i.title;
            site.uri = i.uri;
            site.description = i.description;
            site.courseId = i.courseId;

            this.interestSite.push( site );
          }
          this.showSites = ( this.interestSite.length > 0) ? true : false;
      }

      this._bookNavService.working = false;

    }, err => {

      this.showSites = false;
      this._bookNavService.working = true;

    });
  }

  // Obtener los cursos
  getCours( ): void {
    this.working = true;
    this._srvStuden.getCoursStudent( this.nodeId ).subscribe(result => {

      if (result.success) {
        let coursesData = result.data;
        if (coursesData.length > 0) {
          for (let c of coursesData) {

            let group = c.studentGroups[0];

            this.courses.push( new Course( c.courseSections, c.id, c.name, c.description, c.gol, c.startDate,
                                           c.endDate, c.nodeId, c.subject, c.monday, c.tuesday, c.wednesday,
                                           c.thursday, c.friday, c.saturday, c.sunday, group.id ));
          }
        }
      }

      this.working = false;

    }, err=> {
      this.working = false;
    });
  }
}
