// MODULES
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { CoreModule } from '../../core/core.module';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MatInputModule,
  MatDatepickerModule,
  MatRadioModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatMenuModule,
  MatPaginatorModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatDividerModule,
  MatListModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatPaginatorIntl,
  MatBadgeModule,
  MatTabsModule,
  MatExpansionModule
} from '@angular/material';

// COMPOENENTS
import { ExamSchedulesComponent } from './exam-schedules/exam-schedules.component';
import { ExamSelectQuestionsComponent } from './exam-select-questions/exam-select-questions.component';
import { ExamBuilderComponent } from './exam-builder/exam-builder.component';
import { ExamRatingsComponent } from './exam-ratings/exam-ratings.component';
import { GlossaryAddBookComponent } from './glossary-add-book/glossary-add-book.component';
import { GroupsNavComponent } from './groups-nav/groups-nav.component';

import { DialogGroupComponent } from '../../dialogs/dialog-group/dialog-group.component';
import { DialogCourseComponent } from '../../dialogs/dialog-course/dialog-course.component';
import { TeacherRepositoryComponent } from './teacher-repository/teacher-repository.component';

import { FileUploadModule } from 'ng2-file-upload';
import { TeacherContentAssignComponent } from './teacher-content-assign/teacher-content-assign.component';
import { TasksScheduleComponent } from './tasks-schedule/tasks-schedule.component';
import { ScheduledEventComponent } from './scheduled-event/scheduled-event.component';
import { StudenUploadHomeworkComponent } from './studen-upload-homework/studen-upload-homework.component';
import { StudenGetFileComponent } from './studen-get-file/studen-get-file.component';
import { StudenAnswersComponent } from './studen-answers/studen-answers.component';
import { GlossaryShowComponent } from './glossary-show/glossary-show.component';
import { BookAdminComponent } from './book-admin/book-admin.component';
import { HomeworkRatingsComponent } from './homework-ratings/homework-ratings.component';
import { AddThreadComponent } from './add-thread/add-thread.component';
import { AttendancesListComponent } from './attendances-list/attendances-list.component';
import { InterestSitesComponent } from './interest-sites/interest-sites.component';
import { ShowSitesInterestComponent } from './show-sites-interest/show-sites-interest.component';
import { ShowInteractiveComponent } from './show-interactive/show-interactive.component';
import { VideoConferencesComponent } from './video-conferences/video-conferences.component';
import { AngularAgoraRtcModule, AgoraConfig } from 'angular-agora-rtc';
import { VideoConferenceTComponent } from './video-conference-t/video-conference-t.component';
import { VideoConferenceStatusPipe } from './../../core/pipes/video-conference-status.pipe';
import { GeneralReportsComponent } from './general-reports/general-reports.component';
import { Angular2CsvModule } from 'angular2-csv';


const agoraConfig: AgoraConfig = {
  AppID: 'df48fcc8682f4e7f844b59cfc99acd0f',
};

@NgModule({
  declarations: [
    ExamSchedulesComponent,
    ExamSelectQuestionsComponent,
    ExamBuilderComponent,
    ExamRatingsComponent,
    GlossaryAddBookComponent,
    GroupsNavComponent,
    DialogGroupComponent,
    DialogCourseComponent,
    TeacherRepositoryComponent,
    TeacherContentAssignComponent,
    TasksScheduleComponent,
    ScheduledEventComponent,
    StudenUploadHomeworkComponent,
    StudenGetFileComponent,
    StudenAnswersComponent,
    GlossaryShowComponent,
    BookAdminComponent,
    HomeworkRatingsComponent,
    AddThreadComponent,
    AttendancesListComponent,
    InterestSitesComponent,
    ShowSitesInterestComponent,
    ShowInteractiveComponent,
    VideoConferencesComponent,
    VideoConferenceTComponent,
    VideoConferenceStatusPipe,
    GeneralReportsComponent
    ],
  exports: [
    ExamSchedulesComponent,
    ExamSelectQuestionsComponent,
    ExamBuilderComponent,
    ExamRatingsComponent,
    GlossaryAddBookComponent,
    GroupsNavComponent,
    TeacherRepositoryComponent,
    TeacherContentAssignComponent,
    TasksScheduleComponent,
    ScheduledEventComponent,
    StudenUploadHomeworkComponent,
    StudenGetFileComponent,
    StudenAnswersComponent,
    GlossaryShowComponent,
    BookAdminComponent,
    HomeworkRatingsComponent,
    AddThreadComponent,
    AttendancesListComponent,
    InterestSitesComponent,
    ShowSitesInterestComponent,
    ShowInteractiveComponent,
    VideoConferenceTComponent,
    VideoConferencesComponent,
    GeneralReportsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTreeModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatDividerModule,
    MatListModule,
    MatProgressBarModule,
    MatTooltipModule,
    NgxMaskModule,
    CdkTreeModule,
    MatBadgeModule,
    MatExpansionModule,
    CoreModule,
    AngularAgoraRtcModule.forRoot(agoraConfig),
    FileUploadModule,
    BrowserAnimationsModule,
    Angular2CsvModule
  ],
  entryComponents: [
    DialogGroupComponent,
    DialogCourseComponent
  ]
})
export class AdmonComponentModule {}
