import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

@Injectable({
  providedIn: 'root'
})
export class ForumService {

  constructor( private httpService: HttpService ) { }

  // Obtiene todos los hilos
  getAll() {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/forums/thread`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtiene todos los posts
  getPosts( threadId: number) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/forums/thread?id=${ threadId }`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Agrega un hilo
  saveThread( trehad ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/forums/thread`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( trehad ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Agrega un post a un hilo
  savePost( post ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/forums/post`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( post ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Actualizar el estatus de mejor comentario para el post
  updateStatusPost( postId: number , status: boolean ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/forums/post/${postId}/status`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

   const POST = {
     id: postId,
     status: status
   };

    return this.httpService.put(API_URL, JSON.stringify( POST ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  // Eliminar un post
  deletePost( postId: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/forums/post?id=${ postId }`;
    return this.httpService.delete( API_URL )
                           .map( res => res.json());
  }

  // Actualizar los votos de un post
  votePost( postId: number, vote: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/forums/post/${ postId }/vote`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    const body = {
      id: postId,
      vote: vote
    };

    return this.httpService.post(API_URL, JSON.stringify( body ),
                                 { headers: HEADERS } )
                           .map( res => res.json());

  }


}
