import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BookNavService } from '../../components/layouts/publication/book-nav/book-nav.service';

declare function setHeight(): any;

@Component({
  selector: 'app-book-layout',
  templateUrl: './book-layout.component.html',
  styleUrls: ['./book-layout.component.scss']
})
export class BookLayoutComponent implements OnInit, AfterViewInit {

  constructor( public _bookNavService: BookNavService ) {
    let body: any = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-login');
    body.classList.remove('bg-landing');
   }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // JAVASCRIPT
     setHeight();
  
    }

}
