import { TeacherExamType } from './teacherExamType.model';

export class TeacherExam {
  id: number;
  teacherExamTypeId: number;
  description: string;
  active: boolean;
  questions?: number[];
  totalQuestions: number;
  index: number;
  teacherExamType?: TeacherExamType;
}
