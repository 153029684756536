import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';

// DIALOGS COMPONENTS
import { DialogPermissionComponent } from '../../../../dialogs/dialog-permission/dialog-permission.component';
// tslint:disable-next-line:max-line-length
import { DialogExamSelectQuestionsComponent } from '../../../../dialogs/dialog-exam-select-questions/dialog-exam-select-questions.component';

// COMPONENT SERVICES
import { SitemapService } from '../../../../core/sitemap/sitemap.service';
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';

// Services
import { PermissionService } from '../../../../service/service.index';

// Models
import { Permission } from '../../../../models/security/permission.model';
import { Pagination } from '../../../../models/general/pagination.model';

import swal from 'sweetalert2';
import { Appsettings } from '../../../../configuration/appsettings';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {

  working: boolean;
  permissions: Permission [] = [];
  permissionsAll: Permission [] = [];
  showFilters: boolean;

  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();
  textSearch: string = '';

 @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor( public dialog: MatDialog,
               private _srvPermission: PermissionService,
               public _srvSiteMap: SitemapService,
               private _srvHeader: AdminHeaderService ) {

  this._srvHeader.setTitle('Administración de permisos');

      this.showFilters = false;
      this.working = false;

       this._srvSiteMap.setSiteMap(
          [
            {
              'description' : 'Administración',
              'isCurrent' : false
            },
            {
              'description' : 'Permisos',
              'isCurrent' : true
            }
          ]
        );

    }

  ngOnInit(): void {
    this.setPage( { page: 1, size: this.sizePage });
    this.getAllPermission();
    this._srvHeader.setTitle('Administración de permisos');
  }

  onCreate() {
    const DIALOG_REF = this.dialog.open( DialogPermissionComponent, {
      width: '480px',
      height: '400px',
      autoFocus: false,
      disableClose: true,
      data: { permissions: this.permissionsAll, permision:  new Permission(0, '', '', false)  }
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      if ( response ) {
        this.setPage( { page: 1, size: this.sizePage });
      }
    });
  }

  onEditItem( element ): void {
    const DIALOG_REF = this.dialog.open( DialogPermissionComponent, {
      width: '480px',
      height: '400px',
      autoFocus: false,
      disableClose: true,
      data: { permissions: this.permissionsAll, permision: element  }
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      if ( response ) {
        this.setPage( { page: 1, size: this.sizePage });
      }
    });
  }

  onDeleteItem( element ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.working = true;
        // element.active = 0;
        this._srvPermission.delete( element.id ).subscribe( res => {
            if ( res.success ) {
              swal( Appsettings.APP_NAME , res.message, 'success' );
              this.setPage( { page: 1, size: this.sizePage });
            } else {
              swal( Appsettings.APP_NAME, res.message, 'error' );
            }
            this.working = false;
          }, err => {
            this.working = false;
            swal( Appsettings.APP_NAME, 'Problemas al eliminar el usuario', 'error' );
        });
      }
    });


  }

  onDetailItem( element ): void {
  }

  onClickUpdateStatus( e: Permission ) {
    this.working = true;
    e.active = !e.active;

    this._srvPermission.update(e).subscribe( res => {
      this.setPage( { page: 1, size: this.sizePage } );
    });
  }

  onKeyUp(event) {

    if ( this.textSearch.length >= 4 || this.textSearch.length == 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  getAllPermission() {

    this.working = true;
    this._srvPermission.getAll().subscribe( result => {

      this.permissionsAll = [];

      if ( result.success ) {

        let data = result.data;
        for ( let c of data) {

          let permision = new Permission(0, '', '', false);
          permision.id = c.id;
          permision.module = c.module;
          permision.description = c.description;
          permision.active = c.active;
          permision.parentId = c.parentId;
          this.permissionsAll.push( permision );
        }
      }
    });
  }

  // Cargar datos de la página
  setPage( paginationData: object ) {

    this.working = true;
    this._srvPermission.getAllPagination( Number(paginationData['size']), Number(paginationData['page']) , this.textSearch).subscribe( result => {

      let index = 1;
      this.permissions = [];

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        let data = result.data;
        for ( let c of data) {

          let permision = new Permission(0, '', '', false);

          permision.id = c.id;
          permision.module = c.module;
          permision.description = c.description;
          permision.active = c.active;
          permision.icon = c.icon;
          permision.url = c.url;
          permision.showInMenu = c.showInMenu;
          permision.parentId = c.parentId;

          permision.index = this.pagination.getIndexRegister(index);
          this.permissions.push( permision );
          index++;
        }
      }
      this.working = false;
    }, result => {
      this.working = false;
    });
  }

}
