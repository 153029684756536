import { ExamScheduleType } from './examScheduleType.model';

export class ExamSchedule {
  id: number;
  description: string;
  applicationDate: Date;
  studentGroupId: number;
  active: boolean;
  status?: ExamScheduleType;
  teacherExamId?: number;
  typeExam: number;
  examScheduleTypeId: number;

}
