import { environment } from '../../environments/environment.mantto';
export class Appsettings {
  public static VERSION = 'v0';
  public static SYSTEM_NAME = environment.nameApp;
  public static APP_NAME = environment.nameApp;
  public static API_ENDPOINT = environment.apiUrl;
  public static API_ENDPOINT_FULL = environment.apiUrl + '/api';
  public static RA_URL = environment.raUrl;
  public static PRIVACY_ANNOUNCEMENT = '/assets/docs/politicas_privacidad.pdf';
  public static VIMEO_TOKEN = '9564efdf10605005a6876dc2612f1088';
  public static SALI_STRUCTURE_ID = 2;
  public static RA_STRUCTURE_ID = 124;
  public static SYSTEM_ID = 1;
  public static FIREBASE_CONFIG = environment.firebase;
  public static DEFAULT_SIZE_PAGE = '10';
  public static PLAN_LECTOR_NODE_ID = 500;

}
