import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../../configuration/appsettings';

// SERVICES
import { ScheduledEventService } from '../../../service/service.index';

// MODELS
import { Course } from '../../../models/catalogs/course.model';
import { ScheduledEvent } from '../../../models/courses/scheduledEvent.model';

import swal, { SweetAlertType } from 'sweetalert2';
import { UserUtilities } from '../../../shared/user.utilities';

@Component({
  selector: 'app-scheduled-event',
  templateUrl: './scheduled-event.component.html',
  styleUrls: ['./scheduled-event.component.scss']
})
export class ScheduledEventComponent implements OnInit {

  working: boolean;
  events: ScheduledEvent[];
  frmEvent: FormGroup;
  showForm: boolean = false;

  @Input() course: Course;
  @ViewChild('formEvent') formEvent;

  constructor( private _srvScheduleEvent: ScheduledEventService  ) {

    this.frmEvent = new FormGroup({
      id: new FormControl(0),
      dateEvent: new FormControl('', [ Validators.required ] ),
      description: new FormControl('', [ Validators.required, Validators.maxLength(500) ] ),
      duration: new FormControl('', [ Validators.required ]),
      initTime: new FormControl('', [ Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)]),
      name: new FormControl('', [ Validators.required]),
      groups: new FormControl([], [ Validators.required])
    });
  }

  ngOnInit() {
    this.getEvents();
    // console.log(this.course);
  }

  // Agregar un nuevo evento
  onClickAdd() {
    this.resetForm();
    this.showForm = true;
  }

  // Cancelar la captura de un evento
  onClickCancel() {
    this.resetForm();
    this.showForm = false;
  }

  onClickEdit( event: ScheduledEvent ) {
    this.showForm = true;
    this.frmEvent.controls['id'].setValue( event.id);
    this.frmEvent.controls['description'].setValue( event.description);
    this.frmEvent.controls['initTime'].setValue( event.initTime);
    this.frmEvent.controls['duration'].setValue( event.duration.toString());
    this.frmEvent.controls['dateEvent'].setValue( event.dateEvent);
    this.frmEvent.controls['name'].setValue( event.name);
    this.frmEvent.controls['groups'].setValue( event.groups);

  }

  onClickDelete(event: ScheduledEvent) {
    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if (result.value) {

        this.working = true;
        this._srvScheduleEvent.remove( event.id ).subscribe( res => {
          let swalType: SweetAlertType = 'error';

          if ( res.success ) {
            this.events = this.events.filter( x => x.id != event.id);
            swalType = 'success';
          }
          this.working = false;
          swal(Appsettings.APP_NAME, res.message , swalType);
        }, err => {
          this.working = false;
        });
      }
    });
  }
  // Guardar los datos del evento
  onSubmit() {


    let event = new ScheduledEvent();
    let dateEvent = this.frmEvent.controls['dateEvent'].value;
    let eventMonth =  dateEvent.getMonth() + 1;
    let newDateEventInit = dateEvent.getFullYear() + '/' + eventMonth + '/' +  dateEvent.getDate() ;

    const courseInit = new Date(this.course.startDate);
    let initMonth = courseInit.getMonth() + 1;
    let newDateCourseInit = courseInit.getFullYear() + '/' + initMonth + '/' +  courseInit.getDate() ;

    const courseEnd = new Date(this.course.endDate);
    let endMonth = courseEnd.getMonth() + 1;
    let newDateCourseEnd = courseEnd.getFullYear() + '/' + endMonth + '/' +  courseEnd.getDate() ;

    if ( newDateCourseInit > newDateEventInit ) {
      swal(Appsettings.APP_NAME, 'La fecha del evento no puede ser menor a la fecha de inicio del curso', 'warning');
      return;
    }

    if ( newDateCourseEnd < newDateEventInit ) {
      swal(Appsettings.APP_NAME, 'La fecha del evento no puede ser mayor a la fecha de termino del curso', 'warning');
      return;
    }

    let initEvent = UserUtilities.addHoursToDate(dateEvent, this.frmEvent.controls['initTime'].value );
    let endEvent = UserUtilities.sumMinutes(initEvent, parseInt(this.frmEvent.controls['duration'].value));

    event.id = this.frmEvent.controls['id'].value;
    event.description = this.frmEvent.controls['description'].value;
    event.dateEvent = UserUtilities.addHoursToDate(dateEvent, this.frmEvent.controls['initTime'].value );
    event.duration = this.frmEvent.controls['duration'].value;
    event.initTime = this.frmEvent.controls['initTime'].value;
    event.name = this.frmEvent.controls['name'].value;
    event.courseId = this.course.id;
    event.endEvent = endEvent;
    event.groups = this.frmEvent.controls['groups'].value;

    if ( event.id === 0 ) {
      this.save(event);
    } else {
      this.update(event);
    }

  }

  // Guardar los datos de un  evento
  save( event: ScheduledEvent ) {
    this.working = true;
    this._srvScheduleEvent.save(event).subscribe( res => {

      if ( res.success ) {
        event.id = res.data;
        this.events.push(event);
        this.resetForm();
        this.showForm = false;
        swal(Appsettings.APP_NAME, 'Evento guardado correctamente' , 'success');
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });

   }

  update( event: ScheduledEvent ) {
    this.working = true;
    this._srvScheduleEvent.update(event).subscribe( res => {

      if ( res.success ) {


        let eventUpdate = this.events.filter( x => x.id === event.id)[0];

        if ( eventUpdate ) {
          eventUpdate.description = event.description;
          eventUpdate.dateEvent = event.dateEvent;
          eventUpdate.duration = event.duration;
          eventUpdate.initTime = event.initTime;
          eventUpdate.name = event.name;
          eventUpdate.endEvent = event.endEvent;
          eventUpdate.groups = event.groups;
        }

        this.resetForm();
        this.showForm = false;

        swal(Appsettings.APP_NAME, 'Evento actualizado correctamente' , 'success');

      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Inicializar el formulario
  resetForm( ) {
      this.frmEvent.reset();
      this.frmEvent.markAsUntouched();
      this.frmEvent.markAsPristine();
      this.frmEvent.updateValueAndValidity();


      if (this.formEvent) {
        this.formEvent.resetForm();
      }

      this.frmEvent.controls['id'].setValue(0);
  }

  // Obtener todos los eventos del grupo
  getEvents() {
    this.working = true;
    this._srvScheduleEvent.getByCourseId( this.course.id ).subscribe( res => {

      if ( res.success ) {

        this.events = [];

        for ( let d of res.data ) {

          let event = new ScheduledEvent();

          event.active = d.active;
          event.courseId = d.courseId;
          event.dateEvent = new Date(d.dateEvent);
          event.endEvent = new Date(d.endEvent);
          event.description = d.description;
          event.duration = d.duration;
          event.initTime = d.initTime;
          event.name = d.name;
          event.id = d.id;
          event.groups = [];

          for ( let g of d.groups ) {
            event.groups.push( g.id );
          }

          this.events.push(event);
        }
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

}
