import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';

// Componentes
import { TasksScheduleComponent } from '../../../components/administrator/tasks-schedule/tasks-schedule.component';
// Servicios
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';
// Modelos
import { Group } from '../../../models/courses/group.model';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-task-schedule',
  templateUrl: './task-schedule.component.html',
  styleUrls: ['./task-schedule.component.scss']
})
export class TaskScheduleComponent implements OnInit, OnDestroy {


  @ViewChild(TasksScheduleComponent)
  private timerComponent: TasksScheduleComponent;

  group: Group;
  studenGroupId: number;
  constructor(  private _srvHeader: AdminHeaderService,
                private _route: ActivatedRoute) {
                  this._srvHeader.setBreadcrumb([Appsettings.APP_NAME, 'Mi salón', 'Programación de tareas']);

                }

  ngOnDestroy(): void {
       this._srvHeader.setBreadcrumb([]);
  }

  ngOnInit() {
    this._route.params.subscribe( params => {

      this.studenGroupId = params.studenGroupId;
      this.timerComponent.getTask( this.studenGroupId);
      this.group = JSON.parse(localStorage.getItem('sali.group'));

      this._srvHeader.setTitle('Programación de tareas: ' + this.group.description );
    });
  }

  onClickAddNew() {
    if ( !this.timerComponent.showForm) {
      this.timerComponent.addTaskSchedule(  );
    }
  }


}
