import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// COMPONENT SERVICES
import { BookNavService } from '../../../components/layouts/publication/book-nav/book-nav.service';
import { CourseNavService } from '../../../components/layouts/publication/course-nav/course-nav.service';

// MODELS
import { Course } from '../../../models/catalogs/course.model';

@Component({
  selector: 'app-course-contain',
  templateUrl: './course-contain.component.html',
  styleUrls: ['./course-contain.component.scss']
})
export class CourseContainComponent implements OnInit {

  courseId: number;
  nodeId: number;
  course: Course;

  constructor( private _route: ActivatedRoute,
               public _bookNavService: BookNavService,
               public _courseNavService: CourseNavService ) { }

  ngOnInit() {
    this._route.params.subscribe(params => {

      this.courseId = Number(params['id']);
      this.nodeId = Number(params['nodeId']);

      this._bookNavService.setNode( this.nodeId );
      this._courseNavService.asyncGetStudentCourses( this.nodeId, this.courseId ).then( () => {
             this.course = this._courseNavService.course;
      });

    });

  }

}
