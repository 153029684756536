import { Component, OnInit } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';
import { MatDialog } from '@angular/material';

// SERVICES
import { GeneralConferencesService, StorageService, UserService } from '../../../service/service.index';

// COMPONENT SERVICES
import { SitemapService } from '../../../core/sitemap/sitemap.service';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { Pagination } from '../../../models/general/pagination.model';
import { GeneralConferences } from '../../../models/videoConference/video-conference-model';
import { PaginarionLocal } from '../../../models/general/pagination-local.model';
import { ForumService, PagerService } from '../../../service/service.index';
import { Thread } from '../../../models/catalogs/thread.model';

// DIALOGS
import swal from 'sweetalert2';
import { DialogVideoConferenceFormComponent } from '../../../dialogs/dialog-video-conference-form/dialog-video-conference-form.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Lesson } from '../../../models/content/lesson.model';

@Component({
  selector: 'app-video-conferences',
  templateUrl: './video-conferences.component.html',
  styleUrls: ['./video-conferences.component.scss']
})

export class VideoConferencesComponent implements OnInit {
  working: boolean;
  generalConferences: GeneralConferences[] = [];

  // PAGINATION VARIABLES
  textSearch: string = '';
  page: number = 1;
  pagination: Pagination = new Pagination();
  userId: number;
  arrayCourse: any[] = [];
  arrayUsersGeneral: any[] = [];
  pager: PaginarionLocal;
  threads: any = [];
  sources: Thread[] = [];
  sizePage: 5;
  totalpages: number;

  constructor(public _srvSiteMap: SitemapService,
    private _srvHeader: AdminHeaderService,
    private _srvVideoConference: GeneralConferencesService,
    private _storageSrv: StorageService,
    private _srvPager: PagerService,
    public dialog: MatDialog) {

    this.working = false;
    this._srvSiteMap.setSiteMap([{
      'description': 'Contenido',
      'isCurrent': false
    },
    {
      'description': 'Eventos',
      'isCurrent': true
    }]);
  }

  ngOnInit() {
    this.getAllusers();
    this._srvHeader.setTitle('Administrar videoconferencias');

    if (this._storageSrv.rol === "Administrador") {
      this._srvVideoConference.getCourseAll().subscribe(
        res => {
          for (let d of res.data) {
            this.arrayCourse.push(d);
          }
          this.getVideoConference();
        });
    }
    else {
      this._srvVideoConference.getCourseAll().subscribe(
        res => {
          for (let d of res.data) {
            this.arrayCourse.push(d);
          }
          this.getVideoConference();
        });
    }

  }

  onClickNew() {
    let conference = new GeneralConferences();

    conference.id = 0;
    conference.title = '';
    conference.information = '';
    conference.dateTimeVideoConference = null;
    conference.duration = 0;
    conference.updateDate = null;
    conference.registerDate = null;
    conference.guests = '';
    conference.commentary = '';
    conference.RoomVideoConference = '';
    conference.externalGuest = '';
    conference.groups = 0;
    conference.course = 0;
    conference.statusId = 1;
    conference.ynShowDetails = true;


    if (this._storageSrv.rol === "Administrador") {
      conference.ynNewRegister = false;
    }
    else {
      conference.ynNewRegister = true;
    }

    const DIALOG_REF = this.dialog.open(DialogVideoConferenceFormComponent, {
      width: '800px',
      height: '780px',
      autoFocus: false,
      disableClose: true,
      data: conference
    });
    DIALOG_REF.afterClosed().subscribe(response => {
      if (response) {
        this.getVideoConference();
      }
    });
  }


  onEditItem(data) {
    const DIALOG_REF = this.dialog.open(DialogVideoConferenceFormComponent, {
      width: '800px',
      height: '780px',
      autoFocus: false,
      disableClose: true,
      data: data
    });
    DIALOG_REF.afterClosed().subscribe(response => {
      if (response) {
        this.getVideoConference();
      }
    });
  }

  showItem(data) {
    const DIALOG_REF = this.dialog.open(DialogVideoConferenceFormComponent, {
      width: '800px',
      height: '780px',
      autoFocus: false,
      disableClose: true,
      data: data
    });
    DIALOG_REF.afterClosed().subscribe(response => {
      if (response) {
        this.getVideoConference();
      }
    });
  }

  getCourseAll() {

  }
  // Obtiene los datos del servicio
  getVideoConference(): void {
    this.working = true;
    this.sources = [];
    this._srvVideoConference.getAll().subscribe(
      res => {
        console.log(res);
        this.userId = res.data.id;
        this.generalConferences = [];

        for (let d of res.data.video) {
          let conference = new GeneralConferences();
          conference.id = d.id;
          conference.title = d.title;
          conference.information = d.information;
          conference.dateTimeVideoConference = new Date(d.dateTimeVideoConference);
          conference.duration = d.duration;
          conference.updateDate = new Date(d.updateDate);
          conference.registerDate = new Date(d.registerDate);
          conference.guests = d.guests;
          conference.commentary = d.commentary;
          conference.statusId = d.statusId;
          conference.RoomVideoConference = d.roomVideoConference;
          conference.externalGuest = d.externalGuest;
          conference.groups = d.studentGroupId;
          conference.course = d.courseId;
          conference.emailsExternal = d.emailsExternal;
          conference.userId = d.userId;
          conference.guestMail = d.guestMail;

          let arrayCourse = this.arrayCourse.filter(x => x.id === conference.course);

          if (arrayCourse.length > 0) {
            let groups = arrayCourse[0].groups;
            conference.courseName = this.arrayCourse[0].description;

            if (groups.length > 0) {
              groups = groups.filter(x => x.id === conference.groups)
              if (groups.length > 0) {
                conference.groupName = groups[0].description;
              }
            }
          }

          if (this._storageSrv.rol === "Administrador" && conference.groups === 0 && conference.course === 0) {
            conference.ynShowDetails = true;
          }
          else {
            conference.ynShowDetails = false;
          }
          this.generalConferences.push(conference);

        }

        if (this._storageSrv.rol === "Administrador") {
        }
        else {
          this.generalConferences = this.generalConferences.filter(x => x.userId === this.userId);
        }

        this.setPage(1);


        if (res.data) {
        }
        this.working = false;
      },
      err => {
        this.working = false;
      }
    );
  }
  // Evento para eliminar un nodo
  onClickDelete(node) {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      this.working = true;

      if (result.value) {

        this._srvVideoConference.delete(node.id).subscribe(res => {

          if (res.success) {

            swal(Appsettings.APP_NAME, res.message, 'success');
            this.getVideoConference();
          } else {
            swal(Appsettings.APP_NAME, res.message, 'error');
          }

          this.working = false;

        }, err => {
          this.working = true;
        });
      } else {
        this.working = false;
      }
    });
  }

  // Evento que permite activar o desactivar el contenido
  onClickUpdateStatus(content): void {

    const title = content.active ? 'Desactivar contenido' : 'Activar contenido';
    const titleButton = content.active ? '¡Si, desactivar ahora!' : '¡Si, activar ahora!';
    const subTitle = content.active ? 'Al momento de desactivar el contenido no se mostrará a los usuarios'
      : 'Al momento de activar el contenido será mostrado a los usuarios';
    swal({
      title: title,
      text: subTitle,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: titleButton,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.working = true;
        this._srvVideoConference.update(content.id).subscribe(res => {

          if (res.success) {

            this.working = false;
            content.active = !content.active;

            // swal(Appsettings.APP_NAME, 'El registro fue actualizado correctamente.', 'success' );
            // this.setPage( { page: 1, size: this.sizePage });

          } else {
            this.working = false;
            swal(Appsettings.APP_NAME, res.message, 'error');
          }
        }, err => {
          this.working = false;
          swal(Appsettings.APP_NAME, 'Problemas al actualizar el estatus del contenido', 'error');
        });
      }
    });
  }


  getAllusers() {
    this.working = true;
    this._srvVideoConference.getAllusers().subscribe(result => {
      if (result.success) {
        this.arrayUsersGeneral.push(result.data);
      }
      this.working = false;
    });
  }

  // Realizar paginación local
  setPage(page: number) {
    this.threads = [];
    this.pager = this._srvPager.getPager(this.generalConferences.length, page, this.sizePage);
    this.totalpages = this.pager.totalItems;
    this.threads = this.generalConferences.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
}
