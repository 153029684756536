export class ExamScheduleType{
  id: number;
  description: string;
  active: boolean;

  constructor(_id: number, _description: string ) {
    this.id = _id;
    this.description = _description;
  }
}
