import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FileHolder } from 'angular2-image-upload';
import { Appsettings } from '../../configuration/appsettings';

// MODELS
import { FileType } from '../../models/content/fileType.model';
import { ContentType } from '../../models/content/conentType.model';
import { FileContent } from '../../models/catalogs/file.model';
import { Content } from '../../models/content/content.model';
import { ResourceType } from '../../models/catalogs/resourceType.model';
import { Tag } from '../../models/content/tag.model';

// SERVICES
import { ContentService, CatalogService, VimeoService, TagService } from '../../service/service.index';

import swal from 'sweetalert2';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss']
})

export class DialogContentComponent implements OnInit {

  frmContent: FormGroup;
  contentTypes: ContentType[] = [];
  fileTypes: FileType[] = [];
  resourceType: ResourceType[] = [];
  showUrlVideoInput: boolean;

  fileContent: File;
  fileContentBase: string;
  filenName: string;
  working: boolean;

  isUpdateFile: boolean;

  purposes: any[] = [];
  areas: any[] = [];
  trainingFields: any[] = [];
  formativeFields: any[] = [];

  showSelects: boolean = false;
  showContentSelect: boolean = false;

  tags: any[] = [];
  tagFrmControl = new FormControl();
  workingLocal: boolean;
  selectedTags: any[] = [];

  videoId: string;
  videoUrl: string;
  videoMsg: boolean;

  constructor(public dialogRef: MatDialogRef<DialogContentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Content,
              public _srvContent: ContentService,
              private _srvCatalogS: CatalogService,
              private _srvVimeo: VimeoService,
              private _srvTag: TagService
  ) {

     this.videoMsg = false;
     this.initData();
     this.showUrlVideoInput = false;
     this.working           = false;
     this.isUpdateFile      = false;

     this.tagFrmControl.valueChanges.debounceTime(400).subscribe( item => {
      this.tags = [];
      if ( item.length > 2 ) {
        this.workingLocal = true;
        this._srvTag.getAllPagination(20, 1, item).subscribe( res => {
          if ( res.data ) {
            this.tags = res.data;
          }
          this.workingLocal = false;
        }, err => {
          this.workingLocal = false;
        });
      }
    });
  }

  ngOnInit() {

    this.frmContent = new FormGroup( {
      'id': new FormControl(this.data.id),
      'name': new FormControl( this.data.name, [Validators.required] ),
      'urlContent': new FormControl( this.data.urlContent),
      'isPublic': new FormControl( this.data.isPublic ),
      'active': new FormControl( this.data.active ),
      'contentTypeId': new FormControl( this.data.contentType.id , [ Validators.required ] ) ,
      'fileTypeId': new FormControl( this.data.fileType.id , [ Validators.required ] ),
      'file': new FormControl(''),
      'formativeFields': new FormControl(this.data.formativeFieldId),
      'areas': new FormControl(this.data.areaId),
      'purposes': new FormControl(this.data.purposeId),
      'trainingFields': new FormControl(this.data.trainingFieldId),
      'resourceTypeId': new FormControl(this.data.resourceTypeId),
      'description': new FormControl(this.data.description),
     });

      if ( this.data.id > 0 ) {

        this.loadContentData( this.data );
        this.videoId = this.data.urlContent;
        this.selectedTags = this.data.tags;

        if ( this.data.contentType.id !== 3 ) {
          this.showSelects = true;
        } else {
          this.showContentSelect = true;
        }
      }

  }

  // Metodo que guarda el nuevo contenido
  onSubmit() {

    this.working = true;
    let fileUpload = new FileContent();

    if ( this.fileContent ) {

      const arrayFileBase = this.fileContentBase.split(',');

      fileUpload = new FileContent(
        arrayFileBase[1],
        this.fileContent.type,
        this.fileContent.name,
        arrayFileBase[0]
      );
    }

    const  content = new Content();
    content.id = this.frmContent.controls['id'].value;
    content.name = this.frmContent.controls['name'].value;
    content.urlContent = this.frmContent.controls['urlContent'].value;
    content.isPublic = this.frmContent.controls['isPublic'].value;
    content.contentType = new ContentType(this.frmContent.controls['contentTypeId'].value, '');
    content.fileType = new FileType(this.frmContent.controls['fileTypeId'].value, '');
    content.active = this.frmContent.controls['active'].value;

    content.formativeFieldId = this.frmContent.controls['formativeFields'].value;
    content.areaId = this.frmContent.controls['areas'].value;
    content.purposeId = this.frmContent.controls['purposes'].value;
    content.trainingFieldId = this.frmContent.controls['trainingFields'].value;
    content.resourceTypeId = this.frmContent.controls['resourceTypeId'].value;
    content.description = this.frmContent.controls['description'].value;

    content.tags = [];
    for ( let t of this.selectedTags ) {
       let tag = new Tag();
       tag.id = t.id;
       tag.name = t.name;

       content.tags.push(tag);
     }

    if ( this.videoUrl != null ) {
      content.thumbnails = this.videoUrl;
    }

    if ( content.id > 0) {
      this.update( content, fileUpload );
    } else {
      this.save( content, fileUpload );
    }

  }

  handleFileInput( event ) {
  /*
    let reader = new FileReader();

    if ( event.target.files && event.target.files.lenght > 0 ) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
    }

    this.fileToUpload = files.item(0);
    */
  }

  // Metodo llama la validación del tipo de archivo
  onSelectionFileType( event ): void {
    this.validateFileType( event.value );
  }

  // Metodo cierra el modal
  onCloseConfirm() {
    this.dialogRef.close(null);
  }

  onRemoved(event: FileHolder) {
    this.fileContentBase = '';
    this.fileContent = null;
    this.frmContent.controls['file'].setValue('');
    this.filenName = '';
  }

  onUploadFinished(event: FileHolder) {

    this.fileContentBase = event.src;
    this.fileContent     = event.file;
    this.filenName       = this.fileContent.name;
    this.isUpdateFile    = true;

    this.frmContent.controls['file'].setValue(event.file.name);

  }

  onUploadStateChanged( event ) {
  }

  // Cargar los datos iniciales del contenido en caso de editar
  loadContentData ( content ) {
    this.filenName = 'archivo';
    this.frmContent.controls['file'].setValue( content.urlContent );
    this.validateFileType( content.fileType.id );
  }

  // Validar el tipo de archivo y determinar si muestra el campo
  // de URL o el input file para cargar archivo
  validateFileType ( fileTypeId: number ) {

    const URL_CONTROL = this.frmContent.controls['urlContent'];
    const FILE = this.frmContent.controls['file'];

    if ( fileTypeId === 1 ||  fileTypeId === 5 ) {

      this.showUrlVideoInput = true;
      this.videoMsg = false;
      URL_CONTROL.setValidators( [ Validators.required ] );

      FILE.setValue('');
      FILE.setValidators( [] );
      FILE.clearValidators();

    } else {
      this.videoUrl = null ;
      this.showUrlVideoInput = false;

      URL_CONTROL.setValue('');
      URL_CONTROL.setValidators( [] );
      URL_CONTROL.clearValidators();

      FILE.setValidators( [ Validators.required ] );

    }

    setTimeout(() => {
      URL_CONTROL.updateValueAndValidity();
      FILE.updateValueAndValidity();
    });

    URL_CONTROL.markAsUntouched();

  }

  save ( content: Content , fileUpload ): void {

    this._srvContent.save( content, fileUpload ).subscribe( res => {

      this.working = false;

      if ( res.success ) {
        swal('Datos guardados', 'La ODA se agregó exitosamente', 'success' );

        const contentType = this.contentTypes.filter( x => x.id ===  content.contentType.id )[0];
        const fileType    = this.fileTypes.filter( x => x.id ===  content.fileType.id )[0];

        content.activoDescription = content.active ? 'Activo' : 'Inactivo';
        content.id = res.data.id;
        content.urlContent = res.data.urlContent;
        content.contentType = new ContentType(  contentType.id, contentType.description ) ;
        content.fileType = new FileType(  fileType.id,  fileType.description );

        this.dialogRef.close( content );

      } else {
        swal('Tuvimos problemas', res.message, 'error' );
      }

    }, err => {
      swal('Tuvimos problemas', 'Error al intentar conectarse con el servidor', 'error' );
      this.working = false;
    });

  }

  update ( content, fileUpload ): void {
    this._srvContent.update( content, fileUpload ).subscribe( res => {

      this.working = false;

      if ( res.success ) {
        swal('Datos guardados', 'La ODA se agregó exitosamente', 'success' );

        const contentType = this.contentTypes.filter( x => x.id ===  content.contentType.id )[0];
        const fileType    = this.fileTypes.filter( x => x.id ===  content.fileType.id )[0];

        content.activoDescription = content.active ? 'Activo' : 'Inactivo';
        content.urlContent = res.data.urlContent;
        content.contentType = new ContentType(  contentType.id, contentType.description ) ;
        content.fileType = new FileType(  fileType.id,  fileType.description );

        this.dialogRef.close(content);
      } else {
        swal('Tuvimos problemas', res.message, 'error' );
      }

    }, err => {
      swal('Tuvimos problemas', 'Error al intentar conectarse con el servidor', 'error' );
      this.working = false;
    });
  }

  // Mostramos u Ocultamos los combos segun el tipo de contenido
  onChangeContentType(idContentType: ContentType ) {
    if (  idContentType['value'] === 1 ||  idContentType['value'] === 2) {
      this.showSelects = true;
      this.showContentSelect = false;
      this.frmContent.controls['resourceTypeId'].setValue(0);
    }
    if ( idContentType['value'] === 3) {
      this.showSelects = false;
      this.showContentSelect = true;

      this.frmContent.controls['purposes'].setValue(0);
      this.frmContent.controls['areas'].setValue(0);
      this.frmContent.controls['trainingFields'].setValue(0);
      this.frmContent.controls['formativeFields'].setValue(0);

    }
  }

  // Inicializar todos los catálogos utilizados
  initData(): void {
    this._srvCatalogS.getPurposesAll().subscribe(res => {
      if (res.data) {
        this.purposes = res.data;
        this.frmContent.controls['purposes'].setValue(this.data.purposeId);
      }
    });
    this._srvCatalogS.getFileTypesAll().subscribe(res => {
      this.fileTypes = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.fileTypes.push( new FileType( f.id, f.description ));
        }
        this.frmContent.controls['fileTypeId'].setValue( this.data.fileType.id );
      }
    });
    this._srvCatalogS.getContentTypesAll().subscribe(res => {
      this.contentTypes = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.contentTypes.push( new ContentType( f.id, f.description ));
        }
        this.frmContent.controls['contentTypeId'].setValue( this.data.contentType.id );
      }
    });
    this._srvCatalogS.getResourceTypesAll().subscribe(res => {
      this.resourceType = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.resourceType.push( new ResourceType( f.id, f.description ));
        }
        this.frmContent.controls['resourceTypeId'].setValue( this.data.resourceTypeId );
      }
    });
  }

  // Obtiene la url de la imagen miniatura del video
  onChangeUrlVideo( event ) {
    this._srvVimeo.getVideo( this.videoId ).subscribe( res => {
      this.videoMsg = false;
      this.videoUrl = res.pictures.sizes[2].link_with_play_button;
    }, err => {
      this.videoMsg = true;
    });
  }

  // Seleccionar un tag del listado
  selectItem( tag ) {
    this.tagFrmControl.setValue('');

    if ( this.selectedTags.filter( x => x.id === tag.id).length === 0 ) {
      this.selectedTags.push( tag );
    }
  }

  // Eliminar un tag existente
  onDeleteTag( tag ) {

    swal({
      title: '¿Estás seguro?',
        text: '¡Esta operación no se podrá revertir!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Sí, eliminar ahora!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          let id = this.frmContent.controls['id'].value;
          this.working = true;
          let tagsUpdate = this.selectedTags.filter( x => x.id !== tag.id );
          this._srvContent.updateTags( id , tagsUpdate ).subscribe( res => {
            if ( res.success ) {
              this.selectedTags =   this.selectedTags.filter( x => x.id !== tag.id );
              swal(Appsettings.APP_NAME, res.message, 'success');
            } else {
              swal(Appsettings.APP_NAME, res.message, 'error');
            }
            this.working = false;
          }, err => {
            this.working = false;
          });
        }
      });
  }

  // manejador del evento enter en el formulario
  handleEnterKeyPress(event) {
    const tagName = event.target.tagName.toLowerCase();
    if (tagName !== 'input') {
      return false;
    }

    if ( tagName === 'input') {
      let tag  = new Tag();
      tag.name = this.tagFrmControl.value;
      tag.id   = 0;

      if (this.selectedTags.filter( x => x.name === tag.name).length === 0 ) {
        this.selectedTags.push(tag);
        this.tagFrmControl.setValue('');
      }
      return false;
    }
  }


}
