import { Component, OnInit, OnDestroy } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

// SERVICES COMPONENTS
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { Studen } from '../../../models/courses/student.model';
import { Group } from '../../../models/courses/group.model';

// SERVICES
import { StudenService } from '../../../service/service.index';

// DIALOGS
import { DialogInvitationComponent } from '../../../dialogs/dialog-invitation/dialog-invitation.component';
import { Appsettings } from '../../../configuration/appsettings';




@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit, OnDestroy {


  students: any[] = [];
  working: boolean;
  group: Group;
  groupId: number;
  courseId: number;

  constructor( private _srvHeader: AdminHeaderService,
               private _srvStuden: StudenService,
               private _dialog: MatDialog,
               private _route: ActivatedRoute) {

                this.groupId = 0;

                this._route.params.subscribe( params => {
                  this.groupId = params.id;
                  this.courseId = params.course;

                  this.group = JSON.parse(localStorage.getItem('sali.group'));
                  this._srvHeader.setTitle('Alumnos: ' +   this.group.description);
                  this.getStudents();
                });

                this._srvHeader.setBreadcrumb([Appsettings.APP_NAME, 'Mi salón', 'Alumnos']);

              }

  ngOnInit( ) {

  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  getStudents( ) {
    this.students = [];
    this.working = true;
    this._srvStuden.getAll( this.groupId ).subscribe( result => {
      if ( result.success) {
        for ( let studen of result.data) {
          let student = new Studen();

          student.id = studen.userId;
          student.fullName = studen.userName;
          student.email = studen.userMail;
          student.statusStudent = studen.studentStatusDescription;
          student.checkDate = studen.checkDate;

          this.students.push(  student );

        }
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Lanza el modal para enviar invitacion a un estudiante
  onclickSendInvitation() {
    const DIALOG_REF = this._dialog.open( DialogInvitationComponent, {
    width: '500px',
    height: '600px',
    autoFocus: false,
    disableClose: true,
    data: { groupId: this.groupId,
            courseId: this.courseId
          }
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      if ( response != null) {
          for ( let s of response ) {
            s.statusStudent = 'INVITADO',
            this.students.push(s);
          }
      }
    });
  }

}
