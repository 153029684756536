import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// SERVICES
import { UserService, StorageService } from '../../../service/service.index';

// COMPONENT SERVICES
import { HeaderService } from '../../../components/layouts/anonymous/header/header.service';

import swal from 'sweetalert2';
import { BreadcrumbService } from '../../../core/breadcrumb/breadcrumb.service';
import { Appsettings } from '../../../configuration/appsettings';

declare function setMinHeightByElement(element): any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit,  OnDestroy {


  roles: any[] = [];
  showRoles: boolean = false;
  frmLogin: FormGroup;
  working: boolean;
  systemId: number = 1;

  constructor( private _userSrv: UserService,
               private _storageSrv: StorageService,
               private _router: Router,
               private _svrBreadcrum: BreadcrumbService,
               private _srvHeader: HeaderService ) {

    this.working  = false;
    this.frmLogin = new FormGroup({
      'username': new FormControl('', [ Validators.required,
                                        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]),
      'password': new FormControl('', [ Validators.required]),
      'systemId': new FormControl('')
    });

    this._storageSrv.resetLocalData();

  }

  ngOnInit() {
    this._srvHeader.showReturnButton( true );
    setMinHeightByElement('#pg-banner');
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this._svrBreadcrum.clear();
  }

  onSubmit()  {

    const usuario = this.frmLogin.controls['username'].value;

    this.working = true;
    this._userSrv.login(
      this.frmLogin.controls['username'].value,
      this.frmLogin.controls['password'].value,
      this.systemId
    ).subscribe( res => {

      if ( res.access_token ) {

        this._storageSrv.token          = res.access_token ;
        this._storageSrv.email          = res.email;
        this._storageSrv.name           = res.fullName;
        this._storageSrv.userName       = this.frmLogin.value.username;
        this._storageSrv.rol            = res.rol;
        this._storageSrv.refreshToken   = res.refresh_token;
        this._storageSrv.systemId       = res.systemId;

        this._userSrv.getProfile().subscribe( r => {

          if ( r.success ) {

            if ( r.data.permissions ) {
              this._storageSrv.permissions = JSON.stringify(r.data.permissions);
            }

            if ( r.data.publishingNodes ) {
              this._storageSrv.nodes = JSON.stringify(r.data.publishingNodes);
            }

            if ( r.data.notifications ) {
              this._storageSrv.notifications = JSON.stringify(r.data.notifications);
            }

            this._storageSrv.profileImage =  r.data.user.urlImage;

            // if (res.rol === 'Alumno' || res.rol === 'Profesor') {
            if (res.rol !== 'Administrador') {
              this._router.navigateByUrl('/sali/level');
            } else if (res.rol.indexOf('Administrador') > -1 ) {
              this._router.navigateByUrl('/admin/content-register');
            } else {
              this._router.navigateByUrl('/home/login');
              this.working = false;
            }
          }

        }, err => {
          swal( Appsettings.APP_NAME, 'Problemas al autenticar el usuario',  'error');
          this.working = false;
        } );

      } else {
        swal( Appsettings.APP_NAME, 'Usuario y/o contraseña no validos', 'error');
        this.working = false;
      }
    }, err => {
      swal( Appsettings.APP_NAME, 'Problemas al autenticar el usuario',  'error');
      this.working = false;
    });

  }

  onFocursOutEmail( event ) {

    let email = this.frmLogin.controls['username'].value;
    this.showRoles = false;

    if ( this.frmLogin.controls['username'].valid ) {
      this.working = true;
      this.roles = [];
      this._userSrv.getRolesByUserName(email).subscribe( res => {
        if ( res.success ) {

          for ( let r of res.data ) {

          this.roles.push(
            {
              rol: r.rol,
              rolId: r.rolId,
              systemId: r.systemId,
              systemName: r.systemName
            });
          }
          let adminRole =  this.roles.filter( x => x.rol.toLowerCase().indexOf('administrador') > -1);
          if ( adminRole.length > 0 ) {
            this.showRoles = this.roles.length > 1 && adminRole.length > 0;
            this.systemId = this.roles[0].systemId;
          } else {
            this.systemId = 1;
          }
        }
        this.working = false;
      } , err => {
        this.working = false;
      });
    }
  }

  onKeyUp( event ) {
    this.showRoles = false;
  }

}
