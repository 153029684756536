import { Injectable } from '@angular/core';

import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

// Modelos
import { TaskSchedule } from '../../models/courses/taskSchedule.model';
@Injectable({
  providedIn: 'root'
})
export class HomeworksService {

  constructor(  private httpService: HttpService ) { }

   // Obtiene las tareas programadas de un grupo
   getAll( id: number) {
     // http://{{server}}/api/homeworks/student?studentGroupId=122
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/homeworks/group?studentGroupId=${id}`;
    return this.httpService.get( API_URL )
                            .map( res => res.json());
  }

   // Obtiene las tareas programadas de un grupo
   getAllByStudent( id: number) {
   const API_URL = `${Appsettings.API_ENDPOINT_FULL}/homeworks/student?studentGroupId=${id}`;
   return this.httpService.get( API_URL )
                           .map( res => res.json());
 }

  // Elimina una programacion
  deletContent( id: number) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/homeworks?id=${ id }`;

    return this.httpService.delete(apiUrl)
                           .map( res => res.json());
  }

  // Guadarda una programacion de tarea
  save( params: TaskSchedule ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/homeworks`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Actualiza una programacion de tarea
  update( params: TaskSchedule, id: number) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/homeworks`;
    const HEADERS = new Headers();


    HEADERS.append('Content-Type', 'application/json');

   return this.httpService.put(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Obtiene los archivos de las tareas de un alumno
  getFilesStudent( homeWorkId: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/homeworks/${homeWorkId}/answers`;
    return this.httpService.get( API_URL )
                            .map( res => res.json());
  }

  // Elimina un archivo de tarea del alumno
  deleteFilesHomerwork( homeWorkId: number, homeWorkFileId: number ) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/homeworks/${homeWorkId}/deleteAnswer?answerId=${ homeWorkFileId}`;

    return this.httpService.delete(apiUrl)
                           .map( res => res.json());
  }

  // Obtiene todas las tareas entregadas por los alumnos de un grupo
  getAllHomework( groupId: number, homeworkId: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/homeworks/score?id=${ homeworkId }&studentGroupId=${ groupId}`;
    return this.httpService.get( API_URL )
                            .map( res => res.json());
  }

  // Guarda las califcaciones de un grupo
  saveQualifyGroup( params) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/homeworks/score`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Actualiza el estatus de la tarea
  updateStatusHomwork( params ) {
    // /homeworks/close
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/homeworks/close`;
    const HEADERS = new Headers();


    HEADERS.append('Content-Type', 'application/json');

   return this.httpService.put(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());
  }

}
