import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FileUploader, FileItem, FileUploaderOptions, FileLikeObject } from 'ng2-file-upload';
import { Appsettings } from '../../configuration/appsettings';
import { ValidateUrl } from '../../shared/url.validator';
// MODELS
import { FileType } from '../../models/content/fileType.model';
import { ContentType } from '../../models/content/conentType.model';
import { FileContent } from '../../models/catalogs/file.model';
import { Content } from '../../models/content/content.model';
import { ResourceType } from '../../models/catalogs/resourceType.model';
import { Tag } from '../../models/content/tag.model';

// SERVICES
import { ContentService,
        CatalogService,
        VimeoService,
        TagService,
        StorageService,
        ContentTeacherService } from '../../service/service.index';

import swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-content-large',
  templateUrl: './dialog-content-large.component.html',
  styleUrls: ['./dialog-content-large.component.scss']
})
export class DialogContentLargeComponent implements OnInit {

  frmTags: FormGroup;
  frmContent: FormGroup;
  contentTypes: ContentType[] = [];
  fileTypes: FileType[] = [];
  resourceType: ResourceType[] = [];
  blocks: any[] = [];
  formativeFields: any[] = [];
  showUrlVideoInput: boolean;

  fileContent: File;
  fileContentBase: string;
  filenName: string;
  working: boolean;

  isUpdateFile: boolean;

  purposes: any[] = [];
  areas: any[] = [];
  trainingFields: any[] = [];

  showSelects: boolean = false;
  showContentSelect: boolean = false;

  tags: any[] = [];
  tagFrmControl = new FormControl();
  workingLocal: boolean;
  selectedTags: any[] = [];

  videoId: string;
  videoUrl: string;
  videoMsg: boolean;
  updateFile: boolean;
  urlInt: string;

  // UPLOAD CONTROL
  uploader;
  url = `${Appsettings.API_ENDPOINT_FULL}/contents/create`;
  maxFileSize = 1 * 1024 * 1024;
  fileName: string;

  isSALI: boolean = true;
  odaId: number;
  showInteractive: boolean;
  constructor( public dialogRef: MatDialogRef<DialogContentLargeComponent>,
               @Inject(MAT_DIALOG_DATA) public data: Content,
               public _srvContent: ContentService,
               private _srvCatalogS: CatalogService,
               private _srvStorege: StorageService,
                private _svrContent: ContentTeacherService,
               private _srvVimeo: VimeoService,
               private _srvTag: TagService) {

    this.odaId = this.data.id;

    this.videoMsg = false;
    this.initData();
    this.showUrlVideoInput = false;
    this.working           = false;
    this.isUpdateFile      = false;
    this.showInteractive   = false;
    this.uploader  = new FileUploader({
      url: this.url,
      // maxFileSize: this.maxFileSize
    });
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);

    // AGREGAR OBSERVADOR PARA AUTOCOMPLETAR
    this.tagFrmControl.valueChanges.debounceTime(400).subscribe( item => {
     this.tags = [];
     if ( item.length > 2 ) {
       this.workingLocal = true;
       this._srvTag.getAllPagination(100, 1, item).subscribe( res => {
         if ( res.data ) {
           this.tags = res.data;
         }
         this.workingLocal = false;
       }, err => {
         this.workingLocal = false;
       });
     }
   });

  }

  ngOnInit() {
    this.frmTags = new FormGroup({});

    this.frmContent = new FormGroup( {
      'id': new FormControl(this.data.id),
      'urlInteractive': new FormControl( this.data.urlContent),
      'name': new FormControl( this.data.name, [Validators.required] ),
      'urlContent': new FormControl( this.data.urlContent),
      'isPublic': new FormControl( this.data.isPublic ),
      'isRecommended': new FormControl( this.data.isRecommended ),
      'active': new FormControl( this.data.active ),
      'contentTypeId': new FormControl(  this.data.contentType.id , [ Validators.required ] ) ,
      'fileTypeId': new FormControl( this.data.fileType.id , [ Validators.required ] ),
      'file': new FormControl(''),
      'formativeFields': new FormControl(this.data.formativeFieldId),
      'areas': new FormControl(this.data.areaId),
      'purposes': new FormControl(this.data.purposeId),
      'trainingFields': new FormControl(this.data.trainingFieldId),
      'resourceTypeId': new FormControl(this.data.resourceTypeId),
      'blockId': new FormControl(this.data.blockId),
      'description': new FormControl(this.data.description, [ Validators.required ]),

     });

     if ( this._srvStorege.systemId === 2 ) {
      this.isSALI = false;
      this.frmContent.controls['blockId'].setValidators([ Validators.required ]);
      this.frmContent.controls['formativeFields'].setValidators([ Validators.required ]);
    }

     this.updateFile = true;

      if ( this.data.id > 0 ) {
        this.updateFile = false;
        this.loadContentData( this.data );
        this.videoId = this.data.urlContent;
        this.videoUrl = this.data.thumbnails;
        this.selectedTags = this.data.tags;

        if ( this.data.contentType.id !== 3 ) {
          this.showSelects = true;
        } else {
          this.showContentSelect = true;
        }
      }
  }

  // Metodo llama la validación del tipo de archivo
  onSelectionFileType( event ): void {
    this.validateFileType( event.value );
  }

  // Metodo cierra el modal
  onCloseConfirm( response ) {
    this.dialogRef.close (response );
  }

  // Cargar los datos iniciales del contenido en caso de editar
  loadContentData ( content ) {
    this.filenName = 'archivo';
    this.frmContent.controls['file'].setValue( content.urlContent );
    this.validateFileType( content.fileType.id );
  }

  // Validar el tipo de archivo y determinar si muestra el campo
  // de URL o el input file para cargar archivo
  validateFileType ( fileTypeId: number ) {
    const URL_CONTROL = this.frmContent.controls['urlContent'];
    const FILE = this.frmContent.controls['file'];
    const URL_INTERACTIVE = this.frmContent.controls['urlInteractive'];

    this.validateTypeFile( fileTypeId );
    if ( fileTypeId === 1 ||  fileTypeId === 5 ) {
      this.showUrlVideoInput = true;
      // this.updateFile = false;
      this.videoMsg = false;
      URL_CONTROL.setValidators( [ Validators.required ] );


    } else {
      this.videoUrl = null ;
      this.showUrlVideoInput = false;
      // this.updateFile = true;
      URL_CONTROL.setValue('');
      URL_CONTROL.setValidators( [] );
      URL_CONTROL.clearValidators();
    }
    if ( fileTypeId === 8 || fileTypeId === 17 ) {
      this.updateFile = false;
      this.showInteractive = true;
      URL_INTERACTIVE.setValidators( [ Validators.required, ValidateUrl ] );
    } else {
      this.showInteractive = false;
      URL_INTERACTIVE.setValue('');
      URL_INTERACTIVE.setValidators( [] );
      URL_INTERACTIVE.clearValidators();
    }
    setTimeout(() => {
      URL_CONTROL.updateValueAndValidity();
      URL_INTERACTIVE.updateValueAndValidity();
      FILE.updateValueAndValidity();
    });

    URL_CONTROL.markAsUntouched();
    URL_INTERACTIVE.markAsUntouched();

  }

  // Valiacion para mostrar / ocultar control de carga de archivos
  validateTypeFile( fileTypeId: number) {
    if (fileTypeId === 1 ||  fileTypeId === 5 || fileTypeId === 8 ) {
      this.updateFile = false;
    } else {
      this.updateFile = true;
    }
  }
  // Metodo que guarda el nuevo contenido
  onSubmit() {

    this.working = true;
    let fileUpload = new FileContent();

    const  content = new Content();
    content.id = this.frmContent.controls['id'].value;
    content.name = this.frmContent.controls['name'].value;
    content.urlContent = this.frmContent.controls['urlContent'].value;
    content.isPublic = this.frmContent.controls['isPublic'].value;
    content.contentType = new ContentType(this.frmContent.controls['contentTypeId'].value, '');
    content.fileType = new FileType(this.frmContent.controls['fileTypeId'].value, '');
    content.active = this.frmContent.controls['active'].value;

    content.formativeFieldId = this.frmContent.controls['formativeFields'].value;
    content.areaId = this.frmContent.controls['areas'].value;
    content.purposeId = this.frmContent.controls['purposes'].value;
    content.trainingFieldId = this.frmContent.controls['trainingFields'].value;
    content.resourceTypeId = this.frmContent.controls['resourceTypeId'].value;
    content.description = this.frmContent.controls['description'].value;
    content.isRecommended = this.frmContent.controls['isRecommended'].value;
    content.blockId = this.frmContent.controls['blockId'].value;
    content.urlInteractive = this.frmContent.controls['urlInteractive'].value;
    content.tags = [];
    for ( let t of this.selectedTags ) {
       let tag = new Tag();
       tag.id = t.id;
       tag.name = t.name;

       content.tags.push(tag);
     }

    if ( this.videoUrl != null ) {
      content.thumbnails = this.videoUrl;
    }

    if ( content.id > 0) {
      this.update( content, fileUpload );
    } else {
      this.save( content, fileUpload );
    }

  }

  // Guardar un contenido sin archivo
  save ( content: Content , fileUpload ): void {

    this._srvContent.save( content, fileUpload ).subscribe( res => {

      this.working = false;

      if ( res.success ) {
        swal('Datos guardados', 'La ODA se agregó exitosamente', 'success' );

        const contentType = this.contentTypes.filter( x => x.id ===  content.contentType.id )[0];
        const fileType    = this.fileTypes.filter( x => x.id ===  content.fileType.id )[0];

        content.activoDescription = content.active ? 'Activo' : 'Inactivo';
        content.id = res.data.id;
        content.urlContent = res.data.urlContent;
        content.contentType = new ContentType(  contentType.id, contentType.description ) ;
        content.fileType = new FileType(  fileType.id,  fileType.description );

        this.dialogRef.close( content );

      } else {
        swal('Tuvimos problemas', res.message, 'error' );
      }

    }, err => {
      swal('Tuvimos problemas', 'Error al intentar conectarse con el servidor', 'error' );
      this.working = false;
    });

  }

  // Actualizar los datos de un contenido sin archivo
  update ( content, fileUpload ): void {
    this._srvContent.update( content, fileUpload ).subscribe( res => {

      this.working = false;

      if ( res.success ) {
        swal('Datos guardados', 'La ODA se agregó exitosamente', 'success' );

        const contentType = this.contentTypes.filter( x => x.id ===  content.contentType.id )[0];
        const fileType    = this.fileTypes.filter( x => x.id ===  content.fileType.id )[0];

        content.activoDescription = content.active ? 'Activo' : 'Inactivo';
        content.urlContent = res.data.urlContent;
        content.contentType = new ContentType(  contentType.id, contentType.description ) ;
        content.fileType = new FileType(  fileType.id,  fileType.description );

        this.dialogRef.close(content);
      } else {
        swal('Tuvimos problemas', res.message, 'error' );
      }

    }, err => {
      swal('Tuvimos problemas', 'Error al intentar conectarse con el servidor', 'error' );
      this.working = false;
    });
  }

  // Mostramos u Ocultamos los combos segun el tipo de contenido
  onChangeContentType(idContentType: ContentType ) {
    if (  idContentType['value'] === 1 ||  idContentType['value'] === 2) {
      this.showSelects = true;
      this.showContentSelect = false;
      // this.frmContent.controls['resourceTypeId'].setValue(0);
    }
    if ( idContentType['value'] === 3) {
      this.showSelects = false;
      this.showContentSelect = true;
    }
  }

  // Inicializar todos los catálogos utilizados
  initData(): void {
    this._srvCatalogS.getPurposesAll().subscribe(res => {
      if (res.data) {
        this.purposes = res.data;
        this.frmContent.controls['purposes'].setValue(this.data.purposeId);
      }
    });
    this._srvCatalogS.getFileTypesAll().subscribe(res => {
      this.fileTypes = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.fileTypes.push( new FileType( f.id, f.description ));
        }
        this.frmContent.controls['fileTypeId'].setValue( this.data.fileType.id );
      }
    });
    this._srvCatalogS.getBlockAll().subscribe(res => {
      this.blocks = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.blocks.push(f);
        }
        this.frmContent.controls['fileTypeId'].setValue( this.data.fileType.id );
      }
    });
    this._srvCatalogS.getFormativeFieldskAll().subscribe(res => {
      this.formativeFields = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.formativeFields.push(f);
        }
        this.frmContent.controls['fileTypeId'].setValue( this.data.fileType.id );
      }
    });
    this._srvCatalogS.getContentTypesAll().subscribe(res => {
      this.contentTypes = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.contentTypes.push( new ContentType( f.id, f.description ));
        }
        this.frmContent.controls['contentTypeId'].setValue( this.data.contentType.id );
      }
    });
    this._srvCatalogS.getContentResourcesTypeAll().subscribe(res => {
      this.resourceType = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.resourceType.push( new ResourceType( f.id, f.description ));
        }
        this.frmContent.controls['resourceTypeId'].setValue( this.data.resourceTypeId );
      }
    });
  }

  // Obtiene la url de la imagen miniatura del video
  onChangeUrlVideo( event ) {
    this.working = true;
    this._srvVimeo.getVideo( this.videoId ).subscribe( res => {
      this.videoMsg = false;
      this.uploader = true;
      this.videoUrl = res.pictures.sizes[2].link_with_play_button;
      this.working = false;
    }, err => {
      this.videoMsg = true;
      this.working = false;
    });
  }

  onChangeUrlInteractive( event ) {
    // console.log( event );
  }
  /*********************************************** */
  /* TAGS                                          */
  /*********************************************** */

  // Seleccionar un tag del listado
  selectItem( tag ) {
    this.tagFrmControl.setValue('');

    if ( this.selectedTags.filter( x => x.id === tag.id).length === 0 ) {
      this.selectedTags.push( tag );
    }
  }

  // Eliminar un tag existente
  onDeleteTag( tag ) {

    swal({
      title: '¿Estás seguro?',
        text: '¡Esta operación no se podrá revertir!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Sí, eliminar ahora!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          let id = this.frmContent.controls['id'].value;
          this.working = true;
          let tagsUpdate = this.selectedTags.filter( x => x.id !== tag.id );
          this._srvContent.updateTags( id , tagsUpdate ).subscribe( res => {
            if ( res.success ) {
              this.selectedTags =   this.selectedTags.filter( x => x.id !== tag.id );
              swal(Appsettings.APP_NAME, res.message, 'success');
            } else {
              swal(Appsettings.APP_NAME, res.message, 'error');
            }
            this.working = false;
          }, err => {
            this.working = false;
          });
        }
      });
  }

  // manejador del evento enter en el formulario
  handleEnterKeyPress(event) {
    const tagName = event.target.tagName.toLowerCase();
    if (tagName !== 'input') {
      return false;
    }

    if ( tagName === 'input') {
      let tag  = new Tag();
      tag.name = this.tagFrmControl.value;
      tag.id   = 0;

      if (this.selectedTags.filter( x => x.name === tag.name).length === 0 ) {
        this.selectedTags.push(tag);
        this.tagFrmControl.setValue('');
      }
      return false;
    }
  }

  /********************************************** */
  /* UPLOAD FILE                                  */
  /*********************************************** */

  onClickUploadFile( option: boolean ) {
    this.updateFile = option;
  }

  onSaveResoruce() {
    this._svrContent.validateToken().subscribe( resutl => {
      if ( resutl.status === 200) {
        this.uploader.setOptions({
          authTokenHeader: 'Authorization',
          authToken: 'Bearer ' + this._srvStorege.token
        });

        let tagsString = '';
        for (let t of this.selectedTags ) {
          tagsString += `${t.id}-${t.name},`;
        }

        // Metodo para el envio de archivos
        this.uploader.onBeforeUploadItem = (item) => {
            item.withCredentials = false;

            if ( item ) {
              this.working = true;
            }

            this.uploader.options.additionalParameter = {
            id: this.frmContent.controls['id'].value,
            name : this.frmContent.controls['name'].value,
            active : this.frmContent.controls['active'].value,
            isPublic : this.frmContent.controls['isPublic'].value,
            contentTypeId : this.frmContent.controls['contentTypeId'].value,
            fileTypeId : this.frmContent.controls['fileTypeId'].value,
            purposeId : this.frmContent.controls['purposes'].value,
            description : this.frmContent.controls['description'].value,
            thumbnails: this.videoUrl,
            isRecommended: this.frmContent.controls['isRecommended'].value,
            blockId: this.frmContent.controls['blockId'].value,
            formativeFieldId : this.frmContent.controls['formativeFields'].value,
            tags: tagsString
          };
        };
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          if ( response) {
            let response_json = JSON.parse(response);
            if ( response_json['success'] ) {
              this.onCloseConfirm( true );
              swal( Appsettings.APP_NAME, response_json['message'], 'success');
            } else {
              swal( Appsettings.APP_NAME, response_json['message'], 'warning');
            }
          } else {
            swal( Appsettings.APP_NAME, 'La carga de archivo fue cancelada', 'warning');
          }

          this.working = false;
          // this.resetClassForm();
        };
        this.uploader.uploadAll();
      }
    });
  }

  onChangeFile( e) {
    this.fileName = e;
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    (<HTMLInputElement>document.getElementById('file')).value = '';

    this.fileName = '';
    this.uploader.queue.splice(0, 1);
    // this.frmFile.controls['file'].setValue('');
     /*
    switch (filter.name) {
        case 'fileSize':
            this.errorMessage = `El tamaño del archivo excede el máximo permitido de 10mb.`;
            swal( Appsettings.APP_NAME, this.errorMessage , 'warning');
            break;
        case 'mimeType':
            this.errorMessage = `El tipo de archivo seleccionado no es permitido, solo se permiten achivos .rar ó .zip`;
            swal( Appsettings.APP_NAME, this.errorMessage , 'warning');
            break;
        default:
            this.errorMessage = `Unknown error (filter is ${filter.name})`;
    }
    */

  }

}
