import { Component, OnInit } from '@angular/core';

import { StorageService, UserService } from '../../../../service/service.index';
import { MenuService } from '../../../../service/service.index';
import { SitemapService } from '../../../../core/sitemap/sitemap.service';
// COMPONENT SERVICES
import { AdminHeaderService } from './admin-header.service';
import swal from 'sweetalert2';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Appsettings } from '../../../../configuration/appsettings';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  nameUser: string;
  profileImage: string;
  email: string;
  working: boolean;
  showMessages: boolean;

  constructor(
    public _srvStorage: StorageService,
    private _srvUser: UserService,
    public _srvHeader: AdminHeaderService,
    private afMessaging: AngularFireMessaging
  ) {
    const profileImg = _srvStorage.profileImage !== null
                       ? _srvStorage.profileImage
                       : 'assets/img/profileimg/profile-placeholder.png';

    this.profileImage = profileImg;
    this.email = _srvStorage.email;

    if (this._srvStorage.loggedIn) {
      this.afMessaging.requestToken.subscribe(
        token => {
          if ( this._srvStorage.tokenMessage !== token ) {
            this._srvUser.updateDevice(token).subscribe( res => {
              this._srvStorage.tokenMessage = token;
            });
          }
        },
        error => {
          console.error(error);
        }
      );

     this.afMessaging.messages.subscribe(message => {      

        let messageData = message['data'];
        let notification;
        let optionsNotification = {
          body: `${message['notification'].title} ${messageData['gcm.notification.text']}`,
          icon: 'https://saliweb-deploy.azurewebsites.net/assets/img/brand/logo-sali-sm.png',
        };

        if (!('Notification' in window)) {
          // console.log( 'This browser does not support desktop notification' );
        } else if ( Notification.prototype.permission === 'granted' ) {
          notification = new Notification( Appsettings.APP_NAME, optionsNotification);
          notification.onclick = function() {
            // const layerId =  message.conversationId.substr(message.conversationId.length - 36);
            // window.location.href = `messages;source=notification;conversation=${layerId}`;
            this.close();
           };
        } else if ( Notification.prototype.permission !== 'denied') {
          Notification.requestPermission(function (permission) {
            if (permission === 'granted') {
               notification = new Notification( Appsettings.APP_NAME , optionsNotification);
               notification.onclick = function() {
                // const layerId =  message.conversationId.substr(message.conversationId.length - 36);
                // window.location.href = `messages;source=notification;conversation=${layerId}`;
                this.close();
               };
            }
          });
        }
      });
    }

    this.showMessages = !(this._srvStorage.rol.toLowerCase().indexOf('administrador') > -1);

    this._srvUser.getNotifications(1, 10).subscribe( res => {
      // console.log(res);
    });
  }

  ngOnInit() {
    this.nameUser = this._srvStorage.name;
    this._srvHeader.setNotifications();

  }

  // Actualizar la imagen  del usuario
  updateImageProfile() {
    this.profileImage = this._srvStorage.profileImage;
  }

  // Permite cerrar sesión del sistema y eliminar los daltos locales
  signOut() {
    swal({
      title: 'Cerrar sesión',
      text: '¿Realmente deseas salir de la aplicación?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cerrar ahora',
      cancelButtonText: 'Cancelar'
    }).then(result => {
      if (result.value) {
        this.working = true;
        this._srvUser.logOut().subscribe(
          res => {
            this._srvStorage.logoutRedirect();
            if ( this._srvStorage.tokenMessage) {
              this.afMessaging.deleteToken(this._srvStorage.tokenMessage);
            }
          },
          error => {
            this._srvStorage.logoutRedirect();
          }
        );
      }
    });
  }

  // Marcar como leido las notificaciones
  onReadMessages( id: number ) {
    this._srvUser.readNotification( id ).subscribe( res => {
      if ( res.success ) {
        this._srvHeader.notifications = this._srvHeader.notifications.filter( x => x.id !== id );
        this._srvStorage.notifications = JSON.stringify( this._srvHeader.notifications.filter( x => x.id !== id) );
      }
    });
  }

}
