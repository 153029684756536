import { Injectable, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../../service/service.index';

@Injectable()
export class BreadcrumbService {

  location: any[] = [];
  showBackBotton: boolean = false;

  constructor( private _srvStorage: StorageService ) {
    if (this._srvStorage.breadRemenber) {
      this.location = JSON.parse(this._srvStorage.breadRemenber);
      } else {
      this.location = [];
      }
  }

  setBreadcrumb ( _location: any [] ) {
    this.location = _location;
  }

  clear() {
    this.location = [];
    this.saveBreadcrumb();
  }

  initBreadcrumb ( location: any ) {
    this.clear();
    this.location.push( location );
    this.saveBreadcrumb();
  }

  removeItem ( index: number ) {
    this.location = this.location.slice( 0, index );
  }

  addItem( location: any ) {
    this.location.push( location );
    this.saveBreadcrumb();
  }

  saveBreadcrumb( ) {
    this._srvStorage.breadRemenber = JSON.stringify(this.location);
  }

}
