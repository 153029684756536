import { Component, OnInit, ViewChild } from '@angular/core';

import { Appsettings } from '../../../configuration/appsettings';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

// MODELS
import { Pagination } from '../../../models/general/pagination.model';
import { TeacherExam } from '../../../models/courses/teacherExam.model';

// SERVICES
import { TeacherExamService, PdfService } from '../../../service/service.index';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';


@Component({
  selector: 'app-list-exam',
  templateUrl: './list-exam.component.html',
  styleUrls: ['./list-exam.component.scss']
})
export class ListExamComponent implements OnInit {
  fotos: string[] = [];
  working: boolean = false;
  teacherExams: TeacherExam[] = [];
  typeFilter: string = '0';

  textSearch: string;
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor( private _srvTeacherExam: TeacherExamService,
               private _router: Router,
               private _srvPdf: PdfService,
               private _srvHeader: AdminHeaderService ) {
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                this.setPage( { page: 1, size: this.sizePage });
              }

  ngOnInit() {
    this._srvHeader.setTitle('Administrar exámenes');
    this.preloadImages(this.fotos);
  }

  preloadImages(ar) {
    // Imagen management

    this.toDataURL('~/../../../../../assets/img/exam/Header.png', function(dataUrl) {
      ar.push(dataUrl);
    });
  }

  toDataURL(url, callback) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function() {
      let reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  // Realizar búsqueda por nombre
  onKeyUp( event: any ): void {
    if ( this.textSearch.length >= 4 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Obtener los exámenes registrados del maestro
  setPage( paginationData: object ) {

    this.working = true;
    this._srvTeacherExam.getByUserPagination(Number(paginationData['size']),
                                            Number(paginationData['page']) ,
                                            this.textSearch, Number(this.typeFilter)).subscribe( res => {

      this.teacherExams = [];
      let index = 1;

      if ( res.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let t of res.data ) {

          const exam = new TeacherExam();

           exam.id = t.id;
           exam.active = t.active;
           exam.description = t.description;
           exam.totalQuestions = t.totalQuestions;
           exam.teacherExamType = t.teacherExamTypes;
           exam.index = this.pagination.getIndexRegister(index);

           this.teacherExams.push( exam );
           index++;
        }
      }

      this.working = false;

    }, err => {
      this.working = false;
      swal(Appsettings.APP_NAME, 'Problemas al conectarnos con el servidor', 'warning');
    } );


  }

  // Agregar un nuevo exámen
  onClickAdd( ): void {
    this._router.navigateByUrl('/admin/generate-exam');
   }

   onClickEdit( element ) {
    this._router.navigateByUrl(`/admin/generate-exam/${ element.id}`);
   }

   onClickDelete( element ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if (result.value) {
        this.working = true;
        this._srvTeacherExam.delete( element.id ).subscribe( res => {

          if ( res.success ) {
            this.teacherExams =  this.teacherExams.filter( x =>  x.id !== element.id);
            this.setPage( { page: 1, size: this.sizePage });
            swal(Appsettings.APP_NAME, res.message, 'success');
          } else {
            swal(Appsettings.APP_NAME, res.message, 'error');
          }

          this.working = false;
        }, err => {
          this.working = false;
        });
      }

    });

   }

   // Metodo genera el pdf del examen
   onClickPDF (element, typeExam: boolean) {
    this.working = true;
    let pic1 = this.fotos[0];
    this._srvTeacherExam.getById(element.id).subscribe( res => {


      if ( res.data ) {
        let examQuestions = res.data.teacherExamQuestion;
        let questions: any[] = [];
        for ( let q of examQuestions) {
          // tslint:disable-next-line:max-line-length
          questions.push( {
            'answers': q.question.answers,
            'content': q.question.content,
            'id': q.question.id,
            'questionTypeId': q.question.questionTypeId,
            'explanation': q.question.explanation,
            'urlImage': q.question.urlImage,
            'imageBase64': q.question.imageBase64,
            'imageHeight': q.question.imageHeight,
            'imageWidth': q.question.imageWidth} );
        }

        let exam = {
          'name': res.data.description,
          'data': {
            'questions': questions,
            'name': res.data.description,
            'description': res.data.description,
          }
        };
        this._srvPdf.questionBank( exam, pic1, typeExam);
      } else {
        swal( Appsettings.APP_NAME , 'Error al obtener los datos, vuelve a intentar más tarde', 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;

    });
  }

  onChangeTypeFilter() {
    this.setPage( { page: 1, size: this.sizePage });
  }


}
