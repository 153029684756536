import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../../core/core.module';

// MATERIALIZE
import { MatTabsModule,
  MatInputModule,
  MatDatepickerModule,
  MatRadioModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatMenuModule,
  MatPaginatorModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatToolbarModule } from '@angular/material';

// COMPONENTS
import { BookNavComponent } from './book-nav/book-nav.component';
import { CourseNavComponent } from './course-nav/course-nav.component';

// SERVICES COMPONENTS
import { BookNavService } from './book-nav/book-nav.service';
import { CourseNavService } from './course-nav/course-nav.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatToolbarModule
  ],
  declarations: [
    BookNavComponent,
    CourseNavComponent
  ],
  exports: [
    BookNavComponent,
    CourseNavComponent
  ],
  providers: [
    BookNavService,
    CourseNavService
  ]
})
export class PublicationModule { }
