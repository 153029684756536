import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Appsettings } from '../../../../configuration/appsettings';
import { Router } from '@angular/router';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

// MODELS
import { QuestionBank } from '../../../../models/courses/questionBank.model';
import { Pagination } from '../../../../models/general/pagination.model';

// SERVICES
import { QuestionBankService, PdfService, StorageService } from '../../../../service/service.index';

// Utilities
import { GeneralUtilies } from '../../../../shared/general.utilities';

import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';

import swal from 'sweetalert2';

declare function setHeight(): any;

@Component({
  selector: 'app-question-bank',
  templateUrl: './question-bank.component.html',
  styleUrls: ['./question-bank.component.scss']
})
export class QuestionBankComponent implements OnInit, AfterViewInit, OnDestroy {


  fotos: string[] = [];
  items: string[];
  working: boolean;
  content: QuestionBank[] = [];

  textSearch: string = '';
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();
  urlImage: string;

  constructor( private _srvQuestionBank: QuestionBankService,
               private _router: Router ,
               private _srvPdf: PdfService,
               private _srvStorage: StorageService,
               private _srvHeader: AdminHeaderService) {

        this._srvHeader.setBreadcrumb([ Appsettings.APP_NAME , 'Mi salón', 'Bancos de preguntas']);


        if ( this._srvStorage.systemId === 1 ) {
          this.urlImage = '~/../../../../../assets/img/exam/Header.png';
        } else {
          this.urlImage = '~/../../../../../assets/img/exam/header_ra.png';
        }



  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  ngOnInit() {
    this.setPage( { page: 1, size: this.sizePage } );
    this._srvHeader.setTitle('Administración de bancos de preguntas');
    this.preloadImages(this.fotos);
  }

  ngAfterViewInit(): void {
    setHeight();
  }

  preloadImages(ar) {
    // Imagen management
    GeneralUtilies.toDataURL(this.urlImage, function(dataUrl) {
      ar.push(dataUrl);
    });

  }

  // Evento para enviar al usuario a la panrtalla para editar el banco de preguntas
  onClickEdit( element: QuestionBank ) {
    this._router.navigateByUrl( `/admin/question/${element.id}`);
  }

  onClickNewContent() {
    this._router.navigateByUrl( `/admin/question/0`);
  }

  // Función para obtener todos los bancos de preguntas registrados
  setPage( paginationData: object ) {

    this.working = true;

    this._srvQuestionBank .getAllPagination( Number(paginationData['size']), Number(paginationData['page']) , this.textSearch )
                          .subscribe( res => {

      this.content = [];

      if ( res.data ) {

        let index = 1;

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( const r of res.data ) {

          const bank = new QuestionBank();

          bank.name = r.name;
          bank.description = r.description;
          bank.id = r.id;
          bank.nodeId = r.nodeId;
          bank.active = r.active;
          bank.index = this.pagination.getIndexRegister(index);

          this.content.push( bank );
          index++;
        }

      } else {
        swal(Appsettings.APP_NAME, 'Error al obtener los datos, vulve a intentar más tarde', 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });

  }

  // Evento para actualizar el estatus del banco de preguntas
  onClickUpdateStatus( element ) {


    const title = element.active ? 'Desactivar contenido' : 'Activar contenido' ;
    const titleButton = element.active ? '¡Si, desactivar ahora!' : '¡Si, activar ahora!' ;
    const subTitle = element.active ? 'Al momento de desactivar el contenido no se mostrará a los usuarios'
                                    : 'Al momento de activar el contenido será mostrado a los usuarios';
    swal({
      title: title,
      text: subTitle,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: titleButton,
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if (result.value) {

        this.working = true;
        this._srvQuestionBank.updateStatus( element.id, !element.active ).subscribe( res => {

          if ( res.success ) {

            this.working = false;
            element.active = !element.active;

            swal(Appsettings.APP_NAME, 'El registro fue actualizado correctamente.', 'success' );
            this.setPage( { page: 1, size: this.sizePage } );

          } else {
            this.working = false;
            swal(Appsettings.APP_NAME, res.message,  'error' );
          }
        }, err => {
          this.working = false;
          swal(Appsettings.APP_NAME, 'Problemas al actualizar el estatus del contenido',  'error' );
        });
      }
    });

  }


  // Evento para el filtrado de registros pro nombre
  onKeyUp( event ) {
    if ( this.textSearch.length >= 4 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Generar pdf de preguntas
  generatePDF( element, typeExam: boolean ) {
    this.working = true;
    let pic1 = this.fotos[0];
    this._srvQuestionBank.getBydId(element.id).subscribe( res => {
      if ( res.data ) {
         this._srvPdf.questionBank( res, pic1, typeExam );
        this.working = false;
      } else {
        swal('Tuvimos un problema', 'Error al obtener los datos, vuelve a intentar más tarde', 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }
  // Carga masiva de examenes
  uploadBank() {
    this._router.navigateByUrl( `/admin/upload-question-bank/0/1`);
  }

}
