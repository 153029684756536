export class ContentBook {
  id: number;
  name: string;
  fileType: string;
  urlContent: string;
  thumbnails: string;
  canAccess: boolean;
  isPublic: boolean;
  contentTypeId: number;
  canDisplay: boolean;
  description: string;
  extension: string;
}
