import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';


@Injectable()
export class RoleService {

  constructor( private http: Http,
               private httpService: HttpService ) {
   }

  // Método que realiza petición al API para obtener los roles existentes
  getRoles () {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/roles/`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    return this.httpService.get(apiUrl).map(response => response.json());
  }

  getAllPagination(sizePage: number, currentPage: number, filter: string) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/roles/all`;
    const params = new URLSearchParams();

    params.append('pageSize', sizePage.toString());
    params.append('currentPage', currentPage.toString());
    params.append('filter', filter);

    return this.httpService
        .get(API_URL, { params })
        .map(res => res.json());
   }

   delete( id: number ) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/roles?id=${id}`;
    return this.httpService.delete(apiUrl ).map( res => res.json());
  }

  putRole ( record ) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/roles/`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = record;

    return this.httpService.put(apiUrl, params).map(response => response.json());
  }

  postRole ( record ) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/roles/`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = record;

    return this.httpService.post(apiUrl, params).map(response => response.json());
  }

  getPermissions ( id ) {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/roles/`+id+`/obtenerpermisos`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = {
    };

    return this.httpService.get(apiUrl).map(response => response.json());
  }

  postPermissions (id, objeto) {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/roles/` + id + `/addroles`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = objeto;

    return this.httpService.post(apiUrl, params).map(response => response.json());
  }


}
