import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Servicios
import { HomeworksService, ContentTeacherService } from '../../../service/service.index';

// Modelos
import { TaskSchedule } from '../../../models/courses/taskSchedule.model';
import { TeacherContentType } from '../../../models/courses/teacherContentType.model';
import { TeacherContent } from '../../../models/courses/teacherContent.model';

import { from } from 'rxjs';
import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-tasks-schedule',
  templateUrl: './tasks-schedule.component.html',
  styleUrls: ['./tasks-schedule.component.scss']
})
export class TasksScheduleComponent implements OnInit {

  working: boolean;
  showForm: boolean;

  frmTaskSchudele: FormGroup;

  taskSchedules: TaskSchedule[] = [];
  recourseType: TeacherContentType[] = [];
  contentsTeacher: TeacherContent[] = [];
  totalContents: any[] = [];
  active: number;
  visible: boolean;
  studenGroupId: number;
  filesId: number[] = [];
  filesIds: number[] = [];
  constructor(  private _srvHomework: HomeworksService,
                private _srvContent: ContentTeacherService,
                private _router: Router) {

    this.working = false;
    this.showForm = false;

    this.frmTaskSchudele = new FormGroup({
      id: new FormControl(0),
      name: new FormControl('', [Validators.required, Validators.maxLength(350)]),
      scheduledDate: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }

  // Obtiene todas la programacions de tarea del grupo
  getTask( groupId: number) {
    this.studenGroupId = groupId;
    this.taskSchedules = [];

    this._srvHomework.getAll( groupId ).subscribe( result => {
      this.getTypeContent();
      this.getData();
      if ( result.success ) {
        for (let h of result.data ) {
          let homework = new TaskSchedule();
          homework.id = h.id;
          homework.name = h.name;
          homework.Description = h.description;
          homework.ScheduledDate = h.scheduledDate;
          homework.FilesId = h.files;
          this.taskSchedules.push( homework );
        }
      }
    });
  }

  // Se ejecuta cuando se da guardar en el formulario de programacion de taras
  onSubmit(): void {
    this.working = true;

    let taskSchedule = new TaskSchedule();
    taskSchedule.id = this.frmTaskSchudele.controls['id'].value;
    taskSchedule.ScheduledDate = this.frmTaskSchudele.controls['scheduledDate'].value;
    taskSchedule.Description = this.frmTaskSchudele.controls['description'].value;
    taskSchedule.name = this.frmTaskSchudele.controls['name'].value;
    taskSchedule.StudentGroupId = this.studenGroupId;
    taskSchedule.FilesId = this.filesId;
    if ( taskSchedule.id  > 0 ) {
      this.updateTaskSchedule( taskSchedule );
    } else {
      this.saveTaskSchedule( taskSchedule );
    }

  }

  // Guarda una nueva programacion de examen
  saveTaskSchedule( taskSchedule ) {
    this._srvHomework.save( taskSchedule ).subscribe( result => {
      if ( result.success ) {
        swal( Appsettings.APP_NAME, result.message, 'success');
        this.showForm = false;
        this.frmTaskSchudele.reset();
        this.getTask( this.studenGroupId );
        this.working = false;
      } else {
        swal( Appsettings.APP_NAME , result.message, 'error');
        this.working = false;
      }
    });
  }

  // Actualiza una progrmacion de examen
  updateTaskSchedule( taskSchedule ) {
    this._srvHomework.update( taskSchedule, taskSchedule.id ).subscribe( result => {
      if ( result.success ) {
        swal( Appsettings.APP_NAME, result.message, 'success');
        this.showForm = false;
        this.frmTaskSchudele.reset();
        this.getTask( this.studenGroupId );
        this.working = false;
      } else {
        swal( Appsettings.APP_NAME, result.message, 'error');
        this.working = false;
      }
    });
  }
  onClickCancel() {
    this.showForm = false;
    this.frmTaskSchudele.reset();
  }

  onClickEdit( task ) {
    this.filesIds = [];
    this.showForm = true;
    this.filesId = [];

    this.frmTaskSchudele.controls['id'].setValue( task.id );
    this.frmTaskSchudele.controls['name'].setValue( task.name );
    this.frmTaskSchudele.controls['description'].setValue( task.Description );
    this.frmTaskSchudele.controls['scheduledDate'].setValue( task.ScheduledDate );

    for ( let f of task.FilesId) {

      this.filesIds.push( f.id );
      this.filesId.push( f.id );
    }
    this.getData();
  }

  addTaskSchedule(): void {
    this.showForm = true;
  }

  onClickDelete( taskScheduleId: number ) {
    swal({
      title: '¿Deseas eliminar la tarea?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si!',
      cancelButtonText: 'Cancelar'
    }).then((res) => {
      if ( res.value) {
        this.working = true;
        this._srvHomework.deletContent( taskScheduleId ).subscribe( result => {
          if ( result.success ) {
            swal( Appsettings.APP_NAME , result.message, 'success');
            this.getTask( this.studenGroupId );
            this.working = false;
          } else {
            swal( Appsettings.APP_NAME, result.message, 'error');
            this.working = false;
          }
        });
      }
    });
  }

  // Obtiene el listado de los tipos de contenido
  getTypeContent() {
    this._srvContent.getTypeContent().subscribe( result => {
      this.recourseType = [];
      if ( result.success ) {
        let data = result.data;
        for ( let t of data ) {
          let contentType = new TeacherContentType();
          contentType.id = t.id;
          contentType.description = t.description;
          this.recourseType.push( contentType );
        }
        this.active = this.recourseType[0].id;
      }
    });
  }

  changeFolder( folderId, contents ) {
    this.contentsTeacher = [];
    this.active = folderId;
    this.visible = false;

    let contentsTeacher = this.totalContents.filter( item => item.resourceTypeId === folderId );
    if ( contentsTeacher.length > 0) {
      for ( let content of contentsTeacher) {
        let cont = new TeacherContent();
        cont.id = content.id;
        cont.name = content.name;
        cont.description = content.description;
        cont.registerDate = content.registerDate;
        cont.contentUrl = content.url;

        cont.selected = false;
        for ( let f of this.filesId ) {
          if ( f === cont.id) {
            cont.selected = true;
          }
        }
        this.contentsTeacher.push( cont );
      }
      this.visible = true;
    }
  }

  // Obtiene todo el contenido del profesor
  getData() {
    this._srvContent.getAll().subscribe( result => {
      this.contentsTeacher = [];
      if ( result.success ) {
        // this.active = 1;
        this.totalContents = result.data;
        let contents = this.totalContents.filter( item => item.resourceTypeId === this.active);
        if ( contents.length > 0 ) {
          for ( let content of contents) {
            let cont = new TeacherContent();
            cont.id = content.id;
            cont.name = content.name;
            cont.description = content.description;
            cont.registerDate = content.registerDate;
            cont.contentUrl = content.url;

            cont.selected = false;
            for ( let f of this.filesIds ) {
              if ( f === cont.id) {
                cont.selected = true;
              }
            }
            this.contentsTeacher.push( cont );
          }
          this.visible = true;
        }
      }
    });
  }

  onChangeAssing( id: number, event): void {

    let index = this.filesId.indexOf(id);
    if ( index !== -1 )  {
      this.filesId.splice(index, 1);
    } else {
      this.filesId.push( id );
    }
  }

  // Abre modulo para calificar tareas
  addRating( homework) {
    // this.studenGroupId
    this._router.navigateByUrl(`/teacher/homework-qualify/${this.studenGroupId}/${homework.id}`);

  }
}
