import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Services
import { NewsHomeService } from '../../../service/service.index';

// Models
import { NewHome } from '../../../models/home/new.model';

declare function setMinHeightByElement(selector): any;

@Component({
  selector: 'app-containt-detail',
  templateUrl: './containt-detail.component.html',
  styleUrls: ['./containt-detail.component.scss']
})
export class ContaintDetailComponent implements OnInit {

  id: number;
  newSelected: NewHome;
  working: boolean;

  constructor( private _route: ActivatedRoute,
               private _srvNew: NewsHomeService ) {

    this._route.params.subscribe( params => {
      this.id = Number(params['id']);

      this.working = true;
      if ( this.id > 0) {
        this._srvNew.getById( this.id).subscribe( res => {
          if ( res.success ) {
            this.newSelected = new NewHome();
            this.newSelected .id = res.data.id;
            this.newSelected .description = res.data.description;
            this.newSelected .published = res.data.published;
            this.newSelected .registerDate = new Date(res.data.registerDate);
            this.newSelected .title = res.data.title;
            this.newSelected .urlImage = res.data.urlImage;
          }
        });
      }
      this.working = false;
    }, err => {
      this.working = false;
    });

   }

  ngOnInit() {
    setMinHeightByElement('.container-home');
  }

}
