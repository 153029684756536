import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';


@Injectable()
export class UserService {

  constructor( private http: Http,
               private httpService: HttpService ) {

   }

  // Método que autentica un usuario en la aplicación
  login(userName: string, password: string, systemId: number) {
    let apiUrl = `${Appsettings.API_ENDPOINT}/token`;
    let headers = new Headers();
    let body = `username=${userName}&password=${password}&grant_type=password&client_id=ngAuthApp&origen_id=${systemId}`;
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http
      .post(apiUrl, body, { headers: headers })
      .map(res => res.json());
  }

  // Método que realiza petición al API para reiniciar la contraseña de un usuario
  resetPassword (email: string) {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/resetpassword`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = {
      'email': email,
      'systemId': Appsettings.SYSTEM_ID
    };

    return this.httpService
      .post(apiUrl, JSON.stringify( params ), { headers: headers })
      .map(res => res.json());
  }

  getProfile() {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/profile?origin=1`;
    return this.httpService.get(apiUrl)
                           .map(res => res.json());
  }

  update ( user ) {

    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.httpService
    .put(apiUrl, JSON.stringify( user ), { headers: headers })
    .map(res => res.json());
  }

  // Método que realiza el registro de un nuevo usuario con rol de alumno o maestro
  register( user ) {

    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.httpService
    .post(apiUrl, JSON.stringify( user ), { headers: headers })
    .map(res => res.json());
  }

  // Método que obtiene todos los usuarios registrados este activo o no
  getAll() {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users`;
    return this.httpService.get(apiUrl).map( res => res.json());
  }

   // Método que obtiene todos los usuarios registrados este activo o no
  getAllPagination(sizePage: number, currentPage: number, filter: string) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/users/all`;
    const params = new URLSearchParams();

    params.append('pageSize', sizePage.toString());
    params.append('currentPage', currentPage.toString());
    params.append('filter', filter);

    return this.httpService
        .get(API_URL, { params })
        .map(res => res.json());
   }

  // Método que obtiene todos los usuarios registrados este activo o no
  getUsers () {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = {
    };

    return this.httpService.get(apiUrl).map(response => response.json());
  }

  delete( id ) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users`;
    let params = new URLSearchParams();

    params.append('id', id);
    return this.httpService.delete(apiUrl, { params }).map( res => res.json());
  }

  // Método que realiza la asociación de un perfil con varios usuarios
  linkProfile( profileid, userids ) {

    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/linkprofile`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    let request = {
      ProfileId: profileid,
      UserIds: userids
    };

    return this.httpService
    .post(apiUrl, JSON.stringify( request ), { headers: headers })
    .map(res => res.json());
  }

  changePassword (Email: string, NewPassword: string, OldPassword: string) {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/changepassword`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    let params = {
      'Email': Email,
      'NewPassword': NewPassword,
      'OldPassword': OldPassword,
    };

    return this.httpService
      .put(apiUrl, JSON.stringify( params ), { headers: headers })
      .map(res => res.json());
  }

  getProfileData() {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/profiledata`;
    return this.httpService.get(apiUrl)
                           .map(res => res.json());
  }

  uploadImg(user, imgProfile) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/uploadImgProfile`;
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');


    const params = {

      'fileContentBase64': imgProfile.content,
      'fileName': imgProfile.name
    };

    return this.httpService
    .put(apiUrl, JSON.stringify( user ), { headers: headers })
    .map(res => res.json());
  }

  logOut() {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/logout`;
    return this.httpService.get(apiUrl)
                           .map(res => res.json());
  }

  updateDevice(token: string) {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/registerDevice`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    let device = {
      'deviceId': '',
      'token': token,
      'clientId': 1
    };

    return this.httpService.post( apiUrl, JSON.stringify(device),
                                  { headers: headers } )
                           .map(res => res.json());

  }

  getNotifications(page: number , size: number ) {

    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/all-notifications`;
    let parameters = new URLSearchParams();

    parameters.append('page', page.toString());
    parameters.append('sizePage', size.toString());

    return this.httpService.get( apiUrl, { params: parameters } )
                           .map( res => res.json());

  }

  readNotification ( id: number ) {

    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/read`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    let params = { id: id };

    return this.httpService.put(apiUrl, JSON.stringify( params ), { headers: headers })
                           .map(res => res.json());
  }

  // Obtener los roles de un usuario
  getRolesByUserName( email: string ) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/roles?email=${email}`;
    return this.httpService.get(apiUrl)
                           .map(res => res.json());
  }

  sendContactMail( contentEmail ) {

    let apiUrl = `${Appsettings.API_ENDPOINT}/api/mails/send`;
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    return this.http.post(apiUrl, contentEmail, { headers: headers })
                    .map(res => res.json());

  }

    // Validar usuario en side
    validateUserSIDE ( email: string ) {
      const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/users/side?email=${ email}`;
      return this.httpService.get(apiUrl)
        .map(res => res.json());
    }
}
