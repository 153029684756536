import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';
import { AdministratorModule  } from '../../components/layouts/administrator/administrator.module';

// MODULES
import { AdmonComponentModule } from '../../components/administrator/admon-components.module';
import { VIDEOCONFERENCE_ROUTES } from './videoConference.routes';
import { VideoConferenceCatComponent } from './video-conference/video-conference.component';
import { VideoConferenceComponent} from './videoConference.component';
// COMPONENTS

// MATERIALIZE
import { MatTabsModule,
  MatInputModule,
  MatDatepickerModule,
  MatRadioModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatBadgeModule,
  MatListModule, 
  MatAutocompleteModule,
  MatChipsModule,
  MatDividerModule,
  MatExpansionModule,
  MatSidenavModule,
  MatTooltipModule} from '@angular/material';

import { TreeviewModule } from 'ngx-treeview';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';


  @NgModule({
  imports: [
    VIDEOCONFERENCE_ROUTES,
    CoreModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatListModule,
    AdministratorModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatBadgeModule,
    MatExpansionModule,
    AdmonComponentModule,
    MatSidenavModule,
    MatTooltipModule,
    TreeviewModule.forRoot(),
    CdkTreeModule,
    MatTreeModule,
  ],
  declarations: [
    VideoConferenceCatComponent,
    VideoConferenceComponent
  ],
  entryComponents: [
  ]
})
export class VideoConferenceModule { }
