import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent, TreeItem } from 'ngx-treeview';
import { remove } from 'lodash';
import { FileHolder } from 'angular2-image-upload';

// DIALOG
import { DialogStructureComponent } from '../dialog-structure/dialog-structure.component';

// SERVICES
import { StructureService, NodeService, CatalogService, BookService } from '../../service/service.index';

// MODELS
import { FileContent } from '../../models/catalogs/file.model';

import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';
import { debug } from 'util';


@Component({
  selector: 'app-dialog-admin-structure',
  templateUrl: './dialog-admin-structure.component.html',
  styleUrls: ['./dialog-admin-structure.component.scss']
})
export class DialogAdminStructureComponent implements OnInit {

  working: boolean = false;
  workingDialog: boolean = false;
  showFormBook: boolean = false;

  books: any[] = [];
  bookName: string;

  nodos: any[] = [];
  items: TreeviewItem[] = [];
  structure: any[] = [];
  foundItem: TreeviewItem;

  selectedItem: TreeviewItem;
  selectedItemPath: string = '';
  children: any[] = [];

  showForm: boolean;
  fileContentBase: string;
  fileContent: File;
  filenName: string;
  isUpdateFile: boolean;
  editNode: any = undefined;

  frmNode: FormGroup;

  nodeTypes: any[] = [];

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500
  });

  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  constructor(
    public dialogRef: MatDialogRef<DialogStructureComponent>,
    public _svrStructure: StructureService,
    private _srvNode: NodeService,
    private _srvCatalog: CatalogService,
    private _srvBook: BookService,
    @Inject(DOCUMENT) private _document,
    @Inject(MAT_DIALOG_DATA) public data: any ) {

      this.getAllBook();
      this.showForm = false;

      this.frmNode = new FormGroup({
        'id': new FormControl(0),
        'name': new FormControl('', [ Validators.required ]),
        'description': new FormControl('', [ Validators.required ]),
        'nodeTypeId': new FormControl('', [ Validators.required ]),
        'active': new FormControl( false ),
        'bookId': new FormControl('', [Validators.required]),
        'order' : new FormControl('', [Validators.min(1)])
      });
    }

  ngOnInit() {
    this.getNodes();

    // Obtener todos los tipos de nodos
    this._srvCatalog.getNodenTypesAll().subscribe( res => {
      if ( res.success ) {
        for ( let type of res.data ) {
          this.nodeTypes.push( { id: type.id,description: type.description } );
        }
      }
    });

  }

  onClickAddNode( ) {
    if ( this.selectedItem !== undefined ) {
      this.showForm = true;
    }
  }

  // Evento que permite cancelar la operación de actualizar o agregar nuevo nodo
  onClickCancelNew() {
    this.showForm = false;
    this.frmNode.reset();
    this.editNode = undefined;
  }

  // Obtener la ruta del elemento selecionado
  getFullPathNode(itemsSelected: any): void {
      let child = this.structure.filter(r => r.id === itemsSelected.value.value)[0];
      if ( child != undefined ) {
        let parentId = child.parentId;
        let router: any[] = [];
        let path = '';
        router.push(child);
        while (parentId > 0) {
          child = this.structure.filter(r => r.id === parentId)[0];
          router.push(child);
          parentId = child.parentId;
        }

        router = router.reverse();
        this.selectedItemPath = router.map(r => r.description).join('/');
    }

  }

  // Evento que permite cerrar la ventana modal
  onCloseConfirm() {
    this.dialogRef.close(true);
  }

  // Registrar un nuevo nodo
  onSubmit() {

    let fileUpload = new FileContent();
    let nodeTypeId = this.frmNode.controls['nodeTypeId'].value;
    let name = (nodeTypeId === 5) ? this.bookName : this.frmNode.controls['name'].value;
    let description = (nodeTypeId === 5) ? this.bookName : this.frmNode.controls['description'].value;
    let order = this.frmNode.controls['order'].value;

    if ( this.fileContent ) {

      const arrayFileBase = this.fileContentBase.split(',');

      fileUpload = new FileContent(
        arrayFileBase[1],
        this.fileContent.type,
        this.fileContent.name,
        arrayFileBase[0]
      );
    }

    const newNode  = {
      id:  this.frmNode.controls['id'].value,
      name: name,
      parentId: this.selectedItem.value.value,
      active: this.frmNode.controls['active'].value,
      // active: true,
      nodeTypeId: nodeTypeId,
      description: description,
      bookId: this.frmNode.controls['bookId'].value,
      order: order
    };

    if ( newNode.id > 0 ) {
      this.updateData( newNode, fileUpload );
    } else {
      this.saveData( newNode, fileUpload );
    }
  }

  // Obtiene los datos del select de libro
  changeBook( event) {
    let book = this.books.filter( item => item.id === event)[0];
    this.bookName = book.name;
  }

  // Evento para asignar los datos del nodo seleccionado al formulario
  onClickEdit( child ): void {
    this.editNode = child;
    console.log(child);
    if ( this.editNode !== undefined ) {
        this.showForm = true;
        this.bookName = this.editNode.name;
        const bookId = ( this.editNode.bookId ) ? this.editNode.bookId : 0;
        this.frmNode.controls['id'].setValue( this.editNode.id );
        this.frmNode.controls['name'].setValue( this.editNode.name );
        this.frmNode.controls['description'].setValue( this.editNode.description );
        // Se agrega el NodeTypeId para poder identificar el tipo del nodo que se obtiene ya que antes se enviaba el numero 5 por default. 01/08/2019 MASANTOSA
        this.fnTypeNodoSelected(this.editNode.nodeTypeId);
        this.frmNode.controls['nodeTypeId'].setValue( this.editNode.nodeTypeId );
        this.frmNode.controls['active'].setValue( this.editNode.active );
        this.frmNode.controls['bookId'].setValue( bookId );
        this.frmNode.controls['order'].setValue( this.editNode.order);
    }

  }

  // Actualizar los datos de la estructura al servicio
  updateData( nodeData: any, fileUpload: FileContent ): void {
    this.working =  true;
    this._srvNode.update( nodeData, fileUpload ).subscribe( res => {

      if ( res.success ) {

        let structureNode = this.structure.filter( x => x.id === nodeData.id)[0];
        this.updateNode( nodeData.id, nodeData.name ,  this.treeviewComponent.items );

        if ( structureNode ) {
          structureNode.active = nodeData.active;
          structureNode.name = nodeData.name;
          structureNode.description = nodeData.description;
          structureNode.urlImage = res.data.urlImage;
          structureNode.order = nodeData.order;
        }

        this.resetForm();
        swal( Appsettings.APP_NAME , res.message, 'success');

      } else {
        swal( Appsettings.APP_NAME , res.message, 'error');
      }
      this.working =  false;
      this.items = [];
      this.getNodes();
    }, err => {
      this.working =  false;
    });
  }

  // Guardar datos de un nuevo nodo
  saveData( nodeData: any,  fileUpload: FileContent ): void {

    this.working =  true;
    this._srvNode.save( nodeData, fileUpload ).subscribe( res => {

      if ( res.success ) {

        const node = {
          text: res.data.name,
          value:
          {
            value: res.data.id,
            text: res.data.name,
            collapsed: true,
            checked: false
          }
        };

        let datas = JSON.stringify(node);
        let itemTree = new TreeviewItem(JSON.parse(datas));

        this.addNode(itemTree, this.treeviewComponent.items);
        this.addElementParent(res.data);
        this.getChildren( nodeData.parentId);

        this.resetForm();
        swal( Appsettings.APP_NAME, res.message, 'success');

      } else {
        swal( Appsettings.APP_NAME, res.message, 'error');
      }
      this.working =  false;
    }, err => {
      this.working =  false;
    });
  }

  // Inicializar los datos del formulario
  resetForm() {
    this.showForm = false;
    this.frmNode.reset();
    this.frmNode.updateValueAndValidity();

    this.fileContentBase = '';
    this.fileContent = null;
    this.filenName = '';

  }

  // Evento para eliminar un nodo
  onClickDelete( node ) {

    this.findNode(node.id, this.treeviewComponent.items);
    let childrenNode = this.structure.filter( x => x.parentId === node.id );

    if ( childrenNode.length > 0 ) {
      swal('Operación cancelada', 'No se puede eliminar un nodo que contenga hijos', 'warning');
      return;
    } else {

      swal({
        title: '¿Estás seguro?',
        text: '¡Esta operación no se podrá revertir!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Sí, eliminar ahora!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        this.working = true;

        if (result.value) {

          this._srvNode.delete( node.id ).subscribe( res => {

            if ( res.success ) {

              this.removeItem( node, this.foundItem);
              remove( this.structure, node );
              remove( this.children, node );

              swal( Appsettings.APP_NAME, res.message, 'success');
            } else {
              swal( Appsettings.APP_NAME, res.message, 'error');
            }

            this.working =  false;

          }, err => {
            this.working = true;
          });
        } else {
          this.working =  false;
        }
      });
    }
  }

  // Evento para eliminar una imagen asingnada al nodo
  onClickDeleteImage() {

      swal({
        title: '¿Estás seguro?',
        text: '¡Esta operación eliminará la imagen actual!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Sí, eliminar ahora!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {

        if (result.value) {
          this._srvNode.removeImage( this.editNode.id ).subscribe( res => {
            if ( res.success ) {
              this.editNode.urlImage = null;
            }
            swal(Appsettings.APP_NAME, res.message, 'success');
            this.working =  false;
          }, err => {
            this.working = true;
          });
        }
      });
  }

  // Obtiene los libros
  getAllBook() {
    this._srvBook.getAllBooks().subscribe( result => {
      if ( result.success ) {
        for ( let b of result.data ) {
          this.books.push(
            {
              id: b.id,
              name: b.name
            }
          );
        }
      }
    });
  }
  /******************************************************************** */
  /* EVENTOS MANEJADORES DEL FILEUPLOAD                                 */
  /******************************************************************** */

  // Evento que se ejecuta cuando se remueve un archivo
  onRemoved(event: FileHolder) {
    this.fileContentBase = '';
    this.fileContent = null;
    this.filenName = '';
  }

  // Evento que se ejecuta cuando termina de actualizar los datos
  onUploadFinished(event: FileHolder) {
    this.fileContentBase = event.src;
    this.fileContent     = event.file;
    this.filenName       = this.fileContent.name;
    this.isUpdateFile    = true;
  }

  // Evento cuando se ejecuta estado de cambio
  onUploadStateChanged( event ) {
  }

  /******************************************************************** */
  /* EVENTOS ARBOL                                                      */
  /******************************************************************** */

  // Obtiene los datos del servicio
  getNodes(): void {
    this.workingDialog = true;

    this._srvNode.getStructureByPatent( this.data.id ).subscribe(
      res => {

        if (res.data) {
          for (const nodo of res.data) {

            let checkedNode = false;
            this.structure.push( nodo );

            this.nodos.push({
              text: nodo.name,
              value: nodo.id,
              collapsed: false,
              checked: checkedNode,
              parentId: nodo.parentId,
              order: nodo.order
            });
          }
          this.buildHierarchy(this.nodos);
        }
        this.workingDialog = false;
      },
      err => {
        this.workingDialog = false;
      }
    );
  }

  // Obtener los elementos hijos del nodo seleccionado
  getChildren( parentId: number ) {
    this.children = this.structure.filter( x => x.parentId === parentId );
  }

  // Crea el json con el formato de arbol
  buildHierarchy(arry: any): void {
      const roots = [],
        children = {};

      // find the top level nodes and hash the children based on parent
      for (let i = 0, len = arry.length; i < len; ++i) {
        const item = arry[i],
          p = item.parentId,
          target = !p ? roots : children[p] || (children[p] = []);
          target.push({
            value: item,
            text: item.text,
            collapsed: true,
            checked: item.checked
          });

      }
      // function to recursively build the tree
      let findChildren = function(parentId) {
        if (children[parentId.value.value]) {
          parentId.children = children[parentId.value.value];
          for (let i = 0, len = parentId.children.length; i < len; ++i) {
            findChildren(parentId.children[i]);
          }
        }
      };

      // enumerate through to handle the case where there are multiple roots
      for (let i = 0, len = roots.length; i < len; ++i) {
        findChildren(roots[i]);
      }
      // Elimana los [] extras en json
      let datas = JSON.stringify(roots);
      datas = datas.slice(1, -1);

      // Agregamos la nueva structura a items para crear el arbol
      this.items.push(new TreeviewItem(JSON.parse(datas)));

  }

  // Agregar un nuevo nodo en el componente de árbol
  addNode( node: any, treeNodes ): boolean {

    let isAddNode = false;

    for (const tmpItem of treeNodes) {

      if ( tmpItem !== undefined && tmpItem.value.value === this.selectedItem.value.value ) {

        if ( tmpItem.children === undefined ) {
          tmpItem['children'] = [node];
        } else {
          tmpItem.children.push(node);
        }
        tmpItem.collapsed = false ;
        isAddNode = true;
        break;

      } else {
        if ( tmpItem.children !== undefined ) {
          this.addNode(node, tmpItem.children);
        }
      }
    }
    return isAddNode;
  }

  // Agregar un nuevo nodo en el componente de árbol
  updateNode( nodeId: number , text: string , treeNodes ): boolean {
    let isAddNode = false;
    for (const tmpItem of treeNodes) {
      if ( tmpItem.value.value === nodeId ) {
        tmpItem.text = text;
        tmpItem.value.text = text;
        break;
      } else {
        if ( tmpItem.children !== undefined ) {
          this.updateNode( nodeId, text , tmpItem.children );
        }
      }
    }
    return isAddNode;
  }

  findNode( nodeId: number, treeNodes ): TreeviewItem {

    let node: TreeviewItem;

    for (const tmpItem of treeNodes) {
      if ( tmpItem.value.value === nodeId ) {
        node = tmpItem;
        this.foundItem =  tmpItem;
        break;
      } else {
        if ( tmpItem.children !== undefined ) {
          node = this.findNode( nodeId, tmpItem.children );
        }
      }
    }
    return node;
  }

  removeItem( item, treeItem): boolean {

    let isRemoved = false;
    for (const tmpItem of this.items) {
        if (tmpItem === treeItem) {
            remove( this.items, treeItem );
        } else {
            isRemoved = TreeviewHelper.removeItem(tmpItem, treeItem);
            if (isRemoved) {
                break;
            }
        }
    }
    if (isRemoved) {
        this.treeviewComponent.raiseSelectedChange();
    }

    return isRemoved;
}

  // Agregar elemento padre a la lista de estructuras
  addElementParent( data ) {
      this.structure.push({
            active: data.active,
            description: data.description,
            id: data.id,
            name: data.name,
            nodeTypeDesc: data.id,
            nodeTypeId: data.nodeTypeId,
            parentId: data.parentId,
            urlImage: data.urlImage,
            order: data.order
      });
  }

  // Evento que se ejecuta cuando se selecciona una
  onChange(item, event ): void {

    this.selectedItem = item;

    if ( event !== undefined ) {
      this.marckSelectedNode( this.selectedItem );
      this.getFullPathNode( item );
      this.getChildren( item.value.value );
    }

  }

  // Evento que se ejecuta al maximizar o minimizar las hojas de un nodo
  onCheckSelectedItem( item ): void {
    this.selectedItem = item;
    this.marckSelectedNode( item );
    this.getFullPathNode( item );
    this.getChildren( item.value.value );
  }

  // Agregar la clase de activo al nodo seleccionado y quitarle la clase
  // a los elementos que la tengan
  marckSelectedNode( node ): void {

    let elements: any = this._document.getElementsByClassName('form-check-label');

    for (let ref of elements ) {
      ref.classList.remove('active');
    }

    let selectedNode: any = this._document.getElementById(node.value.value);
    selectedNode.classList.add( 'active' );

    this.showForm = false;
    this.frmNode.reset();

  }

  fnTypeNodoSelected(event){
    console.log(event);
    if ( event === 5) {
      this.showFormBook = true;
      this.frmNode.controls['name'].setValue('nombre');
      this.frmNode.controls['description'].setValue('description');
      this.frmNode.controls['bookId'].setValue('');
    } else {
      this.showFormBook = false;
      this.frmNode.controls['bookId'].setValue(0);
    }
  }
  onValueChange( event) {
   this.fnTypeNodoSelected(event);
  }
}
