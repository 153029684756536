import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

import { Author } from '../../models/catalogs/author.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {

  constructor(
    private _httpService: HttpService) {

     }

     // Método que realiza la petición GET al API para obtener todos los autores
     // Especificando el numero de elementos a mostrar por página
     getAll() {
      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/authors`;

      return this._httpService
          .get(API_URL)
          .map(res => res.json());
     }

     // Obtener todos los registros paginados
     getAllPagination(sizePage: number, currentPage: number, filter: string) {

      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/authors/all`;
      const params = new URLSearchParams();

      params.append('pageSize', sizePage.toString());
      params.append('currentPage', currentPage.toString());
      params.append('filter', filter);

      return this._httpService
          .get(API_URL, { params })
          .map(res => res.json());
     }

     // Método de guarda un nuevo registro de un autor
     save( author ) {
      let API_URL = `${Appsettings.API_ENDPOINT_FULL}/authors/`;
      const HEADERS = new Headers();
      HEADERS.append('Content-Type', 'application/json');

      return this._httpService.post(API_URL, JSON.stringify( author ), { headers: HEADERS } )
                             .map( res => res.json());
    }

    // Método de actualiza un registro de un autor
    update( author ) {
      let API_URL = `${Appsettings.API_ENDPOINT_FULL}/authors/`;
      const HEADERS = new Headers();
      HEADERS.append('Content-Type', 'application/json');
      
      return this._httpService.put(API_URL, JSON.stringify( author ), { headers: HEADERS } )
                             .map( res => res.json());

    }

    // Eliminar los datos de un autor
    delete( id ) {
      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/authors?id=${id}`;
      return this._httpService.delete(API_URL )
                              .map( res => res.json());
    }

}
