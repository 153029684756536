import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatPaginator } from '@angular/material';
import { DialogAdminStructureComponent } from '../../../../dialogs/dialog-admin-structure/dialog-admin-structure.component';

import { NodeService } from '../../../../service/service.index';

// SERVICES COMPONENT
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit {

  working: boolean = false;
  nodes: any[] = [];
  displayedColumns: string[] = [ 'name', 'description'];
  dataSource = new MatTableDataSource<any[]>(this.nodes);
  textSearch: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor( public dialog: MatDialog,
               private _srvNode: NodeService,
               private _srvHeader: AdminHeaderService ) {
    this.working = true;
    this.getData();
   }

  onClickEdit( node ) {

    const DIALOG_REF = this.dialog.open( DialogAdminStructureComponent, {
        width: '900px',
        height: '650px',
        autoFocus: false,
        disableClose: true,
        data: node
      });
  }

  ngOnInit() {
    this.working = false;
    this._srvHeader.setTitle('Administración de estructuras');
  }

  onKeyUp(event) {

    if ( this.textSearch.length >= 2 ) {
      const result = this.nodes.filter(u => u.name.toLocaleLowerCase().indexOf(this.textSearch.toLocaleLowerCase()) > -1);
      this.setDataTable( result );
    } else {
      this.setDataTable( this.nodes );
    }
  }

  setDataTable ( result: any[] ): void {

    this.dataSource.data     = [];
    const resultSearch: any[] = [];

    if (result.length > 0) {

      for (const u of result) {
        resultSearch.push(u);
      }

      this.dataSource = new MatTableDataSource<any[]>(resultSearch);
      this.dataSource.paginator = this.paginator;
    }
  }

  getData() {
    this.working = true;
     this._srvNode.getSubNode(0).subscribe( res => {

      if ( res.success ) {
        this.dataSource.data = [];
        this.nodes = [];

        for (let p  of res.data.children) {
            this.nodes.push(p);
        }

        this.paginator.pageIndex = 0;
        this.dataSource = new MatTableDataSource<any>(this.nodes);
        this.dataSource.paginator = this.paginator;
      }

      this.working = false;
     }, err => {

      this.working = false;

     });
  }
}
