import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';


// SERVICES
import { UserService } from '../../service/service.index';

// MODELS
import { User } from '../../models/security/user.model';
import { ProfileUser } from '../../models/security/profileuser.model';

import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';

@Component({
  selector: 'app-dialog-userprofile',
  templateUrl: './dialog-userprofile.component.html',
  styleUrls: ['./dialog-userprofile.component.scss']
})
export class DialogUserProfileComponent implements OnInit {
  public frmUserProfile: FormGroup;
  working: boolean;
  record: ProfileUser = new ProfileUser(0, '', false, [], []);
  nodeSelected: number[];
  profileUser: ProfileUser;
  miArray: number[];
  users: User[];
  linkedUsers: number[];

  constructor( public _dialogRef: MatDialogRef<DialogUserProfileComponent>,
               private _uService: UserService,
               @Inject(MAT_DIALOG_DATA) public data: ProfileUser ) {
                this.record = data;
                this.users = data.users;
                this.linkedUsers = data.linkedUsers;

                this.frmUserProfile = new FormGroup({
                  'id' : new FormControl(data.id),
                  'description' : new FormControl(data.description),
                  'active' : new FormControl(data.active)
                });
              }

  ngOnInit() {
  }

  onCloseConfirm() {
    this._dialogRef.close(true);
  }

  onChange(items: any) {

  }

  onSubmit() {
    this.working = true;


    this._uService.linkProfile(this.record.id, this.selectedUsers()).subscribe((result) =>{
      if ( result.success ) {
        this._dialogRef.close(true);
        swal( Appsettings.APP_NAME , result.message, 'success');
      } else {
        swal( Appsettings.APP_NAME, result.message, 'error');
      }

      this.working = false;
    });

  }

  selectedUsers() {
    return this.users.filter(u => u.selected === true).map(u => u.id);
  }



}
