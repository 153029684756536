import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ContentBook } from '../../models/content/contentBook.model';

@Component({
  selector: 'app-viewer-audio',
  templateUrl: './viewer-audio.component.html',
  styleUrls: ['./viewer-audio.component.scss']
})

export class ViewerAudioComponent implements OnInit {
  showAudio: boolean;
  selectedAudioId: number;
  stopSound: boolean;
  showContent: boolean;
  textSearch: string;
  showAudioComponent: boolean;
  _dataAudio: ContentBook[] = [];
  _dataAudioResource: ContentBook[] = [];
  _fullData: ContentBook[];
  _song: ContentBook;
  _selected: ContentBook;

  @Output() invoke: EventEmitter<boolean> = new EventEmitter();
  get dataFile(): ContentBook[] {
    return this._dataAudio;
  }

  @Input('dataFiles') set dataFiles(value: ContentBook[]) {
    if (value.length > 0) {

      this._fullData = value;

      this._dataAudio = value.filter( item => item.contentTypeId === 2);
      this._dataAudioResource = value.filter( item => item.contentTypeId === 3);

      if (this._dataAudio && this._dataAudio.length > 0 ) {
        this.changeSong(this._dataAudio[0]);
        this.selectedAudioId = this._dataAudio[0].id;
        this._song = this._dataAudio[0];
        this._selected = this._dataAudio[0];
      } else if ( this._dataAudioResource.length > 0 ) {
        this.changeSong(this._dataAudioResource[0]);
        this._song = this._dataAudioResource[0];
        this.selectedAudioId = this._dataAudioResource[0].id;
        this._selected = this._dataAudioResource[0];
      }


      // this.selectedAudioId = this._song.id;
      this.showAudio = true;
    }
  }
  // Parametro para mostrar listado de recursos del maestro
  @Input('showList') set listResource ( value: boolean ) {
    this.showContent = value;
  }
  @Input('stopSound') set soundStop( value ) {
    this.stopSound = value;
  }
  constructor() {
  }

  ngOnInit() {
  }

  // Realiza el cambio de audio cuando se selecciona uno del listado
  changeSong(song: ContentBook): void {
    this.showAudioComponent = song.canAccess;
    this._song = song;
    this._selected = song;
  }

     // Realizar búsqueda
     onKeyUp( event ) {
      if ( this.textSearch.length >= 3 || this.textSearch.length === 0 ) {
      // this.setPage( { page: 1, size: this.sizePage });
       this.textSearch = this.textSearch.toLowerCase();
       let filterData = this._fullData.filter( x => x.name.toLowerCase().indexOf(this.textSearch) > -1 );
       this._dataAudio = filterData.filter( item => item.contentTypeId === 2);
       this._dataAudioResource = filterData.filter( item => item.contentTypeId === 3);
      }
   }

}
