import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { ImageUploadModule } from 'angular2-image-upload';

import { PAGESEXTERNAL_ROUTES } from './pagesexternal.routes';

// COMPONENTS
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './recover/recover.component';
import { HomeComponent } from '../home/home.component';
import { PagesexternalComponent } from './pagesexternal.component';
import { RegistryComponent } from './registry/registry.component';
import { ContaintListComponent } from './containt-list/containt-list.component';
import { ContaintDetailComponent } from './containt-detail/containt-detail.component';
import { TipDetailComponent } from './tip-detail/tip-detail.component';
import { TipListComponent } from './tip-list/tip-list.component';

// MODULES
import { AnonymousModule } from '../../components/layouts/anonymous/anonymous.module';
import { CoreModule } from '../../core/core.module';

// SERVICES
import { HeaderService } from '../../components/layouts/anonymous/header/header.service';

// DIALOG
import { DialogLoginComponent } from '../../dialogs/dialog-login/dialog-login.component';
import { DialogPasswordRecoveryComponent } from '../../dialogs/dialog-password-recovery/dialog-password-recovery.component';

import { MatInputModule,
         MatDatepickerModule,
         MatRadioModule,
         MatCardModule,
         MatSelectModule,
         MatDialogModule,
         MatIconModule,
         MatButtonModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatCheckboxModule } from '@angular/material';
import { EventListComponent } from './event-list/event-list.component';
import { EventDetailComponent } from './event-detail/event-detail.component';


@NgModule({
  declarations: [
    LoginComponent,
    RecoverComponent,
    PagesexternalComponent,
    RegistryComponent,
    HomeComponent,
    ContaintListComponent,
    ContaintDetailComponent,
    TipDetailComponent,
    TipListComponent,
    EventListComponent,
    EventDetailComponent,
    DialogLoginComponent,
    DialogPasswordRecoveryComponent
  ], exports: [
    LoginComponent,
    RecoverComponent
  ],
  imports: [
    AnonymousModule,
    CoreModule,
    PAGESEXTERNAL_ROUTES,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(),
    ImageUploadModule.forRoot(),
   
  ], providers: [
    HeaderService
  ],
  entryComponents: [
    DialogLoginComponent,
    DialogPasswordRecoveryComponent
  ]
})

export class PagesExternalModule{}
