import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';
import { ExamSchedule } from '../../models/courses/examSchedule.model';

@Injectable({
  providedIn: 'root'
})
export class ExamScheduleService {

  constructor( private httpService: HttpService ) { }

  // Obtener todas programaciones de exámenes por grupo
  getAllByGroup( groupId: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/examchedules/group`;
    const params = new URLSearchParams();

    params.append('groupId', groupId.toString());

    return this.httpService.get(API_URL,{ params })
                           .map( res => res.json() );
  }

  // Obtener todas las programaciones del exámen por grupo para el estudiante
  getAllExamStuden( groupId: number) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/examchedules/student?groupId=${groupId}`;
    const params = new URLSearchParams();


    return this.httpService.get(API_URL, { params })
                           .map( res => res.json() );
  }

  // Registrar una nueva programación de exámenes
  save( examnSchedule: ExamSchedule ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/examchedules`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    const params = {
      description: examnSchedule.description,
      applicationDate: examnSchedule.applicationDate,
      studentGroupId: examnSchedule.studentGroupId,
      teacherExamId: examnSchedule.teacherExamId
    };

    return this.httpService.post( API_URL, JSON.stringify(params), { headers: HEADERS } )
                           .map( res => res.json());

  }

  // Actualizar los datos de una programación de exámenes existente
  update( examnSchedule: ExamSchedule ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/examchedules`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    const params = {
      id: examnSchedule.id,
      description: examnSchedule.description,
      applicationDate: examnSchedule.applicationDate,
      studentGroupId: examnSchedule.studentGroupId,
      teacherExamId: examnSchedule.teacherExamId
    };

    return this.httpService.put( API_URL, JSON.stringify(params), { headers: HEADERS } )
                           .map( res => res.json());

  }


   // Actualizar los datos de una programación de exámenes existente
   updateStatus( id: number, examScheduleTypeId: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/examchedules/updateStatus`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    const params = { id: id, examScheduleTypeId: examScheduleTypeId };

    return this.httpService.put( API_URL, JSON.stringify(params), { headers: HEADERS } )
                           .map( res => res.json());

  }

  remove( id: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/examchedules?id=${id.toString()}`;
    return this.httpService.delete(API_URL)
                           .map( res => res.json());
  }

  getAnswersStuden( examId: number, studentId: number  ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/tests/${examId}/student?studentId=${studentId}`;
    const params = new URLSearchParams();


    return this.httpService.get(API_URL, { params })
                           .map( res => res.json() );
  }

}
