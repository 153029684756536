import { NativeDateAdapter } from "@angular/material";

export class AppDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
      if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
        const str = value.split('/');
        const day = Number(str[0]);
        const month = Number(str[1]) - 1;
        const year = Number(str[2]);
        return new Date(year, month, day);
      }
      return null;
    }
  }
