import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../../configuration/appsettings';

// SERVICES
import { ExamScheduleService, TeacherExamService } from '../../../service/service.index';

// MODELS
import { ExamSchedule } from '../../../models/courses/examSchedule.model';
import { ExamScheduleType } from '../../../models/courses/examScheduleType.model';
import { TeacherExam } from '../../../models/courses/teacherExam.model';

import swal, { SweetAlertType } from 'sweetalert2';
import { Course } from '../../../models/catalogs/course.model';

@Component({
  selector: 'app-exam-schedules',
  templateUrl: './exam-schedules.component.html',
  styleUrls: ['./exam-schedules.component.scss']
})
export class ExamSchedulesComponent implements OnInit {

  working: boolean = false;
  studentGroupId: number;
  groupName: string;
  examSchedules: ExamSchedule[];
  frmExamSchedule: FormGroup;
  showForm: boolean = false;
  teacherExams: TeacherExam[] = [];
  course: Course;

  @ViewChild('formExamSchedule') formExamSchedule;
  @Input() group: any;
  @Input() showButton: boolean = false;

  // Retornamos el arreglo de id's
  @Output() closeModal: EventEmitter<any[]> = new EventEmitter<any[]>();
  constructor( private _srvExam: ExamScheduleService,
               private _router: Router,
               private _srvTeacherExam: TeacherExamService ) {

    this.groupName = 'Matemáticas avanzadas';

    this.frmExamSchedule = new FormGroup({
      id: new FormControl(0),
      description: new FormControl('', [ Validators.required, Validators.maxLength(350)] ),
      applicationDate: new FormControl('', [ Validators.required, Validators.maxLength(10)]),
      teacheExamId: new FormControl(0)
    });

    this.frmExamSchedule.controls['applicationDate'].setValidators([
      Validators.required,
      Validators.maxLength(10),
      this.rangeDateValidator.bind(this.frmExamSchedule)
    ]);
  }

  ngOnInit() {
    this.studentGroupId = this.group.id;
    this.groupName = this.group.description;
    this.getExamSchedules();

    this.course = JSON.parse(localStorage.getItem('sali.course'));
  }

  // Evento para enviar los datos al servicio y guardar o actualizar
  // los datos de la programación
  onSubmit( ): void {

    const exam = new ExamSchedule();
    let applicationDate = new Date(this.frmExamSchedule.controls['applicationDate'].value);

    exam.active = true;
    exam.id = this.frmExamSchedule.controls['id'].value;
    exam.applicationDate = applicationDate;
    exam.description = this.frmExamSchedule.controls['description'].value;
    exam.studentGroupId = this.studentGroupId;
    exam.teacherExamId = this.frmExamSchedule.controls['teacheExamId'].value === 0 ? null : this.frmExamSchedule.controls['teacheExamId'].value;


    if ( exam.id > 0) {
      this.update( exam );
    } else {
      this.save( exam );
    }

  }

  // Evento para mostrar el formulario y agregar una nueva programación o
  // actualizar los datos de una existente
  onClickAdd( ): void {
      this.showForm = true;
  }

  // Eliminar una programación de exámen existente
  onClickDelete( exam: ExamSchedule ) {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if (result.value) {

        this.working = true;
        this._srvExam.remove( exam.id ).subscribe( res => {
          let swalType: SweetAlertType = 'error';

          if ( res.success ) {
            this.examSchedules = this.examSchedules.filter( x => x.id !== exam.id);
            swalType = 'success';
          }
          this.working = false;
          swal(Appsettings.APP_NAME, res.message , swalType);
        }, err => {
          this.working = false;
        });
      }
    });
  }

  // Editar los datos de una programación de exámenes
  onClickEdit( exam: ExamSchedule ) {
    this.showForm = true;
    this.frmExamSchedule.controls['id'].setValue( exam.id );
    this.frmExamSchedule.controls['applicationDate'].setValue( exam.applicationDate );
    this.frmExamSchedule.controls['description'].setValue( exam.description );
    this.frmExamSchedule.controls['teacheExamId'].setValue( exam.teacherExamId );
  }

  // Evento que cancela la actualización de datos en el formulario
  onClickCancel( ): void {
    this.showForm = false;
    this.frmExamSchedule.reset();
  }

  // Guardar nuevo registro de programación de exámenes
  save( exam: ExamSchedule ): void {

      this.working = true;
      this._srvExam.save( exam ).subscribe( res => {

        let swalType: SweetAlertType = 'error';

        if ( res.success ) {

          if ( !this.examSchedules ) {
            this.examSchedules = [];
          }

          exam.id = res.data;
          exam.status = this.getExamSCheduleStaus( exam.teacherExamId );

          this.examSchedules.push( exam );
          this.resetForm();
          swalType = 'success';
        }

        swal(Appsettings.APP_NAME, res.message, swalType);

        this.working = false;
      }, err => {
        this.working = false;
      });

  }

  // Guardar nuevo registro de programación de exámenes
  update( exam: ExamSchedule ): void {

    this.working = true;
    this._srvExam.update( exam ).subscribe( res => {

      let swalType: SweetAlertType = 'error';

      if ( res.success ) {

        let selectedExam = this.examSchedules.filter( x => x.id === exam.id)[0];

        selectedExam.applicationDate = exam.applicationDate;
        selectedExam.description = exam.description;
        selectedExam.status = this.getExamSCheduleStaus( exam.teacherExamId );
        selectedExam.teacherExamId = exam.teacherExamId;

        this.resetForm();
        swalType = 'success';
      }
      swal(Appsettings.APP_NAME, res.message, swalType);

      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Reiniciar el formulario de captura de programación de exámenes
  resetForm( ) {
    this.frmExamSchedule.reset();
    this.formExamSchedule.resetForm();
    this.showForm = false;
  }

  // Obtener todas las programaciones de exámes del grupo
  getExamSchedules( ) {

    this.working = true;
    this.examSchedules = undefined;
    this._srvExam.getAllByGroup( this.studentGroupId ).subscribe( res => {
      if ( res.success ) {

        if ( res.data.length > 0 ) {
          this.examSchedules = [];

          for ( let exam of res.data ) {
            let prom = new ExamSchedule();

            prom.id = exam.id;
            prom.active = exam.active;
            prom.applicationDate = new Date(exam.applicationDate);
            prom.description = exam.description;
            prom.teacherExamId = exam.teacherExamId;
            prom.status = new ExamScheduleType( exam.examScheduleType.id,  exam.examScheduleType.description );
            prom.typeExam = exam.teacherExam.teacherExamTypeId;
            this.examSchedules.push( prom );
          }
        }
        this.working = false;

      }
      this.getTeacherExams();

    }, err => {
      this.working = false;
    });
  }

  // Aber el modal para capturar calificaciones
  addRating( exam: ExamSchedule) {
    let name = exam.description.replace(/\//g, '%2F');
    this._router.navigateByUrl(`/teacher/qualify/true/${this.studentGroupId}/${exam.id}/${exam.typeExam}/${name}/${exam.status.id}`);
    // this.closeModal.emit([ { status: true, groupId: this.studentGroupId, examScheduleId: exam.id, typeExam: exam.typeExam}] );
  }

  getTeacherExams( ): void {

    this.working = true;
    this._srvTeacherExam.getByUserShortInfo( 0 ).subscribe( res => {
      this.teacherExams = [];
      
      if ( res.success ) {

        for ( let t of res.data ) {
          const exam = new TeacherExam();
           exam.id = t.id;
           exam.active = t.active;
           exam.description = t.description;
           exam.questions = t.teacherExamQuestion;
           exam.totalQuestions = t.totalQuestions;
           exam.teacherExamType = t.teacherExamTypes;

           if (exam.totalQuestions > 0) {
            this.teacherExams.push( exam );
           }
        }
      }
      this.working = false;
    }, err => {
      this.working = false;
      swal(Appsettings.APP_NAME, 'Problemas al conectarnos con el servidor', 'warning');
    } );


  }

  getExamSCheduleStaus( teacherExamId: number ): ExamScheduleType {

    let status = new ExamScheduleType(1, 'Programado');

    if ( teacherExamId > 0 ) {
       status = new ExamScheduleType(2, 'Asociado');
    }
    return status;
  }

  // Validar rango de fechas
  rangeDateValidator(control: FormControl): { [s: string]: boolean }  {
    const forma: any = this;
    const course = JSON.parse(localStorage.getItem('sali.course'));
    const scheduleDate =  new Date(control.value);

    if (course) {

      const courseInit = new Date(new Date(course.startDate).toDateString());
      const courseEnd = new Date(new Date(course.endDate).toDateString());

      if ( !(courseInit.getTime() <= scheduleDate.getTime()  &&  scheduleDate.getTime() <= courseEnd.getTime())) {
        return {
          rangeDateValidator: true
        };
      }
    }

    return null;

  }
}
