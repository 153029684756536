import { Component, OnInit, Input } from '@angular/core';

// Servicios
import { StudenService, PagerService } from '../../../service/service.index';

// Modelos
import { Glossary } from '../../../models/courses/glossary.model';
import { PaginarionLocal } from '../../../models/general/pagination-local.model';

@Component({
  selector: 'app-glossary-show',
  templateUrl: './glossary-show.component.html',
  styleUrls: ['./glossary-show.component.scss']
})
export class GlossaryShowComponent implements OnInit {
  showGlossary: boolean;
  glossary: Glossary[] = [];
  pageItems: Glossary[] = [];
  glossarys: any[] = [];
  resGlossary: any[] = [];
  nodeId: number;
  showTableGlossary: boolean;
  textSearch: string;
  showMsg: boolean;

  sizePage: number = 10;
  numberPages: number = 0;
  pager: PaginarionLocal ;

  working: boolean;

  @Input('nodeId') set idNode ( value ) {
    if ( value > 0) {
      this.nodeId = value;
      this.getAllGlossary( this.nodeId );
    }
  }

  constructor(  private _srvStuden: StudenService,
                private _srvPager: PagerService ) {

    this.showGlossary = true;
    this.glossarys = [];
    this.showTableGlossary = false;
    this.showMsg = false;

   }

  ngOnInit() {
  }

  // Obtiene los glosarios
  getAllGlossary( nodeId: number ): void {
    this.working = true;
    this._srvStuden.getAllGlossary( nodeId ).subscribe( result => {
      if ( result.success ) {
        let glossarys = result.data;
        (glossarys.length > 0 ) ? this.showGlossary = true : this.showGlossary = false  ;
        for ( let g of glossarys) {

          let glossary = new Glossary();

          glossary.id = g.id;
          glossary.name = g.name;
          glossary.words = g.words;

          this.glossary.push( glossary );
        }

      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Selección de glosario
  onChangeGlossary( event): void {
    this.glossarys = [];
    this.resGlossary = [];
    let g  = this.glossary.filter( item => item.id === event)[0];

    if ( g.words.length > 0) {
      this.glossarys.push(g.words) ;
      this.resGlossary.push( g.words );

      this.setPage(1);
    }

    this.showTableGlossary  =  this.glossarys.length === 0 ? true : false;
  }

  onKeyUp(event) {
      if ( this.textSearch.length >= 2 ) {
        const result = this.glossarys[0].filter(u => u.name.toLocaleLowerCase().indexOf(this.textSearch.toLocaleLowerCase()) > -1);
        if ( result.length > 0) {
          this.glossarys = [];
          this.glossarys.push( result );
          this.showMsg = false;
          this.setPage(1);
        } else {
          this.showMsg = true;
        }
      } else if ( this.textSearch.length === 0 ) {
        this.glossarys = [];
        this.glossarys.push( this.resGlossary[0] );
        this.setPage(1);
        this.showMsg = false;
      }
  }

  // Realizar paginación local
  setPage(page: number) {
    if  ( this.glossary.length ) {
      this.pager = this._srvPager.getPager(this.glossarys[0].length, page, this.sizePage);
      this.pageItems = this.glossarys[0].slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
  }

}
