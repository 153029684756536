import { Component, OnInit, Input } from '@angular/core';
// Modelos
import { ContentBook } from '../../models/content/contentBook.model';
import { DomSanitizer } from '@angular/platform-browser';
import { connect } from 'tls';

@Component({
  selector: 'app-viewer-video',
  templateUrl: './viewer-video.component.html',
  styleUrls: ['./viewer-video.component.scss']
})

export class ViewerVideoComponent implements OnInit {

  videoEmbed: any;

  showVideo: boolean;
  _dataVideo: ContentBook[];
  _dataVideoResource: ContentBook[];
  _fullData: ContentBook[];
  _selected: ContentBook;
  selectedVideoId: number;
  count: number;
  showVideoMsg: boolean;
  showContent: boolean;
  textSearch: string;
  showVideoComponent: boolean;

  get dataVideo(): ContentBook[] {
    return this._dataVideo;
  }

  // Actualiza el valor de la variable cuando su valor cambia en el componente
  @Input('dataVideos') set dataVideos(value: ContentBook[]) {

    this._fullData = value;
    this.viewVideo(this._fullData);
    // this.count = 0;
    // console.log(  value );
    // if (value &&  value.length > 0 ) {
    //   for ( let v of value ) {
    //     if ( !v.canAccess) {
    //       this.count++;
    //     }
    //   }


    //  } else {
    //   this._selected = new ContentBook();
    //    this.showVideo = false;
    //  }
  }

  // Parametro para mostrar listado de recursos del maestro
  @Input('showList') set listResource ( value: boolean ) {
    this.showContent = value;
  }
  constructor ( private sanitizer: DomSanitizer ) { }

  ngOnInit() {
  }

  // Agrega el video al componente
  viewVideo( content: ContentBook[] ) {

    this.count = 0;
    if (content &&  content.length > 0 ) {
      for ( let v of content ) {
        if ( !v.canAccess) {
          this.count++;
        }
      }

          // Si pueded ver el cotenido del profesor entra aqui
    if ( this.showContent) {
      this._dataVideo = content.filter( item => item.contentTypeId === 2);
      this._dataVideoResource = content.filter( item => item.contentTypeId === 3);
      if ( this._dataVideo.length > 0) {
        this.showVideo = true;
        this.showVideoMsg = ( this.count === this._dataVideo.length ) ? false : true;
        this.videoShow( this.dataVideo[0] );
      } else if (this._dataVideoResource.length > 0) {
        this.showVideo = true;
        this.showVideoMsg = ( this.count === this._dataVideoResource.length ) ? false : true;
        this.videoShow( this._dataVideoResource[0] );
      } else {
        this._selected = new ContentBook();
        this.showVideo = false;
        this.showVideoMsg =  true;
      }
    } else { // Entra aca si no puede ver contenido del profesor
      this._dataVideo = content.filter( item => item.contentTypeId === 2);
      if ( this._dataVideo.length > 0) {
        this.showVideo = true;
        this.showVideoMsg = ( this.count === this._dataVideo.length ) ? false : true;
        this.videoShow( this.dataVideo[0] );
      } else {
        this._selected = new ContentBook();
        this.showVideo = false;
        this.showVideoMsg =  true;

      }

    }

     } else {
      this._selected = new ContentBook();
       this.showVideo = false;
     }

    /*************************************** */
    /******  Nuevo codigo para validaciones  */
    /*************************************** */

    // this._dataVideo = value.filter( item => item.contentTypeId === 2);
    // this._dataVideoResource = value.filter( item => item.contentTypeId === 3);

    // if ( this._dataVideo.length > 0 ) {
    //   this.viewVideo(this._dataVideo[0]);
    // } else if ( this._dataVideoResource.length > 0 ) {
    //   this.viewVideo(this._dataVideoResource[0]);
    // }
    // if(this._dataVideo.length>0){
    //   if ( this._dataVideo[0].canAccess ) {

    //     this.selectedVideoId =  this._dataVideo[0].id;
    //     this._selected = this.dataVideo[0];
    //   }
    //   this.showVideo = true;
    // this.showVideoMsg = ( this.count === this._dataVideo.length ) ? false : true;

    // }
    // else if (this._dataVideoResource.length>0){
    //   if ( this._dataVideoResource[0].canAccess ) {

    //     this.selectedVideoId =  this._dataVideoResource[0].id;
    //     this._selected = this._dataVideoResource[0];
    //   }
    //   this.showVideo = true;
    // this.showVideoMsg = ( this.count === this._dataVideoResource.length ) ? false : true;

    // }
    // else{
    //   this.showVideoMsg =  true;
    // }
    /*************************************** */
    /******  Fin codigo para validaciones  */
    /*****************************************/

    // Codigos para mostrar el video en el componente
    // this.showVideoComponent = content.canAccess;
    // this._selected  = content;
    // this.videoEmbed = `<iframe src="https://player.vimeo.com/video/${ content.urlContent }"
    //                           width="100%" height="100%" frameborder="0"
    //                           webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;

  }

  videoShow( video: ContentBook) {

    // Codigos para mostrar el video en el componente
    this.showVideoComponent = video.canAccess;
    this._selected  = video;
    this.videoEmbed = `<iframe src="https://player.vimeo.com/video/${ video.urlContent }"
                              width="100%" height="100%" frameborder="0"
                              webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
  }
   // Realizar búsqueda
   onKeyUp(event) {
    if ( this.textSearch.length >= 3 || this.textSearch.length === 0 ) {
    // this.setPage( { page: 1, size: this.sizePage });
     this.textSearch = this.textSearch.toLowerCase();
     let filterData = this._fullData.filter( x => x.name.toLowerCase().indexOf(this.textSearch) > -1 );
     this._dataVideo = filterData.filter( item => item.contentTypeId === 2);
     this._dataVideoResource = filterData.filter( item => item.contentTypeId === 3);
    }
 }

}
