import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { fromEvent } from 'rxjs/observable/fromEvent';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

// Componentes
import { DialogSearchUserComponent } from '../../../dialogs/dialog-search-user/dialog-search-user.component';

// Servicios
import { MessageService } from '../../../service/service.index';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// Modelos
import { Message } from '../../../models/content/message.model';
import { MessageUser } from '../../../models/content/messagesUser.model';
import { Contact } from '../../../models/content/contacts.model';

import swal from 'sweetalert2';
import { UserUtilities } from '../../../shared/user.utilities';

declare function setFullHeightByElement(selector): any;

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})

export class MessagesComponent implements OnInit, OnDestroy {
  initials: string;
  showImage: boolean;
  showSendMessage: boolean;

  message: Message;
  messages: Message[] = [];
  messagesUser: MessageUser[] = [];
  dataMessaegeUser = {};
  avatarImg: string;
  senderName: string;

  frmMessage: FormGroup;

  contacts: Contact[] = [];
  newUserId: number;
  working: boolean;

  @ViewChild('chatMessages') private chatMessagesContainer: ElementRef;
  @ViewChild('formMessages') formMessages;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(  public dialog: MatDialog,
                private _srvMessage: MessageService,
                private _srvHeader: AdminHeaderService ) {
    // this.working = false;
    this.working = true;
    this._srvHeader.setTitle( 'Mensajes ');

    this._srvMessage.getAllUser().subscribe( result => {

      this.contacts = [];
      if ( result.success ) {
        for ( let c of result.data ) {
          let contact = new Contact();
          contact.id = c.id;
          contact.avatar = c.avatar;
          contact.email = c.email;
          contact.fullName = c.fullName;

          this.contacts.push( contact );
        }
      }
      this.working = false;
    }, err => {}, () => {
      this.getAll();
    });

    this.senderName = 'Nuevo Mensaje';

    this.showImage = true;
    this.showSendMessage = false;
    this.frmMessage = new FormGroup({
      message: new FormControl('', [ Validators.required, Validators.maxLength(1000) ] ),
    });

  }


  ngOnInit() {

    setFullHeightByElement('.cnt-contacts-messages, .background-nofound');
    this.resizeObservable$ = fromEvent(window, 'resize');

    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      setFullHeightByElement('.cnt-contacts-messages, .background-nofound');
    });
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }

  // Muestra modal para buscar destinatario del mensaje
  onWriteMessage() {
    const DIALOG_REF = this.dialog.open( DialogSearchUserComponent, {
      width: '600px',
      height: '700px',
      autoFocus: false,
      disableClose: true,
      data: { users: this.contacts}
    });

    DIALOG_REF.afterClosed().subscribe( response => {

      if ( response.status ) {
        let user = response.item.id;

        this.newUserId = user;
        let u = this.messages.filter( item => item.receiverUserId === user);
        if ( u.length === 0 ) {
          this.onShowNewConversation( response.item );
        } else {
          this.onShowConversation( u[0] );
        }
      }
    });
  }

  // Ver una conversacion
  onShowConversation( message: Message ) {

    this.working = true;
    this.dataMessaegeUser = {};
    this.message = message;
    this.senderName = message.receiverUserName;
    this.avatarImg = message.receiverAvatar;
    let chatGroupId = message.chatGroupId;
    this.messagesUser = [];
    this.dataMessaegeUser =  {
      'receiverUserId' : message.receiverUserId,
      'chatGroupId' : chatGroupId
    };

    this._srvMessage.getAllMessages( chatGroupId ).subscribe( result => {
      if ( result.success ) {
        for ( let m of result.data ) {
          let msgUser = new MessageUser();
          msgUser.messages = m.message;
          msgUser.senderDate = m.senderDate;
          msgUser.owner = m.owner;
          this.messagesUser.push( msgUser );
        }

        this.message.isRead = true;
        setTimeout(() => {
          this.scrollToBottom();
        });
      }
      this.showSendMessage = true;
      this.working = false;
    });

  }

  // Ver conversación nueva
  onShowNewConversation( item ) {
    this.senderName = item.fullName;
    this.avatarImg = item.avatar;
    this.messagesUser = [];
    this.showSendMessage = true;

    this.dataMessaegeUser =  {
      'receiverUserId' : this.newUserId,
      'chatGroupId' : 0
    };
  }
  
  // Obtiene todas las conversaciones
  getAll() {
    this.working = true;
    this._srvMessage.getAll().subscribe( result => {

      this.messages = [];
      if ( result.success ) {
        for ( let m of result.data ) {
          let message = new Message();
          message.chatGroupId = m.chatGroupId;
          message.isRead = m.isRead;
          message.lastMessage = m.lastMessage;
          message.lastMessageDate = m.lastMessageDate;
          message.receiverAvatar = m.receiverAvatar;
          message.receiverUserId = m.receiverUserId;
          message.receiverUserName = m.receiverUserName;
          message.owner = m.owner;

          this.messages.push( message );
        }

      }
      this.working = false;
    });
  }

  // Envia un mensaje
  onSubmit() {
    this.working = true;
    let message = this.frmMessage.controls['message'].value;
    let ChatGroupId = (this.dataMessaegeUser['chatGroupId']) ? this.dataMessaegeUser['chatGroupId'] : 0;
    let ReceiverUserId = (this.dataMessaegeUser['receiverUserId']) ? this.dataMessaegeUser['receiverUserId'] : this.newUserId;

    const dateMessage = UserUtilities.dateFullSerialize(new Date());

    let data = {
      'Message': message,
      'ReceiverUserId': ReceiverUserId,
      'ChatGroupId': ChatGroupId,
      'DateMessage': dateMessage
    };

    this._srvMessage.save( data ).subscribe( result => {
      if ( result.success ) {
        if ( data.ChatGroupId === 0) {

          let msg = new Message();

          msg.chatGroupId = result.data.chatGroup.chatGroupId;
          msg.isRead = result.data.chatGroup.isRead;
          msg.lastMessage = result.data.chatGroup.lastMessage;
          msg.lastMessageDate = result.data.chatGroup.lastMessageDate;
          msg.receiverAvatar = result.data.chatGroup.receiverAvatar;
          msg.receiverUserId = result.data.chatGroup.receiverUserId;
          msg.receiverUserName = result.data.chatGroup.receiverUserName;
          this.onShowConversation( msg );

        } else {
          this.onShowConversation( this.message );
        }
        this.resetForm();
        this.getAll();
      }
      this.working = false;
    });
  }

  // Inicializar el formulario
  resetForm( ) {
    this.frmMessage.reset();

    if (this.formMessages) {
      this.formMessages.resetForm();
    }

    this.frmMessage.updateValueAndValidity();
  }

  cancelSendMessage() {

    if(this.showSendMessage){
      swal({
        title: '¿Deseas cancelar la operación?',
        text: '¡Se perderán los datos en caso de no guardarlos!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Sí!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.showSendMessage = false;
          this.resetForm();
        }
      });
    }
  }

  scrollToBottom(): void {
    try {
        this.chatMessagesContainer.nativeElement.scrollTop = this.chatMessagesContainer.nativeElement.scrollHeight;
    } catch ( err ) { }
  }
}
