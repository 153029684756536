import { Component, OnInit, OnDestroy } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { Pagination } from '../../../models/general/pagination.model';
import { Resource } from '../../../models/general/resource.model';

// SERVICES
import { CourseService } from '../../../service/service.index';

@Component({
  selector: 'app-inventory-course',
  templateUrl: './inventory-course.component.html',
  styleUrls: ['./inventory-course.component.scss']
})
export class InventoryCourseComponent implements OnInit, OnDestroy {

  working: boolean = false;
  showListContent: boolean = false;
  nodeId: number = 0;
  publications: any[] = [];

  recources: Resource[] = [];

  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor( private _srvHeader: AdminHeaderService,
               private _srvCourse: CourseService ) {
    this._srvHeader.setTitle('Recursos del profesor');
    this._srvHeader.setBreadcrumb([ Appsettings.APP_NAME , 'Mi salón', 'Recursos del profesor']);
   }

  ngOnInit() {
    this.setPage( { page: 1, size: this.sizePage });
  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  // funcion para cambiar la pagina
  setPage( paginationData: object ) {

    this.working = true;

    this._srvCourse.getResourcesPagination( Number(paginationData['size']), Number(paginationData['page']), this.nodeId ).subscribe( res => {
    this.recources = [];

      if ( res.success ) {
        this.showListContent = true;

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        this.sizePage = paginationData['size'];
        this.publications = res.data.publications;

        for ( let f of res.data.resources ) {
          this.recources.push( f );
        }
      }

      this.working = false;

    }, err => {
       this.working = false;
    });


  }

  onChangeCourse() {
    this.setPage( { page: 1, size: this.sizePage });
  }
}
