import { Component, OnInit } from '@angular/core';
import { Appsettings } from '../../configuration/appsettings';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// COMPONENT SERVicES
import { HeaderService } from '../../components/layouts/anonymous/header/header.service';

// SERVICES
import { UserService } from '../../service/service.index';

import swal from 'sweetalert2';
import { MatDialog, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-dialog-password-recovery',
  templateUrl: './dialog-password-recovery.component.html',
  styleUrls: ['./dialog-password-recovery.component.scss']
})
export class DialogPasswordRecoveryComponent implements OnInit {

  working: boolean;
  frmRecover: FormGroup;
  urlPrivacy = Appsettings.PRIVACY_ANNOUNCEMENT;

  constructor( private _srvUser: UserService,
               private _router: Router,
               public dialogRef: MatDialogRef<DialogPasswordRecoveryComponent>,
               private _srvHeader: HeaderService ) {

                this.working = false;

                this.frmRecover = new FormGroup({
                  'username': new FormControl('', [ Validators.required,
                                                    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')])
                });


               }

  ngOnInit() {
  }

  onSubmit() {

    const email = this.frmRecover.controls['username'].value;
    this.working = true;
    this._srvUser.resetPassword( email ).subscribe( res => {

       if ( res.success ) {
        swal( Appsettings.APP_NAME, res.message, 'success');
        this.onCloseConfirm();
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      swal( Appsettings.APP_NAME, 'Problemas al recobrar la contraseña',  'error');
      this.working = false;
    } );

  }

  onCloseConfirm() {
    this.dialogRef.close(null);
  }


}
