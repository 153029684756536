import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';

// Modelos
import { Notice } from '../../../models/courses/notice.model';
import { Pagination } from '../../../models/general/pagination.model';

// Servicios
import { NoticeService } from '../../../service/service.index';

// Complemetos
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';


@Component({
  selector: 'app-notice-list',
  templateUrl: './notice-list.component.html',
  styleUrls: ['./notice-list.component.scss']
})
export class NoticeListComponent implements OnInit , OnDestroy {

  working: boolean;
  announcements: Notice[] = [];

  textSearch: string;
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor(  private _srvNotice: NoticeService,
                private _router: Router,
                private _srvHeader: AdminHeaderService ) {

    this.working = false;
    this.setPage( { page: 1, size: this.sizePage });
  }

  ngOnInit() {
    this._srvHeader.setTitle('Administrar avisos');
    this._srvHeader.setBreadcrumb([ Appsettings.APP_NAME , 'Mi salón', 'Administración de avisos']);
  }

  ngOnDestroy(): void {
   this._srvHeader.setBreadcrumb([]);
  }

  // Obtiene todos los avisos del maestro
  setPage( paginationData: object ) {
    this.working = true;
    this._srvNotice.getAll(
      Number(paginationData['size']),
                             Number(paginationData['page']) ,
                             this.textSearch
                             ).subscribe( res => {

      this.announcements = [];
      let index = 1;

      if ( res.success) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let g of res.data) {

          let notice = new Notice();

          notice.id = g.id;
          notice.title = g.title;
          notice.description = g.description;
          notice.active = g.active;
          notice.courseId = 0;
          notice.index = this.pagination.getIndexRegister(index);

          this.announcements.push( notice);
          index++;
        }
      }

      this.working = false;

    }, err => {
      this.working = false;
    });
  }

  // Funcion del buscador
  onKeyUp( event) {
    if ( this.textSearch.length >= 4 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Editar un aviso
  onEditItem( element: Notice ) {
    this._router.navigateByUrl( `/sali/generate-notice/${element.id}`);
  }

  // Eliminar un aviso
  onDeleteItem( element: Notice ) {
    this.working = true;
    this._srvNotice.deleteNotice( element.id).subscribe( result => {
      if ( result. success) {
        this.setPage( { page: 1, size: this.sizePage });
        swal(Appsettings.APP_NAME, result.message , 'success');

      } else {
        swal(Appsettings.APP_NAME, result.message , 'warning');
      }

      this.working = false;
    });
  }

  // Agregar nuevo aviso
  onClickNewNotice() {
    this._router.navigateByUrl( `sali/generate-notice/0`);
  }


}
