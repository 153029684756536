import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

// Servicios
import { ContentTeacherService } from '../../service/service.index';

// Modelos
import { TeacherContent } from '../../models/courses/teacherContent.model';

@Component({
  selector: 'app-dialog-relation-content',
  templateUrl: './dialog-relation-content.component.html',
  styleUrls: ['./dialog-relation-content.component.scss']
})

export class DialogRelationContentComponent implements OnInit {

  teacherContent: TeacherContent[] = [];
  totalContents: any[] = [];
  lessonId: number;
  filesIds: number[] = [];
  constructor(  private dialogRef: MatDialogRef<DialogRelationContentComponent>,
                private _srvContent: ContentTeacherService,
                @Inject(MAT_DIALOG_DATA) public data) {

      this.lessonId = data.id;
      this.filesIds = data.filesIds;

      this._srvContent.getAll().subscribe( result => {
      this.teacherContent = [];
      if ( result.success ) {
        this.totalContents = result.data;

       //  let contents = this.totalContents.filter( item => item.resourceTypeId === 1);
        if ( this.totalContents.length > 0 ) {
          for ( let content of this.totalContents) {
            let cont = new TeacherContent();
            cont.id = content.id;
            cont.name = content.name;
            cont.description = content.description;
            cont.registerDate = content.registerDate;
            cont.contentUrl = content.url;

            cont.resourceTypeId = content.resourceTypeId;

            cont.selected = false;
            for ( let f of this.filesIds ) {
              if ( f === cont.id) {
                cont.selected = true;
              }
            }
            this.teacherContent.push( cont );
          }
        }
      }
      });

  }

  ngOnInit() {
  }

  onCloseConfirm( event ) {
    this.dialogRef.close( event );
  }
}
