import { Component, OnInit, AfterViewInit } from '@angular/core';

import { MenuService, UserService } from '../../../../service/service.index';
import { SitemapService } from '../../../../core/sitemap/sitemap.service';

// COMPONENT SERVICES
import { AdminHeaderService } from '../admin-header/admin-header.service';

// SERVICES
import { StorageService } from '../../../../service/service.index';
import swal from 'sweetalert2';
import { Appsettings } from '../../../../configuration/appsettings';

@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.scss']
})
export class AdminNavComponent implements OnInit, AfterViewInit {

  menu: any[] = [];
  working: boolean;

  constructor( public _srvSiteMap: SitemapService,
    public _srvMenu: MenuService,
    private _srvUser: UserService,
    public _srvHeader: AdminHeaderService,
    public _srvStorage: StorageService) {
      this.menu = _srvMenu.getMenu();
}

  ngOnInit() {
    this._srvHeader.setUrlBackButton([]);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._srvHeader.setNotifications();
    });
  }

  getNotifications() {
    this._srvHeader.setNotifications();
  }

   // Permite cerrar sesión del sistema y eliminar los daltos locales
   RedirectTo() {
    swal({
      title: 'Salir de SALI',
      text: '¿Realmente deseas cerrar sesión y ser redirigido a recursos académicos?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cerrar ahora',
      cancelButtonText: 'Cancelar'
    }).then(result => {
      if (result.value) {
        this.working = true;
        this._srvUser.logOut().subscribe(
          res => {
            window.location.href = Appsettings.RA_URL;
          },
          error => {
            window.location.href = Appsettings.RA_URL;
          }
        );
      }
    });
  }

}
