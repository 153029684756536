export class BookTeacher {
  id: number;
  name: string;
  description: string;
  isbn: number;
  codebar: string;
  edition: string;
  authorId: number[];
  active: boolean;
  authorName: string;
  index: number;
}
