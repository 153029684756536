import { AbstractControl } from '@angular/forms';
import { ConstantPool } from '@angular/compiler';

export function ValidateUrl(control: AbstractControl) {
  if ( control.value !== '' &&  control.value !== null && control.value !== undefined ) {
      if (!control.value.startsWith('https://') && !control.value.startsWith('http://')) {
    return { validUrl: false };
    }


  }
  return null;
}
