import { Injectable } from '@angular/core';

import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';
import { Attendance } from '../../models/catalogs/attendances.model';

@Injectable({
    providedIn: 'root'
  })

  export class AttendanceService {

    constructor(  private httpService: HttpService) { }

    // Obtiene los datos del curso y del grupo para la asistencia
    getAttendanceData(init, end, id: number){
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/attendances?init=${init}&end=${end}&studentGroupId=${id}`;
    return this.httpService.get( API_URL )
                            .map( res => res.json());
    }

    // Guadarda la asistencia del grupo
    saveGroupAttendance( attendances ) {
      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/attendances`;
      const HEADERS = new Headers();

      HEADERS.append('Content-Type', 'application/json');

      return this.httpService.post(API_URL, JSON.stringify( attendances ), { headers: HEADERS } )
                           .map( res => res.json());
    }

  }
