import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../configuration/appsettings';
import { FileHolder } from 'angular2-image-upload';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

// MODELS
import { FileContent } from '../../models/catalogs/file.model';
import { NewHome } from '../../models/home/new.model';

// SERVICEs
import { NewsHomeService } from '../../service/service.index';
import swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-news',
  templateUrl: './dialog-news.component.html',
  styleUrls: ['./dialog-news.component.scss']
})
export class DialogNewsComponent implements OnInit {

  frmNews: FormGroup;
  working: boolean = false;
  isChangeData: boolean = false;

  fileContent: File;
  fileContentBase: string;
  filenName: string;
  isUpdateFile: boolean;

  @ViewChild('formNews') formNews;

  constructor(public dialogRef: MatDialogRef<DialogNewsComponent>,
              public _srvNew: NewsHomeService,
              @Inject(MAT_DIALOG_DATA) public data: NewHome) {               

                this.frmNews =  new FormGroup({
                  'id' : new FormControl(  this.data.id , [ Validators.required ]),
                  'title': new FormControl( this.data.title, [ Validators.required]),
                  'description': new FormControl( this.data.description, [ Validators.required]),
                  'published': new FormControl( this.data.published, [ Validators.required]),
                  'file': new FormControl('')
                });

                if ( this.data.urlImage != null  && this.data.id > 0) {
                    this.filenName = 'archivo';
                    this.frmNews.controls['file'].setValue( this.data.urlImage );
                    this.isUpdateFile = false;
                }

              }

  ngOnInit() {
  }

  // Evento para cerrar la modal
  onCloseConfirm() {
    this.dialogRef.close(this.isChangeData);
  }

  // Evento para agregar o editar una noticia
  onSubmit() {

    this.working = true;
    let fileUpload = new FileContent();
    const newHome = new NewHome();

    if ( this.fileContent ) {

      const arrayFileBase = this.fileContentBase.split(',');

      fileUpload = new FileContent(
        arrayFileBase[1],
        this.fileContent.type,
        this.fileContent.name,
        arrayFileBase[0]
      );
    }

    newHome.id =  this.data.id;
    newHome.title =   this.frmNews.controls['title'].value;
    newHome.description =   this.frmNews.controls['description'].value;
    newHome.published =   this.frmNews.controls['published'].value;
    newHome.fileContentBase64 = fileUpload.content;
    newHome.filename = fileUpload.name;

    if ( newHome.id > 0) {
      this.update( newHome );
    } else {
      this.save( newHome );
    }
  }

  // Evento para remover el archivo que se subio
  onRemoved(event: FileHolder) {
    this.fileContentBase = '';
    this.fileContent = null;
    this.frmNews.controls['file'].setValue('');
    this.filenName = '';
  }

  // Inicializar los valorea al asignar el archivo
  onUploadFinished(event: FileHolder) {
    this.fileContentBase = event.src;
    this.fileContent     = event.file;
    this.filenName       = this.fileContent.name;
    this.isUpdateFile    = true;
    this.frmNews.controls['file'].setValue(event.file.name);
  }

  onUploadStateChanged( event ) {
  }

  // Guardar los datos de una noticia existente
  save( newHome: NewHome ): void {
    this._srvNew.save(newHome).subscribe( res => {
      if ( res.success ) {
        this.isChangeData = true;
        this.onCloseConfirm();
        swal( Appsettings.APP_NAME,  res.message, 'success');
      } else {
        swal( Appsettings.APP_NAME,  res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Actualizar los datos de una noticia existente
  update( newHome: NewHome ): void {
    this._srvNew.update( newHome ).subscribe( res => {
      if ( res.success ) {
        this.isChangeData = true;
        this.onCloseConfirm();
        swal( Appsettings.APP_NAME,  res.message, 'success');
      } else {
        swal( Appsettings.APP_NAME,  res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

}
