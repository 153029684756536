import { Component, OnInit, ViewChild } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';
import { Appsettings } from '../../../configuration/appsettings';
import { UserUtilities } from '../../../shared/user.utilities';

// Servicios
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';
import { AttendancesListComponent } from '../../../components/administrator/attendances-list/attendances-list.component';

// Modelos
import { Group } from '../../../models/courses/group.model';
import { Course } from '../../../models/catalogs/course.model';

@Component({
  selector: 'app-attendance-list',
  templateUrl: './attendance-list.component.html',
  styleUrls: ['./attendance-list.component.scss']
})
export class AttendanceListComponent implements OnInit {

  studenGroupId: number;
  group: Group;
  course: Course;
  today: string;
  minDate: Date;
  maxDate: Date;

  @ViewChild(AttendancesListComponent)
  public timerComponent: AttendancesListComponent;

  constructor(  private _srvHeader: AdminHeaderService,
    private _route: ActivatedRoute) {

      this.today = UserUtilities.dateFullSerialize(new Date());
      this.minDate = new Date(2000, 0, 1);
      this.maxDate = new Date(2020, 0, 1);

  }

  ngOnInit() {
    this._route.params.subscribe( params => {
      this.studenGroupId = params.studenGroupId;
      this.timerComponent.getStudentsAssistance(this.today, this.today, this.studenGroupId);
      // this.timerComponent.getStudents();
      this.group = JSON.parse(localStorage.getItem('sali.group'));
      this._srvHeader.setTitle('Lista de Asistencias: ' + this.group.description);
    });
  }

}
