import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Servicios
import { HomeworksService } from '../../../service/service.index';

// Modelos
import { StudenHomework } from '../../../models/courses/studenHomework.model';

// Components services
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';


@Component({
  selector: 'app-homework-qualify',
  templateUrl: './homework-qualify.component.html',
  styleUrls: ['./homework-qualify.component.scss']
})
export class HomeworkQualifyComponent implements OnInit, OnDestroy {

  homeworkId: number;
  studenGroupId: number;
  students: StudenHomework[] = [];
  statusHomework: boolean;
  homeworkName: string;
  homeworkDescription: string;

  constructor(  private _route: ActivatedRoute,
                private _srvHomework: HomeworksService,
                private _srvHeader: AdminHeaderService ) {



    this._route.params.subscribe( params => {
      this.studenGroupId = params.groupId;
      this.homeworkId = params.examScheduleId;
      this.getAllHomework( this.studenGroupId, this.homeworkId );
      let urlBack = `/teacher/task-schedule/${ this.studenGroupId }`;
      this._srvHeader.setUrlBackButton([urlBack, 'Tareas']);
    });
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this._srvHeader.setUrlBackButton([]);
  }

  // Obtiene el listado de alumnos y sus tareas
  getAllHomework(studenGroupId: number, homeworkId: number ) {
    this._srvHomework.getAllHomework(studenGroupId, homeworkId).subscribe( result => {
      if (result.success) {
        this.statusHomework = result.data.homeWork.qualified;
        this.homeworkName = result.data.homeWork.name;
        this.homeworkDescription = result.data.homeWork.description;
        for ( let s of result.data.students) {
          let status = false;
          if ( s.files ) {
            status = true;
          }
          let student = new StudenHomework();
          student.id = s.id;
          student.comment = s.comment;
          student.fullName = s.fullName;
          student.hasHomwRowk = s.hasHomwRowk;
          student.homeWorkId = s.homeWorkId;
          student.score = s.score;
          student.files = s.files;
          student.userId = s.userId;
          student.statusFiles = status;

          this.students.push( student );
        }
      }
    });
  }

}
