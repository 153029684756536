export class StudenHomework {
  id: number;
  comment: string;
  fullName: string;
  hasHomwRowk: boolean;
  homeWorkId: number;
  score: number;
  files: any[];
  userId: number;
  statusFiles: boolean;
}
