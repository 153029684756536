import { Component, OnDestroy, OnInit, Input  } from '@angular/core';

// Modelos
import { ContentBook } from '../../models/content/contentBook.model';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit, OnDestroy {

  _dataAudios: ContentBook[];
  audio: any;
  audioName: string;
  currentTime: string;
  audioDuration: any;
  progress: any;
  progressBar: any;
  iconPlay: string;

  stopSound: boolean;

  get currentTimes(): ContentBook[] {
    return this._dataAudios;
  }

  @Input('dataAudios') set dataAudios(value: ContentBook[]) {
    if (value) {
        this._dataAudios = value;
          this.createAudio(this._dataAudios);

    }
  }
  @Input('stopSound') set soundStop( value) {
    this.stopSound = value;
    this.stopSpund( this.stopSound );
  }
  constructor() {
    this.audio = new Audio();

    this.audioDuration = '00:00';
    this.iconPlay = 'play_arrow';
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio = new Audio();
    }
  }
  // Crea el nuevo elemeto de audio
  createAudio(song: any ) {
    this.progress = 0;
    this.progressBar =  '0%';
    this.iconPlay = 'play_arrow';
    this.audioName = song.name;
    this.audio.src = song.urlContent;
    this.audio.oncanplaythrough = () => {
      this.currentTime = this.formatTime(this.audio.duration);
    };
  }
  // conversion de minutos a segundos
  formatTime(seconds: any) {
    let minutes: any = Math.floor(seconds / 60);
    minutes = (minutes >= 10) ? minutes : '0' + minutes;
    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : '0' + seconds;
    return minutes + ':' + seconds;
  }

  // Crea procenteje
  getProgress(seconds: any) {
    let porcentaje = ((seconds / this.audio.duration) * 100);
    return porcentaje;
  }

  // Reproduce el audio seleccionado
  onPlay() {

    if (this.audio.paused) {
      this.iconPlay = 'pause';
      this.audio.play();
      this.audio.ontimeupdate = () => {
          this.audioDuration = this.formatTime(this.audio.currentTime);
          this.progress = this.getProgress(this.audio.currentTime);
          this.progressBar = this.progress + '%';
      };
    } else {
      this.iconPlay = 'play_arrow';
      this.audio.pause();
    }
  }

  stopSpund( param: boolean ) {
    if ( param ) {
      if ( this.audio ) {
        this.audio.pause();
        if ( this._dataAudios ) {
          this.createAudio(this._dataAudios);
        }
      }
    }
  }

}
