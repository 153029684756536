import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Appsettings } from '../../../../configuration/appsettings';

// MODELS
import { Question } from '../../../../models/courses/question.model';
import { QuestionBank } from '../../../../models/courses/questionBank.model';
import { Answer } from '../../../../models/courses/answer.model';
import { FileContent } from '../../../../models/catalogs/file.model';

// SERVICES
import { QuestionBankService , NodeService, CatalogService, StorageService} from '../../../../service/service.index';
import { QuestionType } from '../../../../models/courses/questionType.model';

// DIALOG
import { DialogResourceComponent } from '../../../../dialogs/dialog-resource/dialog-resource.component';

import { TreeUtilities } from '../../../../shared/tree.utilities';

import swal from 'sweetalert2';
import { element } from 'protractor';
import { FileHolder } from 'angular2-image-upload';
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit, OnDestroy {

  showAddAnswerButton: boolean;

  questionBank: QuestionBank = new QuestionBank();
  working: boolean = false;
  frmQuestions: FormGroup;
  frmQuestionBank: FormGroup;
  answers: Answer[] = [];
  showForm: boolean;
  showTreeStructure: boolean = false;
  editMode: boolean = true;
  selectedQuestion: Question;

  structure: any[] = [];
  selectedItemPath = '';
  value;
  items: TreeviewItem[] = [];
  config = TreeviewConfig.create({
      hasFilter: true,
      hasCollapseExpand: true
  });

  questionTypes: any[] = [];

  displayedColumns: string[] = [
    'content',
    'questionType.description'];

  dataSource = new MatTableDataSource<Question>( this.questionBank.questions );
  nodos: any[] = [];

  // IMAGEN DE PREGUNTA
  imageProfile: string;
  fileContent: File;
  fileContentBase: string;
  fileName: string;
  isUpdateFile: boolean;

  @ViewChild('formQuestion') formQuestion;
  @ViewChild('imageUpload') imageUpload;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // CONSTURCTOR
  constructor( private _srvQuestionBank: QuestionBankService,
               private _route: ActivatedRoute,
               private _srvNode: NodeService,
               private _srvCatalog: CatalogService,
               public dialog: MatDialog,
               private _storageSrv: StorageService,
               private _srvHeader: AdminHeaderService ) {

    // Formulario de pregunta
    this.frmQuestions = new FormGroup({
      id: new FormControl(0),
      // question: new FormControl('', [Validators.required, Validators.minLength(20)]),
      question: new FormControl('', [Validators.required]),
      explanation: new FormControl(''),
      questionTypeId: new FormControl('', [Validators.required]),
    });

    // Formulario para banco de preguntas
    this.frmQuestionBank = new FormGroup({
      id: new FormControl(0),
      // name: new FormControl('', [ Validators.required, Validators.minLength(30) ]),
      // description: new FormControl('', [ Validators.required, Validators.minLength(30) ]),
      name: new FormControl('', [ Validators.required ]),
      description: new FormControl('', [ Validators.required ]),
      nodeId: new FormControl('', [ Validators.required ])
    });

    this.questionBank = new QuestionBank();
    this.dataSource.paginator = this.paginator;
    this.showTreeStructure = true;

    this._route.params.subscribe( params => {
       this.questionBank.id = params['id'];

       if ( this.questionBank.id > 0 ) {
          this.working = true;
          this._srvQuestionBank.getBydId( this.questionBank.id).subscribe( res => {

            if ( res.success ) {
              this.setQuestionBank( res.data );
              this.showTreeStructure = false;
              this.editMode  = false;
            }

            this.working = false;
          }, err => {
            this.working = false;
          });
      }
    });

  }

  ngOnInit( ) {
    setTimeout(() => this.getNodes(), 0);
    const title = this.questionBank.id > 0 ? 'Editar banco de preguntas' : 'Crear banco de preguntas';

    // SET CONTROLS
    this._srvHeader.setTitle(title);
    this._srvHeader.setUrlBackButton(['/admin/questions-list', 'Banco de preguntas']);

    // Obtener todos los tpos de respuestas
    this._srvCatalog.getQuestionTypesAll().subscribe( res => {
      if ( res.success ) {
        for ( let type of res.data ) {
          this.questionTypes.push( { id: type.id, name: type.description });
        }
      }
    });

  }

  ngOnDestroy(): void {
    this._srvHeader.setUrlBackButton([]);
  }

  // Evento que se ejecuta al dar clic en la opción eliminar de la tabla de preguntas
  onClickDeleteQuestion( question: Question ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.working = true;
        this._srvQuestionBank.removeQuestion( question.id ).subscribe( res => {

          if ( res.success ) {
            this.questionBank.questions = this.questionBank.questions.filter( x => x.id !== question.id);
            this.resetQuestinTable();
            swal(Appsettings.APP_NAME, res.message, 'success');
          } else {
            swal(Appsettings.APP_NAME, res.message, 'error');
          }
          this.working = false;
        }, err => {
          this.working = false;
          swal(Appsettings.APP_NAME, 'Error de comunicación con el servidor', 'error');
        });


      }
    });

  }

  // Evento que se ejecuta al dar clic al formulario del banco de preguntas
  onSubmitBank( ): void {

    this.questionBank.description = this.frmQuestionBank.controls['description'].value;
    this.questionBank.name = this.frmQuestionBank.controls['name'].value;
    this.questionBank.nodeId = this.frmQuestionBank.controls['nodeId'].value;
    this.questionBank.id = this.frmQuestionBank.controls['id'].value;

    if ( this.questionBank.id > 0 ) {
      this.update();
    } else {
      this.save();
    }
  }

  // Método para actualizar los datos d eun banco de imagenes existente
  update( ): void {

    this.working = true;

    this._srvQuestionBank.update( this.questionBank ).subscribe( res => {
      if ( res.success ) {
        this.showTreeStructure = false;
        this.editMode = false;
        this.selectedItemPath = TreeUtilities.getFullPathNode(this.questionBank.nodeId, this.structure );
        swal(Appsettings.APP_NAME, res.message, 'success');
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Método para crear un nuevo banco de preguntas
  save( ): void {

    this.working = true;

    this._srvQuestionBank.save( this.questionBank ).subscribe( res => {
      if ( res.success ) {
        this.questionBank.id = res.data.id;
        this.showTreeStructure = false;
        this.editMode = false;
        this.selectedItemPath = TreeUtilities.getFullPathNode(this.questionBank.nodeId, this.structure );
        swal(Appsettings.APP_NAME, res.message, 'success');
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });

  }

  // Evento que se ejecuta al dar clic en el boton cancelar del formulario de captura
  onClickCancelQuestion( ): void {

    swal({
      title: '¿Deseas cancelar la operación?',
      text: '¡Se perderán los datos en caso de no guardarlos!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, cancelar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.showForm = false;
        this.resetQuestionForm();
      }
    });
  }

  // Evento que permite mostrar el formulario de captura de la pregunta
  onClickAddQuestion( ): void {
     this.showForm = true;
  }

  // Evento que se ejecuta al cambiar el combo de tipo de pregunta
  onChangeQuestionType( event ): void {

    // if ( parseInt(event.value) === 2 ) {
      if (this.answers.length > 0) {
        swal({
          title: '¿Estás seguro?',
          text: 'Si realizas el cambio de tipo de pregunta se perderán las respuestas registradas',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '¡Sí, cambiar ahora!',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.value) {
            this.answers = [];
          } else {
            this.frmQuestions.controls['questionTypeId'].setValue(this.selectedQuestion.questionTypeId);
          }
        });
      } else {
        this.answers = [];
      }
    // }

    if ( event.value === 1 || event.value === 3 || event.value === 5 ) {
      this.showAddAnswerButton = true;
    } else {
      this.showAddAnswerButton = false;
    }


    if ( this.frmQuestions.controls['questionTypeId'].value  === 4 ) {
      this.addAnswersTrueFalse();
    }
  }

  onClickEditBank( ): void {
    this.showTreeStructure = true;
    this.editMode = true;
  }

  onClicBankCancel( ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Perderá la información actualizada!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, cancelar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.showTreeStructure = false;
        this.editMode = false;

        if (this.questionBank.id > 0 ) {
          this.frmQuestionBank.controls['description'].setValue( this.questionBank.description );
          this.frmQuestionBank.controls['name'].setValue( this.questionBank.name );
          this.frmQuestionBank.controls['nodeId'].setValue( this.questionBank.nodeId );
          this.frmQuestionBank.controls['id'].setValue( this.questionBank.id );

          this.selectedItemPath = TreeUtilities.getFullPathNode(this.questionBank.nodeId, this.structure );
        }
      }
    });
  }

  // Setear datos de un banco de preguntas
  setQuestionBank( data ): void {

    this.questionBank.active = data.active;
    this.questionBank.description = data.description;
    this.questionBank.name = data.name;
    this.questionBank.nodeId = data.nodeId;
    this.questionBank.questions = data.questions;

    if (this.questionBank.nodeId > 0 ) {
      const node = this.structure.filter( x => x.id === this.questionBank.nodeId )[0];
      if ( node ) {
        this.value = {
          text: node.description,
          value: node.id,
          collapsed: false,
          checked: false,
          parentId: node.parentId
        };
      }
    }

    this.resetQuestinTable();

    this.frmQuestionBank.controls['description'].setValue( this.questionBank.description );
    this.frmQuestionBank.controls['name'].setValue( this.questionBank.name );
    this.frmQuestionBank.controls['nodeId'].setValue( this.questionBank.nodeId );
    this.frmQuestionBank.controls['id'].setValue( this.questionBank.id );

    this.selectedItemPath = TreeUtilities.getFullPathNode(this.questionBank.nodeId, this.structure );
  }

  // Inicializar el formulario de preguntas
  resetQuestionForm( ): void {

    this.frmQuestions.reset();
    this.formQuestion.resetForm();
    this.answers = [];
    this.selectedQuestion = undefined;
    this.frmQuestions.controls['id'].setValue(0);
    this.onRemoved();
    this.imageUpload.deleteFile(this.imageUpload.files[0]);
  }

  resetQuestinTable( ): void {

    this.paginator.pageIndex  = 0;
    this.dataSource           = new MatTableDataSource<Question>( this.questionBank.questions );
    this.dataSource.paginator = this.paginator;
  }

  /********* QUESTION ***********/

  onClickEditQuestion( eleme: Question ): void {
    // this.answers = [];
    this.answers = eleme.answers.filter( x => x.id > 0);
    this.showForm = true;
    this.selectedQuestion = eleme;


    let index = 0;
    for ( const a of this.answers ) {
      a.index = index;
      index++;
    }

    this.frmQuestions.controls['id'].setValue(eleme.id);
    this.frmQuestions.controls['question'].setValue(eleme.content);
    this.frmQuestions.controls['explanation'].setValue(eleme.explanation);
    this.frmQuestions.controls['questionTypeId'].setValue(eleme.questionTypeId);
    this.fileName = this.selectedQuestion.urlImage;
    this.isUpdateFile = false;

    if ( eleme.questionTypeId === 1 || eleme.questionTypeId === 3 || eleme.questionTypeId === 5 ) {
      this.showAddAnswerButton = true;
    } else {
      this.showAddAnswerButton = false;
    }

  }

   // Evento que se ejecuta al dar clic al formulario de la preunta
   onSubmit( ): void {

    let fileUpload = new FileContent();

    if ( this.fileContent ) {

      const arrayFileBase = this.fileContentBase.split(',');

      fileUpload = new FileContent(
        arrayFileBase[1],
        this.fileContent.type,
        this.fileContent.name,
        arrayFileBase[0]
      );
    }

    const question = new Question();
    question.active = true;
    question.id = this.frmQuestions.controls['id'].value;
    question.content = this.frmQuestions.controls['question'].value;
    question.questionBankId = this.questionBank.id;
    question.questionTypeId = this.frmQuestions.controls['questionTypeId'].value;
    question.explanation = this.frmQuestions.controls['explanation'].value;
    question.fileContentBase64 = fileUpload.content;
    question.filename = this.fileName;

    if ( question.questionTypeId !== 2 ) {
        question.answers = this.answers;
    }

    if ( question.id === 0 ) {
     this.saveQuestion( question );
    } else {
      this.updateQuestion( question );
    }

  }

  // GUARDAR LOS DATOS DE UNA NUEVA PREGUNTA
  saveQuestion ( question: Question ): void {

    this.working = true;
    this._srvQuestionBank.addQuestion( question ).subscribe( res => {

      if ( res.success ) {
        let description;
        if (this.questionBank.questions === undefined ) {
          this.questionBank.questions = [];
        }
        question.id      = res.data.id;
        // question.answers = res.data.answers;
        let questionType = new QuestionType();
        questionType.id  = res.data.questionType.id;
        questionType.description = res.data.questionType.description;

       if ( res.data.answers ) {

        question.answers = [];
          let index = 0;

          for ( const q of res.data.answers ) {
            let answer = new Answer();

            answer.index = index;
            answer.id = q.id;
            answer.description = q.description;
            answer.isCorrect = q.isCorrect;
            answer.relationDescription = q.relationDescription;

            question.answers.push(answer);
            index++;
          }
       }
        question.questionType  = questionType;

        this.questionBank.questions.push( question );
        this.resetQuestinTable();
        this.resetQuestionForm();

        swal(Appsettings.APP_NAME, 'Pregunta agregada correctamente', 'success');
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });

  }

  // ACTUALIZAR LOS DATOS DE UNA PREGUNRA
   updateQuestion ( question: Question ): void {
    this.working = true;
    this._srvQuestionBank.updateQuestion( question ).subscribe( res => {
      if ( res.success ) {

        if (this.questionBank.questions === undefined ) {
          this.questionBank.questions = [];
        }

        let questionUpdate = this.questionBank.questions.filter(  x => x.id === question.id )[0];
        if ( questionUpdate ) {

          if ( res.data.answers ) {

            questionUpdate.answers = [];
            let index = 0;

            for ( const q of res.data.answers ) {
              let answer = new Answer();

              answer.index = index;
              answer.id = q.id;
              answer.description = q.description;
              answer.isCorrect = q.isCorrect;
              answer.relationDescription = q.relationDescription;

              questionUpdate.answers.push(answer);
              index++;
            }
         }

          let questionType = new QuestionType();
          questionType.id  = res.data.questionType.id;
          questionType.description = res.data.questionType.description;

          questionUpdate.explanation = question.explanation;
          questionUpdate.content = question.content;
          questionUpdate.questionType = questionType;
        }

        this.resetQuestinTable();
        this.resetQuestionForm();

        swal(Appsettings.APP_NAME, 'Pregunta agregada correctamente', 'success');
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });

   }

   // Función que agrega un nuevo elemento al listado de respuestas
   addAnswers( ): void {

    const answer = new Answer();
    const lastindex = this.answers.length - 1;
    const lastAnswer = this.answers[ ( lastindex < 0 ? 0 : lastindex ) ];

    if ( lastAnswer ) {
      answer.index = lastAnswer.index + 1;
    } else {
      answer.index = 0;
    }
    answer.id = 0;

    this.answers.push(answer);
    // setTimeout(function() {
    //   document.documentElement.scrollTop =  document.documentElement.scrollHeight + 200;
    // }, 200);
   }

   // Función para agregar preguntas de verdadero y falso
   addAnswersTrueFalse( ): void {
    this.answers = [];

    for (let index = 0; index <= 1; index++) {
      const answer = new Answer();
      const lastindex = this.answers.length - 1;
      const lastAnswer = this.answers[ ( lastindex < 0 ? 0 : lastindex ) ];

      if ( lastAnswer ) {
        answer.index = lastAnswer.index + 1;
      } else {
        answer.index = 0;
      }
      answer.id = 0;

      this.answers.push(answer);
    }

    setTimeout(function() {
      document.documentElement.scrollTop =  document.documentElement.scrollHeight + 200;
    }, 200);
   }

  // Eliminar una respuesta del listado
  remove( index: number): void {

      swal({
        title: '¿Estás seguro?',
        text: '¡Esta operación no se podrá revertir!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Sí, eliminar ahora!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {

          if ( this.answers[index].id === 0) {
            this.answers = this.answers.filter( x => x.index !== index);
            swal(Appsettings.APP_NAME, 'Respuesta eliminada correctamente', 'success');
          } else {
            this.working = true;
            this._srvQuestionBank.removeAnswer( this.answers[index].id).subscribe( res => {
                if ( res.success ) {
                  this.answers = this.answers.filter( x => x.index !== index);

                  let question = this.questionBank.questions.filter(  x => x.id === this.selectedQuestion.id )[0];

                  if ( question ) {
                    question.answers = this.answers;
                  }

                  swal(Appsettings.APP_NAME, res.message, 'success');
                } else {
                  swal(Appsettings.APP_NAME, res.message, 'error');
                }
                this.working = false;
            }, err => {
              this.working = false;
              swal(Appsettings.APP_NAME, 'Problemas al intentar eliminar la respuesta' , 'error');
            });
          }
        }
      });
  }

  onRemoved() {
    this.imageProfile = '';
    this.fileContentBase = '';
    this.fileContent = null;
    this.fileName = '';
  }

  onUploadFinished(event: FileHolder) {

    this.imageProfile    = event.src;
    this.fileContentBase = event.src;
    this.fileContent     = event.file;
    this.fileName        = this.fileContent.name;
    this.isUpdateFile    = true;

  }

  onUploadStateChanged(state: boolean) {
  }

  /********* ANSWERS **********/

  onClickAddImage( answer: Answer ) {

    const DIALOG_REF = this.dialog.open( DialogResourceComponent, {
      width: '400px',
      minHeight: '500px',
      autoFocus: false,
      disableClose: true,
      data: {
        id: 0,
        name: '',
        description: '',
        url: ''
      }

    });

    DIALOG_REF.afterClosed().subscribe( res => {
      if ( res ) {
        answer.description = res.url;
      }

    });
  }

  /******** STRUCTURE **********/

  // Evento que se ejecuta cuando se realiza un cambio en el elemento seleccionado
  onValueChange( value ): void {
    this.frmQuestionBank.controls['nodeId'].setValue(value.value);
  }

  // Obtiene los datos del servicio
  getNodes(): void {
      let nodeId   =  this._storageSrv.systemId === 1 ?  Appsettings.SALI_STRUCTURE_ID :  Appsettings.RA_STRUCTURE_ID ;

      if ( this._storageSrv.rol.indexOf('Administrador') > -1 ) {

      this.working = true;
      this._srvNode.getStructureByPatent( nodeId ).subscribe(
        res => {
          if (res.data) {
            for (const node of res.data) {
              let checkedNode = false;
              this.structure.push(node);
              this.nodos.push({
                text: node.description,
                value: node.id,
                collapsed: false,
                checked: checkedNode,
                parentId: node.parentId
              });
            }
            this.items.push(TreeUtilities.buildHierarchy(this.nodos));
          }
          this.selectedItemPath = TreeUtilities.getFullPathNode(this.questionBank.nodeId, this.structure );
          this.working = false;
        },
        err => {
          this.working = false;
        }
      );

      } else {
        const jsonNode = JSON.parse(this._storageSrv.nodes);
        for (const node of jsonNode) {
          let checkedNode = false;
          this.structure.push(node);

          let nodeExist = this.nodos.filter( x => x.value === node.id );
          if ( nodeExist.length  === 0 ) {
            this.nodos.push({
              text: node.description,
              value: node.id,
              collapsed: false,
              checked: checkedNode,
              parentId: node.parentId
            });
          }

        }
        this.items.push(TreeUtilities.buildHierarchy(this.nodos));
      }
  }

  /************************** */


  onLoadImageAnswer(img, answer) {
    if ( img ) {
     answer.imageWidth = img.clientWidth;
     answer.imageHeight = img.clientHeight;
    }
  }


}


