import { Component, OnInit, Input } from '@angular/core';

// Servicios
import { GlossaryService, NodeService } from '../../../service/service.index';

// Modelos
import { Word } from '../../../models/courses/word.model';

// Complementos
import { TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent, TreeItem } from 'ngx-treeview';
import { TreeUtilities } from '../../../shared/tree.utilities';
import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-glossary-add-book',
  templateUrl: './glossary-add-book.component.html',
  styleUrls: ['./glossary-add-book.component.scss']
})
export class GlossaryAddBookComponent implements OnInit {

  glossaryId: number;
  publications: any[] = [];
  selectedPublications: any[] = [];
  wordContent: Word[] = [];
  structure: any[] = [];
  nodos: any[] = [];
  nodeId: number;
  working: boolean;
  value;
  items: TreeviewItem[] = [];
  config = TreeviewConfig.create({
      hasFilter: true,
      hasCollapseExpand: true
  });

  // Obtenemos los estudiantes
  @Input('glossaryId') set setGlossaryId ( value) {
    this.glossaryId = value;
    this.getDataGlossary( this.glossaryId );
}
  constructor(  private _srvGlossary: GlossaryService,
                private _srvNode: NodeService) {

    this.working = false;
    this.getNodes();
  }

  ngOnInit() {
  }

  // Obtiene los datos del glosario
  getDataGlossary( glossaryId: number) {
    this.working = true;
    this._srvGlossary.getGlossaryById( glossaryId ).subscribe( result => {
      if ( result.success ) {
        let glossary = result.data;
        this.publications = [];

        if ( glossary.nodes.length > 0) {
          for ( let n of glossary.nodes) {
            this.publications.push({
                id: n.id,
                publication: n.name
            });
          }
        }
      }
      this.working = false;
    });
  }

  // Obtiene los datos del arbolito
  getNodes(): void {
    this.working = true;
    this._srvNode.getStructureByPatent(  Appsettings.SALI_STRUCTURE_ID ).subscribe(
      res => {
        if (res.data) {
          let data = res.data;

          // let nodes = data ( node => node.nodeTypeId !== 6 );
          for (const node of res.data) {
            if ( node.nodeTypeId !== 6 ) {
              let checkedNode = false;
              this.structure.push(node);
              this.nodos.push({
                text: node.description,
                value: node.id,
                collapsed: false,
                checked: checkedNode,
                parentId: node.parentId,
                nodeTypeId: node.nodeTypeId
              });
            }
          }
          //  Elementos para el arbol
          this.items.push(TreeUtilities.buildHierarchy(this.nodos));
        }
        this.working =  false;
      },
      err => {
        this.working = false;
      }
    );
  }

  // Evento que se ejecuta cuando se realiza un cambio en el elemento seleccionado
  onValueChange( value ) {
    if ( value.nodeTypeId !== 5 ) {
      swal(Appsettings.APP_NAME, 'El elemento seleccionado no es un libro.', 'error');
      return false;
    }
    this.selectedPublications = [];
    this.nodeId = value.value;
    this.selectedPublications.push({
      id: value.value,
      publication: value.text
    });


  }

  // Guarda la asociacion de libro-glosario
  onAddBooks() {
    this.working = true;
    let result;
    result = this.publications.findIndex( elem =>  elem.id === this.nodeId );

    if ( result < 0) {
      this._srvGlossary.addNode( this.nodeId, this.glossaryId).subscribe( res => {
        if ( res.success) {
          this.getDataGlossary( this.glossaryId);
          swal(Appsettings.APP_NAME,  res.message, 'success');
        } else {
          swal(Appsettings.APP_NAME,  res.message, 'warning');
        }
        this.working = false;
      });
    } else {
      swal(Appsettings.APP_NAME, 'El elemento ya fue seleccionado.', 'warning');
      this.working = false;
      return false;
    }

  }

  onDeletePublication( book) {
    swal({
      title: '¿Deseas eliminar el libro?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí!',
      cancelButtonText: 'Cancelar'
    }).then((res) => {
      if ( res.value) {
        this.working = true;
        this._srvGlossary.deleteNodoGlossary( this.glossaryId, book.id ).subscribe( result => {
          if ( result.success) {
            if ( result. success) {
              this.getDataGlossary( this.glossaryId);
              swal( Appsettings.APP_NAME, result.message , 'success');
            } else {
              swal( Appsettings.APP_NAME, result.message , 'warning');
            }
          }
          this.working = false;
        });
      }
    });

  }
}
