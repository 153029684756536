import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';
import { MatDialog } from '@angular/material';
import { Appsettings } from '../../../configuration/appsettings';

// Modelos
import { Notice } from '../../../models/courses/notice.model';
import { Course } from '../../../models/catalogs/course.model';
import { Group } from '../../../models/courses/group.model';
import { Pagination } from '../../../models/general/pagination.model';

// Servicios
import { NoticeService } from '../../../service/service.index';
import { CourseService } from '../../../service/service.index';

// Complementos
import swal from 'sweetalert2';

declare function setHeight(): any;

@Component({
  selector: 'app-generate-notice',
  templateUrl: './generate-notice.component.html',
  styleUrls: ['./generate-notice.component.scss']
})
export class GenerateNoticeComponent implements OnInit, OnDestroy {

  courses: Course[] = [];
  groups: Group[] = [];
  announcements: Notice[] = [];
  notice: Notice;

  noticeId: number;
  working: boolean;
  editMode: boolean = true;

  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();


  publications: any[] = [];
  selectedPublications: any[] = [];
  frmNotice: FormGroup;
  contentSection: any[] = [];

  courseId: string = '0';
  groupId: string = '0';
  textSearch: string = '';  
  displayedColumns: string[] = ['index', 'title', 'description', 'registerDate'];
  showBotton: boolean;
  showEdit: boolean;
  

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(  private _route: ActivatedRoute,
                private _srvCourse: CourseService,
                private _srvNotice: NoticeService,               
                private _srvHeader: AdminHeaderService ) {
      this.showBotton = false;
      this.showEdit = false;

      // Obtener todos los cursos del profesor
      this.getCourses();

      // Creamos los elementos del formulario para nuevo aviso
      this.frmNotice = new FormGroup({
        id: new FormControl(0),
        title: new FormControl('', [ Validators.required, Validators.maxLength(100) ] ),
        description: new FormControl('', [ Validators.required, Validators.maxLength(350) ] ),
        registerDate: new FormControl('', [ Validators.required]),
        course: new FormControl('', [Validators.required]),
        groups: new FormControl('', [Validators.required])

      });

      // Obtenemos los parametros de la ruta
      this._route.params.subscribe( params => {
        this.noticeId = params['id'];
        if ( this.noticeId > 0 ) {
          this.showBotton = true;
          this.editMode = true;
          this.showEdit = false;
          // Obtenemos los datos del aviso
          this.getDataNotice();
        }
      });

     // this.setPage( { page: 1, size: this.sizePage });
   }

  ngOnInit() {
    setHeight();
  
    const title = this.noticeId == 0 ? 'Crear nuevo aviso' : 'Editar aviso';
        this._srvHeader.setTitle(title);
        this._srvHeader.setUrlBackButton(['/sali/list-notice', 'Mis avisos']);

  }

  ngOnDestroy(): void {
    this._srvHeader.setUrlBackButton([]);
  }

  getDataNotice() {
    this.publications = [];
    this._srvNotice.getNoticeById( this.noticeId).subscribe( result => {
      if ( result.success ) {

        let notice = result.data;
        // if ( notice.nodes.length > 0) {
        //   for ( let n of notice.nodes) {
        //     this.publications.push({
        //         id: n.id,
        //         publication: n.title
        //     });
        //   }
        // }

      //     if ( notice.groups.length > 0 ) {
          
      //   for ( let g of notice.groups ) {
      //     notice.groups.push( g.id );
      //   }
      // }
        this.setForm( notice);
        // this.notice.id = notice.id;
        // this.notice.title = notice.title;
        // this.notice.description = notice.description;
      //   this.notice.active = notice.active;
      //   this.notice.registerDate = notice.registerDate;
      //   this.notice.courseId = Number(this.courseId);
      //   this.notice.groups = [];

      //   if ( notice.groups.length > 0 ) {
          
      //   for ( let g of notice.groups ) {
      //     notice.groups.push( g.id );
      //   }
      // }
      }
      this.working = false;
    });
  }

  // Setea los datos en el formulario para editarlos
  setForm( notice: any ) {
    this.frmNotice.controls['title'].setValue( notice.title);
    this.frmNotice.controls['description'].setValue( notice.description);
    this.frmNotice.controls['registerDate'].setValue( notice.registerDate);
    this.frmNotice.controls['course'].setValue( notice.courseId);
    this.frmNotice.controls['groups'].setValue( notice.groups);
  
  }

  getCourses(): void {
    this.courses = [];

    this._srvCourse.getAll().subscribe( result => {
      if ( result.success) {
        let data = result.data;
        for ( let c of data) {

          let course = new Course( this.contentSection, c.id, c.name, c.description, c.gol, c.startDate, c.endDate, c.nodeId,
                                   c.subject, c.monday, c.tuesday, c.wednesday, c.thursday, c.friday, c.saturday, c.sunday, c.parentId);

          if ( c.groups.length > 0 ) {
            for ( let g of c.groups ) {
              let group = new Group(g.id, g.description, g.status, g.courseId);
              course.groups.push(group);
            }
          }

          this.courses.push(course);
        }
      }
    });
  }

  onChangeCourse( event: any): void {
    if ((event != undefined) || (event.value > 0))
       this.courseId = event.value;

    if ( Number(this.courseId) > 0 ) {
      this.groups = this.courses.filter( x => x.id === Number(this.courseId))[0].groups;
      this.groupId = '0';
    } else {
      this.groups = [];
    }
    this.onChangeFilter();
  }

  // Realiza filtrado por curso y grupo
  onChangeFilter( ): void {
    this.setPage( { page: 1, size: this.sizePage });
  }


   // Obtiene todos los avisos del maestro
   setPage( paginationData: object ) {
    this.working = true;
    this._srvNotice.getAll(
      Number(paginationData['size']),
                             Number(paginationData['page']) , 
                             this.textSearch
                             ).subscribe( res => {

      this.announcements = [];
      let index = 1;

      if ( res.success) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let g of res.data) {

          let notice = new Notice();

          notice.id = g.id;
          notice.title = g.title;
          notice.description = g.description;
          notice.active = g.active;
          notice.courseId = g.courseId;
          notice.index = this.pagination.getIndexRegister(index);

          this.announcements.push( notice);
          index++;
        }
      }

      this.working = false;

    }, err => {
      this.working = false;
    });
  }


   // Activa los controles para editar un aviso
   onClickNotice(): void {
    this.editMode = true;
    this.showEdit = false;
  }

  // Guarda el nuevo aviso
  onClickAddNotice(): void {
    this.working = true;
    let notice = new Notice();
    notice.id = this.noticeId;
    notice.description = this.frmNotice.controls['description'].value;
    notice.title = this.frmNotice.controls['title'].value;
    notice.registerDate = this.frmNotice.controls['registerDate'].value; 
    notice.courseId =  Number(this.courseId);
    notice.groups = this.frmNotice.controls['groups'].value;    
    notice.active = true;    
    this.showEdit = true;
    if ( this.noticeId > 0 ) {
      this.updateNotice( notice);
    } else {
      this.saveNotice( notice );
    }
    this.working = false;
  }

  // Guarda un nuevo aviso
  saveNotice( notice: Notice ) {
    this._srvNotice.save( notice ).subscribe( result => {
      if ( result.success) {        
        this.editMode = false;
        this.showBotton = true;
        this.showEdit = true;
        this.noticeId = result.data;
        this.notice.id = result.data;
        this.notice.title = this.frmNotice.controls['title'].value;
        this.notice.description = this.frmNotice.controls['description'].value;        
        this.notice.registerDate = this.frmNotice.controls['registerDate'].value;
        this.notice.courseId = this.frmNotice.controls['courseId'].value;   
        this.notice.groups = this.frmNotice.controls['groups'].value;
        swal(Appsettings.APP_NAME,  result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME,  result.message, 'error');
      }
      this.working = false;
    });
  }
  // Actualiza
  updateNotice( notice: Notice ) {
    this._srvNotice.update( notice).subscribe( result => {
      if ( result.success) {
        this.notice.title = this.frmNotice.controls['title'].value;
        this.notice.description = this.frmNotice.controls['description'].value;
        this.notice.registerDate = this.frmNotice.controls['registerDate'].value;
        this.notice.courseId = this.frmNotice.controls['courseId'].value;
        this.notice.groups = this.frmNotice.controls['groups'].value;
        this.editMode = false;
        this.showBotton = true;
        swal(Appsettings.APP_NAME,  result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME,  result.message, 'error');
      }
      this.working = false;
    });
  }
  // Se cancela la edicion del aviso
  onClicBankCancel() {
    swal({
      title: '¿Estás seguro?',
      text: 'Perderá la información actualizada',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, cancelar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.editMode = false;
        this.showEdit = true;
        if (this.notice.id > 0 ) {
          this.frmNotice.controls['title'].setValue(this.notice.title);
          this.frmNotice.controls['description'].setValue(this.notice.description);
          this.frmNotice.controls['registerDate'].setValue(this.notice.registerDate);
          this.frmNotice.controls['groups'].setValue(this.notice.groups);
        }
      }
    });
  }


}
