import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';

import { HttpService } from '../security/http.service';
import { Appsettings} from '../../configuration/appsettings';

// Modelo
import { Section } from '../../models/content/section.model';
@Injectable({
  providedIn: 'root'
})
export class SectionService {

  constructor( private _httpService: HttpService) { }


  getAll() {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/coursesections`;


    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }


  save( section: Section ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/coursesections/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');


   return this._httpService.post(API_URL, JSON.stringify( section ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  update( course ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/coursesections/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');



   return this._httpService.put(API_URL, JSON.stringify( course ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  getSectionDetail( idSection: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/coursesections/${idSection}/detail`;

    return this._httpService.get(API_URL )
                            .map( res => res.json());
  }

  getContentByIdCourse( idCourse: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/coursesections/${idCourse}/getByCourse`;

    return this._httpService.get(API_URL )
                            .map( res => res.json());

  }

  // Eliminar una sección de un curso
  delete( sectionId: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/coursesections?id=${sectionId}`;
    return this._httpService.delete(API_URL)
                           .map( res => res.json());
  }
}
