export class Author {
    id: number;
    fullName: string;
    lastName: string;
    comment: string;
    index: number;
    // constructor(_id: number, _fullName: string, _lastName: string, _comments: string) {
    //   this.id = _id;
    //   this.fullName = _fullName;
    //   this.lastName = _lastName;
    //   this.comments = _comments;
    // }
  }
