import { User } from './user.model';

export class ProfileUser
 {
  id: number;
  description: string;
  active: boolean;
  users: User[];
  linkedUsers: number[];

  constructor(_id: number, _description: string, _active: boolean, _users: User[], _linkedUsers: number[]) {
    this.id = _id;
    this.description = _description;
    this.active = _active;

    this.users = _users;
    this.linkedUsers = _linkedUsers;
  }
}
