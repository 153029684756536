import { State } from '../catalogs/state.model';
import { Role } from './role.model';
import { Level } from '../catalogs/level.model';
import { System } from '../catalogs/system.model';

export class User {
  id: number;
  fullName: string;
  birthday: Date;
  gender: string;
  state: State;
  email: string;
  role: Role;
  keycct: string;
  institution: string;
  semester: number;
  level: Level;
  subsystem: System;
  active: boolean;
  selected: boolean;
  urlImg: string;
  index: number;
  cityId: number;

  constructor(_id: number, _name: string, _email: string, _birthday: Date,
              _gender: string, _role: Role, _key: string, _stateId: string,
              _institution: string, _semester: number, _levelId: string,
              _subsystemId: string, _urlImg: string) {

                this.fullName = _name;
                this.email    = _email;
                this.birthday = _birthday;
                this.gender   = _gender;
                this.role     = _role;
                this.id       = _id;
                this.keycct   = _key;
                this.state = new State( parseInt( _stateId ), '');
                this.selected = false;
                this.urlImg = _urlImg;
                this.institution = _institution;
                this.semester = _semester;
                this.level = new Level(parseInt(_levelId), '', '', '');
                this.subsystem = new System(parseInt(_subsystemId), '', '', '');
  }

}
