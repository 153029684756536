import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../../service/service.index';

@Injectable({
  providedIn: 'root'
})
export class AdminHeaderService {

  title: string;
  breadcrumb: string[] = [];
  urlReturn: string[];
  notifications: any[];
  imgProfile: string;
  constructor( private _router: Router,
               private _storageSrv: StorageService) {

               }

  setTitle( title: string ): void {
    this.title = title;
  }

  setBreadcrumb( breadcrumb: string[] ): void {
    this.breadcrumb = breadcrumb;
  }

  clearBreadcrumb( ): void {
    this.breadcrumb = [];
  }

  setUrlBackButton( url: string[] ): void {
    this.urlReturn = url;
  }

  setNotifications() {
    this.notifications = JSON.parse(this._storageSrv.notifications);
  }

  onClickBackButton() {
    this._router.navigateByUrl( this.urlReturn[0] );
    this.urlReturn = [];
  }

  setImgProfile(): void {
    this.imgProfile = this._storageSrv.profileImage;
  }

}
