import { Component, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-studen-get-file',
  templateUrl: './studen-get-file.component.html',
  styleUrls: ['./studen-get-file.component.scss']
})
export class StudenGetFileComponent implements OnInit {

  task: any[] = [];
  files: any[] = [];
  @Input('task') set taskData(value) {
    this.task = value;
    this.files = value['FilesId'];
  }
  constructor() { }

  ngOnInit() {
  }

}
