// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  nameApp: 'SALI- LOCAL',
  apiUrl: 'http://localhost:60817',
  raUrl: 'https://recursosacademicos.azurewebsites.net',
  firebase:  {
    apiKey: 'AIzaSyC6pRUOdHuELne0QrpqIDqetPeLobTc4OM',
    authDomain: 'sali-2.firebaseapp.com',
    databaseURL: 'https://sali-2.firebaseio.com',
    projectId: 'sali-2',
    storageBucket: 'sali-2.appspot.com',
    messagingSenderId: '753653057085'
  }
};
