import { Component, OnInit, Inject, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatCheckbox } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import {
  TreeviewItem,
  TreeviewConfig,

} from '../../../../node_modules/ngx-treeview/src';

import { StructureService,
         CatalogService,
         ContentService,
         NodeService} from '../../service/service.index';


// Models
import { Content } from '../../models/content/content.model';
import { NodeContent } from '../../models/content/nodeConents.model';
import { Node } from '../../models/content/nodes.model';

import swal from 'sweetalert2';
import { TreeUtilities } from '../../shared/tree.utilities';
import { Appsettings } from '../../configuration/appsettings';



@Component({
  selector: 'app-dialog-structure',
  templateUrl: './dialog-structure.component.html',
  styleUrls: ['./dialog-structure.component.scss']
})
export class DialogStructureComponent implements OnInit {

  items: TreeviewItem[] = [];
  nodos: any[] = [];
  structure: any[] = [];

  routesDetail: any[] = [];
  contentNode: any[] = [];

  working: boolean;
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: true,
    maxHeight: 350
  });

  @ViewChildren('checkboxMultiple') private checkBoxNodes: QueryList<any>;

  constructor(
    public dialogRef: MatDialogRef<DialogStructureComponent>,
    public _svrStructure: StructureService,
    public _srvCatalogS: CatalogService,
    public _srvContent: ContentService,
    private _srvNode: NodeService,
    @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    this.getNodes();
    setTimeout(() => {
      for ( let n of  this.routesDetail) {
        this.resetChecked(n.nodeId);
      }
    }, 1500);
  }

  // Evento para cerrar la venta modal
  onCloseConfirm(): void {



   for ( const detail of this.routesDetail ) {
    if ( !detail.node ) {
      const node = this.structure.filter( x => x.id === detail.nodeId)[0];

      if ( node ) {
      const nodeContent = new Node( node.active, node.description,  node.id , '',
                                    node.nodeTypeId,  node.parentId, node.urlImage );
        detail.node = node;
      }
    }
   }

    this.dialogRef.close( this.routesDetail );


  }

  // Actualizar el estatus del checkbox
  onUpdateCheked( nodeId: number, cheked: boolean) {
    let treeNodeItem = TreeUtilities.findElementTree(this.items[0], nodeId);

    if ( treeNodeItem ) {
      treeNodeItem.checked = cheked;

      treeNodeItem.value.checked = cheked;
      treeNodeItem.collapsed = true;
      treeNodeItem.correctChecked();
      // this.items[0].correctChecked();
    }
  }


  // Obtiene los datos del servicio
  getNodes(): void {
    this.working = true;

    this._srvNode.getStructureByPatent( 0 ).subscribe(
      res => {
        console.log( res );
        if (res.data) {
          for (const nodo of res.data) {
            let checkedNode = false;

            if (this.data.content) {
              for (const n of this.data.content) {
                if (n.node.id === nodo.id) {
                  checkedNode = true;
                }
              }
            }

            this.structure.push(nodo);
            this.nodos.push({
              text: nodo.name,
              value: nodo.id,
              collapsed: false,
              checked: checkedNode,
              parentId: nodo.parentId
            });
          }

          this.items.push( TreeUtilities.buildHierarchy(this.nodos) );

          if ( this.data.content ) {
            for (const content of this.data.content) {
              const node = {
                id: content.id,
                nodeId: content.node.id,
                description: content.node.name,
                path: content.nodeRoute || content.path,
                routes: []
              };
              this.routesDetail.push(node);
              this.contentNode.push(node);
            }
          }
        }
        this.working = false;
      },
      err => {
        this.working = false;
      },
      () => {
        setTimeout(() => {
          for ( let n of  this.contentNode) {
            this.resetChecked(n.nodeId);
          }
        }, 1000 );
      }
    );
  }

  // Obtiene toda la ruta de los elementos seleccionados
  getRoute(treeItem: any): void {

      let child = this.structure.filter(r => r.id === treeItem.value)[0];
      let parentId = child.parentId;
      let router: any[] = [];
      let path = '';

      router.push(child);

      while (parentId > 0) {
        child = this.structure.filter(r => r.id === parentId)[0];
        router.push(child);
        parentId = child.parentId;
      }

      if (router.length) {
        router = router.reverse();
        path = router.map(r => r.description).join('/');
        const node = {
          nodeId: treeItem.value,
          description: treeItem.text,
          path: path,
          routes: router
        };

        const assign = this.contentNode.filter(n => n.nodeId === treeItem.value);
        if (assign.length) {
          node['id'] = assign[0].id;
        }

        this.routesDetail.push(node);
      }
  }

  // Asigna un contenido a los elementos seleccionados
  onSaveAssign(treeItem: any): void {

    const contentNode = new NodeContent();
    contentNode.nodeId    = treeItem.value;
    contentNode.contentId = this.data.id;
    contentNode.nodeRoute = TreeUtilities.getFullPathNode(treeItem.value, this.structure);

    this.working = true;

    this._srvContent.assignContent(contentNode).subscribe(
      res => {
        if (res.success) {

          const node = this.structure.filter( x => x.id === treeItem.value )[0];
          const nodeContent = new Node( node.active, node.description,  node.id , '',
                                        node.nodeTypeId,  node.parentId, node.urlImage );

          contentNode.id   = res.data.id;
          contentNode.node = nodeContent;
          this.contentNode.push(contentNode);
          this.getRoute(treeItem);

          swal('Asignación realizada', 'La asignación del contenido fue exitosa', 'success');

        } else {
          swal('Tuvimos problemas', res.message, 'error');
        }

        this.working = false;
      },
      err => {
        this.working = false;
      }
    );

  }

  // Eliminar una asígnación del nodo
  removeNode( nodeId: number ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!'
    }).then((result) => {
      if (result.value) {
        const nodeDelete = this.routesDetail.filter( x => x.nodeId === nodeId );
        if (nodeDelete.length && nodeDelete[0].id > 0) {
          this.working = true;
          this._srvContent.removeAssignContent(nodeDelete[0].id).subscribe(
            res => {
              if (res.success) {
                this.routesDetail = this.routesDetail.filter( x => x.nodeId !== nodeDelete[0].nodeId   );
                swal( Appsettings.APP_NAME, 'Se eliminó la asignación al contenido', 'success' );
              } else {
                swal(Appsettings.APP_NAME, 'Error al intentar eliminar la asignación', 'error');
              }
              this.working = false;
            },
            err => {
              this.working = false;
            }
          );
        } else {
           this.routesDetail = this.routesDetail.filter( x => x.nodeId !==  nodeId   );
           swal('Asignación eliminada', 'Se eliminó la asignación al contenido', 'success' );
        }
      } else {
        this.onUpdateCheked( nodeId, true );
      }
    });
  }

  select(item: TreeviewItem, checked: boolean ) {

    if ( checked ) {

      let nodeExist = this.routesDetail.filter( x=> x.nodeId == item.value.value)[0];

      if ( nodeExist ) {
        this.removeNode(item.value.value);
        this.onUpdateCheked( item.value.value, false);
      } else {
        this.onSaveAssign(item.value);

      }
    } else {
      this.removeNode(item.value.value);
    }
  }

  resetChecked(nodeId: number): void {
    let checkBox = this.checkBoxNodes.filter( x => parseInt(x.id) === nodeId)[0];
    if ( checkBox ) {
      checkBox.checked = true;
      checkBox.indeterminate = false;
      this.onUpdateCheked( nodeId, true);
    }
  }

}
