import { Component, OnInit } from '@angular/core';

// SERVICES
import { GeneralEventService } from '../../../service/service.index';

// MODELS
import { GeneralEvent } from '../../../models/home/general-event.model';
import { Pagination } from '../../../models/general/pagination.model';
import { ActivatedRoute } from '@angular/router';

declare function setMinHeightByElement(selector): any;

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  events: GeneralEvent[] = [];
  working: boolean;
  id: number;
  eventSelected: GeneralEvent;

  constructor( private _srvEvent: GeneralEventService,
               private _route: ActivatedRoute ) { 
                this._route.params.subscribe( params => {
                  this.id = Number(params['id']);
            
                  this.working = true;
                  if ( this.id > 0) {
                    this._srvEvent.getById( this.id).subscribe( res => {
                                 
                      if ( res.success ) {
                        this.eventSelected = new GeneralEvent();
                        this.eventSelected.id = res.data.id;
                        this.eventSelected.description = res.data.description;
                        this.eventSelected.published = res.data.published;
                        this.eventSelected.eventDate = new Date(res.data.eventDate);
                        this.eventSelected.name = res.data.name;
                        this.eventSelected.urlImage = res.data.urlImage;
                        this.eventSelected.place = res.data.place;
                        this.eventSelected.hours = res.data.hours;
                      }
                    });
                  }
                  this.working = false;
                }, err => {
                  this.working = false;
                });

               }

  ngOnInit() {
    setMinHeightByElement('.container-home');
  }

}
