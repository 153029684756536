import { Component, OnInit, Input } from '@angular/core';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { Appsettings } from '../../configuration/appsettings';

// Modelos
import { ContentBook } from '../../models/content/contentBook.model';
import { Node } from '../../models/content/nodes.model';

// Servicios
import {NodeService} from '../../service/service.index';
// import { NodeService } from '../../service/publishing/node.service';

import { UserUtilities } from '../../shared/user.utilities';

@Component({
  selector: 'app-viewer-file',
  templateUrl: './viewer-file.component.html',
  styleUrls: ['./viewer-file.component.scss']
})



export class ViewerFileComponent implements OnInit {

  showFile: boolean;
  pageurl: SafeResourceUrl;
  selectedFileId: number;
  showContent: boolean;
  textSearch: string;
  showFileComponent: boolean;
  _dataFile: ContentBook[];
  _dataFileResource:  ContentBook[];
  _fullData: ContentBook[];
  _selected: ContentBook;


  get dataFile(): ContentBook[] {
    return this._dataFile;
  }

  @Input() selectedNode: any;
  @Input('dataFiles') set dataFiles(value: ContentBook[]) {
    if ( value.length > 0 ) {
      this._fullData = value;

      for ( let d of value ) {
        if ( d.urlContent) {
          let extension = UserUtilities.getExtensionToUrl(d.urlContent);
          d.canDisplay = extension.toLowerCase() === 'pdf' ? true : false;
          d.extension = extension;
        } else {
          d.canDisplay = false;
          d.canAccess = false;
        }

      }

      this._dataFile = value.filter( item => item.contentTypeId === 2);
      this._dataFileResource = value.filter( item => item.contentTypeId === 3);

      if ( this._dataFile.length > 0 ) {
        if ( this._dataFile[0].canDisplay ) {
          this.viewFile(this._dataFile[0].id, this._dataFile[0].canAccess);
          this.selectedFileId = this._dataFile[0].id;
          this._selected = this._dataFile[0];
        }
      } else if ( this._dataFileResource.length > 0) {
        if ( this._dataFileResource[0].canDisplay ) {
          this.viewFile(this._dataFileResource[0].id, this._dataFileResource[0].canAccess);
          this.selectedFileId = this._dataFileResource[0].id;
          this._selected = this._dataFileResource[0];
        }
      }
      this.showFile = true;

    } else {
      this.showFile = false;
    }
  }

  // Parametro para mostrar listado de recursos del maestro
  @Input('showList') set listResource ( value: boolean ) {
    this.showContent = null;
     this.showContent = value;
  }

  constructor( private _domSanitizer: DomSanitizer,
               private _svrNode: NodeService) {

                // console.log(this.pageurl);

                }

  ngOnInit() {
  }

  viewFile(idFile: number, canAccess: boolean) {
    this.showFileComponent = canAccess;
    this._selected = this._fullData.filter( x => x.id === idFile)[0];
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/contents/${idFile}/download`;
    this.pageurl = this._domSanitizer.bypassSecurityTrustResourceUrl(apiUrl);
  }

   // Realizar búsqueda
   onKeyUp(event) {
     if ( this.textSearch.length >= 3 || this.textSearch.length === 0 ) {
      this.textSearch = this.textSearch.toLowerCase();
      let filterData = this._fullData.filter( x => x.name.toLowerCase().indexOf(this.textSearch) > -1 );
      this._dataFile = filterData.filter( item => item.contentTypeId === 2);
      this._dataFileResource = filterData.filter( item => item.contentTypeId === 3);
     }
  }

}
