import { Component, OnInit } from '@angular/core';;
import { MatDialog } from '@angular/material';

// Modelos
import { BookTeacher } from '../../../../models/catalogs/bookTeacher.model';
import { Pagination } from '../../../../models/general/pagination.model';

// Dialogos
import { DialogAdminBooksComponent } from '../../../../dialogs/dialog-admin-books/dialog-admin-books.component';
// Servicios
import { BookService } from '../../../../service/service.index';
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';



import swal from 'sweetalert2';
import { Appsettings } from '../../../../configuration/appsettings';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit {
  working: boolean;
  books: BookTeacher[] = [];

  textSearch: string = '';
  lastTextSearch: string = '';
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor(  public dialog: MatDialog,
                private _srvBook: BookService,
                private _srvHeader: AdminHeaderService ) {

                this.setPage( { page: 1, size: this.sizePage } );
                this._srvHeader.setTitle('Administración de libros');
  }

  ngOnInit() {
    this._srvHeader.setTitle('Administración de libros');
  }
  // Crea un nuevo libro
  onCreate() {
    let book = new BookTeacher();
    book.name = '';
    book.description = '';
    book.authorId = [];
    book.isbn = null;
    book.id = 0;
    book.codebar = '';
    book.edition = '';
    const DIALOG_REF = this.dialog.open( DialogAdminBooksComponent, {
      width: '600px',
      height: '700px',
      autoFocus: false,
      disableClose: true,
      data: { book: book}
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      if ( response ) {
        this.setPage( { page: 1, size: this.sizePage } );
      }
    });
  }

  // Edita un libro
  onEditItem( item: BookTeacher ) {
    let book = new BookTeacher();
    book.name = item.name;
    book.description = item.description;
    book.authorId = item.authorId;
    book.isbn = item.isbn;
    book.id = item.id;
    book.codebar = item.codebar;
    book.edition = item.edition;
    const DIALOG_REF = this.dialog.open( DialogAdminBooksComponent, {
      width: '600px',
      height: '700px',
      autoFocus: false,
      disableClose: true,
      data: { book: book}
    });

    DIALOG_REF.afterClosed().subscribe( response => {
      if ( response ) {
        this.setPage( { page: 1, size: this.sizePage } );
      }
    });
  }

  // Elimina un libro
  onDeleteItem( bookId: number) {
    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((res) => {
      this.working = true;
      if (res.value) {
        this._srvBook.delete( bookId ).subscribe( result => {
          if ( result.success ) {
            swal( Appsettings.APP_NAME, result.message , 'success');
            this.setPage( { page: 1, size: this.sizePage } );
          } else {
            swal( Appsettings.APP_NAME, result.message, 'warning');
          }
          this.working = false;
        });
      }
    });
  }

  // Evento del buscador
  onKeyUp( event ) {
    if ((this.textSearch.length >= 4 || this.textSearch.length === 0) && this.textSearch !== this.lastTextSearch) {
      this.lastTextSearch = this.textSearch;
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Funcion para cambiar la página
  setPage( paginationData: object ) {

    this.working = true;

    this._srvBook.getAll( Number(paginationData['size']), Number(paginationData['page']) , this.textSearch ).subscribe( result => {      
      this.books = [];
      if ( result.success ) {
        let index = 1;

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let b of result.data) {
          let authorId = b.authors;
          let authorName = 'Anonimo';

          if ( authorId ) {
            authorName = '';
            let fullName: string[] = [];
            for ( let au of authorId ) {
              fullName.push( au.fullName);
            }
            authorName = fullName.join(', ');
          }
          let book = new BookTeacher();
          book.name = b.name;
          book.description = b.description;
          book.authorId = b.authors;
          book.id = b.id;
          book.codebar = b.codebar;
          book.edition = b.edition;
          book.isbn = b.isbn;
          book.authorName = authorName;
          book.index= this.pagination.getIndexRegister(index);
          this.books.push( book );

          index++;
        }
      }
      this.working = false;
    });
  }


}
