import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

// Modelos
import { StudenHomework } from '../../../models/courses/studenHomework.model';

// Servicios
import { HomeworksService  } from '../../../service/service.index';

import { DialogStudenHomeworkComponent } from '../../../dialogs/dialog-studen-homework/dialog-studen-homework.component';
import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-homework-ratings',
  templateUrl: './homework-ratings.component.html',
  styleUrls: ['./homework-ratings.component.scss']
})
export class HomeworkRatingsComponent implements OnInit {

  working: boolean;
  readOnly: boolean;
  examScheduleStatus: boolean;
  studentsHomework: StudenHomework[] = [];
  homeworkId: number;
  statusHomework: boolean;
  homeworkName: string;
  homeworkDescription: string;

  // Obtiene el listado de los alumnos
  @Input('students') set student ( value) {
    this.studentsHomework = value;
  }
  // Obtiene el id de la tarea
  @Input('homeworkId') set idHomework ( value ) {
    this.homeworkId = value;
  }
  // Obtiene el status de la tarea
  @Input('statusHomework') set homeworkStatus ( value ) {
    this.examScheduleStatus = value;
  }
  // Obtiene el nombre de la tarea
  @Input('homeworkName') set nameHomework ( value ) {
    this.homeworkName = value;
  }
  // Obtiene la descripcion de la tarea
  @Input('homeworkDescription') set descriptionHomework ( value ) {
    this.homeworkDescription = value;
  }
  constructor(  private _srvHomework: HomeworksService,
                private _dialog: MatDialog) {
    this.working = false;
    this.readOnly = false;
  }

  ngOnInit() {
  }

  // Actualiza el estatus de la tarea
  onChangeExamScheduleStatus( event ) {
    this.working = true;
    let status = {
      'homeWorkId': this.homeworkId,
      'status': event
    };

    this._srvHomework.updateStatusHomwork( status ).subscribe( result => {
      if ( result.success ) {
        swal( Appsettings.APP_NAME, result.message, 'success');
        this.working = false;
      } else {
        swal( Appsettings.APP_NAME, result.message, 'error');
        this.working = false;
      }
      this.working = false;
    });
  }

  // Guarda las calificacones de un grupo
  sendData() {
    this.working = true;
    let homework = [];
    let studentsHomework = {};
      for (let h of this.studentsHomework ) {
        homework.push({
          'homeWorkAnswerId': h.id,
          'userId': h.userId,
          'score': h.score,
          'comment': h.comment
        });
      }

      studentsHomework = {
        'homeWorkId': this.homeworkId,
        'scores': homework
      };

    this._srvHomework.saveQualifyGroup(studentsHomework).subscribe( result => {
      if ( result.success ) {
        swal(Appsettings.APP_NAME, result.message, 'success');
        this.working = false;
      } else {
        swal(Appsettings.APP_NAME, result.message, 'error');
        this.working = false;
      }
    });
  }

  // Muestra modal archivos de tarea
  onClickShowFiles( item ) {
    const DIALOG_REF = this._dialog.open(DialogStudenHomeworkComponent, {
      width: '600px',
      height: '600px',
      autoFocus: false,
      disableClose: true,
      data: { files: item, homeworkName: this.homeworkName, homeworkDescription: this.homeworkDescription }
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      // console.log( response );
    });
  }
}
