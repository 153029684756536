import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule, XHRBackend, RequestOptions } from '@angular/http';

import {
  CatalogService,
  ContentService,
  NodeService,
  PublishingProfileService,
  AuthGuardService,
  HttpService,
  PermissionService,
  RoleService,
  UserService,
  StorageService,
  StructureService,
  SessionValidateService,
  CourseService,
  GroupService,
  StudenService,
  SectionService,
  ExamRatingService,
  PdfService,
  SessionService,
  GlossaryService,
  ContentTeacherService,
  HomeworksService,
  AuthorService,
  PagerService,
  AttendanceService,
  TagService,
  NewsHomeService,
  TipsHomeService,
  GeneralEventService,
  CityService,
  GeneralConferencesService,
  NoticeService
} from './service.index';


@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    CatalogService,
    ContentService,
    NodeService,
    PublishingProfileService,
    AuthGuardService,
    HttpService,
    PermissionService,
    RoleService,
    UserService,
    StorageService,
    StructureService,
    SessionValidateService,
    CourseService,
    GroupService,
    StudenService,
    SectionService,
    ExamRatingService,
    PdfService,
    SessionService,
    GlossaryService,
    ContentTeacherService,
    HomeworksService,
    AuthorService,
    PagerService,
    TagService,
    NewsHomeService,
    TipsHomeService,
    GeneralEventService,
    AttendanceService,
    CityService,
    GeneralConferencesService,
	NoticeService,
    {
      provide: HttpService,
      useFactory: (
        backend: XHRBackend,
        options: RequestOptions,
        storageService: StorageService,
        session: SessionService
        ) => {
        return new HttpService(backend, options, storageService, session);
      },
      deps: [XHRBackend, RequestOptions, StorageService, SessionService]
    }
  ],
  declarations: []
})
export class ServiceModule { }
