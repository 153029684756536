import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-add-thread',
  templateUrl: './dialog-add-thread.component.html',
  styleUrls: ['./dialog-add-thread.component.scss']
})
export class DialogAddThreadComponent implements OnInit {

  constructor(  private dialogRef: MatDialogRef<DialogAddThreadComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

    // Cierre del modal
    onCloseConfirm( event: boolean) {
      this.dialogRef.close( event );
    }


}
