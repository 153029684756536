export class Thread {
  id: number;
  title: string;
  description: string;
  creatorRol: string;
  votes: number;
  totalPosts: number;
  views: number;
  bestAnswer: boolean;
  courseName: string;
  courseSubject: string;
  initDate: Date;
  creator: string;
  avatar: string;
  creatorEmail: string;
  groups: string[];
  owner: boolean;
  canLike: boolean;
  process: boolean;
}
