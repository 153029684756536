import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CourseNavService } from '../../../components/layouts/publication/course-nav/course-nav.service';
import { BookNavService } from '../../../components/layouts/publication/book-nav/book-nav.service';
import { StorageService } from '../../../service/service.index';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent implements OnInit {


  nodeId: number;

  constructor( private _route: ActivatedRoute,
               public _bookNavService: BookNavService,
               public _courseNavService: CourseNavService,
               private _svrStorage: StorageService ) { }

  ngOnInit() {

    if (this._svrStorage.rol === 'Profesor') {
      this._bookNavService.showMenu = true;
      this._bookNavService.showContentTab = true;
    }

    if (this._svrStorage.rol === 'Alumno') {
      this._bookNavService.showCourseTab = true;
    }

    this._route.params.subscribe(params => { 

      this.nodeId =  Number(params['id']);
      this._bookNavService.setNode(   this.nodeId  );
      this._courseNavService.setCourse(  this.nodeId  , 0 );
     
    });

  }

}
