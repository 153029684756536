import { Injectable } from '@angular/core';
import { PersistenceService, StorageType } from 'angular-persistence';
import { Router } from '@angular/router';

@Injectable()
export class StorageService {
  constructor( private persistenceService: PersistenceService,
    private router: Router) {

    }

    get token (): string {
      return this.persistenceService.get( 'token', StorageType.LOCAL);
    }

    set token (token: string) {
      this.persistenceService.remove('token');
      this.persistenceService.set('token', token, { type: StorageType.LOCAL, timeout: 3600000 });
    }

    get refreshToken (): string {
      return this.persistenceService.get( 'refreshToken', StorageType.LOCAL);
    }

    set refreshToken (refreshToken: string) {
      this.persistenceService.remove('refreshToken');
      this.persistenceService.set('refreshToken', refreshToken, { type: StorageType.LOCAL, timeout: 18000000 });
    }

    get name (): string {
      return this.persistenceService.get( 'name', StorageType.LOCAL);
    }

    set name (name: string) {
      this.persistenceService.remove('name');
      this.persistenceService.set('name', name, { type: StorageType.LOCAL });
     }

     get userName (): string {
       return this.persistenceService.get( 'username', StorageType.LOCAL );
     }

     set userName( username: string ) {
       this.persistenceService.remove('username');
       this.persistenceService.set( 'username', username,  { type: StorageType.LOCAL } );
     }

     get email (): string {
       return this.persistenceService.get( 'email', StorageType.LOCAL );
     }

     set email (email: string) {
       this.persistenceService.remove('email');
       this.persistenceService.set( 'email', email, { type: StorageType.LOCAL} );
     }

     get rol(): string {
       return this.persistenceService.get( 'rol', StorageType.LOCAL );
     }

     set rol (rolId: string) {
       this.persistenceService.remove('rol');
       this.persistenceService.set( 'rol', rolId, { type: StorageType.LOCAL } );
     }

    get changePassword (): boolean {
      return this.persistenceService.get('changePassword', StorageType.LOCAL);
    }

    set changePassword (changePassword: boolean) {
      this.persistenceService.remove('changePassword');
      this.persistenceService.set( 'changePassword', changePassword, { type: StorageType.LOCAL} );
    }

    get permissions (): string {
      return this.persistenceService.get('permissions', StorageType.LOCAL);
    }

    set permissions ( permissions: string ) {
      this.persistenceService.remove('permissions');
      this.persistenceService.set( 'permissions', permissions, { type: StorageType.LOCAL} );
    }

    get nodes (): string {
      return this.persistenceService.get('nodes', StorageType.LOCAL);
    }

    set nodes ( nodes: string ) {
      this.persistenceService.remove('nodes');
      this.persistenceService.set( 'nodes', nodes, { type: StorageType.LOCAL} );
    }

    get profileImage (): string {
      return this.persistenceService.get('profileImage', StorageType.LOCAL);
    }

    set profileImage ( profileImage: string ) {
      this.persistenceService.remove('profileImage');
      this.persistenceService.set( 'profileImage', profileImage, { type: StorageType.LOCAL} );
    }

    get notifications (): string {
      return this.persistenceService.get('notifications', StorageType.LOCAL);
    }

    set notifications ( notifications: string ) {
      this.persistenceService.remove('notifications');
      this.persistenceService.set( 'notifications', notifications, { type: StorageType.LOCAL} );
    }

    get tokenMessage (): string {
      return this.persistenceService.get('tokenMessage', StorageType.LOCAL);
    }

    set tokenMessage ( tokenMessage: string ) {
      this.persistenceService.remove('tokenMessage');
      this.persistenceService.set( 'tokenMessage', tokenMessage, { type: StorageType.LOCAL} );
    }

    get systemId (): number {
      return this.persistenceService.get('systemId', StorageType.LOCAL);
    }

    set systemId ( systemId: number ) {
      this.persistenceService.remove('systemId');
      this.persistenceService.set( 'systemId', systemId, { type: StorageType.LOCAL} );
    }

     loggedIn (): boolean {
      if (this.token === undefined || this.token === '') {
        return false;
      } else {
        return true;
      }
     }

     resetLocalData(): void {
        this.persistenceService.removeAll(StorageType.LOCAL);
     }

     logoutRedirect(): void {
      this.resetLocalData();
      this.router.navigateByUrl('/');
     }

     get breadRemenber (): string {
      return this.persistenceService.get( 'persistenceBread', StorageType.LOCAL );
     }

     set breadRemenber (bread: string) {
      this.persistenceService.set( 'persistenceBread', bread, { type: StorageType.LOCAL } );
    }

    get selectedNode (): number {
      return this.persistenceService.get( 'selectedNode', StorageType.LOCAL );
     }

     set selectedNode (selectedNode: number ) {
      this.persistenceService.set( 'selectedNode', selectedNode, { type: StorageType.LOCAL } );
    }

    get selectedUnit (): number {
      return this.persistenceService.get( 'selectedUnit', StorageType.LOCAL );
     }

     set selectedUnit (selectedUnit: number ) {
      this.persistenceService.set( 'selectedUnit', selectedUnit, { type: StorageType.LOCAL } );
    }
}
