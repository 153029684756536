import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../configuration/appsettings';
import { FileHolder } from 'angular2-image-upload';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

// MODELS
import { FileContent } from '../../models/catalogs/file.model';
import { GeneralEvent } from '../../models/home/general-event.model';

// SERVICEs
import { GeneralEventService } from '../../service/service.index';
import swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-general-event',
  templateUrl: './dialog-general-event.component.html',
  styleUrls: ['./dialog-general-event.component.scss']
})
export class DialogGeneralEventComponent implements OnInit {

  frmEvent: FormGroup;
  working: boolean = false;
  isChangeData: boolean = false;

  fileContent: File;
  fileContentBase: string;
  filenName: string;
  isUpdateFile: boolean;

  @ViewChild('forEvent') formNews;

  constructor(  public dialogRef: MatDialogRef<DialogGeneralEventComponent>,
                public _srvEvent: GeneralEventService,
                @Inject(MAT_DIALOG_DATA) public data: GeneralEvent) {

                  this.frmEvent =  new FormGroup({
                    'id' : new FormControl(  this.data.id , [ Validators.required ]),
                    'name': new FormControl( this.data.name, [ Validators.required]),
                    'place': new FormControl( this.data.place, [ Validators.required]),
                    'eventDate': new FormControl( this.data.eventDate, [ Validators.required]),
                    'description': new FormControl( this.data.description, [ Validators.required]),
                    'published': new FormControl( this.data.published, [ Validators.required]),
                    'hours': new FormControl(this.data.hours, [ Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)]),
                    'file': new FormControl('')
                  });
  
                  if ( this.data.urlImage != null  && this.data.id > 0) {
                      this.filenName = 'archivo';
                      this.frmEvent.controls['file'].setValue( this.data.urlImage );
                      this.isUpdateFile = false;
                  }

                 }

  ngOnInit() {
  }

  // Evento para cerrar la modal
  onCloseConfirm() {
    this.dialogRef.close(this.isChangeData);
  }

    // Evento para agregar o editar una noticia
    onSubmit() {

      this.working = true;
      let fileUpload = new FileContent();
      const generalEvent = new GeneralEvent();
  
      if ( this.fileContent ) {
  
        const arrayFileBase = this.fileContentBase.split(',');
  
        fileUpload = new FileContent(
          arrayFileBase[1],
          this.fileContent.type,
          this.fileContent.name,
          arrayFileBase[0]
        );
      }
  
      generalEvent.id =  this.data.id;
      generalEvent.name =   this.frmEvent.controls['name'].value;
      generalEvent.description =   this.frmEvent.controls['description'].value;
      generalEvent.place =   this.frmEvent.controls['place'].value;
      generalEvent.eventDate =   this.frmEvent.controls['eventDate'].value;
      generalEvent.published =   this.frmEvent.controls['published'].value;
      generalEvent.hours =   this.frmEvent.controls['hours'].value;
      generalEvent.fileContentBase64 = fileUpload.content;
      generalEvent.filename = fileUpload.name;
  
      if ( generalEvent.id > 0) {
        this.update( generalEvent );
      } else {
        this.save( generalEvent );
      }
    }
  
    // Evento para remover el archivo que se subio
    onRemoved(event: FileHolder) {
      this.fileContentBase = '';
      this.fileContent = null;
      this.frmEvent.controls['file'].setValue('');
      this.filenName = '';
    }
  
    // Inicializar los valorea al asignar el archivo
    onUploadFinished(event: FileHolder) {
      this.fileContentBase = event.src;
      this.fileContent     = event.file;
      this.filenName       = this.fileContent.name;
      this.isUpdateFile    = true;
      this.frmEvent.controls['file'].setValue(event.file.name);
    }
  
    onUploadStateChanged( event ) {
    }
  
    // Guardar los datos de una noticia existente
    save( generalEvent: GeneralEvent ): void {
      this._srvEvent.save(generalEvent).subscribe( res => {
        if ( res.success ) {
          this.isChangeData = true;
          this.onCloseConfirm();
          swal( Appsettings.APP_NAME,  res.message, 'success');
        } else {
          swal( Appsettings.APP_NAME,  res.message, 'error');
        }
        this.working = false;
      }, err => {
        this.working = false;
      });
    }
  
    // Actualizar los datos de una noticia existente
    update( generalEvent: GeneralEvent ): void {
      this._srvEvent.update( generalEvent ).subscribe( res => {
        if ( res.success ) {
          this.isChangeData = true;
          this.onCloseConfirm();
          swal( Appsettings.APP_NAME,  res.message, 'success');
        } else {
          swal( Appsettings.APP_NAME,  res.message, 'error');
        }
        this.working = false;
      }, err => {
        this.working = false;
      });
    }

}
