export class NodeTotal {

    id: number;
    name: string;
    description: string;
    panrentId: number;
    nodeTypeId: number;
    totalVideo: number;
    totalAudio: number;
    totalImage: number;
    totalDocument: number;
    total: number;

}
