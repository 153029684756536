import { Routes, RouterModule } from '@angular/router';

// COMPONENTS
import { SaliLayoutComponent } from './sali-layout.component';
import { LevelComponent } from './level/level.component';
import { BookComponent } from './book/book.component';
import { ListExamComponent } from './list-exam/list-exam.component';
import { UserProfileDataComponent } from './profile/user-profile-data/user-profile-data.component';
import { GenerateGlossaryComponent } from './generate-glossary/generate-glossary.component';
import { GlossaryListComponent } from './glossary-list/glossary-list.component';
import { ApplyExamComponent } from './apply-exam/apply-exam.component';
import { CalendarComponent } from './calendar/calendar.component';
import { MessagesComponent } from './messages/messages.component';
import { ContentComponent } from './content/content.component';
import { ForumComponent } from './forum/forum.component';
import { PostComponent } from './post/post.component';
import { GenerateNoticeComponent } from './generate-notice/generate-notice.component';
import { NoticeListComponent } from './notice-list/notice-list.component';

// Servicios
import { AuthGuardService } from '../../service/security/auth-guard.service';
import { RelatedStudentsComponent } from './related-students/related-students.component';
import { InventoryCourseComponent } from './inventory-course/inventory-course.component';


const AppPageSali: Routes = [
  {
    path: 'sali',
    component: SaliLayoutComponent,
    children: [
        { path: 'admin-exams', component: ListExamComponent, canActivate: [AuthGuardService] },
        { path: 'apply-exam/:examId/:examScheduleId/:nodeId', component: ApplyExamComponent},
        { path: 'book/:id/:accessType', component: BookComponent, canActivate: [ AuthGuardService ]},
        { path: 'calendar', component: CalendarComponent },
        { path: 'generate-glossary/:id', component: GenerateGlossaryComponent},
        { path: 'level', component: LevelComponent, canActivate: [ AuthGuardService ]},
        { path: 'list-glossary', component: GlossaryListComponent},
        { path: 'repository', component: ContentComponent},
        { path: 'userprofile', component: UserProfileDataComponent },
        { path: 'messages', component: MessagesComponent, canActivate: [ AuthGuardService ]},
        { path: 'forum', component: ForumComponent, canActivate: [ AuthGuardService ]},
        { path: 'post/:id', component: PostComponent, canActivate: [ AuthGuardService ]},
        { path: 'related-students', component: RelatedStudentsComponent, canActivate: [ AuthGuardService ]},
        { path: 'inventory-course', component: InventoryCourseComponent },
		{ path: 'generate-notice/:id', component: GenerateNoticeComponent},
        { path: 'list-notice', component: NoticeListComponent},
        { path: '', redirectTo: '/level', pathMatch: 'full'},
    ]
  }
];
export const SALI_ROUTES = RouterModule.forChild( AppPageSali );
