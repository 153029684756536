import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

// DIALOGS COMPONENTS
import { DialogContentComponent } from '../../../../dialogs/dialog-content/dialog-content.component';
import { DialogStructureComponent } from '../../../../dialogs/dialog-structure/dialog-structure.component';

// COMPONENT SERVICE
import { SitemapService } from '../../../../core/sitemap/sitemap.service';
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { Content } from '../../../../models/content/content.model';
import { ContentType } from '../../../../models/content/conentType.model';
import { FileType } from '../../../../models/content/fileType.model';
import { Pagination } from '../../../../models/general/pagination.model';
import { ResourceType } from '../../../../models/catalogs/resourceType.model';
import { Tag } from '../../../../models/content/tag.model';

// SERVICES
import { ContentService, CatalogService } from '../../../../service/service.index';

import swal from 'sweetalert2';
import { Appsettings } from '../../../../configuration/appsettings';
import { DialogContentLargeComponent } from '../../../../dialogs/dialog-content-large/dialog-content-large.component';

@Component({
  selector: 'app-content-register',
  templateUrl: './content-register.component.html',
  styleUrls: ['./content-register.component.scss']
})
export class ContentRegisterComponent implements OnInit {
  items: string[];
  working: boolean;
  content: Content[] = [];

  textSearch: string;
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  contentTypeId: number = 0;
  fileTypeId: number = 0;
  contentTypes: ContentType[] = [];
  fileTypes: FileType[] = [];
  resourceType: ResourceType[] = [];

  constructor(
    public _srvSiteMap: SitemapService,
    public dialog: MatDialog,
    private _srvCatalog: CatalogService,
    public _srvContent: ContentService,
    private _srvHeader: AdminHeaderService) {
    this._srvSiteMap.setSiteMap( [
      { 'description' : 'Administración', 'isCurrent' : false },
      { 'description' : 'ODA' , 'isCurrent' : true }]);

      this.initData();
   }

  ngOnInit() {
    this._srvHeader.setTitle('Administración de contenidos');
    this.setPage( { page:  this.page, size: this.sizePage });
  }

  // Evento para mostrar el formulario de registro de un contenido
  onClickNewContent(): void {

    const content    = new Content();
    content.isPublic = true;
    content.active   = true;

    // DialogContentComponent
    const DIALOG_REF = this.dialog.open( DialogContentLargeComponent, {
      width: '700px',
      minHeight: '500px',
      autoFocus: false,
      disableClose: true,
      data: content
    });

    DIALOG_REF.afterClosed().subscribe( res => {
        this.setPage( { page: this.page, size: this.sizePage });
    });

  }

  // Evento para mostar la estructura de arbol
  onClickAssignStructure( content ): void {
    const DIALOG_REF = this.dialog.open( DialogStructureComponent, {
      width: '950px',
      minHeight: '650px',
      autoFocus: false,
      disableClose: true,
      data: content
    });

    DIALOG_REF.afterClosed().subscribe( res => {
      this.setPage( { page: this.page, size: this.sizePage });
    });
  }

  // Evento que permite activar o desactivar el contenido
  onClickUpdateStatus( content ): void {

    const title = content.active ? 'Desactivar contenido' : 'Activar contenido' ;
    const titleButton = content.active ? '¡Si, desactivar ahora!' : '¡Si, activar ahora!' ;
    const subTitle = content.active ? 'Al momento de desactivar el contenido no se mostrará a los usuarios'
                                    : 'Al momento de activar el contenido será mostrado a los usuarios';
    swal({
      title: title,
      text: subTitle,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: titleButton,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.working = true;
        this._srvContent.updateStatus( content.id, !content.active ).subscribe( res => {

          if ( res.success ) {

            this.working = false;
            content.active = !content.active;

            swal(Appsettings.APP_NAME, 'El registro fue actualizado correctamente.', 'success' );
            this.setPage( { page: 1, size: this.sizePage });

          } else {
            this.working = false;
            swal( Appsettings.APP_NAME, res.message,  'error' );
          }
        }, err => {
          this.working = false;
          swal( Appsettings.APP_NAME, 'Problemas al actualizar el estatus del contenido',  'error' );
        });
      }
    });
  }

  // Evento que muestra el formulario de registro y los datos del contenido existente
  onClickEdit( content: Content ): void {

    const DIALOG_REF = this.dialog.open( DialogContentLargeComponent, {
      width: '700px',
      minHeight: '500px',
      autoFocus: false,
      disableClose: true,
      data: content
    });

    DIALOG_REF.afterClosed().subscribe((res) => {
      // this.setterEditedValues( content, res );
      if ( res ) {
        this.setPage( { page: this.page, size: this.sizePage });
      }
    });
  }

  // Evento para eliminar permanentemente un contenido
  onClickDelete ( content ): void {

    swal({
        title: '¿Estás seguro?',
          text: '¡Esta operación no se podrá revertir!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '¡Sí, eliminar ahora!',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.value) {

            this.working = true;
            this._srvContent.delete( content.id ).subscribe( res => {

              if ( res.success ) {

                this.working = false;
                content.active = !content.active;
                this.content = this.content.filter( x => x.id !== content.id );
                this.setPage( { page: this.page, size: this.sizePage });
                swal( Appsettings.APP_NAME, 'El registro fue eliminado correctamente.', 'success' );

              } else {
                this.working = false;
                swal(Appsettings.APP_NAME, res.message, 'error' );
              }
            }, err => {
              this.working = false;
              swal( Appsettings.APP_NAME, 'Problemas al eliminar del contenido',  'error' );
            });
          }
        });

  }

  // Filtrar por texto
  onKeyUp(event): void {
    if ( this.textSearch.length >= 3 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Evento para cambiar tipo de archivo
  onChangeTypeFilter(): void {
    this.setPage( { page: 1, size: this.sizePage });
  }

  // Función para obtener todos los contenidos registrados
  setPage( paginationData: object ): void {

    this.working = true;

    this._srvContent.getAllPagination( Number(paginationData['size']),
                                       Number(paginationData['page']),
                                       this.textSearch,
                                       this.contentTypeId,
                                       this.fileTypeId ).subscribe( res => {

      let content;
      this.content = [];
      let index = 1;
      this.page = Number(paginationData['page']);
      this.sizePage = paginationData['size'];
      if ( res.data ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        for ( const r of res.data ) {
          content = new Content();
          content.id = r.id;
          content.active = r.active;
          content.name = r.name;
          content.urlContent = r.urlContent;
          content.isPublic = r.isPublic;
          content.contentType = new ContentType( r.contentTypeId, r.contentTypeDesc ) ;
          content.fileType = new FileType( r.fileTypeId,  r.fileTypeDesc );
          content.activoDescription = r.active ? 'Activo' : 'Inactivo';
          content.content = r.content;
          content.areaId = r.area.id;
          content.formativeFieldId = r.formativeField.id;
          content.purposeId = r.purpose.id;
          content.trainingFieldId = r.trainingField.id;
          content.resourceTypeId = r.contentResourceType.id;
          content.description = r.description;
          content.thumbnails = r.thumbnails;
          content.index = this.pagination.getIndexRegister(index);
          content.formativeFieldId = r.formativeField.id;
          content.isRecommended = r.isRecommended;
          content.blockId = r.block.id;
          content.urlInteractive = r.urlContent;

          for ( let t of r.tags ) {
            let tag = new Tag();
            tag.id = t.id;
            tag.name = t.name;

            content.tags.push(tag);
          }

          this.content.push( content );
          index++;
        }

      } else {
        swal( Appsettings.APP_NAME , 'Error al obtener los datos, vulve a intentar más tarde', 'error' );
      }
      this.working = false;
    }, err => {
      this.working = false;
    });

  }

  // Asignar los nuevos datos del
  setterEditedValues( oldData: Content , newData: Content ): void {

    oldData.active = newData.active;
    oldData.activoDescription = newData.active ? 'Activo' : 'Inactivo';
    oldData.name = newData.name;
    oldData.urlContent = newData.urlContent;
    oldData.isPublic = newData.isPublic;
    oldData.contentType = newData.contentType ;
    oldData.fileType = newData.fileType;
    oldData.content = newData.content;
    oldData.areaId = newData.areaId;
    oldData.formativeFieldId = newData.formativeFieldId;
    oldData.purposeId = newData.purposeId;
    oldData.trainingFieldId = newData.trainingFieldId;
    oldData.resourceTypeId = newData.resourceTypeId;
    oldData.tags = newData.tags;

  }

  // Inicializar todos los catálogos utilizados
  initData(): void {
    this._srvCatalog.getFileTypesAll().subscribe(res => {
      this.fileTypes = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.fileTypes.push( new FileType( f.id, f.description ));
        }
      }
    });
    this._srvCatalog.getContentTypesAll().subscribe(res => {
      this.contentTypes = [];
      if (res.data) {
        for ( let f of res.data ) {
          this.contentTypes.push( new ContentType( f.id, f.description ));
        }
      }
    });
  }

}
