import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

// Modelos
import { Studen } from '../../../models/courses/student.model';
import { Rating } from '../../../models/courses/rating.model';

// Servicios
import { ExamRatingService, ExamScheduleService } from '../../../service/service.index';

import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';

import { DialogStudentAnswersComponent } from '../../../dialogs/dialog-student-answers/dialog-student-answers.component';
@Component({
  selector: 'app-exam-ratings',
  templateUrl: './exam-ratings.component.html',
  styleUrls: ['./exam-ratings.component.scss']
})
export class ExamRatingsComponent implements OnInit {
  students: Studen[] = [];
  ratings: Rating[] = [];
  examScheduleId: number;
  tipeExam: number;
  examScheduleStatus: boolean;
  readOnly: boolean = false;
  examName: string;
  // Obtenemos los estudiantes
  @Input('students') set student ( value) {
      this.students = value;
  }
  // Obtenemos el examScheduleId
  @Input('examId') set examId ( value: number) {
    this.examScheduleId = value;
  }

  @Input('typeExamId') set typeExamId ( value: number) {
    this.tipeExam = value;
  }

  @Input('examName') set nameExamen ( value: string) {
    this.examName = value;
  }

  @Input() examScheduleType: number;

  working: boolean;
  constructor( private _srvRating: ExamRatingService,
               private _srvExamSchedulte: ExamScheduleService,
               private _dialog: MatDialog ) {

  }

  ngOnInit() {
    if ( this.examScheduleType == 6) {
      this.readOnly = true;
      this.examScheduleStatus = true;
    }
  }

  sendData() {
    this.working = true;
    for ( let s of this.students) {
      let rating = new Rating();
      // userId = 39
      rating.userId = s.id;
      rating.correctAnswers = s.totalCorrect;
      rating.score = s.rating;
      rating.comments = s.commentary;
      rating.examScheduleId = this.examScheduleId;
      rating.testId = s.testId;
      this.ratings.push( rating);
    }
    this._srvRating.save( this.ratings ).subscribe( result => {
      if ( result.success ) {
        swal( Appsettings.APP_NAME, result.message, 'success' );
      } else {
        swal( Appsettings.APP_NAME, result.message, 'warning' );
      }
      this.working = false;
    });

  }

  onChangeExamScheduleStatus( event ) {

    swal({
      title: '¿Estás seguro de cerrar el examen?',
      text: 'Ya no podrá actualizar la calificación y se cerrará el examen',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, cerrar examen!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
          this.working = true;
          this._srvExamSchedulte.updateStatus( this.examScheduleId, 6).subscribe( res => {
            if ( res.success ) {
              swal(Appsettings.APP_NAME, 'Examen actualizado correctamente', 'success');
            } else {
              swal(Appsettings.APP_NAME, res.message , 'error');
            }
            this.working = false;
          }, err => {
            this.working = false;
          });
      } else {
        this.examScheduleStatus = false;
      }
    });
  }

  // Ver las respuestas del alumno
  onShowAnswers( studen ) {

    const DIALOG_REF = this._dialog.open(DialogStudentAnswersComponent, {
      width: '1000px',
      height: '700px',
      autoFocus: false,
      disableClose: true,
      data: { teacherExamId: this.examScheduleId, nameExam: this.examName, studenId: studen.id }
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      // (console.log( response );
    });
  }
}
