import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { HttpService } from '../security/http.service';
import { Appsettings} from '../../configuration/appsettings';

import { Group } from '../../models/courses/group.model';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor( private _httpService: HttpService) { }

  getAll(groupId: number) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/studentgroups/${groupId}/getbycourse`;


    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }

  // Permite guardar los nuevos datos de un grupo
  save( group: Group ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/studentgroups/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');


   return this._httpService.post(API_URL, JSON.stringify( group ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  // Actualizar los datos de un grupo ya existentes
  update( course ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/studentgroups/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');



   return this._httpService.put(API_URL, JSON.stringify( course ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  getStudenByEmail( email: string) {
    //  const emai = 'ramadrigalp@integraitsoluciones.com';
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/users/${email}/getbypartialmail`;

    return this._httpService.get(API_URL )
                            .map( res => res.json());

  }

  getStudenByCourse( courseId: number, examScheduleId: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/students/getbygroup?studentGroupId=${courseId}&examScheduleId=${examScheduleId}`;

    return this._httpService.get(API_URL )
                            .map( res => res.json());
  }

  // Eliminar los datos de un curso
  delete( courseId: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/studentgroups?id=${courseId}`;
    return this._httpService.delete(API_URL )
                            .map( res => res.json());

  }

}
