import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FileHolder } from 'angular2-image-upload';
import { TeacherResource } from '../../models/courses/teacherResource.model';
import { FileContent } from '../../models/catalogs/file.model';

import { TeacherResourceService } from '../../service/service.index';
import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';
import { GlossaryService } from '../../service/publishing/glossary.service';


@Component({
  selector: 'app-dialog-resource',
  templateUrl: './dialog-resource.component.html',
  styleUrls: ['./dialog-resource.component.scss']
})
export class DialogResourceComponent implements OnInit {

  frmResource: FormGroup;

  fileContent: File;
  fileContentBase: string;
  filenName: string;
  working: boolean;

  isUpdateFile: boolean;

  resourceTypes: any[] = [];

  constructor( public _dialogRef: MatDialogRef<DialogResourceComponent>,
               private _srvTeacherResource: TeacherResourceService,
               @Inject(MAT_DIALOG_DATA) public data ) {

                this.frmResource = new FormGroup( {
                  'id': new FormControl(this.data.id),
                  'name': new FormControl( this.data.name, [Validators.required] ),
                  'description': new FormControl( this.data.description),
                  'url': new FormControl( this.data.url ),
                  'resourceTypeId': new FormControl( 1, [Validators.required] ),
                  'file': new FormControl('', [Validators.required])
                 });

                 this.resourceTypes.push( { id: 1 , description: 'Imagen' });

              }

  ngOnInit() {
  }

  onCloseConfirm() {
    this._dialogRef.close();
  }

  onSubmit() {

    this.working = true;
    let fileUpload = new FileContent();
    const resource =  new TeacherResource();

    if ( this.fileContent ) {

      const arrayFileBase = this.fileContentBase.split(',');

      fileUpload = new FileContent(
        arrayFileBase[1],
        this.fileContent.type,
        this.fileContent.name,
        arrayFileBase[0]
      );
    }

    resource.name =  this.frmResource.controls['name'].value;
    resource.description =  this.frmResource.controls['description'].value;
    resource.teacherResourceId =  this.frmResource.controls['resourceTypeId'].value;
    resource.fileContentBase64 = fileUpload.content;
    resource.filename = fileUpload.name;

    this._srvTeacherResource.save(resource).subscribe( res => {
      if ( res.success ) {
         resource.url = res.data.url;
         resource.id = res.data.id;

         this.working = false;
         const toast = swal.mixin({
           toast: true,
           position: 'top-end',
           showConfirmButton: false,
           timer: 3000
         });

         toast({
           type: 'success',
           title: 'Recurso agregado correctamente'
         });

         this._dialogRef.close( resource );
      } else {
        swal(Appsettings.APP_NAME, res.message , 'error');
      }

      this.working = false;

    }, err => {
      this.working = false;
      swal(Appsettings.APP_NAME, 'Problemas al conectarse con el servidor', 'error');
    });
  }

  onRemoved(event: FileHolder) {
    this.fileContentBase = '';
    this.fileContent = null;
    this.frmResource.controls['file'].setValue('');
    this.filenName = '';
  }

  onUploadFinished(event: FileHolder) {

    this.fileContentBase = event.src;
    this.fileContent     = event.file;
    this.filenName       = this.fileContent.name;
    this.isUpdateFile    = true;

    this.frmResource.controls['file'].setValue(event.file.name);

  }

  onUploadStateChanged( event ) {
  }

}
