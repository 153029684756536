import { Injectable } from '@angular/core';

import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

@Injectable({
  providedIn: 'root'
})
export class ContentTeacherService {

  constructor(  private httpService: HttpService) { }

  // Obtiene los contenidos de un profesor
  getAll() {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/resources/teacher`;
    return this.httpService.get( API_URL )
                            .map( res => res.json());
  }

  // Elimina un contenido
  deletContent( contentId: number) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/resources?id=${ contentId }`;

    return this.httpService.delete(apiUrl)
                           .map( res => res.json());
  }

  // Guadarda un contenido nuevo
  save() {

  }

  // valida si el token se session esta activo
  validateToken() {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/users/validateSession`;
    return this.httpService.get( API_URL );
  }

  // Consulta los tipos de contenido
  getTypeContent() {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/resourcestypes`;
    return this.httpService.get( API_URL )
                            .map( res => res.json());
  }

  // Guarda la relacion recurso clase
  saveContentSection( filesId: any[], id: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/courseClasses/${id}/files`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    const params = {
      'id': id,
      'filesId': filesId
    };
    return this.httpService.post(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Elimina una ralacion clase-archivo
  deleteContentSection( relationId: number, filesId: any[] ) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/courseClasses/${relationId}/files`;

    const params = {
      'filesId': filesId
    };

    return this.httpService.delete(apiUrl, { params })
                           .map( res => res.json());
  }
}
