export class NewHome {
    id: number;
    title: string;
    description: string;
    urlImage: string;
    registerDate: Date;
    published: boolean;
    index: number;
    filename?: string;
    fileContentBase64?: string;

    constructor() {
        this.id = 0;
        this.title = '';
        this.description = '';
        this.urlImage = '';
        this.published = false;
    }
}
