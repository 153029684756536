import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { HttpService } from '../security/http.service';
import { Appsettings} from '../../configuration/appsettings';

import { Course } from '../../models/catalogs/course.model';
@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor( private _httpService: HttpService ) { }

  // Obtener todos los cursos
  getAll() {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/courses/teacher`;

    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }

  // Agregar un nuevo curso
  save( course: Course ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/courses/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

   return this._httpService.post( API_URL, JSON.stringify( course ),
                                 { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Actualizar los datos de un curso
  update( course: Course ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/courses/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

   return this._httpService.put(  API_URL, JSON.stringify( course ),
                                { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Eliminar los datos de un curso
  delete( id: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/courses?id=${id}`;
    return this._httpService.delete(API_URL )
                            .map( res => res.json());

  }

  // Obtener la relación de los estudiantes con el profesor
  getRelatedStudents() {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/relationship?id=0`;

    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }

  // Obtener la relación de los estudiantes con el profesor
  getRelatedStudentsPagination(pageSize: number, currentPage: number, filter: string, courseId: string, groupId: string ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/relationship/all?id=0&currentPage=${currentPage}&pageSize=${pageSize}&filter=${filter}&courseId=${courseId}&groupId=${groupId}`;

    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }

   // Obtener la relación de los estudiantes con el profesor
   getResourcesPagination( pageSize: number, currentPage: number, nodeId: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/resources/all?id=0&currentPage=${currentPage}&pageSize=${pageSize}&type=${nodeId}`;

    return this._httpService
        .get(API_URL)
        .map(res => res.json());
  }

}
