export class Studen {
  id: number;
  fullName: string;
  email: string;
  statusStudent: string;
  checkDate: Date;
  rating: number;
  commentary: string;
  totalCorrect: number;
  totalQuestions: number;
  testId: number;
  isAnswered: boolean;
  studentStatusId: number;

  day1: boolean;
  day2: boolean;
  day3: boolean;
  day4: boolean;
  day5: boolean;
  day6: boolean;
  day7: boolean;

  day1Active: boolean;
  day2Active: boolean;
  day3Active: boolean;
  day4Active: boolean;
  day5Active: boolean;
  day6Active: boolean;
  day7Active: boolean;

  day1Name: string;
  day2Name: string;
  day3Name: string;
  day4Name: string;
  day5Name: string;
  day6Name: string;
  day7Name: string;
  active: boolean;

  // constructor();
  // constructor(_id: number, _fullName: string, _email: string,  _statusStudent?: string, _checkDate?: Date);
  // constructor(_id?: number, _fullName?: string, _email?: string, _statusStudent?: string, _checkDate?: Date) {
  //   this.id = _id;
  //   this.fullName = _fullName;
  //   this.email = _email;
  //   this.statusStudent = _statusStudent;
  //   this.checkDate = _checkDate;
  // }
}
