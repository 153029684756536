import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, MatTableDataSource } from '@angular/material';

// Modelos
import { Glossary } from '../../../models/courses/glossary.model';
import { Word } from '../../../models/courses/word.model';

// Servicios
import { NodeService, GlossaryService } from '../../../service/service.index';

// Complementos
import { TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent, TreeItem } from 'ngx-treeview';
import { TreeUtilities } from '../../../shared/tree.utilities';
import swal from 'sweetalert2';

import { DialogBookGlossaryComponent } from '../../../dialogs/dialog-book-glossary/dialog-book-glossary.component';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { element } from 'protractor';
import { MatDialog } from '@angular/material';
import { Appsettings } from '../../../configuration/appsettings';

declare function setHeight(): any;

@Component({
  selector: 'app-generate-glossary',
  templateUrl: './generate-glossary.component.html',
  styleUrls: ['./generate-glossary.component.scss']
})
export class GenerateGlossaryComponent implements OnInit, OnDestroy {

  glossaryId: number;
  working: boolean;
  editMode: boolean = true;
  showForm: boolean;
  showAddBook: boolean;
  nodeId: number;
  glossary: Glossary;

  wordContent: Word[] = [];

  publications: any[] = [];
  selectedPublications: any[] = [];

  nodos: any[] = [];
  structure: any[] = [];

  frmWord: FormGroup;
  frmGlossary: FormGroup;

  // Arbol
  value;
  items: TreeviewItem[] = [];
  config = TreeviewConfig.create({
      hasFilter: true,
      hasCollapseExpand: true
  });


  displayedColumns: string[] = ['index', 'name', 'description'];
  dataSource = new MatTableDataSource<any>(this.wordContent);
  showBotton: boolean;
  showEdit: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(  private _route: ActivatedRoute,
                private _srvNode: NodeService,
                private _srvGlossary: GlossaryService,
                private _dialog: MatDialog,
                private _srvHeader: AdminHeaderService ) {
      this.showBotton = false;
      this.showAddBook = false;
      this.showEdit = false;

      // Generamos una nueva instancia del glosario
      this.glossary = new Glossary();


      // Obtenemos los elementos para el arbolito
      // this.getNodes();

      // Creamos los elementos del formulario para nuevo glosario
      this.frmGlossary = new FormGroup({
        id: new FormControl(0),
        name: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required])
      });

      // Cremos los elementos del formulario para palabras
      this.frmWord = new FormGroup({
        id: new FormControl(0),
        name: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required])
      });

      // Obtenemos los parametros de la ruta
      this._route.params.subscribe( params => {
        this.glossaryId = params['id'];
        if ( this.glossaryId > 0 ) {
          this.showBotton = true;
          this.showAddBook = true;
          this.editMode = true;
          this.showEdit = false;
          // Obtenemos los datos del glosario
          this.getDataGlossary();
        }



      });
   }

  ngOnInit() {
    setHeight();

    const title = this.glossaryId == 0 ? 'Crear nuevo glosario' : 'Editar glosario';
        this._srvHeader.setTitle(title);
        this._srvHeader.setUrlBackButton(['/sali/list-glossary', 'Mis glosarios']);

  }

  ngOnDestroy(): void {
    this._srvHeader.setUrlBackButton([]);
  }


  // Obtiene los datos y nodos de un glosario
  getDataGlossary() {
    this.publications = [];
    this._srvGlossary.getGlossaryById( this.glossaryId).subscribe( result => {
      if ( result.success ) {
        let glossary = result.data;
        if ( glossary.nodes.length > 0) {
          for ( let n of glossary.nodes) {
            this.publications.push({
                id: n.id,
                publication: n.name
            });
          }
        }
        if ( glossary.words.length > 0) {
          this.wordContent = [];
          let index = 1;

          for ( let w of glossary.words) {
 
            let words = new Word();
            words.id = w.id;
            words.name = w.name;
            words.description = w.description;
            words.index = index;

            this.wordContent.push( words );
            index++;
          }
          this.paginator.pageIndex = 0;
          this.dataSource = new MatTableDataSource<any>(this.wordContent);
          this.dataSource.paginator = this.paginator;
        }
        this.setForm( glossary);
        this.glossary.id = glossary.id;
        this.glossary.name = glossary.name;
        this.glossary.description = glossary.description;
        this.glossary.active = glossary.active;
      }
      this.working = false;
    });
  }
  // Setea los datos en el formulario para editarlos
  setForm( glossary ) {
    this.frmGlossary.controls['name'].setValue( glossary.name);
    this.frmGlossary.controls['description'].setValue( glossary.description);
  }

   // Activa los controles para editar un gloasrio
   onClickGlossary(): void {
    this.editMode = true;
    this.showEdit = false;
  }

  // Guarda el nuevo glosario
  onClickAddGlossary(): void {
    this.working = true;
    let glossary = new Glossary();
    glossary.id = this.glossaryId;
    glossary.description = this.frmGlossary.controls['description'].value;
    glossary.name = this.frmGlossary.controls['name'].value;
    glossary.active = true;
    this.showEdit = true;
    if ( this.glossaryId > 0 ) {
      this.updateGlossary( glossary);
    } else {
      this.saveGlossary( glossary );
    }
  }

  // Guarda un nuevo glosario
  saveGlossary( glossary: Glossary ) {


    this._srvGlossary.save( glossary ).subscribe( result => {
      if ( result.success) {
        this.showAddBook = true;
        this.editMode = false;
        this.showBotton = true;
        this.showEdit = true;
        this.glossaryId = result.data;
        this.glossary.id = result.data;
        this.glossary.name = this.frmGlossary.controls['name'].value;
        this.glossary.description = this.frmGlossary.controls['description'].value;
        swal(Appsettings.APP_NAME,  result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME,  result.message, 'error');
      }
      this.working = false;
    });
  }
  // Actualiza
  updateGlossary( glossary: Glossary ) {
    this._srvGlossary.update( glossary).subscribe( result => {
      if ( result.success) {
        this.glossary.name = this.frmGlossary.controls['name'].value;
        this.glossary.description = this.frmGlossary.controls['description'].value;
        this.editMode = false;
        this.showBotton = true;
        swal(Appsettings.APP_NAME,  result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME,  result.message, 'error');
      }
      this.working = false;
    });
  }
  // Se cancela la edicion del glosario
  onClicBankCancel() {
    swal({
      title: '¿Estás seguro?',
      text: 'Perderá la información actualizada',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, cancelar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.editMode = false;
        this.showEdit = true;
        if (this.glossary.id > 0 ) {
          this.frmGlossary.controls['name'].setValue(this.glossary.name);
          this.frmGlossary.controls['description'].setValue(this.glossary.description);

        }
      }
    });
  }

  // Muesta el formulari para capturar un nuevo glosario
  onClickAddConcepto() {
    this.showForm = true;
    this.showBotton = false;
  }


  // Agrega un nuevo concepto
  onClicAddConcept() {
    this.working = true;
    let id = this.frmWord.controls['id'].value;

    let word = new Word();
    word.id = id;
    word.name = this.frmWord.controls['name'].value;
    word.description = this.frmWord.controls['description'].value;
    word.glossaryId = this.glossaryId;

    if (  id > 0) {
      this.updateWord( word );
    } else {
      this.createWord( word );
    }

  }

  // Crea una nueva clase
  createWord( word: Word) {
    this._srvGlossary.addWord( this.glossaryId, word).subscribe( result => {
      if ( result.success) {
        this.showBotton = true;
        this.getDataGlossary();
        this.showForm = false;
        this.resetWordForm();
        swal(Appsettings.APP_NAME,  result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME, result.message , 'error');
      }
      this.working = false;
    });
  }

  // Actualiza una clase
  updateWord( word: Word) {
    this._srvGlossary.updateWord( word, this.glossaryId).subscribe( result => {
      if ( result.success) {
        this.showBotton = true;
        this.getDataGlossary();
        this.showForm = false;
        this.resetWordForm();
        swal(Appsettings.APP_NAME,  result.message, 'success');
      } else {
        swal(Appsettings.APP_NAME, result.message , 'error');
      }
      this.working = false;
    });
  }

  // Evento que se ejecuta al dar clic en el boton cancelar del formulario de captura
  onClickCancelConcept( ): void {
    swal({
      title: '¿Deseas cancelar la operación?',
      text: '¡Se perderán los datos en caso de no guardarlos!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if ( result.value) {
        this.showForm = false;
        this.showBotton = true;
        this.resetWordForm();
      }
    });
  }

  // Inicializar el formulario de preguntas
  resetWordForm( ): void {
  this.frmWord.reset();
  this.frmWord.markAsUntouched();
  this.frmWord.markAsPristine();
  this.frmWord.updateValueAndValidity();
  }

  // Update lesson
  onClickUpdateLesson(word) {
    this.showForm = true;
    this.frmWord.controls['id'].setValue( word.id);
    this.frmWord.controls['name'].setValue( word.name);
    this.frmWord.controls['description'].setValue( word.description);
  }

  // Elimina una clase
  onClickDeleteWord( word) {
    swal({
      title: '¿Deseas eliminar la palabra del glosario?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí!',
      cancelButtonText: 'Cancelar'
    }).then((res) => {
      if ( res.value) {
        this.working = true;
          this._srvGlossary.deleteWord(this.glossaryId, word.id).subscribe( result => {
          if ( result.success ) {
            this.wordContent = [];
            this.getDataGlossary();
            swal(Appsettings.APP_NAME, result.message , 'success');
          } else {
            swal(Appsettings.APP_NAME,  result.message, 'error');
          }
          this.working = false;
        });
      }
    });
  }

  /******** STRUCTURE **********/

  // Evento que se ejecuta cuando se realiza un cambio en el elemento seleccionado
  // onValueChange( value ) {
  //   if ( value.nodeTypeId !== 5 ) {
  //     swal(Appsettings.APP_NAME, 'El elemento seleccionado no es un libro.', 'error');
  //     return false;
  //   }
  //    this.selectedPublications = [];
  //   this.nodeId = value.value;
  //   this.selectedPublications.push({
  //         id: value.value,
  //         publication: value.text
  //       });


  // }

  onDeletePublication( book) {
    swal({
      title: '¿Deseas eliminar el libro?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí!',
      cancelButtonText: 'Cancelar'
    }).then((res) => {
      if ( res.value) {
        let result;
        result = this.selectedPublications.findIndex( elem =>  elem.publication === book.text );
        this.selectedPublications.splice( result, 1);
      }
    });

  }

  // Guarda la asociacion de libro-glosario
  onAddBooks() {
    this.working = true;

    let result;
    result = this.publications.findIndex( elem =>  elem.id === this.nodeId );

    if ( result < 0) {
      this._srvGlossary.addNode( this.nodeId, this.glossaryId).subscribe( res => {
        if ( res.success) {
          this.getDataGlossary();
          swal(Appsettings.APP_NAME,  res.message, 'success');
        } else {
          swal(Appsettings.APP_NAME,  res.message, 'error');
        }
      });
    } else {
      this.working = false;
      swal(Appsettings.APP_NAME, 'El elemento ya fue seleccionado.', 'error');
      return false;
    }

  }

  // Abre el modal para asigar libro a glosario
  openModalBookGlossary() {
    const DIALOG_REF = this._dialog.open( DialogBookGlossaryComponent, {
      width: '600px',
      height: '600px',
      autoFocus: false,
      disableClose: true,
      data: { glossatyId: this.glossaryId }
    });

    DIALOG_REF.afterClosed().subscribe( response => {

    });
  }
}
