import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

// Modelos
import { Node } from '../../../models/content/nodes.model';
import { Pagination } from '../../../models/general/pagination.model';

// Servicios
import { NodeService, StorageService } from '../../../service/service.index';
import { BreadcrumbService } from '../../../core/breadcrumb/breadcrumb.service';

import swal from 'sweetalert2';
import { Book } from '../../../models/content/book.model';
import { Appsettings } from '../../../configuration/appsettings';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';
import { UserUtilities } from '../../../shared/user.utilities';



@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss']
})
export class LevelComponent implements OnInit, OnDestroy {

  working: boolean;
  nodes: Node[] = [];
  children: Node[] = [];
  selectedParent: Node;
  pagination: Pagination;
  pages: number[] = [];
  sizePage: number = 12;
  books: Node[] = [];
  routeNode: Node[] = [];

  // Responsive
  isShowNavLevesl: boolean = false;

  constructor(  private _svrNode: NodeService,
                private _router: Router,
                public dialog: MatDialog,
                public _svrBreadcrum: BreadcrumbService,
                private _svrStorage: StorageService,
                private _srvHeader: AdminHeaderService) {

    this.working = false;
  }

  ngOnInit() {

    // LOCAL
    this._srvHeader.setTitle('Mis libros');
    this._svrBreadcrum.showBackBotton = false;
    this.setPageSize();
    this.getData();
    // this.routeNode.push(this.nodes[0]);
    this.getBooks( this.nodes[0].id, 1, this.sizePage);
    this._srvHeader.setBreadcrumb([this.nodes[0].description]);
  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  // Obtiene toda la estructura de sali
  getData() {

    let data = JSON.parse(this._svrStorage.nodes);
    const parent = data[0];
    if (data.length > 0) {
      this.nodes   = [];
      for ( const u of data) {
          this.nodes.push( new Node( u.active, u.name, u.id, u.nodeType.description, u.nodeTypeId, u.parentId, u.urlImage ));
      }
    }

    this.children = this.nodes.filter( x => x.parentId === parent.id );
    this.selectedParent = new Node( parent.active, parent.name, parent.id, parent.nodeTypeDesc, parent.nodeTypeId, parent.parentId, parent.urlImage );

  }

  onClickSelectNode( node ) {

    if ( node.nodeTypeId !== 5) {
      this.selectedParent = node;
      this.children = this.nodes.filter( x => x.parentId === node.id );
      this.getBooks( this.selectedParent.id, 1, this.sizePage);

      let router = UserUtilities.getSelectedNodeUrl(node.id, this._svrStorage.nodes);
      let descriptions = router.map( x => x.name );
      this._srvHeader.setBreadcrumb(descriptions);

      // this.routeNode = UserUtilities.getSelectedNodeUrl(node.id, this._svrStorage.nodes);
    }

    if ( node.nodeTypeId === 5) {
      let book = this.books.filter( x => x.id === node.id)[0];
      if ( book ) {
        this.onClickBook(book);
      }
    }
  }

  // Regresar un nivel anteriro de la búsqueda
  onClickReturn( node: Node ) {
    const parent = this.nodes.filter( x => x.id === node.parentId )[0];
    this.selectedParent = parent;
    this.children = this.nodes.filter( x => x.parentId === parent.id );
    this.getBooks( this.selectedParent.id, 1, this.sizePage);

    let router = UserUtilities.getSelectedNodeUrl(parent.id, this._svrStorage.nodes);
    let descriptions = router.map( x => x.name );
    this._srvHeader.setBreadcrumb(descriptions);

    // this.routeNode = UserUtilities.getSelectedNodeUrl(parent.id, this._svrStorage.nodes);
  }

  // Cambio de pagina de la navegación
  onClickChangePage( page: number) {
    this.getBooks(this.selectedParent.id, page, this.sizePage);
  }

  // Redirecciónar al libro
  onClickBook( node: Node ) {
    if ( node.accessType !== 2 ) {
      this._router.navigateByUrl(`/book/video/${node.id}`);
    } else {
      swal(Appsettings.APP_NAME, 'Aún no tienes acceso al libro', 'warning');
    }
  }

  // Obtener los libros
  getBooks( nodeId: number, page: number, size: number ) {

    this.working = true;
    this._svrNode.booksByNode(nodeId, page, size).subscribe( res => {

      if ( res.success ) {

        this.pages = [];
        this.books = [];

        this.pagination = new Pagination();
        this.pagination.pageNumber = page;
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        for ( let i = 1; i <= this.pagination.totalPage; i++ ) {
          this.pages.push(i);
        }
      let  arraynode = res.data;
      arraynode = arraynode.filter( x => x.active === true );
      for ( let u of arraynode ) {
          const url = u.urlImage != null ? u.urlImage : '/assets/images/icon_book.png';
          const node = new Node( u.active, u.description, u.id, u.nodeTypeDesc, u.nodeTypeId, u.parentId, url );

          u.book = new Book();
          u.book.id = u.book.id;
          u.book.codebar = u.book.codebar;
          u.book.description = u.book.description;
          u.book.isbn = u.book.isbn;
          u.book.name = u.book.name;

          node.accessType = u.nodeAccessTypeId;
          switch ( node.accessType ) {
            case 1:
              node.accessTypeDescription = 'Público';
              break;
            case 2:
              node.accessTypeDescription = 'Privado';
              break;
            case 3:
              node.accessTypeDescription = 'Propietario';
              break;
          }
          this.books.push(node);
        // if (node.active)
        // {
        //   this.books.push(node);
        // }

        }
      }
      this.working = false;
    }, err => {
      swal(Appsettings.APP_NAME, 'Problemas de conexión con el servidor, favor de intentar más tarde', 'warning');
      this.working = false;
    });
  }

  setPageSize() {


    if ( window.innerWidth > 1050 ) {
      this.sizePage = 15;
    }

    if ( window.innerWidth > 1600 ) {
      this.sizePage = 25;
    }

  }

}
