import { Component, OnInit, OnDestroy } from '@angular/core';

// COMPONENT SERVICES
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';
import { Appsettings } from '../../../configuration/appsettings';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit,OnDestroy {


  constructor(private _srvHeader: AdminHeaderService) {
    this._srvHeader.setTitle('Administración de cursos');
    this._srvHeader.setBreadcrumb([Appsettings.APP_NAME, 'Mi salón', 'Cursos']);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

}
