import { Component, OnInit, ViewChild } from '@angular/core';

// import { AdminHeaderService } from '../../../../../components/layouts/administrator/admin-header/admin-header.service';
import { AdminHeaderService} from '../../../components/layouts/administrator/admin-header/admin-header.service';
import { Angular2CsvComponent } from 'angular2-csv';

@Component({
  selector: 'app-general-reports',
  templateUrl: './general-reports.component.html',
  styleUrls: ['./general-reports.component.scss']
})
export class GeneralReportsComponent implements OnInit {

  working: boolean;
  startDate: Date;
  endDate: Date;
  @ViewChild(Angular2CsvComponent) csvComponent: Angular2CsvComponent;

  csvOptions = {
    title: 'User Details',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    useBom: true,
    // tslint:disable-next-line:max-line-length
    headers: ['Ctt', 'Nombre usuario', 'Nombre escuela', ' Calificación de escuela', 'Nivel', 'Entidad', 'Zona', 'Control', 'Promotor', 'Fecha de ingreso', 'Tiempo de navegación', 'Sección', 'Nombre del recurso'],
    nullToEmptyString: true,
  };
  csvData  = [
    {
      name: "Test, 1",
      age: 13,
      age1: 13,
      age2: 13,
      age3: 13,
      age4: 13,
      age5: 13,
      age6: 13,
      age7: 13,
      age8: 13,
      average: 8.2,
      approved: true,
      description: "using 'Content here, content here' "
    },
    {
      name: "Test, 1",
      age: 13,
      age1: 13,
      age2: 13,
      age3: 13,
      age4: 13,
      age5: 13,
      age6: 13,
      age7: 13,
      age8: 13,
      average: 8.2,
      approved: true,
      description: "using 'Content here, content here' "
    },
    {
      name: "Test, 1",
      age: 13,
      age1: 13,
      age2: 13,
      age3: 13,
      age4: 13,
      age5: 13,
      age6: 13,
      age7: 13,
      age8: 13,
      average: 8.2,
      approved: true,
      description: "using 'Content here, content here' "
    }
  ];
  constructor(  private _srvHeader: AdminHeaderService ) {
    this.working = false;
  }

  ngOnInit() {
    this._srvHeader.setTitle('Modulo de Reportes');
  }

  downloadCsv() {



    setTimeout(() => { this.csvComponent.onDownload(); }, 0);
  }
}
