import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

// Servicios
import { ExamScheduleService } from '../../../service/service.index';

@Component({
  selector: 'app-studen-answers',
  templateUrl: './studen-answers.component.html',
  styleUrls: ['./studen-answers.component.scss']
})
export class StudenAnswersComponent implements OnInit {

  teacherExamId: number;
  questions: any[] = [];
  arrayQuestion: any[] = [];
  working: boolean;
  pageIndex: number;
  studenId: number;

  @Input('studenId') set idStuden ( value ) {
    this.studenId = value;
  }

  @Input('examId') set examId ( value ) {
    this.teacherExamId = value;
    this.getAnswers( this.teacherExamId );
  }

  @Input('indexPage') set indexPage ( value ) {
    this.pageIndex = value;
    this.getPaginateQuestion( this.pageIndex );
  }


  // Rertorna el index del paginador
  @Output() indexPagination: EventEmitter<number> = new EventEmitter<number>();

  constructor(  private _srvExam: ExamScheduleService) {
    this.working = false;
   }

  ngOnInit() {
 }

  // Obtiene las preguntas del examen y
  getAnswers( examId: number ): void {
    this.working = true;
    let questionTotal = [];
    this._srvExam.getAnswersStuden( examId, this.studenId ).subscribe( result => {
      let dataExam = result.data.teacherExamn.teacherExamQuestion;

      let questions = {};
      let i = 1;
      for (let e of dataExam ) {
        let answers = [];
          for ( let a of e.question.answers) {

              let res = {
                answers:  a.description,
                relation: a.relationDescription,
                imageHeight: a.imageHeight,
                imageWidth: a.imageWidth
              };

            answers.push( res );
          }
          questions = {
            index: i,
            imgQuestion: e.question.urlImage,
            content: e.question.content,
            answers: answers,
            questionTypeId: e.question.questionType.id,
            imageHeight: e.question.imageHeight,
            imageWidth: e.question.imageWidth
          };

          questionTotal.push( questions );
          i++;
      }

      this.arrayQuestion = this.chunkArray( questionTotal, 5);

      this.indexPagination.emit( this.arrayQuestion.length  );
      this.questions = this.arrayQuestion[0];
      this.working = false;
    });
  }

  chunkArray( array: any[], size) {
    let index = 0;
    let arrayLength = array.length;
    let tempArray = [];

    for ( index = 0; index < arrayLength; index += size) {
      let myChunk = array.slice( index, index + size);
      tempArray.push( myChunk);
    }

    return tempArray;
  }

  getPaginateQuestion( index: number) {
    this.questions = [];
    this.questions = this.arrayQuestion[index];
  }

}
