import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material';

@Component({
  selector: 'app-dialog-student-answers',
  templateUrl: './dialog-student-answers.component.html',
  styleUrls: ['./dialog-student-answers.component.scss']
})
export class DialogStudentAnswersComponent implements OnInit {

  teacheExamId: number;
  nameExam: string;
  studenId: number;

  indexPage: number;
  page: number;
  constructor(  private dialogRef: MatDialogRef<DialogStudentAnswersComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                  this.teacheExamId = data.teacherExamId;
                  this.nameExam = data.nameExam;
                  this.studenId = data.studenId;
                  this.indexPage = 0;
                 }

  ngOnInit() {
  }

  // Cierre el modal
  onCloseConfirm( event ) {
      this.dialogRef.close( event );
  }

  // Avanza paginación
  addPagination() {
    this.indexPage++;
  }

  // Retrocede paginación
  reducePagination() {
    this.indexPage--;
  }

  indexPagination( event ) {
    this.page = event - 1;
  }
}
