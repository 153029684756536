export class Section {
  id: number;
  name: string;
  content: string;
  order?: number;
  courseId: number;
  class: number;
  // constructor (_id: number, _name: string, _content, _courseId: number, _order?: number) {

  //   this.id = _id;
  //   this.name = _name;
  //   this.content = _content;
  //   this.order = (_order) ? _order : 0;
  //   this.courseId = _courseId;
  // }
}
