import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

@Injectable({
  providedIn: 'root'
})

export class CatalogService {

  constructor( private httpService: HttpService ) { }

  // Obtener todos los contenidos registrados
  getLotationAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/locations`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todos los propositos registrados
  getPurposesAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/purposes`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todas las areas registradas
  getAreasAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/areas`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todos los bloques registrados
  getBlockAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/blocks`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todos los campos de entrenamiento
  getTrainingFieldskAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/trainingfields`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todos los campos de formación registrados
  getFormativeFieldskAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/formativefields`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todos los grados registrados
  getGradesAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/grades`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todos los subsistemas registrados
  getSubSystemAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/subsystems`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todos los roles registrados
  getRoleAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/roles`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todos los roles registrados
  getQuestionTypesAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/questiontypes`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Obtener todos los tipos de nodo
  getNodenTypesAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodetypes`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }


  // Obtener todos los tipos de nodo
  getFileTypesAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/filetypes`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

   // Obtener todos los tipos de nodo
   getContentTypesAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contenttypes`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

   // Obtener todos los tipos de nodo
   getResourceTypesAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/resourcestypes`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

   // Obtener todos los tipos de nodo
   getContentResourcesTypeAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contentsresourcetypes`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }



}

