import { Injectable } from '@angular/core';


import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

// Modelo
import { Rating } from '../../models/courses/rating.model';

@Injectable({
  providedIn: 'root'
})
export class ExamRatingService {

  constructor( private _httpService: HttpService) { }

    // Registrar las calificaciones de un grupo
    save ( ratings: Rating[] ) {

      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/examscores/groupscore`;
      const HEADERS = new Headers();

      HEADERS.append('Content-Type', 'application/json');

      return this._httpService.post(API_URL, JSON.stringify( ratings ), { headers: HEADERS } )
                             .map( res => res.json());
    }

    // Obtener todos los contenidos registrados
    getAll () {

      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contents`;
      return this._httpService.get( API_URL )
                             .map( res => res.json());
    }

    // Actualizar el estatus del contenido
    updateStatus ( id: number, status: boolean ) {

       const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contents/${id}/status`;
       const HEADERS = new Headers();

       const params = {
        'id': id,
        'status': status
       };

       HEADERS.append('Content-Type', 'application/json');

      return this._httpService.put(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                              .map( res => res.json());

    }

     // Método que elimina todos los datos de un contenido
    delete( id ) {
      const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/contents`;
      const  params = new URLSearchParams();

      params.append('id', id);
      return this._httpService.delete(apiUrl, { params })
                             .map( res => res.json());
    }

    // Registrar un nuevo contenido
    update ( content, file ) {

      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contents`;
      const HEADERS = new Headers();
      const params = {
        'id': content.id,
        'name': content.name,
        'urlContent': content.urlContent,
        'isPublic': content.isPublic,
        'contentTypeId': content.contentType.id,
        'fileTypeId': content.fileType.id,
        'fileContentBase64': file.content,
        'fileName': file.name
      };

      HEADERS.append('Content-Type', 'application/json');

      return this._httpService.put(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                             .map( res => res.json());
    }

    // Asignar un nodo del arbol a un contenido
    assignContent( contentNode ) {

      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodecontents`;
      const HEADERS = new Headers();
      HEADERS.append('Content-Type', 'application/json');

      const params = {
        'nodeId': contentNode.nodeId,
        'contentId': contentNode.contentId,
        'areaId': contentNode.areaId,
        'levelId': contentNode.levelId,
        'subsystemId': contentNode.subsystemId,
        'trainingFieldId': contentNode.trainingFieldId,
        'formativeFieldId': contentNode.formativeFieldId,
        'gradeId': contentNode.gradeId,
        'blockId': contentNode.blockId,
        'purposeId': contentNode.purposeId,
        'locationId': contentNode.locationId,
        'nodeRoute': contentNode.nodeRoute,
      };

      return this._httpService.post(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                             .map( res => res.json());
    }

    // Remover la asignación de un arbol al contenido
    removeAssignContent( id: number ) {

      const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodecontents`;
      const params = new URLSearchParams();
      params.append('id', id.toString());

      return this._httpService.delete( API_URL, { params} )
                             .map( res => res.json());

    }
}
