import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

import { Appsettings } from '../../../configuration/appsettings';

// Modelos
import { Glossary } from '../../../models/courses/glossary.model';
import { Pagination } from '../../../models/general/pagination.model';

// Servicios
import { GlossaryService } from '../../../service/service.index';

// Complemetos
import { DialogBookGlossaryComponent } from '../../../dialogs/dialog-book-glossary/dialog-book-glossary.component';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';


import swal from 'sweetalert2';

@Component({
  selector: 'app-glossary-list',
  templateUrl: './glossary-list.component.html',
  styleUrls: ['./glossary-list.component.scss']
})
export class GlossaryListComponent implements OnInit , OnDestroy {

  working: boolean;
  glossaries: Glossary[] = [];

  textSearch: string;
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor(  private _srvGlossary: GlossaryService,
                private _router: Router,
                private _dialog: MatDialog,
                private _srvHeader: AdminHeaderService ) {

    this.working = false;
    this.setPage( { page: 1, size: this.sizePage });
  }

  ngOnInit() {
    this._srvHeader.setTitle('Administrar glosarios');
    this._srvHeader.setBreadcrumb([ Appsettings.APP_NAME , 'Mi salón', 'Administración de glosarios']);
  }

  ngOnDestroy(): void {
    this._srvHeader.setBreadcrumb([]);
  }

  // Obtiene todos los glosarios del maestro
  setPage( paginationData: object ) {
    this.working = true;
    this._srvGlossary.getAll(Number(paginationData['size']),
                             Number(paginationData['page']) ,
                             this.textSearch).subscribe( res => {

      this.glossaries = [];
      let index = 1;

      if ( res.success) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let g of res.data) {

          let glossary = new Glossary();

          glossary.id = g.id;
          glossary.name = g.name;
          glossary.description = g.description;
          glossary.active = g.active;
          glossary.index = this.pagination.getIndexRegister(index);

          this.glossaries.push( glossary);
          index++;
        }
      }

      this.working = false;

    }, err => {
      this.working = false;
    });
  }

  // Funcion del buscador
  onKeyUp( event) {
    if ( this.textSearch.length >= 4 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Editar un glosario
  onEditItem( element: Glossary ) {
    this._router.navigateByUrl( `/sali/generate-glossary/${element.id}`);
  }

  // Eliminar un glosario
  onDeleteItem( element: Glossary ) {
    this.working = true;
    this._srvGlossary.deleteGlossary( element.id).subscribe( result => {
      if ( result. success) {
        this.setPage( { page: 1, size: this.sizePage });
        swal(Appsettings.APP_NAME, result.message , 'success');

      } else {
        swal(Appsettings.APP_NAME, result.message , 'warning');
      }

      this.working = false;
    });
  }

  // Agregar nuevo glosario
  onClickNewGlossary() {
    this._router.navigateByUrl( `/sali/generate-glossary/0`);
  }

   // Abre el modal para asigar libro a glosario
   openModalBookGlossary( glossary ) {
    const DIALOG_REF = this._dialog.open( DialogBookGlossaryComponent, {
      width: '600px',
      height: '600px',
      autoFocus: false,
      disableClose: true,
      data: { glossatyId: glossary.id }
    });

    DIALOG_REF.afterClosed().subscribe( response => {

    });
  }
}
