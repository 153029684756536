export class SelectQuestions {
  id: number;
  content: string;
  select: boolean;
  answers: any[];
  typeQuestions: number;
  relationDescription?: string;
  urlImage: string;

}
