import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';

import { Headers} from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';
import { QuestionBank } from '../../models/courses/questionBank.model';
import { Question } from '../../models/courses/question.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionBankService {

  constructor( private httpService: HttpService ) { }

  /**** BANCO PREGUNTAS  **** */

  // Obtener todos los bancos de imagenes registrados
  getAllPagination(sizePage: number, currentPage: number, filter: string) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/questionbanks/all?currentPage=${currentPage}&pageSize=${sizePage}&filter=${filter}`;
    const params = new URLSearchParams();

    return this.httpService.get( API_URL )
                            .map( response => response.json() );
  }

  // Obtiene los datos de un banco de preguntas por identificador
  getBydId(id) {
    let status = true;
    let apiUrl  = `${Appsettings.API_ENDPOINT_FULL}/questionbanks?id=${id}&image=${status}`;
    return this.httpService.get( apiUrl ).map( response => response.json() );
  }

  // Obtener todos los bancos de preguntas que estan asignados a un nodo en específico
  geAlltByNodeId(nodeId: number) {
    let apiUrl  = `${Appsettings.API_ENDPOINT_FULL}/questionbanks/node?nodeId=${ nodeId }`;
    return this.httpService.get( apiUrl ).map( response => response.json() );
  }

  // Guarda un nuevo banco de preguntas
  save( questionBank: QuestionBank ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/questionbanks`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( questionBank ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  // Guarda un banco de preguntas existente
  update( questionBank: QuestionBank ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/questionbanks`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.put(API_URL, JSON.stringify( questionBank ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  updateStatus ( id: number, status: boolean ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/questionbanks/${id}/status`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    const params = {
     'id': id,
     'status': status
    };
   return this.httpService.put(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());
 }

  // Obtiene la estructura de los elementos que tiene examenes
  getStructureWithBanks() {
    let apiUrl  = `${Appsettings.API_ENDPOINT_FULL}/questionbanks/structure`;
    return this.httpService.get( apiUrl ).map( response => response.json() );
  }

  // Obtiene la estructura de los elementos que tiene examenes
  getList( ids: string ) {
    let apiUrl  = `${Appsettings.API_ENDPOINT_FULL}/questionbanks/list?ids=${ids}`;
    return this.httpService.get( apiUrl ).map( response => response.json() );
  }

  /***** PREGUNTAS *******/

  addQuestion( question: Question ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/questions`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( question ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  updateQuestion( question: Question ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/questions`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.put(API_URL, JSON.stringify( question ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  removeQuestion( questionId: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/questions?id=${questionId.toString()}`;
    return this.httpService.delete(API_URL)
                           .map( res => res.json());

  }

  /***** ANSWERS ****/

  removeAnswer( id: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/answers?id=${id.toString()}`;
    return this.httpService.delete(API_URL)
                           .map( res => res.json());

  }

  // Guarda un nuevo banco de preguntas http://{{server}}/api/questionbanks/load
  saveUpload( questionBank ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/questionbanks/load`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( questionBank ), { headers: HEADERS } )
                            .map( res => res.json());

  }

}
