import { Routes, RouterModule } from '@angular/router';
import { TeacherLayoutComponent } from './teacher-layout.component';

// GUARD
import { AuthGuardService } from '../../service/service.index';
import { StudentsComponent } from './students/students.component';
import { SectionsComponent } from './sections/sections.component';
import { ExamScheduleComponent } from './exam-schedule/exam-schedule.component';
import { CoursesComponent } from './courses/courses.component';
import { ExamQualifyComponent } from './exam-qualify/exam-qualify.component';
import { TaskScheduleComponent } from './task-schedule/task-schedule.component';
import { EventsComponent } from './events/events.component';
import { HomeworkQualifyComponent } from './homework-qualify/homework-qualify.component';
import { AttendanceListComponent } from './attendance-list/attendance-list.component';
import { InterestSiteComponent } from './interest-site/interest-site.component';
import { VideoConferenceComponent } from './video-conference/video-conference.component';
import { TeacherVideoConferenceComponent } from './teacher-video-conference/teacher-video-conference.component';
import { VideoConferenceTestComponent } from '../administrator/video-conference-test/video-conference-test.component';

const AppPageSali: Routes = [
  {
    path: 'teacher',
    component: TeacherLayoutComponent,
    children: [
      { path: 'attendance/:studenGroupId/:course', component: AttendanceListComponent },
      { path: 'courses', component: CoursesComponent },
      { path: 'students/:id/:course', component: StudentsComponent},
      { path: 'exam-schedule/:id/:course', component: ExamScheduleComponent},
      { path: 'course-content/:id', component: SectionsComponent },
      { path: 'events/:id', component: EventsComponent },
      { path: 'qualify/:status/:groupId/:examScheduleId/:typeExam/:description/:statusId', component: ExamQualifyComponent },
      { path: 'task-schedule/:studenGroupId', component: TaskScheduleComponent},
      { path: 'homework-qualify/:groupId/:examScheduleId', component: HomeworkQualifyComponent},
      { path: 'interest-site/:courseId', component: InterestSiteComponent},
      { path: 'video-conference', component: VideoConferenceComponent },
      { path: 'video-conference/:id', component: VideoConferenceTestComponent },
      { path: '', component: CoursesComponent }
    ]
  }
];
export const TEACHER_ROUTES = RouterModule.forChild( AppPageSali );
