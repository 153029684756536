import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Appsettings } from '../../../../configuration/appsettings';

// MODELS
import { Question } from '../../../../models/courses/question.model';
import { QuestionBank } from '../../../../models/courses/questionBank.model';
import { Answer } from '../../../../models/courses/answer.model';
import { QuestionBankUpload } from '../../../../models/courses/questionBankUpload.model';

// SERVICES
import { ContentTeacherService, QuestionBankService , CatalogService, StorageService} from '../../../../service/service.index';



// COMPONENT MODELS
// import { AdminNavService } from '../../../components/layouts/administrator/admin-nav/admin-nav.service';
import { AdminNavComponent } from '../../../../components/layouts/administrator/admin-nav/admin-nav.component';
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';
import { TreeUtilities } from '../../../../shared/tree.utilities';

import swal from 'sweetalert2';

/******************** Carga de archivos */
import { FileUploader, FileLikeObject } from 'ng2-file-upload';

@Component({
  selector: 'app-upload-question-bank',
  templateUrl: './upload-question-bank.component.html',
  styleUrls: ['./upload-question-bank.component.scss']
})
export class UploadQuestionBankComponent implements OnInit, OnDestroy {

  questionBank: QuestionBank = new QuestionBank();
  working = false;
  frmQuestions: FormGroup;
  frmQuestionBank: FormGroup;
  answers: Answer[] = [];
  showForm: boolean;
  showTreeStructure = false;
  editMode = true;
  selectedQuestion: Question;

  structure: any[] = [];
  selectedItemPath = '';
  value;
  items: TreeviewItem[] = [];
  config = TreeviewConfig.create({
      hasFilter: true,
      hasCollapseExpand: true
  });

  questionTypes: any[] = [];


  nodos: any[] = [];

  // IMAGEN DE PREGUNTA
  imageProfile: string;
  fileContent: File;
  fileContentBase: string;
  fileName: string;
  isUpdateFile: boolean;
  nodeType: number;

  @ViewChild('formQuestion') formQuestion;
  @ViewChild('imageUpload') imageUpload;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /*********** Carga de archivos */
  // Url para carga de archivos
  url = `${Appsettings.API_ENDPOINT_FULL}/questionbanks/parse-file`;
  maxFileSize = 1 * 1024 * 1024;
  errorMessage: string;
  frmFile: FormGroup;
  uploader;
  token: string;
  arrayQuestions: QuestionBankUpload[] = [];

  // CONSTURCTOR
  constructor( private _srvQuestionBank: QuestionBankService,
               private _srvStorage: StorageService,
               private _route: ActivatedRoute,
               private _router: Router,
               private _srvCatalog: CatalogService,
               public _srvNavService: AdminHeaderService,
               public _srvHeaderAdmin: AdminHeaderService,
               private _svrContent: ContentTeacherService,) {

    // Formulario de pregunta
    this.frmQuestions = new FormGroup({
      id: new FormControl(0),
      question: new FormControl('', [Validators.required]),
      explanation: new FormControl(''),
      questionTypeId: new FormControl('', [Validators.required]),
    });

    // Formulario para banco de preguntas
    this.frmQuestionBank = new FormGroup({
      id: new FormControl(0),
      name: new FormControl('', [ Validators.required ]),
      description: new FormControl('', [ Validators.required ]),
      nodeId: new FormControl('', [ Validators.required ]),
      file: new FormControl('')
    });

    this.questionBank = new QuestionBank();
    this.showTreeStructure = true;

    this.frmFile = new FormGroup({
      'description': new FormControl('', [Validators.required]),
      'file': new FormControl('', [Validators.required])
    });
    this.uploader  = new FileUploader({
      url: this.url,
      maxFileSize: this.maxFileSize,
      // allowedMimeType: []
    });
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
    this._route.params.subscribe( params => {
       this.questionBank.id = params['id'];
       this.nodeType = Number(params['type']);


    });



  }

  ngOnInit( ) {
    setTimeout(() => this.getNodes(), 0);
    const title = 'Cargar banco de preguntas';

    // SET CONTROLS
    this._srvNavService.setTitle(title);
    this._srvHeaderAdmin.setUrlBackButton(['/admin/questions-list', 'Banco de preguntas']);


  }

  ngOnDestroy(): void {
    this._srvHeaderAdmin.setUrlBackButton([]);
  }

  /******** STRUCTURE **********/

  // Evento que se ejecuta cuando se realiza un cambio en el elemento seleccionado
  onValueChange( value ): void {
    this.frmQuestionBank.controls['nodeId'].setValue(value.value);
  }
  // Obtiene los datos del servicio
  getNodes(): void {

    this.structure = [];
    this.nodos = [];

    const fullNodes = JSON.parse(this._srvStorage.nodes);
    let nodes = [];

    if ( this.questionBank.nodeId > 0 ) {
      const selectedNode = fullNodes.filter( x => x.id ===  this.questionBank.nodeId )[0];
      if ( selectedNode ) {
        if ( selectedNode.id === Appsettings.PLAN_LECTOR_NODE_ID ||
             selectedNode.parentId === Appsettings.PLAN_LECTOR_NODE_ID  ) {
          this.nodeType = 2;
        }
      }
    }


    for (const node of fullNodes) {
      const checkedNode = false;

      const nodeAdd = {
        text: node.description,
        value: node.id,
        collapsed: false,
        checked: checkedNode,
        parentId: node.parentId
      };

      this.structure.push(node);
      this.nodos.push(nodeAdd);
    }

    this.items.push(TreeUtilities.buildHierarchy(this.nodos));

  }

 /********   Componente carga de archivo*************** */
  onChangeFile( e) {
    this.working = true;
    this._svrContent.validateToken().subscribe( resutl => {
      if ( resutl.status === 200) {
        this.fileName = e;
        this.token = this._srvStorage.token;
        this.uploader.setOptions({
          authTokenHeader: 'Authorization',
          authToken: 'Bearer ' + this.token
        });

          // Metodo para el envio de archivos
          this.uploader.onBeforeUploadItem = (item) => {
            item.withCredentials = false;
            this.uploader.options.additionalParameter = {
          };
        };
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

          if ( response) {
            let response_json = JSON.parse(response);

            if ( response_json['success'] ) {
              this.arrayQuestions = [];
              let question =  response_json['data'].questions;
              if ( question) {
                for( let q of question) {
                    let newQuestion = new QuestionBankUpload();
                    newQuestion.answers = q.answers;
                    newQuestion.content = q.content;
                    newQuestion.explanation = q.explanation;
                    newQuestion.questionTypeId = q.questionTypeId;
                    newQuestion.selected = true;
                    newQuestion.questionType = q.questionType;

                    this.arrayQuestions.push( newQuestion );
                  }
                  swal( Appsettings.APP_NAME, response_json['message'], 'success');
                  this.working = false;
              } else {
                swal( Appsettings.APP_NAME, 'No se obtuvieron preguntas del archivo.', 'success');
                this.working = false;
              }
            } else {
              swal( Appsettings.APP_NAME, response_json['message'], 'warning');
              this.working = false;
            }
          } else {
            this.working = false;
            swal( Appsettings.APP_NAME, 'La carga de archivo fue cancelada', 'warning');
          }
        };
        this.uploader.uploadAll();
      } else {
        console.log( 'El token no esta activo');
        this.working = false;
      }
    });
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    (<HTMLInputElement>document.getElementById('file')).value = '';
    this.fileName = '';
    this.uploader.queue.splice(0, 1);
    this.frmFile.controls['file'].setValue('');
    switch (filter.name) {
        case 'fileSize':
            this.errorMessage = `El tamaño del archivo excede el máximo permitido de 10mb.`;
            swal( Appsettings.APP_NAME, this.errorMessage , 'warning');
            break;
        default:
            this.errorMessage = `Unknown error (filter is ${filter.name})`;
            swal( Appsettings.APP_NAME, this.errorMessage , 'warning');
    }
}


onSaveBank() {
  this.working = true;
    const questions: QuestionBankUpload[] = [];
    for (let i of this.arrayQuestions) {
      if ( i.selected) {
        let q = new QuestionBankUpload();
        q.answers = i.answers;
        q.content = i.content;
        q.explanation = i.explanation;
        q.questionTypeId = i.questionTypeId;
        questions.push( q );
      }
    }
    const data = {
      'name': this.frmQuestionBank.controls['name'].value,
      'description': this.frmQuestionBank.controls['description'].value,
      'nodeId': this.frmQuestionBank.controls['nodeId'].value,
      'questions': questions
    };

    this._srvQuestionBank.saveUpload( data ).subscribe( result => {
      if ( result.success ) {
        swal( Appsettings.APP_NAME, result.message , 'success');
        this.working = false;
        this._router.navigateByUrl('/admin/questions-list');
      } else {
        swal( Appsettings.APP_NAME, result.message , 'warning');
        this.working = false;
      }

    });
  }

}
