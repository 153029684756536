import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { Appsettings } from '../../../configuration/appsettings';

// Servicios
import { StorageService } from '../../../service/shared/storage.service';
import { ContentTeacherService } from '../../../service/service.index';

// Componente para carga de archivo
import { FileUploader, FileItem, FileUploaderOptions } from 'ng2-file-upload';
import { from } from 'rxjs';

import swal from 'sweetalert2';

export interface Resource {
  id: number;
  value: string;

}
@Component({
  selector: 'app-teacher-repository',
  templateUrl: './teacher-repository.component.html',
  styleUrls: ['./teacher-repository.component.scss']
})
export class TeacherRepositoryComponent implements OnInit {

  working: boolean;
  frmContent: FormGroup;
  fileName: string;
  cancel: boolean;
  recourseType: Resource[] = [
    { id: 1, value: 'Imagen'},
    { id: 4, value: 'Video'},
    { id: 5, value: 'Documentos'},
    { id: 6, value: 'Audio'},
    { id: 7, value: 'Otros'}
  ];

  // Url para carga de archivos
  // url = 'https://ralarousse.azurewebsites.net/api/resources/upload';
  url = `${Appsettings.API_ENDPOINT_FULL}/resources/upload`;
  uploader;
  token: string;

  // Obtenemos los estudiantes
  @Input('cancelUpload') set cancelUpload ( value) {
    this.cancel = value;
    this.onCancelUpload( this.cancel);
  }

  // Retornamos el arreglo de id's
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(  private _srvStorege: StorageService,
                private _svrContent: ContentTeacherService ) {
    this.working = false;
    this.token = this._srvStorege.token;

    this.uploader  = new FileUploader({
      url: this.url
    });
    this.frmContent = new FormGroup({
      'typeFile': new FormControl('', [Validators.required]),
      'resourceName': new FormControl('', [Validators.required]),
      'resourceDescription': new FormControl('', [Validators.required]),
      'file': new FormControl('', [Validators.required])
    });

  }

  ngOnInit() {
  }

  onChangeFile( e) {
    this.fileName = e;
  }

  onSaveResoruce() {
    this._svrContent.validateToken().subscribe( resutl => {
      if ( resutl.status === 200) {
        this.token = this._srvStorege.token;
        this.uploader.setOptions({
          authTokenHeader: 'Authorization',
          authToken: 'Bearer ' + this.token
        });

        // Metodo para el envio de archivos
        this.uploader.onBeforeUploadItem = (item) => {
            item.withCredentials = false;
            this.uploader.options.additionalParameter = {
            Name: this.frmContent.controls['resourceName'].value,
            Description: this.frmContent.controls['resourceDescription'].value,
            TeacherResourceId: this.frmContent.controls['typeFile'].value
          };
        };

        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          // console.log('A ITEM UPLOADED SUCCESSFULLY');
          if ( response) {
            let response_json = JSON.parse(response);
            if ( response_json['success'] ) {
              this.onCloseConfirm( true );
              swal( Appsettings.APP_NAME , response_json['message'], 'success');
            } else {
              this.onCloseConfirm( false );
              swal( Appsettings.APP_NAME,   response_json['message'], 'warning');
            }
          } else {
            swal( Appsettings.APP_NAME ,  'La carga de archivo fue cancelada', 'warning');
          }

          // this.resetClassForm();
        };
        this.uploader.uploadAll();
      } else {
      }
    });
  }

  // Inicializar el formulario de preguntas
  resetClassForm( ): void {
    this.frmContent.reset();
    this.frmContent.markAsUntouched();
    this.frmContent.markAsPristine();
    this.frmContent.updateValueAndValidity();
  }

  onCloseConfirm( event: boolean) {
    setTimeout(() => {
      this.closeModal.emit( event  );
    }, 2000);
  }

  onCancelUpload( param ) {
    if ( param) {
      if ( this.uploader.progress > 0) {
        this.uploader.cancelAll();
      }
    }

  }
}
