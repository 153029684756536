export class Message {
  chatGroupId: number;
  isRead: boolean;
  lastMessage: string;
  lastMessageDate: string;
  receiverAvatar: string;
  receiverUserId: number;
  receiverUserName: string;
  owner: boolean;
}


