import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-admin-books',
  templateUrl: './dialog-admin-books.component.html',
  styleUrls: ['./dialog-admin-books.component.scss']
})
export class DialogAdminBooksComponent implements OnInit {

  constructor( private dialogRef: MatDialogRef<DialogAdminBooksComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any  ) { }

  ngOnInit() {
  }

  // Cierre del modal
  onCloseConfirm( event ) {
    this.dialogRef.close( event );
  }
}
