import { Injectable } from '@angular/core';
import { PaginarionLocal } from '../../models/general/pagination-local.model';


@Injectable({
  providedIn: 'root'
})
export class PagerService {
  constructor() {}

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10): PaginarionLocal {

    let totalPages = Math.ceil(totalItems / pageSize);

    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;

    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i
    );

    const pager = new PaginarionLocal();

    pager.totalItems  = totalItems;
    pager.currentPage  = currentPage;
    pager.pageSize  = pageSize;
    pager.totalPages  = totalPages;
    pager.startPage  = startPage;
    pager.endPage  = endPage;
    pager.startIndex  = startIndex;
    pager.endIndex  = endIndex;
    pager.pages  = pages;

    return pager;
  }
}
