import { Injectable } from '@angular/core';
import { StorageService } from '../shared/storage.service';
import { HttpService } from '../security/http.service';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

@Injectable()
export class StructureService {
  constructor(public storageService: StorageService,
    private http: Http,
    private httpService: HttpService) {

  }

  getAll() {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/nodes`;

    return this.httpService
        .get(apiUrl)
        .map(res => res.json());
  }
}
