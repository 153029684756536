import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

// MODELS
import { Content } from '../../models/content/content.model';
import { FileContent } from '../../models/catalogs/file.model';
import { NodeContent } from '../../models/content/nodeConents.model';
import { Tag } from '../../models/content/tag.model';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor( private httpService: HttpService ) { }

  // Registrar un nuevo contenido
  save ( content: Content, file: FileContent ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contents`;
    const HEADERS = new Headers();
    let isRecomended = false;

    if ( content.isRecommended == null ) {
       isRecomended = false;
    } else {
       isRecomended =  content.isRecommended;
    }

    const params = {
      'name': content.name,
      'active': content.active,
      'urlContent': content.urlContent,
      'isPublic': content.isPublic,
      'contentTypeId': content.contentType.id,
      'trainingFieldId': content.trainingFieldId,
      'purposeId': content.purposeId,
      'areaId': content.areaId,
      'formativeFieldId': content.formativeFieldId,
      'contentResourceTypeId': content.resourceTypeId,
      'fileTypeId': content.fileType.id,
      'fileContentBase64': file.content,
      'fileName': file.name,
      'thumbnails': content.thumbnails,
      'tags': content.tags,
      'description': content.description,
      'isRecommended': isRecomended,
      'blockId': content.blockId,
      'urlInteractive': content.urlInteractive
    };

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Obtener todos los contenidos registrados
  getAll () {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contents`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

   // Obtener todos los contenidos registrados
   getAllPagination (sizePage: number, currentPage: number, filter: string, contentTypeId: number, fileTypeId:number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contents/all`;
    const params = new URLSearchParams();

    params.append('pageSize', sizePage.toString());
    params.append('currentPage', currentPage.toString());
    params.append('filter', filter);
    params.append('contentTypeId', contentTypeId.toString());
    params.append('fileTypeId', fileTypeId.toString());

    return this.httpService
        .get(API_URL, { params })
        .map(res => res.json());
  }

 // Actualizar el estatus del contenido
 updateStatus ( id: number, status: boolean ) {

     const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contents/${id}/status`;
     const HEADERS = new Headers();

     const params = {
      'id': id,
      'status': status
     };

     HEADERS.append('Content-Type', 'application/json');

    return this.httpService.put(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                            .map( res => res.json());

 }

 // Actualizar el estatus del contenido
 updateTags ( id: number, tags: Tag[] ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contents/${id}/tags`;
    const HEADERS = new Headers();

    const params = {
     'id': id,
     'tags': tags
    };

    HEADERS.append('Content-Type', 'application/json');

   return this.httpService.post(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());

 }

   // Método que elimina todos los datos de un contenido
  delete( id ) {
    const apiUrl = `${Appsettings.API_ENDPOINT_FULL}/contents`;
    const  params = new URLSearchParams();

    params.append('id', id);
    return this.httpService.delete(apiUrl, { params })
                           .map( res => res.json());
  }

  // Registrar un nuevo contenido
  update ( content: Content, file: FileContent ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/contents`;
    const HEADERS = new Headers();
    let isRecomended = false;

    if ( content.isRecommended == null ) {
       isRecomended = false;
    } else {
       isRecomended =  content.isRecommended;
    }

    const params = {
      'id': content.id,
      'name': content.name,
      'urlContent': content.urlContent,
      'isPublic': content.isPublic,
      'contentTypeId': content.contentType.id,
      'trainingFieldId': content.trainingFieldId,
      'purposeId': content.purposeId,
      'areaId': content.areaId,
      'formativeFieldId': content.formativeFieldId,
      'contentResourceTypeId': content.resourceTypeId,
      'fileTypeId': content.fileType.id,
      'fileContentBase64': file.content,
      'fileName': file.name,
      'thumbnails': content.thumbnails,
      'tags': content.tags,
      'description': content.description,
      'isRecommended': isRecomended,
      'blockId': content.blockId,
      'urlInteractive': content.urlInteractive,
      'active': content.active
    };

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.put(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Asignar un nodo del arbol a un contenido
  assignContent( contentNode: NodeContent ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodecontents`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    const params = {
      'nodeId': contentNode.nodeId,
      'contentId': contentNode.contentId,
      'nodeRoute': contentNode.nodeRoute
    };

    return this.httpService.post(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Remover la asignación de un arbol al contenido
  removeAssignContent( id: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodecontents`;
    const params = new URLSearchParams();
    params.append('id', id.toString());

    return this.httpService.delete( API_URL, { params} )
                           .map( res => res.json());

  }

}
