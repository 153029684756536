import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Modelos
import { Question } from '../../../models/courses/question.model';

// Service
import { TeacherExamService } from '../../../service/service.index';

import swal from 'sweetalert2';
import { Appsettings } from '../../../configuration/appsettings';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

@Component({
  selector: 'app-apply-exam',
  templateUrl: './apply-exam.component.html',
  styleUrls: ['./apply-exam.component.scss']
})
export class ApplyExamComponent implements OnInit {

  examId: number;
  questions: Question[] = [];
  working: boolean;
  question: Question;

  questionsTotal: number;

  orderResponse: any[] = [];
  answersOrder: any[] = [];
  answers: any[] = [];
  finalAnswers: any[] = [];
  relationsQuestions: any[] = [];
  relations: any[] = [];
  idd: number;
  idds: number[] = [];

  elementQuestions: any[] = [];
  questionsResonse: any[] = [];

  examScheduleId: number;
  nodeId: number;
  typeQuestion: number;
  countTotalQuestiosn: number;

  examName: string;

  constructor(  private _srvTeacherExam: TeacherExamService,
                private _routeActive: ActivatedRoute,
                private _router: Router,
                private _srvHeader: AdminHeaderService ) {



                  this.countTotalQuestiosn = 0;
                  this.typeQuestion = 0;
                  this._routeActive.params.subscribe( params => {

                    this.working = false;
                    this.examId = params['examId'];
                    this.nodeId = params['nodeId'];
                    this.examScheduleId = params['examScheduleId'];
                    this.getExam( params['examId'] );
                  });
                 }

  ngOnInit() {

  }

  // Obtiene las preguntas del examen
  getExam( examId: number) {
    this.working = true;
    this._srvTeacherExam.getById( examId ).subscribe( result => {
      let data = result.data;
      this.examName = data.description;
      this._srvHeader.setTitle( this.examName);
      let c = 0;
      let isCorrect: boolean;
      for (let questions of data.teacherExamQuestion) {
        let cr = 0;
        let q = questions.question;

        let response: any[] = [];
        response = q.answers;
        if ( q.questionTypeId !== 2) {

          if ( q.questionTypeId === 3) {
            response = [];
            for (let p of q.answers) {
              p.indexRelation = '';
              p.textrRelation = '';
              response.push( p );

            }
          }
          for ( let r of response) {
            if ( r.isCorrect) {
              cr++;
            }
          }

          ( cr > 1 ) ? isCorrect = true : isCorrect = false;

          let question = new Question();
          question.id = q.id;
          question.content = q.content;
          question.answers = response;
          question.questionTypeId = q.questionTypeId;
          question.index = c;
          question.urlImage = q.urlImage;
          question.isMultiple = isCorrect;
          question.imageHeight = q.imageHeight;
          question.imageWidth = q.imageWidth;

          this.questions.push( question);
          c++;
        }

      }
      this.questionsTotal = this.questions.length;
      this.getQuestion(0);
    });
  }

  // Obtiene una pregunta individual
  getQuestion( question: number) {
    if ( this.question) {
      this.validQuestionRelations( this.question);
    }

    this.question = this.questions[question];
    this.typeQuestion = this.question.questionTypeId;

    this.working = false;
    // validamos en tipo de pregunta
    if ( this.question.questionTypeId === 3) {
      // revisamo si la pregunta ya tienes respuestas
      let totalRelation = this.relationsQuestions[ this.question.id ];
      // Si no tiene agregamos la pregunta al banco
      if ( !totalRelation ) {
        this.relationsQuestions[ this.question.id ] = [];
      } else {
        this.relations = this.relationsQuestions[ this.question.id ];
      }
      this.orderAnswers( this.question.answers, this.question.id );
    }
    if ( this.question.questionTypeId === 1 || this.question.questionTypeId === 4  || this.question.questionTypeId === 5 ) {
      let id  = this.answers.filter( item => item.QuestionId === this.question.id);
      this.idds = [];
      if ( this.question.isMultiple ) {
        this.idds = [];
        let c = 0;
        let aws = this.question.answers;
        if ( id.length > 0 ) {
        for ( let i of id ) {
          let cc = 0;
          for ( let a of aws) {
            if (i.AnswerId  == a.id) {
              this.idds[cc] = i.AnswerId;
            }
            cc++;
          }
          c++;
        }
        }
      } else {
        if ( id.length > 0 ) {
          this.idd = id[0].AnswerId;
         }
      }

    }

  }

  // Agrega la respuesta salecionda al array de respuestas
  change(event) {
    let res = this.questionsResonse.lastIndexOf(this.question.id );

    if ( res !== -1) {
      this.answers.splice(res, 1);
    } else {
      this.questionsResonse.push(this.question.id);
      this.countTotalQuestiosn++;
    }
    this.answers.push({
      QuestionId: this.question.id,
      AnswerId: event.value,
      Explanation: ''
    });
  }

  // Agrega la respuesta salecionda al array de respuestas
  changeCheck(event) {
    let res = this.questionsResonse.lastIndexOf(this.question.id );
    if ( res === -1) {
      this.questionsResonse.push(this.question.id);
      this.countTotalQuestiosn++;
    }

    let resQ = this.answers.lastIndexOf( event.source.value );
    if ( event.checked ) {
      this.answers.push({
        QuestionId: this.question.id,
        AnswerId: event.source.value,
        Explanation: '',
        index: resQ
      });
    } else {
      this.answers = this.answers.filter( item => item.AnswerId !== event.source.value );
      let resR = this.answers.filter( item => item.QuestionId === this.question.id);
      if ( resR.length === 0) {
        this.countTotalQuestiosn--;
        this.questionsResonse.splice(res, 1);
      }
    }


  }

  orderAnswers( answers, questionId: number) {
    // variables
    this.elementQuestions = [];
    let relationDescription: any[] = [];
    // Obtenemos los complementos para la relacion
    for ( let a of answers) {
      relationDescription.push( a.relationDescription);
    }
      let result = this.orderResponse[questionId] ;
    // Ordenamos de forma aleatorea los complementos
    if ( result !== undefined ) {
      this.answersOrder = this.orderResponse[questionId];
    } else {
      this.orderResponse[ questionId ] = this.randArray(relationDescription );
      this.answersOrder = this.orderResponse[questionId];
    }

    let c = 0;
    // Creamos el arreglo de respuestas
    for ( let ans of answers) {
      this.elementQuestions.push(
        {
          answers: ans.description,
          relation: this.answersOrder[c]
        });
        c++;
    }



  }

  // Ordenena el array de respuesta en random
  public randArray( arra1: any[]) {
    let ctr = arra1.length, temp, index;

    while (ctr > 0) {
        index = Math.floor(Math.random() * ctr);
        ctr--;
        temp = arra1[ctr];
        arra1[ctr] = arra1[index];
        arra1[index] = temp;
    }
    return arra1;
  }

  // Genera la ralacion respues-termino
  relationAsugned( index: number, complementText: string, answersId: number, questionId: number, i: number ) {
    if ( index ) {
      this.question.answers[i].indexRelation = index;
      this.question.answers[i].textrRelation = complementText;
    } else {
      this.question.answers[i].indexRelation = index;
      this.question.answers[i].textrRelation = '';
    }
  }

  // Guarda las respuestas del examen
  qualifyExam() {
    // obtenemos las preguntas relacionales
    let c = 0;
    for ( let q of this.questions) {
      if (q.questionTypeId === 3) {
        for ( let a of q.answers ) {
          this.finalAnswers.push({
            'AnswerId': a.id,
            'Explanation': a.textrRelation
          });
        }
      }
      c++;
    }

    for ( let i of this.answers) {
      this.finalAnswers.push({
        'AnswerId': i.AnswerId,
        'Explanation': i.Explanation
      });
    }
    let data = {
      'clientId': 1,
      'examScheduleId': this.examScheduleId,
      'answers': this.finalAnswers
    };
    this._srvTeacherExam.saveExamStuden( data ).subscribe( result => {
      if ( result.success ) {
        swal({
          title: Appsettings.APP_NAME,
          text: 'Su examen concluyó satisfactoriamente',
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this._router.navigateByUrl(`/book/video/${this.nodeId}`);
          }
        });
      } else {
        // message
        swal(Appsettings.APP_NAME, result.message, 'warning');

      }
    });
  }

  // Valida que la pregunta relacional tenga toda las relaciones asginadas
  validQuestionRelations( question) {
    // Validamos sea pregunta relacional
    if ( question.questionTypeId === 3 ) {
      // buscamos para ver si ya fue respondida anteriormente
      let res = this.questionsResonse.lastIndexOf(question.id );
      // Contador de control para la cantidad de respuesa
      let c = 0;
      // recorremos las respuetas de la pregunta
      for ( let q of question.answers) {

        // si la respusta no tiene asociado un index se incrementa el contador
        if ( q.indexRelation ) {
          c++;
        }
      }
      if ( res === -1 && c > 0) {
        this.questionsResonse.push( question.id);

          this.countTotalQuestiosn++;

      }
      // else if ( res !== -1 && c > 0) {
      //   this.countTotalQuestiosn--;
      // } else if ( res !== -1 && c === 0 ) {
      //   this.countTotalQuestiosn--;
      //   this.countTotalQuestiosn++;
      // }
    }
  }
}
