import { Component, OnInit } from '@angular/core';
import { Appsettings } from '../../../configuration/appsettings';
import { MatDialog } from '@angular/material';

// SERVICES
import { TipsHomeService } from '../../../service/service.index';

// COMPONENT SERVICES
import { SitemapService } from '../../../core/sitemap/sitemap.service';
import { AdminHeaderService } from '../../../components/layouts/administrator/admin-header/admin-header.service';

// MODELS
import { Pagination } from '../../../models/general/pagination.model';
import { TipHome } from '../../../models/home/tip.model';

// DIALOGS
import { DialogTipComponent } from '../../../dialogs/dialog-tip/dialog-tip.component';

import swal from 'sweetalert2';


@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent implements OnInit {

  working: boolean;
  tips: TipHome[] = [];

  // PAGINATION VARIABLES
  textSearch: string = '';
  sizePage: string = Appsettings.DEFAULT_SIZE_PAGE;
  page: number = 1;
  pagination: Pagination = new Pagination();

  constructor( public _srvSiteMap: SitemapService,
               private _srvHeader: AdminHeaderService,
               private _srvTip: TipsHomeService,
               public dialog: MatDialog) {

                this.working  = false;
                this._srvSiteMap.setSiteMap( [{ 'description' : 'Contenido',
                                                'isCurrent' : false },
                                              { 'description' : 'Tips' ,
                                              'isCurrent' : true }]);

               }

  ngOnInit() {
    this.setPage( { page: 1, size: this.sizePage });
    this._srvHeader.setTitle('Administración de noticias');
  }

  // Evento para crear un nuevo tip
  onCreate() {
    const DIALOG_REF = this.dialog.open( DialogTipComponent, {
      width: '500px',
      minHeight: '400px',
      data: new TipHome(),
      autoFocus: false,
      disableClose: true
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      if ( response ) {
        this.setPage( { page: this.page, size: this.sizePage });
      }
    });

  }

  // Realizar búsqueda
  onKeyUp(event) {
    if ( this.textSearch.length >= 3 || this.textSearch.length === 0 ) {
      this.setPage( { page: 1, size: this.sizePage });
    }
  }

  // Evento para editar un tip existente
  onEditItem( tip: TipHome ): void {

    const DIALOG_REF = this.dialog.open( DialogTipComponent, {
      width: '500px',
      minHeight: '400px',
      data: tip,
      autoFocus: false,
      disableClose: true
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      if ( response ) {
        this.setPage( { page: this.page, size: this.sizePage });
      }
    });

  }

  // evento para eliminar un tip existente
  onDeleteItem( tip: TipHome ): void {

    swal({
      title: '¿Estás seguro?',
      text: '¡Esta operación no se podrá revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar ahora!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.working = true;
        this._srvTip.delete( tip.id ).subscribe( res => {

          if ( res.success ) {

            this.working = false;
            swal(Appsettings.APP_NAME, 'El registro fue eliminado correctamente.', 'success' );
            this.setPage( { page: this.page, size: this.sizePage });

          } else {
            this.working = false;
            swal(Appsettings.APP_NAME, res.message, 'error' );
          }
        }, err => {
          this.working = false;
          swal( Appsettings.APP_NAME , 'Problemas al eliminar la noticia',  'error' );
        });
      }
    });

  }

  // Cambiar de página
  setPage( paginationData: object ) {

    this.working = true;
    this.page    =  Number(paginationData['page']);
    this._srvTip.getAll( this.page , Number(paginationData['size']) , this.textSearch ).subscribe( result => {

      this.tips   = [];
      let index = 1;

      if ( result.success ) {

        this.pagination.pageNumber = Number(paginationData['page']);
        this.pagination.pageSize = result.pagination.pageSize;
        this.pagination.showNextPage = result.pagination.showNextPage;
        this.pagination.showPreviousPage = result.pagination.showPreviousPage;
        this.pagination.total = result.pagination.total;
        this.pagination.totalPage = result.pagination.totalPage;

        this.sizePage = paginationData['size'];

        for ( let t of result.data) {
            let tip = new TipHome();

            tip.id = t.id;
            tip.description = t.description;
            tip.published = t.published;
            tip.registerDate = new Date(t.registerDate);
            tip.name = t.name;
            tip.urlImage = t.urlImage;
            tip.index = this.pagination.getIndexRegister(index);

            this.tips.push( tip );
            index++;
        }
      }
      this.working = false;
    }, result => {
      this.working = false;
    });

  }

}
