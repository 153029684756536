import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers} from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

// MODELS
import { ScheduledEvent } from '../../models/courses/scheduledEvent.model';
// UTILITIES
import { UserUtilities } from '../../shared/user.utilities';
import { StorageService } from '../shared/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduledEventService {

  constructor( private httpService: HttpService,
               private _storageSevices: StorageService ) { }

  // Obtener todos los eventos de un curso
  getByCourseId( id: number ) {
    let apiUrl  = `${Appsettings.API_ENDPOINT_FULL}/events/course?id=${id}`;
    return this.httpService.get( apiUrl ).map( response => response.json() );
  }

  // Guardar un nuevo evento
  save( event: ScheduledEvent ) {

    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/events/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    let data = {
      courseId: event.courseId,
      description: event.description,
      duration: event.duration,
      groups: event.groups,
      id: event.id,
      initTime: event.initTime,
      name: event.name,
      dateEvent: UserUtilities.dateFullSerialize(event.dateEvent),
      endEvent: UserUtilities.dateFullSerialize(event.endEvent),
    };

   return this.httpService.post(  API_URL,
                                  JSON.stringify( data ),
                                 { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Actualizar los datos de un evento
  update( event: ScheduledEvent ) {

    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/events/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    let data = {
      description: event.description,
      duration: event.duration,
      groups: event.groups,
      id: event.id,
      initTime: event.initTime,
      name: event.name,
      dateEvent: UserUtilities.dateFullSerialize(event.dateEvent),
      endEvent: UserUtilities.dateFullSerialize(event.endEvent),
    };

   return this.httpService.put(  API_URL,
                                  JSON.stringify( data ),
                                 { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Eliminar un evento
  remove( id: number ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/events?id=${id}`;
    return this.httpService.delete(API_URL).map( res => res.json());
  }

  // Obtener todos eventos del alumno por mes
  getByMonth(date: Date) {
    let dateString =  `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    let controller = 'student';

    if (this._storageSevices.rol === 'Profesor') {
      controller  = 'teacher';
    }

    let apiUrl  = `${Appsettings.API_ENDPOINT_FULL}/events/${controller}?date=${dateString}`;

    return this.httpService.get( apiUrl ).map( response => response.json() );
  }

}
