import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';


@Injectable({
  providedIn: 'root'
})
export class InterestSiteService {

  constructor( private httpService: HttpService ) { }


  getAll( id ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/linkinterests?id=${ id }`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  delete( id ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/linkinterests?id=${ id }`;
    return this.httpService.delete(API_URL)
                           .map( res => res.json());
  }

  save(  item ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/linkinterests`;
      const HEADERS = new Headers();

      HEADERS.append('Content-Type', 'application/json');

      return this.httpService.post(API_URL, JSON.stringify( item ), { headers: HEADERS } )
                            .map( res => res.json());
  }

  update( item ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/linkinterests`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.put(API_URL, JSON.stringify( item ), { headers: HEADERS } )
                            .map( res => res.json());
  }

  // Obtener todos los sitios de interés por libro y que el alumno este inscrito en algún grupo
  getAllCourse( nodeId) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/linkinterests?nodeId=${ nodeId }`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }
}
