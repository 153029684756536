import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Appsettings } from '../../../configuration/appsettings';

// Componente para carga de archivo
import { FileUploader, FileItem, FileUploaderOptions, FileLikeObject } from 'ng2-file-upload';
import swal from 'sweetalert2';

// Servicios
import { StorageService } from '../../../service/shared/storage.service';
import { ContentTeacherService, HomeworksService } from '../../../service/service.index';

// Modelos
import { HomeworkFile } from '../../../models/courses/homeworkFile.model';

@Component({
  selector: 'app-studen-upload-homework',
  templateUrl: './studen-upload-homework.component.html',
  styleUrls: ['./studen-upload-homework.component.scss']
})
export class StudenUploadHomeworkComponent implements OnInit {
  uploader;
  token: string;
  fileName: string;
  // allowedFileType =  ['image/jpeg', 'image/png'];
  maxFileSize = 10 * 1024 * 1024;
  errorMessage: string;
  frmFile: FormGroup;
  taskId: number;

  homeworkFiles: HomeworkFile[] = [];

  working: boolean;

  @Input('taskId') set homeWorkId ( value ) {
    this.taskId = value;
    this.getFilesHomeWork( this.taskId);
  }

  // Retornamos el arreglo de id's
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();


  // Url para carga de archivos
  url = `${Appsettings.API_ENDPOINT_FULL}/homeworks/uploadAnswer`;
  // url = 'https://ralarousse.azurewebsites.net/api/homeworks/uploadAnswer';
  // url = 'http://localhost:60817/api/homeworks/uploadAnswer';
  constructor(  private _srvStorege: StorageService,
                private _svrContent: ContentTeacherService,
                private _svrHomework: HomeworksService) {
    this.frmFile = new FormGroup({
      'description': new FormControl('', [Validators.required]),
      'file': new FormControl('', [Validators.required])
    });
    this.uploader  = new FileUploader({
      url: this.url,
      maxFileSize: this.maxFileSize,
      allowedMimeType: [
        'application/x-rar-compressed',
        'application/x-zip', 
        'application/zip', 
        'application/octet-stream', 
        'application/x-zip-compressed', 
        'multipart/x-zip'
      ]
    });
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
   }

  ngOnInit() {
  }

  // Obtiene todos los archivos de la tarea
  getFilesHomeWork( homeWorkId: number ) {
    this.working = true;
    this.homeworkFiles = [];
    this._svrHomework.getFilesStudent( homeWorkId ).subscribe( result => {
      if ( result.success ) {
        for ( let f of result.data ) {
          let file = new HomeworkFile();
          file.id = f.id;
          file.url = f.url;
          file.comment = f.comment;
          file.registerDate = f.registerDate;

          this.homeworkFiles.push( file );
        }
      }
      this.working = false;
    });
  }
  onSaveResoruce() {
    this._svrContent.validateToken().subscribe( resutl => {
      if ( resutl.status === 200) {
        this.token = this._srvStorege.token;
        this.uploader.setOptions({
          authTokenHeader: 'Authorization',
          authToken: 'Bearer ' + this.token
        });

        // Metodo para el envio de archivos
        this.uploader.onBeforeUploadItem = (item) => {
            item.withCredentials = false;
            this.uploader.options.additionalParameter = {
            homeWorkId: this.taskId,
            comment: this.frmFile.controls['description'].value,
            // Description: this.frmContent.controls['resourceDescription'].value,
            // TeacherResourceId: this.frmContent.controls['typeFile'].value
          };
        };

        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          if ( response) {
            let response_json = JSON.parse(response);
            if ( response_json['success'] ) {
              this.onCloseConfirm( true );
              swal( Appsettings.APP_NAME, response_json['message'], 'success');
            } else {
              this.onCloseConfirm( false );
              swal( Appsettings.APP_NAME, response_json['message'], 'warning');
            }
          } else {
            swal( Appsettings.APP_NAME,'La carga de archivo fue cancelada', 'warning');
          }

          // this.resetClassForm();
        };
        this.uploader.uploadAll();
      } else {
        console.log( 'El token no esta activo');
      }
    });
  }

  onChangeFile( e) {
    this.fileName = e;
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    (<HTMLInputElement>document.getElementById('file')).value = '';
    this.fileName = '';
    this.uploader.queue.splice(0, 1);
    this.frmFile.controls['file'].setValue('');
    switch (filter.name) {
        case 'fileSize':
            this.errorMessage = `El tamaño del archivo excede el máximo permitido de 10mb.`;
            swal( Appsettings.APP_NAME, this.errorMessage , 'warning');
            break;
        case 'mimeType':
            this.errorMessage = `El tipo de archivo seleccionado no es permitido, solo se permiten achivos .rar ó .zip`;
            swal( Appsettings.APP_NAME, this.errorMessage , 'warning');
            break;
        default:
            this.errorMessage = `Unknown error (filter is ${filter.name})`;
    }
}

onCloseConfirm( event: boolean) {
  setTimeout(() => {
    this.closeModal.emit( event  );
  }, 2000);
}

onDeleteContent( homeworkFileId: number) {
   this._svrHomework.deleteFilesHomerwork( this.taskId, homeworkFileId ).subscribe( result => {
     if ( result.success ) {

      this.getFilesHomeWork( this.taskId);
     }
   });
}
}
