import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { StorageService } from '../shared/storage.service';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';
import { FileContent } from '../../models/catalogs/file.model';



@Injectable()
export class NodeService {
  constructor(  public storageService: StorageService,
                private http: Http,
                private httpService: HttpService) {
  }

  // Método que realiza petición al API para obtener la estrtructura de SALI
  getAll() {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes`;


    return this.httpService
        .get(API_URL)
        .map(res => res.json());
  }

  // Obtiene la estructura de todos los hijos apartir del
  // identificador del padre
  getStructureByPatent( id: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/${id}/structure`;
    const params = new URLSearchParams();

    params.append('id', id.toString());

    return this.httpService
        .get(API_URL, { params})
        .map(res => res.json());
  }

  // Método que devuelve los subnodos de un nodo padre
  getSubNode(idNode: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/${idNode}/subnodes`;

    return this.httpService
    .get(API_URL)
    .map(res => res.json());
  }

  // Método que devuelve el contenido
  getContent(idNode: number) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/${idNode}/contents`;

    return this.httpService
    .get(API_URL)
    .map( res => res.json());
  }

  // Metodo que devuelve un documento por su id
  getFile(idFile: number) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/contents/${idFile}/download`;

    return this.httpService
    .get(apiUrl)
    .map( res => res);

  }

  // Guardar un nuevo nodo
  save( node: any, file: FileContent ) {
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    const params = {
      'name': node.name,
      'parentId': node.parentId,
      'active': node.active,
      'nodeTypeId': node.nodeTypeId,
      'bookId': node.bookId,
      'description': node.description,
      'fileContentBase64': file.content,
      'fileName': file.name,
      'order': node.order
    };

   return this.httpService.post(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  // Actualizar la información de los nodos
  update( node: any, file: FileContent ) {
    debugger;
    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/`;
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');

    const params = {
      'id': node.id,
      'name': node.name,
      'parentId': node.parentId,
      'active': node.active,
      'nodeTypeId': node.nodeTypeId,
      'bookId': node.bookId,
      'description': node.description,
      'fileContentBase64': file.content,
      'fileName': file.name,
      'order': node.order
    };


   return this.httpService.put(API_URL, JSON.stringify( params ), { headers: HEADERS } )
                           .map( res => res.json());

  }

  // Eliminar un nodo existente
  delete( id: number ) {

    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/`;
    const params = new URLSearchParams();
    params.append('id', id.toString());

    return this.httpService.delete( API_URL, { params} )
                           .map( res => res.json());
  }

  // Remover imagen asignada al nodo
  removeImage( id: number ) {

    let API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/${id}/image`;
    const params = new URLSearchParams();
    params.append('id', id.toString());

    return this.httpService.delete( API_URL, { params} )
                           .map( res => res.json());
  }

  // Obtener libros por nodo y paginación
  booksByNode( nodeId: number, page: number, size: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/${nodeId}/books?page=${page}&size=${size}`;

    return this.httpService
                .get(API_URL)
                .map( res => res.json());
  }

  // Obtener el total de contenidos por nodo
  getChildTotalContent( nodeId: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/inventory?id=${nodeId}`;

    return this.httpService
                .get(API_URL)
                .map( res => res.json());
  }

  // Obtener todos los archivos por tipo y nodo
  getAllNodeFilesType( nodeId: number, fileTypeId: number, currentPage: number, pageSize: number ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/nodes/inventory-files?id=${nodeId}&fileTypeId=${fileTypeId}&currentPage=${currentPage}&sizePage=${pageSize}`;

    return this.httpService
                .get(API_URL)
                .map( res => res.json());

  }

}
