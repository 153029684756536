import { Injectable } from '@angular/core';
import { HttpService } from '../security/http.service';
import { Headers, URLSearchParams } from '@angular/http';
import { Appsettings } from '../../configuration/appsettings';

// MODELS
import { NewHome } from '../../models/home/new.model';

@Injectable({
  providedIn: 'root'
})
export class NewsHomeService {

  constructor( private httpService: HttpService ) { }

  // Obtiene todas las noticias paginadas
  getAll( page: number, size: number, filter: string ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/news/all?currentPage=${page}&pageSize=${size}&filter=${filter}
                     &systemId=${ Appsettings.SYSTEM_ID }`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  getById( id: number ) {
    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/news/${id}/detail`;
    return this.httpService.get( API_URL )
                           .map( res => res.json());
  }

  // Registrar un nuevo recurso
  save ( ratings: NewHome ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/news/`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.post(API_URL, JSON.stringify( ratings ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Actualizar un nuevo recurso
  update ( ratings: NewHome ) {

    const API_URL = `${Appsettings.API_ENDPOINT_FULL}/news/`;
    const HEADERS = new Headers();

    HEADERS.append('Content-Type', 'application/json');

    return this.httpService.put(API_URL, JSON.stringify( ratings ), { headers: HEADERS } )
                           .map( res => res.json());
  }

  // Eliminar una noticia existente
  delete( id: number ) {
    let apiUrl = `${Appsettings.API_ENDPOINT_FULL}/news?id=${id}`;
    return this.httpService.delete(apiUrl)
                           .map( res => res.json());
  }

}
