import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';

import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { Appsettings } from '../../../../configuration/appsettings';
import { ActivatedRoute, Router } from '@angular/router';

// SERVICES
import { UserService, CatalogService, CityService } from '../../../../service/service.index';
import { StorageService } from '../../../../service/shared/storage.service';

// MODELS
import { Role } from '../../../../models/security/role.model';
import { Contry } from '../../../../models/catalogs/contry.model';
import { State } from '../../../../models/catalogs/state.model';
import { User } from '../../../../models/security/user.model';
import { System } from '../../../../models/catalogs/system.model';
import { Level } from '../../../../models/catalogs/level.model';

// COMPONENT SERIVCES
import { HeaderService } from '../../../../components/layouts/anonymous/header/header.service';

// COMPONENTES
import { AdminHeaderComponent } from '../../../../components/layouts/administrator/admin-header/admin-header.component';

// DIALOGS
import { DialogUserProfileImgComponent } from '../../../../dialogs/dialog-user-profile-img/dialog-user-profile-img.component';
import { DialogUserProfilePasswordComponent } from '../../../../dialogs/dialog-user-profile-password/dialog-user-profile-password.component';

import swal from 'sweetalert2';
import { AdminHeaderService } from '../../../../components/layouts/administrator/admin-header/admin-header.service';
import { Pagination } from '../../../../models/general/pagination.model';

@Component({
  selector: 'app-user-profile-data',
  templateUrl: './user-profile-data.component.html',
  styleUrls: ['./user-profile-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileDataComponent implements OnInit {
  working: boolean = false;
  userName: string;
  userRole: Role;

  roles: Role[] = [];
  contries: Contry[] = [];
  states: State[] = [];
  levels: Level[] = [];
  subsystems: System[] = [];
  institutions: string[] = [];
  semesters: number[] = [];
  profileImage: string = '';
  defaultImg: string = './../../../../../assets/img/profileimg/profile-placeholder.png';

  frmUserProfileData: FormGroup;
  public user: User;

  showKey: boolean;

  fileContent: File;
  fileContentBase: string;
  fileName: string;
  isUpdateFile: boolean;

  notifications: any[];
  selectedTabIndex: number = 0;

  pagination: Pagination;
  pages: number[] = [];
  sizePage: number = 10;

  @ViewChild(AdminHeaderComponent)
  public header: AdminHeaderComponent;


  constructor(
    public dialog: MatDialog,
    private _srvUser: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _srvStorage: StorageService,
    private _srvHeader: AdminHeaderService,
    public _srvCatalog: CatalogService,
    private _srvCity: CityService
  ) {
    this._srvHeader.setTitle('Perfil de usuario');
    this.userName = '';
    this.userRole = new Role(1, '', '');
    this.showKey = false;

    this.initCatalogs();

    // Inicializar controles del formulario
    this.frmUserProfileData = new FormGroup({
      fullName: new FormControl('', [Validators.required]),
      birthDay: new FormControl(new Date(), [Validators.required]),
      gender: new FormControl('M', [Validators.required]),
      contry: new FormControl(1, [Validators.required]),
      state: new FormControl(1, [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')
      ]),
      keycct: new FormControl('')
    });
  }

  ngOnInit() {

    this.getUserProfile();
    this.getNotifications(1, this.sizePage);

    this._route.queryParams.subscribe(params => {
      if (params['tab']) {
        this.selectedTabIndex = Number(params['tab']);
      } else {
        this.selectedTabIndex = 0;
      }
    });

  }

  // Obtener el cátalogo de ciudades
  getCities() {
    this.working = true;
    this._srvCity.getAll().subscribe(res => {
      if (res.success) {
        for (const c of res.data) {
          const city = new Contry(c.id, c.description);
          this.contries.push(city);
        }
      }
      this.working = false;
    }, err => {
      this.working = false;
    }, () => {
      if (this.contries.length > 0) {
        this.frmUserProfileData.controls['contry'].setValue(this.contries[0].id);
        this.getStates(this.contries[0].id);
      }
    });
  }

  // Obtener los estados de una ciudad
  getStates(cityId: number) {

    this.working = true;
    this._srvCity.getStates(cityId).subscribe(res => {

      if (res.success) {
        for (const s of res.data) {
          this.states.push(new State(s.id, s.name));
        }
      }

      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Obtener datos del perfil
  getUserProfile() {
    this.working = true;

    this._srvUser.getProfileData().subscribe(
      res => {
        if (res.success) {
          let user = res.data;
          // tslint:disable-next-line:max-line-length
          this.user = new User(
            user.id,
            user.fullName,
            user.email,
            user.birthday,
            user.gender,
            user.role,
            user.key,
            user.state.id,
            '',
            0,
            '',
            '',
            user.urlImage
          );

          this.frmUserProfileData.controls['contry'].setValue(user.state.city.id);

          this.userName = this.user.fullName;
          this.userRole = this.user.role;

          if (this.user.urlImg == null) {
            this.profileImage = this.defaultImg;
          } else {
            this.profileImage = this.user.urlImg;
          }

          if (this.user.role.id === 1) {
            this.roles.push(new Role(1, 'Administrador', 'Administrador'));
          }

          if (this.user.role.id === 2) {
            // ROL PROFESOR
            this.showKey = true;
            this.frmUserProfileData.controls['keycct'].setValidators([
              Validators.required
            ]);
            this.frmUserProfileData.controls['keycct'].updateValueAndValidity();
          }
        }

        this.setFormData();

        this.working = false;
      },
      err => {
        this.working = false;
      }
    );
  }

  // Asignar datos del usuario al formularia
  setFormData() {
    this.frmUserProfileData.controls['fullName'].setValue(this.user.fullName);
    this.frmUserProfileData.controls['email'].setValue(this.user.email);
    this.frmUserProfileData.controls['birthDay'].setValue(
      new Date(this.user.birthday)
    );
    this.frmUserProfileData.controls['gender'].setValue(this.user.gender);
    this.frmUserProfileData.controls['state'].setValue(this.user.state.id);
    this.frmUserProfileData.controls['keycct'].setValue(this.user.keycct);
  }

  onChangeRole(event) {
    // tslint:disable-next-line:radix
    if (parseInt(event.value) === 2) {
      this.showKey = true;
      this.frmUserProfileData.controls['keycct'].setValidators([
        Validators.required
      ]);
      this.frmUserProfileData.controls['keycct'].updateValueAndValidity();
    } else {
      this.showKey = false;
      this.frmUserProfileData.controls['keycct'].setValue('');
      this.frmUserProfileData.controls['keycct'].setValidators([]);
      this.frmUserProfileData.controls['keycct'].clearValidators();
      this.frmUserProfileData.controls['keycct'].updateValueAndValidity();
    }
    this.frmUserProfileData.controls['keycct'].markAsUntouched();
  }

  // Guardar los datos del usuario
  onSubmit() {
    const USER = {
      id: this.user.id,
      fullName: this.frmUserProfileData.controls['fullName'].value,
      birthDay: this.frmUserProfileData.controls['birthDay'].value,
      gender: this.frmUserProfileData.controls['gender'].value,
      contry: this.frmUserProfileData.controls['contry'].value,
      stateId: this.frmUserProfileData.controls['state'].value,
      email: this.frmUserProfileData.controls['email'].value,
      key: this.frmUserProfileData.controls['keycct'].value
    };

    this.working = true;
    this._srvUser.update(USER).subscribe(
      res => {
        // this.working = false;
        if (res.success) {
          swal(Appsettings.APP_NAME, res.message, 'success');
        } else {
          swal(Appsettings.APP_NAME, res.message, 'error');
        }
        this.working = false;
      },
      err => {
        swal('Problemas al realizar el registro', 'SAIL', 'error');
        this.working = false;
      }
    );
  }

  // Mostrar dialogo para actualizar imágen de perfil
  onChangeImg() {
    const DIALOG_REF = this.dialog.open(DialogUserProfileImgComponent, {
      width: '750px',
      height: '600px',
      data: this.user
    });

    DIALOG_REF.afterClosed().subscribe(response => {
      this.working = true;
      if (response.status) {
        if (response.image !== null) {
          this._srvStorage.profileImage = response.image;
          this.profileImage = response.image;
          this._srvHeader.setImgProfile();
        } else {
          this._srvStorage.profileImage = 'assets/img/profileimg/profile-placeholder.png';
        }

        this.working = false;
      }
      this.working = false;
    });
  }

  // Mostrar dialogo para cambiar de contraseña
  onChangePasswrd() {
    const DIALOG_REF = this.dialog.open(DialogUserProfilePasswordComponent, {
      width: '350px',
      height: '420px',
      autoFocus: false,
      disableClose: true
    });
  }

  // Inicializar los datos del catálogos
  initCatalogs() {

    // INIT DATA SELECT

    for (let role of this.roles) {
      this.roles.push(new Role(role.id, role.name, role.name));
    }

    this.getCities();


    // Obtener roles
    this._srvCatalog.getRoleAll().subscribe(res => {
      if (res.success) {
        let activeRoles = res.data.filter(x => x.active === true);
        this.roles = activeRoles;
      }
    });

  }

  // Obtener las notificaciones activas
  getNotifications(page: number, size: number) {
    // this.notifications = JSON.parse(this._srvStorage.notifications);
    this._srvUser.getNotifications(page, size).subscribe(res => {
      if (res.success) {

        this.pages = [];

        this.notifications = res.data;
        this._srvStorage.notifications = JSON.stringify(res.data);

        this.pagination = new Pagination();
        this.pagination.pageNumber = page;
        this.pagination.pageSize = res.pagination.pageSize;
        this.pagination.showNextPage = res.pagination.showNextPage;
        this.pagination.showPreviousPage = res.pagination.showPreviousPage;
        this.pagination.total = res.pagination.total;
        this.pagination.totalPage = res.pagination.totalPage;

        for (let i = 1; i <= this.pagination.totalPage; i++) {
          this.pages.push(i);
        }

        this._srvHeader.setNotifications();
      }
    });
  }

  // Asignar el index del tab seleccionado
  onChangeNotificationTab() {
    this.selectedTabIndex = 1;
  }

  // Cambiar de página de datos
  onClickChangePage(page: number) {
    this.getNotifications(page, this.sizePage);
  }

  // Marcar una notificación como leida
  onReadMessages(id: number) {
    this._srvUser.readNotification(id).subscribe(res => {
      if (res.success) {
        this._srvStorage.notifications = JSON.stringify(this._srvHeader.notifications.filter(x => x.id !== id));
        this.notifications = this.notifications.filter(x => x.id !== id);
      }
    });
  }

}
