import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../../core/core.module';

// COMPONENTS
import { AdminFooterComponent } from './admin-footer/admin-footer.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminNavComponent } from './admin-nav/admin-nav.component';

// SERVICES
import { AdminHeaderService  } from './admin-header/admin-header.service';

// MATERIALIZE
import { MatTabsModule,
  MatInputModule,
  MatDatepickerModule,
  MatRadioModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatMenuModule,
  MatPaginatorModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatToolbarModule } from '@angular/material';


@NgModule({
  declarations: [
    AdminFooterComponent,
    AdminHeaderComponent,
    AdminNavComponent
  ],
  exports: [
    AdminFooterComponent,
    AdminHeaderComponent,
    AdminNavComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatToolbarModule,
    CoreModule
  ],
  providers: [
    AdminHeaderService
  ]
})

export class AdministratorModule {}
