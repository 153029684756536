import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';



@Component({
  selector: 'app-dialog-event-detail',
  templateUrl: './dialog-event-detail.component.html',
  styleUrls: ['./dialog-event-details.component.scss']
})
export class DialogEventDetailComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogEventDetailComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
               }

  ngOnInit() {
  }

  onCloseConfirm() {
    this.dialogRef.close();
  }
}
