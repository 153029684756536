import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  TreeviewItem,
  TreeviewConfig
} from '../../../../node_modules/ngx-treeview/src';

// SERVICES
import { RoleService } from '../../service/service.index';

import { Permission } from '../../models/security/permission.model';
import { RolePermission } from '../../models/security/rolepermission.model';
import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';

@Component({
  selector: 'app-dialog-role',
  templateUrl: './dialog-roleperm.component.html',
  styles: []
})
export class DialogRolePermComponent implements OnInit {
  public permissions: Permission[];
  public rolePermission: RolePermission = new RolePermission(0, []);
  public idRole: number;

  working: boolean;
  items: TreeviewItem[] = [];
  nodos: any[] = [];
  idpermisos: number[] = [];

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 360
  });

  constructor(public dialogRef: MatDialogRef<DialogRolePermComponent>, private _roleSrv: RoleService,
              @Inject(MAT_DIALOG_DATA) public data ) {
    this.working = true;
    this.permissions = data.permissions;
    this.idRole = data.idRole;
    // Agregramos un nodo padre para que funcione el componenete
    this.nodos.push({
      text: 'Permisos',
      value: 1,
      collapsed: false,
      checked: true,
      parentId: 0
    });
    for (const nodo of data.permissions) {
      let checkedNode = false;
      let collapsed = true;
      // Validamos si el item esta asignado para porner el check = true
        if ( nodo.assigned) {
          checkedNode = true;
          collapsed = false;
        }

      // Creamos el nuevo nodo
      this.nodos.push({
        text: nodo.module,
        value: nodo.id + 1,
        collapsed: collapsed,
        checked: checkedNode,
        parentId: nodo.parentId + 1
      });
    }
    // Solicitamos a la funcion crea el formato para el componente de arbol
    this.buildHierarchy(this.nodos);
}

  ngOnInit() {

  }

  onCloseConfirm() {
    this.dialogRef.close(true);
  }

  onSubmit() {
    this.working = true;
    this.rolePermission.id = this.idRole;

    this.rolePermission.Permissions = this.idpermisos;

    this._roleSrv.postPermissions(this.idRole, this.rolePermission).subscribe(role => {
      if ( role.success ) {
        swal( Appsettings.APP_NAME , role.message, 'success');
      } else {
        swal( Appsettings.APP_NAME , role.message, 'error');
      }
      this.dialogRef.close(true);
      this.working = false;
    });

  }

  // Crea el json con el formato de arbol
  buildHierarchy(arry: any): void {

    const roots = [],
      children = {};

    // find the top level nodes and hash the children based on parent
    for (let i = 0, len = arry.length; i < len; ++i) {
      const item = arry[i],
        p = item.parentId,
        target = !p ? roots : children[p] || (children[p] = []);
      target.push({
        value: item,
        text: item.text,
        collapsed: item.collapsed,
        checked: item.checked
      });
    }

    // function to recursively build the tree
    let findChildren = function(parentId) {
      if (children[parentId.value.value]) {
        parentId.children = children[parentId.value.value];
        for (let i = 0, len = parentId.children.length; i < len; ++i) {
          findChildren(parentId.children[i]);
        }
      }
    };

    // enumerate through to handle the case where there are multiple roots
    for (let i = 0, len = roots.length; i < len; ++i) {
      findChildren(roots[i]);
    }
    // Elimana los [] extras en json
    let datas = JSON.stringify(roots);
      datas = datas.slice(1, -1);
    // Agregamos la nueva structura a items para crear el arbol
      this.items.push(new TreeviewItem(JSON.parse(datas)));
      this.working = false;
  }

  onChange(items: any): void {
    this.idpermisos = [];
    for (let i of items) {
      this.idpermisos.push(i.value - 1);
    }
    this.addParentId(items, this.idpermisos);
  }

  private addParentId(items, ids) {
    let  parentId;
    let result;
    for (let i of items) {
      parentId = ( i.parentId - 1 );
      result = this.idpermisos.filter(function(id) {
        return id === parentId;
      });
      if (result.length === 0) {
        if ( parentId > 0) {
          this.idpermisos.push(parentId);
        }
      }
    }
  }
}
