export class Pagination {
  pageNumber?: number;
  pageSize?: number;
  showNextPage: boolean;
  showPreviousPage: boolean;
  total?: number;
  totalPage?: number;

  constructor() {
    this.pageNumber = 0;
    this.pageSize = 0;
    this.showNextPage = false;
    this.showPreviousPage = false;
    this.total = 0;
    this.totalPage = 0;
  }

  public getInitRegister(): number  {
    return this.pageNumber === 1 ? 1 : ((this.pageNumber - 1 ) * this.pageSize ) + 1;
  }

  public getEndRegister():number  {
    let endRegister = this.pageSize * this.pageNumber;
    return  endRegister > this.total ? this.total : endRegister ;
  }

  public getIndexRegister( index: number ): number {
    return this.pageNumber === 1 ? index : ((this.pageNumber - 1) * Number(this.pageSize) ) + index;
  }

  public getNextPage(): number  {
    return  this.pageNumber + 1 ;
  }

  public getPreviousPage(): number  {
    return  this.pageNumber - 1 ;
  }

}
