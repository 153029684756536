export class UserUtilities {

  // OBTENER LA URL COMPLETA D EUN NODO
  public static getSelectedNodeUrl( nodeId: number,  nodesJSON: string ): any[] {

    const json = JSON.parse(nodesJSON);
    let node     = json.filter( x => x.id === nodeId )[0];


    let parentId = node.parentId;
    let router: any[] = [];
    let path = '';

    router.push(node);

    while (parentId > 0) {
      node = json.filter(r => r.id === parentId)[0];
      router.push(node);
      parentId = node.parentId;
    }

    if (router.length) {
      router = router.reverse();
      path = router.map(r => r.description).join('/');
    }

    return router;
  }

  // Agregar hora a la fecha
  public static addHoursToDate( date: Date, hour: string ): Date {

    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());

    let dateString     = `${year}/${month}/${day}`;
    let dateTiemString = dateString + ' ' + hour;
    let initEvent      = new Date(dateTiemString);

    return initEvent;
  }

  // Agregar minutos a una fecha
  public static sumMinutes( date: Date, minutes: number ): Date {
    let duration  = minutes * 60 * 1000;
    let endEvent = new Date(date.setTime(date.getTime() + duration));
    return endEvent;
  }

  public static dateFullSerialize( date: Date): string {

    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());

    const hour = date.getHours() < 10 ? `0${String( date.getHours())}` : String(date.getHours());
    const minute = date.getMinutes() < 10 ? `0${String( date.getMinutes())}` : String(date.getMinutes());

    return `${year}/${month}/${day}T${hour}:${minute}:00Z`;
  }


  public static dateSerialize( date: Date): string {

    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());

    return `${year}/${month}/${day}`;
  }

  public static getNumersOfDays(date1: Date, date2: Date) {
    let date1_unixtime = date1.getTime() / 1000;
    let date2_unixtime = date2.getTime() / 1000;

    let timeDifference = date2_unixtime - date1_unixtime;
    let timeDifferenceInHours = timeDifference / 60 / 60;
    let timeDifferenceInDays = timeDifferenceInHours  / 24;

    return timeDifferenceInDays;
  }

  public static getExtensionToUrl( url: string ): string {

    let filenameString = url.substring(url.lastIndexOf('/') + 1 );
    let fileName = filenameString.split('?')[0];
    let extension = fileName.split('.')[1].replace('%22', '');

    return extension;
  }

}

 // Here are the two dates to compare
    // let date1: any = '2018-01-01';
    // let date2: any = '2018-01-05';

    // First we split the values to arrays date1[0] is the year, [1] the month and [2] the day
    // date1 = date1.split('-');
    // date2 = date2.split('-');

    // Now we convert the array to a Date object, which has several helpful methods
    // let date11 = new Date(date1[0], date1[1], date1[2]);
    // let date22 = new Date(date2[0], date2[1], date2[2]);

    // We use the getTime() method and get the unixtime (in milliseconds, but we want seconds, therefore we divide it through 1000)
