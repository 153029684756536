export class TaskSchedule {
  id: number;
  name: string;
  ScheduledDate: Date;
  Description: string;
  StudentGroupId: number;
  FilesId: number[];
  score: number;
  comment: string;
}
