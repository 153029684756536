import { Injectable } from '@angular/core';
import { StorageService, StudenService } from '../../../../service/service.index';

// MODEL
import { Course } from '../../../../models/catalogs/course.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CourseNavService {


  nodeId: number;
  courseId: number = 0;
  course: Course = null;
  courses: Course[] = [];

  working: boolean;
  private changeCourseEndSource = new Subject<void>();
  public ChangeCourseEnd$ = this.changeCourseEndSource.asObservable();

  constructor( private _svrStorage: StorageService,
               private _srvStuden: StudenService ) { }

  // Obtener los cursos del estudiante
  public setCourse( id: number, courseId: number ) {

    this.nodeId = id;
    this.courseId = courseId;

    this.getStudentCourses();
  }

  // Obtener los cursos del estudiante
  private getStudentCourses(): void {

    this.working = true;
    this._srvStuden.getCoursStudent( this.nodeId ).subscribe( result => {

      if (result.success) {

        this.courses = [];
        this.course = null;

        if (result.data.length > 0) {


          for (let c of result.data) {

            let group  = c.studentGroups[0];
            let course =  new Course( c.courseSections, c.id, c.name, c.description, c.gol, c.startDate, c.endDate, c.nodeId,
                          c.subject, c.monday, c.tuesday, c.wednesday, c.thursday, c.friday, c.saturday, c.sunday, group.id );

            course.institution = c.institution;
            course.teacherFullName = c.user.fullName,
            course.teacherEmail = c.user.email,

            this.courses.push( course );

          }

          if ( this.courses.length > 0  &&  this.courseId > 0 ) {
            this.course = this.courses.filter( x => x.id === this.courseId)[0];
          }

        }
        this.working = false;
      }
    }, err => {
      this.working = false;
    });
  }

  // Obtener los cursos del estudiante por medio de una promesa
  public asyncGetStudentCourses( id: number, courseId: number ) {

    this.nodeId = id;

    if ( this.courseId === 0) {
      this.courseId = courseId;
    }

    let promise = new Promise(( resolve, reject ) => {
      this.working = true;
      this._srvStuden.getCoursStudent( this.nodeId ).subscribe( result => {

        if (result.success) {

          this.courses = [];
          this.course = null;

          if (result.data.length > 0) {

            for (let c of result.data) {

              let group  = c.studentGroups[0];
              let course =  new Course( c.courseSections, c.id, c.name, c.description, c.gol, c.startDate, c.endDate, c.nodeId,
                            c.subject, c.monday, c.tuesday, c.wednesday, c.thursday, c.friday, c.saturday, c.sunday, group.id );

              course.institution = c.institution;
              course.teacherFullName = c.user.fullName,
              course.teacherEmail = c.user.email,

              this.courses.push( course );

            }

            if ( this.courses.length > 0 ) {
                this.course = this.courses.filter( x => x.id === this.courseId)[0];
            }
         }
          this.working = false;
          resolve();
        }
      }, err => {
        this.working = false;
        reject();
      });
    });

    return promise;
  }

  // Cambiar el curso seleccionado
  public changeCorurse( id: number ) {
    if ( this.course.id !== id ) {
      this.course   = this.courses.filter( x => x.id === id )[0];
      this.courseId = this.course.id;
      this.changeCourseEndSource.next();
     }
  }

}
