import { Component, OnInit, Inject } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { Router } from '@angular/router';
import { Location } from '@angular/common';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

//Model
import { GeneralConferences } from '../../models/videoConference/video-conference-model';
import { CourseTeacher } from '../../models/courses/courseTeacher.model';
import { Studen } from '../../models/courses/student.model';
import { User } from '../../models/security/user.model';

// SERVICES
import { GeneralConferencesService, StorageService, StudenService } from '../../service/service.index';


import { Appsettings } from '../../configuration/appsettings';
import { UserUtilities } from '../../shared/user.utilities';

import swal from 'sweetalert2';
import { getHours } from 'date-fns';
import { MOMENT } from 'angular-calendar';
import { Course } from '../../models/catalogs/course.model';


@Component({
  selector: 'app-dialog-video-conference-form',
  templateUrl: './dialog-video-conference-form.component.html',
  styleUrls: ['./dialog-video-conference-form.component.scss']
})
export class DialogVideoConferenceFormComponent implements OnInit {
  //Modal
  isChangeData: boolean = false;

  //Loader
  working: boolean = false;

  //Form
  frmVideoConference: FormGroup;
  videoConferencia: GeneralConferences;
  showForm: boolean;
  editvideoConferencia: any = undefined;
  isSALI: boolean = true;
  courses: CourseTeacher[] = [];
  groups: any[] = [];
  students: any[] = [];
  codeRoomConfrence: number;
  URL: string;
  generalConferences: GeneralConferences[] = [];
  finalDateTo: Date;
  finalDateTFrom: Date;
  finalDateGet: Date;
  arrayStudentds: any[] = [];
  arrayExternalGuests: any[] = [];
  arrayUsersGeneral: any[] = [];
  dataDialog: any[] = [];
  showFormDetail: boolean;
  ynAdministraador: boolean;
  ynSaliUser: boolean;

  // listStatus = [{ status: 'Creada', id: '0'}, { status: 'Aceptada', id: '1'}, { status: 'Rechazada', id: '2'}, { status: 'Terminada', id: '3'}];
  // statusValue = 0;



  constructor(public dialogRef: MatDialogRef<DialogVideoConferenceFormComponent>,
    private _storageSrv: StorageService,
    private _srvStuden: StudenService,
    private _srvVideoConference: GeneralConferencesService,
    public router: Router,
    public location: Location,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.dataDialog = data;
    this.showFormDetail = (this.data.ynShowDetails) ? true : false;
    this.codeRoomConfrence = Math.floor(Math.random() * 10000000000) + 1;
    this.URL = window.location.origin + "/#/video/video-conference/" + this.codeRoomConfrence;
    this.getCourses();
    this.getAllusers();
    this.showForm = false;
    let statusInicial = 1;

    this.frmVideoConference = new FormGroup({
      'id': new FormControl(data['id']),
      'title': new FormControl(data['title'], [Validators.required]),
      'information': new FormControl(data['information'], [Validators.required]),
      'dateTimeVideoConference': new FormControl(data['dateTimeVideoConference'], [Validators.required]),
      'duration': new FormControl(data['duration'], [Validators.required, Validators.min(0), Validators.max(300)]),
      'guests': new FormControl(data['guests']),
      'externalGuest': new FormControl(data['guestMail'] + data['externalGuest']),
      'status': new FormControl(0),
      'courseId': new FormControl(data['courseId']),
      'groups': new FormControl(data['groups']),
      'commentary': new FormControl(data['commentary']),
      'RoomVideoConference': new FormControl(data['RoomVideoConference']),
      'initTime': new FormControl('', [Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)]),
    });

    if (data.id > 0) {
      let fecha = new Date(data.dateTimeVideoConference);
      let luserAgent = window.navigator.userAgent.toLowerCase();
      if (luserAgent.indexOf('chrome') > 0)
        this.frmVideoConference.controls['initTime'].setValue(fecha.getHours() + ':' + fecha.toTimeString().substring(3, 5));
      else if (luserAgent.indexOf('safari') > 0)
        this.frmVideoConference.controls['initTime'].setValue(fecha.getUTCHours() + ':' + fecha.toTimeString().substring(3, 5));
      else
        this.frmVideoConference.controls['initTime'].setValue(fecha.getHours() + ':' + fecha.toTimeString().substring(3, 5));
      this.URL = data.RoomVideoConference;
      if (data.guestMail === "") {
        this.frmVideoConference.controls['externalGuest'].setValue(data.emailsExternal);
      }
      else if (data.emailsExternal === "") {
        this.frmVideoConference.controls['externalGuest'].setValue(data.guestMail);
      }
      else {
        this.frmVideoConference.controls['externalGuest'].setValue(data.guestMail + ',' + data.emailsExternal);
      }

    }
    else {
      this.frmVideoConference.controls['externalGuest'].setValue('');
    }

    if (this._storageSrv.rol === "Administrador") {
      console.log(this._storageSrv.rol);
      this.isSALI = false;
      this.frmVideoConference.controls['status'].setValue(this.data['statusId'].toString());
      this.frmVideoConference.controls['courseId'].setValue(0);
      this.frmVideoConference.controls['groups'].setValue(0);
      this.ynAdministraador = true
      this.frmVideoConference.controls['commentary'].setValidators(Validators.required);
      this.ynSaliUser = true;

      if (this.frmVideoConference.controls['status'].value > 1) {
        this.frmVideoConference.controls['status'].disable();
      } else if (this.frmVideoConference.controls['status'].value === 1) {

      }
      else {
        this.frmVideoConference.controls['status'].enable();
      }

    }
    else if (this._storageSrv.rol === "Profesor") {
      console.log(this._storageSrv.rol);
      this.frmVideoConference.controls['status'].setValue(statusInicial.toString());
      this.isSALI = true;
      this.ynAdministraador = false;
      this.ynSaliUser = false;
    }
    else {
      this.ynSaliUser = true;
      this.frmVideoConference.controls['courseId'].setValue(0);
      this.frmVideoConference.controls['status'].setValue(1);

      console.log(this._storageSrv.rol);
    }
    if (data.id > 0 && this._storageSrv.rol === "profesor") {
      this.frmVideoConference.controls['status'].setValue(this.data['statusId'].toString());
    }

  }
  ngOnInit() {


  }

  onCloseConfirm(value: boolean) {
    this.dialogRef.close(value);
  }

  private getUTCDate(date: Date) {
    if (date != null) {
      //if (window.navigator.userAgent.indexOf('safari') > 0)
      //  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
      //else
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }
    return null;
  }

  // Registrar una nueva video conferencia
  onSubmit() {
    const Conference = new GeneralConferences();
    Conference.id = this.frmVideoConference.controls['id'].value;
    Conference.title = this.frmVideoConference.controls['title'].value;
    Conference.information = this.frmVideoConference.controls['information'].value;
    Conference.dateTimeVideoConference = this.frmVideoConference.controls['dateTimeVideoConference'].value;
    Conference.duration = this.frmVideoConference.controls['duration'].value;
    Conference.guests = this.frmVideoConference.controls['guests'].value;
    Conference.externalGuest = this.frmVideoConference.controls['externalGuest'].value;
    Conference.commentary = this.frmVideoConference.controls['commentary'].value;
    Conference.groups = this.frmVideoConference.controls['groups'].value;
    Conference.course = this.frmVideoConference.controls['courseId'].value;
    Conference.statusId = this.frmVideoConference.controls['status'].value;
    Conference.RoomVideoConference = this.URL;

    let dateVideoConference = this.frmVideoConference.controls['dateTimeVideoConference'].value;
    let HoursVideoConference = this.frmVideoConference.controls['initTime'].value;
    let initEvent = UserUtilities.addHoursToDate(dateVideoConference, HoursVideoConference);


    let externalGuest = this.frmVideoConference.controls['externalGuest'].value;

    let externalGuestPush = externalGuest.split(',');

    for (let ans of externalGuestPush) {
      let trimAns = ans.trim();
      this.arrayExternalGuests.push({
        name: 'Invitado externo',
        email: trimAns
      });
    }

    const ConstConference = {
      id: Conference.id,
      title: Conference.title,
      information: Conference.information,
      dateTimeVideoConference: this.getUTCDate((initEvent)),
      duration: Conference.duration,
      Guests: this.arrayStudentds,
      ExternalGuest: this.arrayExternalGuests,
      commentary: Conference.commentary,
      StudentGroupId: Conference.groups,
      CourseId: Conference.course,
      StatusId: Conference.statusId,
      RoomVideoConference: Conference.RoomVideoConference,
    };


    if (ConstConference.id > 0) {
      this.updateData(ConstConference);
    } else {
      this.saveData(ConstConference);
    }

  }

  getAllusers() {
    this.working = true;
    this._srvVideoConference.getAllusers().subscribe(result => {
      if (result.success) {
        for (let item of result.data) {
          this.arrayUsersGeneral.push(item);
        }
      }
      this.working = false;
    });
  }

  // Obtiene todos los cursos
  getCourses() {
    this.working = true;
    this._srvVideoConference.getAllCourses().subscribe(result => {
      if (result.success) {
        for (let c of result.data) {
          let course = new CourseTeacher();
          course.id = c.id;
          course.description = c.description;
          course.groups = c.groups;
          course.name = c.name;

          this.courses.push(course);
        }
        this.courses = this.courses.filter(item => item.groups.length > 0);

        if (this.frmVideoConference.controls['id'].value > 0) {
          this.frmVideoConference.controls['courseId'].setValue(this.data.course);
          if (this.showFormDetail) {
            this.onChangeSection(this.data.course);
          }
        }
      }
      this.working = false;
    });
  }

  // Selección de un curso
  onChangeSection(courseId) {

    if (courseId == 0) {
      return;
    }

    this.groups = [];
    let groups = this.courses.filter(item => item.id === courseId)[0];
    for (let g of groups.groups) {
      this.groups.push({
        id: g.id,
        description: g.description
      });
    }

    if (this.frmVideoConference.controls['id'].value > 0) {
      this.frmVideoConference.controls['groups'].setValue(this.data.course);
      this.onChangeGroup(this.data.groups);
    }
  }

  onChangeGroup(groupId) {

    if (groupId == 0) {
      return;
    }

    this.students = [];
    this.working = true;
    this._srvStuden.getAll(groupId).subscribe(result => {
      if (result.success) {
        for (let studen of result.data) {
          let student = new Studen();

          student.id = studen.userId;
          student.fullName = studen.userName;
          student.email = studen.userMail;
          student.statusStudent = studen.studentStatusDescription;
          student.checkDate = studen.checkDate;
          student.studentStatusId = studen.studentStatusId;

          this.students.push(student);

        }
        this.students = this.students.filter(x => x.studentStatusId === 2);
      }
      this.frmVideoConference.controls['guests'].setValue(this.data.groups);

      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  onChangeStudents(event) {

    this.arrayStudentds = [];

    for (let i = 0; i < this.students.length; i++) {
      for (let ans of this.students) {
        if (ans.id === event[i]) {

          this.arrayStudentds.push(
            {
              name: ans.fullName,
              email: ans.email
            });
        }
      }
    }
  }

  onChangeUsers(event) {
    this.arrayStudentds = [];

    for (let i = 0; i < this.arrayUsersGeneral.length; i++) {
      for (let ans of this.arrayUsersGeneral) {
        if (ans.id === event[i]) {
          this.arrayStudentds.push(
            {
              name: ans.fullName,
              email: ans.email
            });
        }
      }
    }
    console.log(this.arrayStudentds);
  }
  // Guardar datos de un nuevo nodo
  saveData(videoConference: any): void {
    this.working = true;
    this._srvVideoConference.save(videoConference).subscribe(res => {

      if (res.success) {

        this.onCloseConfirm(true);

        this.resetForm();
        swal(Appsettings.APP_NAME, res.message, 'success');

      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Inicializar los datos del formulario
  resetForm() {
    this.showForm = false;
    this.frmVideoConference.reset();
    // this.frmVideoConference.updateValueAndValidity();  
  }

  // Actualizar los datos de la estructura al servicio
  updateData(nodeData: any): void {
    this.working = true;
    this._srvVideoConference.update(nodeData).subscribe(res => {

      if (res.success) {

        this.onCloseConfirm(true);

        this.resetForm();
        swal(Appsettings.APP_NAME, res.message, 'success');

      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  onChangeDuration(e) {
    // this.finalDateTFrom = new Date(this.frmVideoConference.controls['dateTimeVideoConference'].value);
    // this.finalDateTo = new Date(this.frmVideoConference.controls['dateTimeVideoConference'].value);
    // this.finalDateTo.setMinutes(this.frmVideoConference.controls['duration'].value);
    // console.log(this.finalDateTo);
    this.getVideoConference()

    this.finalDateTFrom = new Date(this.frmVideoConference.controls['dateTimeVideoConference'].value);
    this.finalDateTo = UserUtilities.sumMinutes(new Date(this.frmVideoConference.controls['dateTimeVideoConference'].value), this.frmVideoConference.controls['duration'].value);
  }
  // Obtiene los datos del servicio
  getVideoConference(): void {
    // this.working = true;
    this._srvVideoConference.getAll().subscribe(
      res => {
        console.log(res);
        this.generalConferences = [];
        for (let d of res.data) {
          let conference = new GeneralConferences();
          conference.id = d.id;
          conference.title = d.title;
          conference.information = d.information;
          conference.dateTimeVideoConference = new Date(d.dateTimeVideoConference);
          conference.duration = d.duration;
          conference.updateDate = new Date(d.updateDate);
          conference.registerDate = new Date(d.registerDate);;
          conference.guests = d.invited;
          conference.commentary = d.comentary;
          conference.statusId = d.statusId;
          conference.externalGuest = d.externalGuest;
          conference.course = d.courseId;
          conference.RoomVideoConference = d.RoomVideoConference;
          conference.emailsExternal = d.emailsExternal;
          conference.guestMail = d.guestMail;

          this.generalConferences.push(conference);

          let testing = UserUtilities.sumMinutes(new Date(conference.dateTimeVideoConference), conference.duration);
        }


        // this.finalDateGet = UserUtilities.sumMinutes(new Date(this.generalConferences.dateTimeVideoConference), this.generalConferences.duration);
        // console.log(this.finalDateGet);
        this.generalConferences = this.generalConferences.filter(x => x.statusId === 2);


        this.generalConferences = this.generalConferences.filter(x => x.dateTimeVideoConference > this.finalDateTo);
        // this.generalConferences = this.generalConferences.filter(x => x.dateTimeVideoConference > this.finalDateTFrom);



        if (res.data) {
        }
        this.working = false;
      },
      err => {
        this.working = false;
      }
    );
  }

}
