export class GeneralUtilies {

  /// Obtener una imagen en un arreglo de bytes
  public static toDataURL(url, callback) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function() {

      let reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };

      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
}
