import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

// Modelos
import { Role } from '../../models/security/role.model';

// Servicios
import { RoleService } from '../../service/service.index';
import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';

@Component({
  selector: 'app-dialog-role',
  templateUrl: './dialog-role.component.html',
})
export class DialogRoleComponent implements OnInit {

  public frmRole: FormGroup;
  record: Role = new Role(0, '', '', false);

  working: boolean;
  isUpdateData: boolean;
  roles: Role[] = [];

  // Constructor del componente
  constructor(public dialogRef: MatDialogRef<DialogRoleComponent>,
              public _svrRole: RoleService,
              @Inject(MAT_DIALOG_DATA) public data: Role,
              ) {

            this.working = false;
            this.isUpdateData = false;
            this.frmRole = new FormGroup({
              'id' : new FormControl(data.id, [ Validators.required]),
              'name' : new FormControl(data.name, [ Validators.required] ),
              'description' : new FormControl(data.description),
            });
  }

  // Evento de componente
  ngOnInit() {
  }

  // Evento para cerrar la ventana modal
  onCloseConfirm(event: boolean) {
    this.dialogRef.close( event );
  }

  // Evento que se ejecuta para actualizar o agregar un nuevo registo
  onSubmit() {
      this.working = true;
      let record = new Role(0, '', '', false);
      record.id = this.frmRole.controls['id'].value;
      record.name = this.frmRole.controls['name'].value;
      record.description = this.frmRole.controls['description'].value;

      if ( record.id !== 0 ) {
        this.updateRole( record );
      } else {
        record.active = true;
        this.saveRole( record );

      }
  }

  // Crea un nuevo rol
  saveRole( role: Role ): void {
    this._svrRole.postRole( role ).subscribe(res  => {
      if ( res.success ) {
        swal(Appsettings.APP_NAME, res.message, 'success');
        this.onCloseConfirm( true );
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }

  // Actualiza un rol
  updateRole( role: Role ): void {
    this._svrRole.putRole( role ).subscribe(res  => {
      if ( res.success ) {
        swal(Appsettings.APP_NAME, res.message, 'success');
        this.onCloseConfirm( true );
      } else {
        swal(Appsettings.APP_NAME, res.message, 'error');
      }
      this.working = false;
    }, err => {
      this.working = false;
    });
  }


}
