import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-exam-schedule',
  templateUrl: './dialog-exam-schedule.component.html',
  styleUrls: ['./dialog-exam-schedule.component.scss']
})
export class DialogExamScheduleComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<DialogExamScheduleComponent>,
               @Inject(MAT_DIALOG_DATA) public data ) {}

  ngOnInit() {
  }

  // Evento para cerrar la ventana modal
  onCloseConfirm() {
    this.dialogRef.close(false);
  }

  closeModalConfirm( event ) {
    this.dialogRef.close(event[0]);
  }
}
