import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from '../../core/breadcrumb/breadcrumb.service';
import { StorageService } from '../../service/shared/storage.service';

declare function setHeight(): any;

@Component({
  selector: 'app-sali-layout',
  templateUrl: './sali-layout.component.html',
  styleUrls: ['./sali-layout.component.scss'],
  /*encapsulation: ViewEncapsulation.None */
})
export class SaliLayoutComponent implements OnInit, AfterViewInit {

  nameUser: string;
  breadcrum: any[];

  constructor( public _srvBreadcrumb: BreadcrumbService,
               private _srvStorage: StorageService) {

    let body: any = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-login');
    body.classList.remove('bg-landing');
  }

  ngOnInit() {
    this.nameUser = this._srvStorage.name;
  }

  ngAfterViewInit() {
  // JAVASCRIPT
   setHeight();

  }

  signOut() {
    this._srvStorage.logoutRedirect();
  }

}
