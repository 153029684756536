import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TreeviewItem, TreeviewConfig, DownlineTreeviewItem } from 'ngx-treeview';

// SERVICES
import { StructureService, PublishingProfileService, NodeService } from '../../service/service.index';

// MODELS
import { PublishingProfile } from '../../models/security/publishingProfile.model';

import swal from 'sweetalert2';
import { Appsettings } from '../../configuration/appsettings';

@Component({
  selector: 'app-dialog-publishing',
  templateUrl: './dialog-publishing.component.html',
  styleUrls: ['./dialog-publishing.component.scss']
})
export class DialogPublishingComponent implements OnInit {
  public frmProfile: FormGroup;
  items: TreeviewItem[] = [];
  item: TreeviewItem[];
  values: number[];
  nodos: any[] = [];
  currentRecord: PublishingProfile;
  record: PublishingProfile = new PublishingProfile(0, '', false, []);
  nodeSelected: number[];
  miArray: number[];

  working: boolean = false;

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400
});

  constructor( public _dialogRef: MatDialogRef<DialogPublishingComponent>,
               public _svrStructure: StructureService,
               private _ppService: PublishingProfileService,
               private _srvNode: NodeService,
               @Inject(MAT_DIALOG_DATA) public data: PublishingProfile ) {


                this.frmProfile = new FormGroup({
                  'id' : new FormControl(data.id),
                  'description' : new FormControl(data.description, [Validators.required]),
                  'active' : new FormControl(data.active)
                });
              }

  ngOnInit() {
    this.getNodes();
  }

  onCloseConfirm() {
    this._dialogRef.close(true);
  }

  onChange(items: any) {
    this.items2Array(items);
  }

  onSubmit() {
    this.record.id = this.frmProfile.value.id;
    this.record.active = this.frmProfile.value.active;
    this.record.description = this.frmProfile.value.description;
    this.record.nodes = this.miArray;

    this.working = true;
    if (this.record.id !== 0) {
        this._ppService.putPublishingProfile(this.record).subscribe(role => {
        swal( Appsettings.APP_NAME , 'Datos actualizados.' , 'success');
        this.working = false;
        this._dialogRef.close(true);
      }, err => {
        this.working = false;
      });

    } else {
      this._ppService.postPublishingProfile(this.record).subscribe(role => {
        if ( role.success ) {
          swal(Appsettings.APP_NAME, role.message , 'success');
          this._dialogRef.close(true);
        } else {
          swal(Appsettings.APP_NAME, role.message , 'error');
        }
        this.working = false;
      }, err => {
        this.working = false;
      });
    }

  }

  // Obtener la estructura para mostrar en la selección para el perfil
  // Si mandas cero se toma el identificador configurado de la estructura pro default
  getNodes() {
    this.working = true;
    this._srvNode.getStructureByPatent( 0 ).subscribe( res => {
      if ( res.data ) {
        for (const nodo of res.data) {

          const checkNode = obj => obj.id === nodo.id;
          let selected = this.data.nodes.some(checkNode);

            this.nodos.push(
              { text: nodo.name,
                value: nodo.id,
                collapsed: true,
                checked: selected,
                parentId: nodo.parentId
              }
            );
        }
        this.buildHierarchy(this.nodos);
        this.working = false;
      }
    });
  }

  // Crea el json con el formato de arbol
  buildHierarchy(arry: any): void {
    this.working = true;
    const roots = [], children = {};

    // find the top level nodes and hash the children based on parent
    for (let i = 0, len = arry.length; i < len; ++i) {


        const item = arry[i],
            p = item.parentId,
            target = !p ? roots : (children[p] || (children[p] = []));
        target.push({ value: item, text: item.text, collapsed: true, checked: item.checked });
    }

    // function to recursively build the tree
    let findChildren = function(parentId) {
        if (children[parentId.value.value]) {
            parentId.children = children[parentId.value.value];
            for (let i = 0, len = parentId.children.length; i < len; ++i) {
                findChildren(parentId.children[i]);
            }
        }
    };

    // enumerate through to handle the case where there are multiple roots
    for (let i = 0, len = roots.length; i < len; ++i) {
        findChildren(roots[i]);
    }
    // Elimana los [] extras en json
    let datas = JSON.stringify(roots);
    datas = datas.slice(1, -1);
    // Agregamos la nueva structura a items para crear el arbol
    this.items.push(new TreeviewItem(JSON.parse(datas)));
    this.working = false;
  }

  items2Array(items){

    this.miArray = [];
    for (let n of items){
      this.miArray.push(n.value);
    }
    for ( let p of items ) {
      let res = this.miArray.includes( p.parentId);

      if ( !res ) {
        this.miArray.push( p.parentId);
      }
    }

  }
}
