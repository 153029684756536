import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';

// Services
import { ExamScheduleService, HomeworksService } from '../../../service/service.index';

// MODELS
import { TaskSchedule } from '../../../models/courses/taskSchedule.model';
import { Course } from '../../../models/catalogs/course.model';

// COMPONENT SERVICES
import { BookNavService } from '../../../components/layouts/publication/book-nav/book-nav.service';
import { CourseNavService } from '../../../components/layouts/publication/course-nav/course-nav.service';

// DIALOGS
import { DialogUploadFileComponent } from '../../../dialogs/dialog-upload-file/dialog-upload-file.component';
import { DialogGetFileComponent } from '../../../dialogs/dialog-get-file/dialog-get-file.component';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-course-homeworks',
  templateUrl: './course-homeworks.component.html',
  styleUrls: ['./course-homeworks.component.scss']
})
export class CourseHomeworksComponent implements OnInit, OnDestroy {

  courseId: number;
  nodeId: number;
  course: Course;

  taskSchedules: TaskSchedule[] = [];
  private changeCourseEndRef: Subscription = null;

  constructor(  public _dialog: MatDialog,
                private _route: ActivatedRoute,
                private _router: Router,
                private _srvHomework: HomeworksService,
                public _bookNavService: BookNavService,
                public _courseNavService: CourseNavService ) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.courseId = Number(params['id']);
      this.nodeId = Number(params['nodeId']);
      this._bookNavService.setNode( this.nodeId );

      this._courseNavService.asyncGetStudentCourses( this.nodeId, this.courseId ).then( () => {
        this.course = this._courseNavService.course;
        this.getHomeWork();
      });

      this.changeCourseEndRef = this._courseNavService.ChangeCourseEnd$.subscribe( () => {
        this.getHomeWork();
      });

    });

  }

  ngOnDestroy() {
    this.changeCourseEndRef.unsubscribe();
  }

  // Obtiene todas la programacions de tarea del grupo
  getHomeWork( ) {

    if ( this.course == null ) {
      return;
    }

    this.taskSchedules = [];
    this._courseNavService.working = true;

    this._srvHomework.getAllByStudent( this._courseNavService.course.groupId ).subscribe( result => {
      if ( result.success ) {
        for (let h of result.data ) {

          let homework = new TaskSchedule();

          homework.id = h.id;
          homework.name = h.name;
          homework.Description = h.description;
          homework.ScheduledDate = h.scheduledDate;
          homework.FilesId = h.files;
          homework.score = h.score;
          homework.comment = h.comment;

          this.taskSchedules.push( homework );
        }
      }
      this._courseNavService.working = false;
    }, err => {
      this._courseNavService.working = false;
    });
  }

   // Muestra modal para cargar las tareas del alumno
   onShowUploadFile( taskId ) {
    const DIALOG_REF = this._dialog.open( DialogUploadFileComponent, {
      width: '800px',
      height: '600px',
      autoFocus: false,
      disableClose: true,
      data: { taskId: taskId }
    });

    DIALOG_REF.afterClosed().subscribe(response => {});
  }

   // Muestra modal con los archivos asociados a la tarea
   onShowFilesTask( task ) {
    const DIALOG_REF = this._dialog.open( DialogGetFileComponent, {
      width: '800px',
      height: '600px',
      autoFocus: false,
      disableClose: true,
      data: { task: task }
    });

    DIALOG_REF.afterClosed().subscribe(response => {});
  }

}
