import { TreeviewItem, TreeviewSelection, TreeviewI18nDefault } from 'ngx-treeview';

export class DropdownTreeviewSelectI18n extends TreeviewI18nDefault {
    private internalSelectedItem: TreeviewItem;

    set selectedItem(value: TreeviewItem) {
        if (value && value.children === undefined) {
            this.internalSelectedItem = value;
        }
    }

    get selectedItem(): TreeviewItem {
        return this.internalSelectedItem;
    }

   getText(selection: TreeviewSelection): string {

      let textNode = '';

      if ( this.internalSelectedItem ) {
        textNode = this.internalSelectedItem.text;

        if ( this.internalSelectedItem.text == 'All' ) {
          textNode = 'Seleccione un elemento';
        }
      } else {
        textNode = 'Estructura';
      }
      return textNode;
    }
}
